/* @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700');
@import url('../font/flaticon.css'); */

html, body {
    height: 100%;
    font-family: 'Poppins', sans-serif;
    background: #fff;
    color: #333f50;
    overflow-x: hidden;
}

body {
    background-size: cover;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a:hover {
    text-decoration: none;
}

.wrapper {
    width: 100%;
    height: 100vh;
}



.sidebar {
    width: 260px;
    height: auto;
    background-color: #253566;
    box-shadow: 5px 5px 25px rgba(0,0,0,0.1);
    float: left;
}

.logo {
    padding: 15px;
    text-align: center;
    border-radius: 50px;
    /*background-color: #fff;*/
    background-color: rgba(60,91,206,1);
    text-align: center;
    margin: 10px;
}

    .logo img {
        width: 90%;
    }


.navigation {
    width: 100%;
    float: left;
    margin-top: 15px;
}

    .navigation .mainmenu > li:first-child {
        border-top: none;
    }

    .navigation .mainmenu li {
        vertical-align: middle;
        border-top: 1px solid rgba(255,255,255,0.2);
        font-size: 15px;
        width: 100%;
        float: left;
        margin-bottom: 1px;
    }

    .navigation .mainmenu > li > ul {
        background-color: transparent;
    }

    .navigation .mainmenu li > a {
        color: #fff;
        display: block;
        padding: 8px 10px;
    }

    .navigation .mainmenu > li i {
        display: inline-block;
        padding-right: 5px;
        vertical-align: middle;
        color: #fff;
    }

        .navigation .mainmenu > li i.fa {
            margin-left: 26px;
        }

    .navigation .mainmenu > li .material-icons {
        float: right;
        padding-top: 9px;
        font-size: 18px;
    }

    .navigation .mainmenu > li i:before {
        font-size: 26px;
    }

    .navigation .mainmenu > li.active {
        border-top: none;
    }

        .navigation .mainmenu > li.active > a, .navigation .mainmenu > li:hover > a {
            background: rgba(60,91,206,1);
            background: -moz-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(60,91,206,1)), color-stop(100%, rgba(22,132,222,1)));
            background: -webkit-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            background: -o-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            background: -ms-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            background: linear-gradient(to right, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c5bce', endColorstr='#1684de', GradientType=1 );
            width: 90%;
            border-radius: 0 50px 50px 0;
        }

        .navigation .mainmenu > li.active > a, .navigation .mainmenu > li:hover > a {
            color: #fff;
        }

            .navigation .mainmenu > li.active > a i, .navigation .mainmenu > li:hover > a > i {
                color: #fff;
            }

.submenu {
    display: none;
    background-color: #fff;
    margin: 0 15px 8px 40px;
    border-left: 1px solid #efeef7;
    float: left;
    padding: 7px 0;
}

    .submenu li {
        font-size: 13px;
    }

        .submenu li a {
            display: block;
            padding: 6px 18px;
            position: relative;
            transition: 0.5s ease all;
            color: #333f50;
        }

            .submenu li a:before {
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                width: 15px;
                height: 1px;
                background-color: #efeef7;
            }

            .submenu li a:hover {
                color: #236ddc;
                transition: 0.5s ease all;
            }

                .submenu li a:hover:before {
                    background-color: #236ddc;
                    transition: 0.5s ease all;
                }

.mcontentarea {
    height: 100vh;
    margin-left: 260px;
    padding: 0 3%;
    position: relative;
}

.contenthead {
    width: 100%;
    float: left;
    margin: 10px 0;
}

    .contenthead .btn-white {
        border-radius: 25px;
        background-color: #fff;
        font-size: 15px;
        position: relative;
        box-shadow: 0 5px 10px rgba(0,0,0,0.1);
        padding-right: 50px;
        padding-left: 50px;
    }

        .contenthead .btn-white i {
            color: rgba(60,91,206,1);
            color: -moz-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            color: -webkit-gradient(left top, right top, color-stop(0%, rgba(60,91,206,1)), color-stop(100%, rgba(22,132,222,1)));
            color: -webkit-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            color: -o-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            color: -ms-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            color: linear-gradient(to right, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c5bce', endColorstr='#1684de', GradientType=1 );
            position: absolute;
            right: 0;
            top: -8px;
        }

            .contenthead .btn-white i:before {
                font-size: 38px;
            }

    .contenthead .dropdown-toggle:after {
        position: absolute;
        top: 15px;
        left: 10px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #ffffff;
        border-left: 6px solid transparent;
        content: '';
        color: #a0a7c0;
    }

    .contenthead .dropdown-menu {
        margin: 0;
        border: none;
        margin-top: 3px;
        background: #fff;
        border-radius: 0;
        box-shadow: 5px 3px 10px rgba(0,0,0,0.1);
        padding: 0;
    }

        .contenthead .dropdown-menu a {
            font-size: 13px;
            border-bottom: 1px solid #efeef7;
            padding: 10px 15px !important;
            display: block;
            ;
        }

            .contenthead .dropdown-menu a:last-child {
                border-bottom: none;
            }

.noborder {
    border-top: none !important;
}

.contentarea {
    width: 100%;
    float: left;
}

.heading {
    width: 100%;
    float: left;
    margin-top: 5px;
}

    .heading h1 {
        font-size: 30px;
        display: block;
    }

        .heading h1 span {
            display: inline-block;
            position: relative;
        }

        .heading h1 b {
            display: block;
            font-size: 18px;
            color: #236ddc;
            font-weight: 400;
        }

        .heading h1 span:after {
            position: absolute;
            right: -400px;
            top: 70%;
            height: 1px;
            background-color: #0facf2;
            content: '';
            width: 400px;
        }

        .heading h1 span:before {
            position: absolute;
            right: -400px;
            top: 60%;
            height: 1px;
            background-color: #0facf2;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }


.heading2 {
    width: 100%;
    float: left;
    margin-top: 5px;
}

    .heading2 h1 {
        font-size: 32px;
        width: calc(100% - 100px);
        overflow: hidden;
        float: left;
    }

        .heading2 h1 span {
            display: inline-block;
            position: relative;
            padding-right: 15px;
        }

        .heading2 h1 b {
            display: block;
            font-size: 18px;
            color: #236ddc;
            font-weight: 400;
        }

        .heading2 h1 span:after {
            position: absolute;
            right: -1000px;
            top: 50%;
            height: 1px;
            background-color: #0facf2;
            content: '';
            width: 1000px;
        }

    .heading2 ul {
        float: right;
    }

        .heading2 ul li {
            display: inline-block;
            text-align: center;
        }

            .heading2 ul li a {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                background-color: #7f92b1;
                color: #fff;
                display: block;
                padding-top: 5px;
            }

label {
    margin-bottom: 5px;
}

.topna {
    float: right;
}

.breadcum p {
    font-size: 13px;
    padding: 5px 0;
}

    .breadcum p a {
        padding: 0 5px;
    }

        .breadcum p a:first-child {
            padding-left: 0;
        }

.dashbox {
    width: 100%;
    float: left;
    margin: 3% 0;
    padding: 0;
}

    .dashbox li {
        width: 30%;
        background-color: #fff;
        border-radius: 90px;
        margin: 2% 1%;
        padding: 10px;
        float: left;
        min-height: 100px;
        position: relative;
        box-shadow: 5px 5px 20px rgba(0,0,0,0.2);
    }

        .dashbox li h3 {
            font-size: 20px;
            display: table-cell;
            vertical-align: middle;
            height: 60px;
        }

            .dashbox li h3 span {
                display: block;
                font-size: 36px;
            }

        .dashbox li img, .dashbox li i {
            display: inline-block;
            margin: 5px 14px 5px 10px;
            float: left;
            font-size: 64px;
            color: #3277E1;
        }

        .dashbox li a {
            color: #303f76;
            display: block;
            padding-top: 5px;
        }

.ktable {
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
    margin-top: 10px;
    width: 100%;
    float: left;
}

    .ktable thead {
        background-color: #4fa3ef;
    }

        .ktable thead th {
            border: none;
            color: #fff;
            font-size: 13px;
            /*font-weight: 400;*/
            vertical-align: middle;
        }

    .ktable tbody tr:nth-of-type(odd) {
        background-color: #fcfcfd;
    }

    .ktable tbody tr:nth-of-type(even) {
        background-color: #eee;
    }

    .ktable tbody td {
        font-size: 13px;
        color: #000;
    }

.tbicon a {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #a0a7c0;
    text-align: center;
    color: #a0a7c0;
    display: inline-block;
    padding-top: 3px;
}

    .tbicon a i {
        font-size: 19px;
    }

.topna li {
    display: inline-block;
}

    .topna li:first-child a {
        padding: 5px 15px;
    }

        .topna li:first-child a b {
            font-size: 16px;
            color: #236ddc;
            font-weight: 400;
        }

    .topna li a > img {
        width: 26px;
        margin-right: 10px;
    }


.adduserbox {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(223,222,239,0.7);
    left: 0;
    top: 0;
}

.formarea {
    background-color: #333f50;
    width: 40%;
    max-width: 500px;
    height: 100vh;
    float: right;
    padding: 20px;
    min-width: 300px;
}

.darkheading {
    width: 100%;
    float: left;
    margin-top: 5px;
}

    .darkheading h2 {
        font-size: 32px;
        overflow: hidden;
        display: block;
        color: #fff;
    }

        .darkheading h2 span {
            display: inline-block;
            position: relative;
            padding-right: 20px;
        }

            .darkheading h2 span:after {
                position: absolute;
                right: -1000px;
                top: 50%;
                height: 1px;
                background-color: #7f92b1;
                content: '';
                width: 1000px;
            }

.form {
    width: 100%;
    float: left;
    margin: 20px 0;
}

    .form .form-control, .form .bootstrap-select .dropdown-toggle {
        border-radius: 25px;
        background-color: #dfdeef;
        font-size: 13px;
        color: #495057;
    }

    .form .btn {
        border-radius: 25px;
        font-size: 14px;
        min-width: 100px;
    }

.bootstrap-select .bs-ok-default:after {
    border-width: 0 .10em .10em 0;
    border-color: #1a80dd;
}


.btn-primary {
    background: rgba(60,91,206,1);
    background: -moz-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(60,91,206,1)), color-stop(100%, rgba(22,132,222,1)));
    background: -webkit-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
    background: -o-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
    background: -ms-linear-gradient(left, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
    background: linear-gradient(to right, rgba(60,91,206,1) 0%, rgba(22,132,222,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3c5bce', endColorstr='#1684de', GradientType=1 );
}

.btn-cancel {
    background: rgba(193,191,234,1);
    background: -moz-linear-gradient(left, rgba(193,191,234,1) 0%, rgba(206,199,236,1) 49%, rgba(235,233,249,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(193,191,234,1)), color-stop(49%, rgba(206,199,236,1)), color-stop(100%, rgba(235,233,249,1)));
    background: -webkit-linear-gradient(left, rgba(193,191,234,1) 0%, rgba(206,199,236,1) 49%, rgba(235,233,249,1) 100%);
    background: -o-linear-gradient(left, rgba(193,191,234,1) 0%, rgba(206,199,236,1) 49%, rgba(235,233,249,1) 100%);
    background: -ms-linear-gradient(left, rgba(193,191,234,1) 0%, rgba(206,199,236,1) 49%, rgba(235,233,249,1) 100%);
    background: linear-gradient(to right, rgba(193,191,234,1) 0%, rgba(206,199,236,1) 49%, rgba(235,233,249,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c1bfea', endColorstr='#ebe9f9', GradientType=1 );
}





.slogo, .contenthead .dropdown-menu p {
    display: none;
}

.menubtn {
    display: none;
}


.loading {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    display: none;
    z-index: 99999999;
}

    .loading img, .loading i {
        margin-top: 150px;
        font-size: 100px;
    }

.innercontent {
    clear: both;
    margin: 10px auto;
    padding-bottom: 50px;
}

.heading2 ul li.paginate_button a {
    background-color: #2178d9;
    color: #fff;
}

.heading2 ul li.paginate_button.active a {
    background-color: #fff;
    color: #2178d9;
}

.heading2 ul li.paginate_button.previous a, .heading2 ul li.paginate_button.next a {
    width: auto;
    padding: 6px 10px;
    border-radius: 25px;
}

.failed {
    background-color: #dd4b39 !important;
}

    .failed td {
        color: #fff !important;
    }

.wbtn {
    border-radius: 25px;
    font-size: 15px;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
}

@media screen and (max-width: 1024px) {
    .wbtn {
        margin-top: -20px;
    }

    .slogo {
        float: left;
        width: 90px;
        margin: 5px;
    }

        .slogo img {
            max-width: 100%;
        }

    .logo {
        display: none;
    }

    .slogo {
        display: block;
        float: left;
    }

    .menubtn {
        display: inline-block;
        margin-top: -4px;
        color: rgba(60,91,206,1);
        max-height: 38px;
    }

    .sidebar {
        position: fixed;
        left: -220px;
        top: 0;
        z-index: 99;
        -webkit-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: .3s ease all;
        transition: .3s ease all;
        width: 220px;
    }

    .mob .sidebar {
        -webkit-transform: translateX(220px);
        transform: translateX(220px);
    }

    .bg {
        position: absolute;
        background-color: rgba(0,0,0,0.2);
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        z-index: 10;
    }

    .mcontentarea {
        margin: 0;
        width: 100%;
    }

    .contenthead {
        width: 100%;
        padding: 10px;
        float: left;
        background-color: #fff;
        box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        margin: 0;
    }

    .menubtn i:before {
        font-size: 36px;
    }

    .contenthead .btn-white {
        height: 40px;
        padding-right: 25px;
        padding-left: 15px;
        float: right;
        margin-left: 15px;
        box-shadow: none;
    }

        .contenthead .btn-white span {
            display: none;
        }

    .contenthead .dropdown-menu p {
        display: block;
        padding: 5px 10px;
        background-color: #1882dd;
        color: #fff;
        border-radius: 25px;
        margin: 5px;
    }

    .contenthead .dropdown-menu.show {
        left: -100% !important;
    }

    .contenthead .dropdown-toggle:after {
        display: none;
    }

    .navigation .mainmenu > li i:before {
        margin-left: 0 !important;
        font-size: 25px;
    }

    .navigation .mainmenu > li {
        font-size: 14px;
    }

    .contentarea {
        margin-top: 80px;
    }

    .dashbox li {
        width: 46%;
        margin: 4% 2%;
    }

        .dashbox li img {
            max-width: 65px;
            top: 15px;
            left: 20px;
        }

        .dashbox li:nth-child(2) img {
            max-width: 50px;
        }

    .mobhide {
        display: none;
    }

    .formarea {
        margin-top: 60px;
    }

    .hide-xs {
        display: none !important;
    }
}



@media screen and (max-width: 640px) {
    .wbtn {
        font-size: 11px !important;
        padding: 9px 10px !important;
    }

    .topna li a > img {
        width: 20px;
    }

    [class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
        margin-left: 0;
    }

    .dashbox li {
        width: 92%;
    }

    .heading h1 span:after {
        display: none;
    }

    .wrapper {
        background: none;
    }

    body {
        background-size: cover;
    }

    .contenthead .btn-white {
        margin-left: 0;
    }
}

#pnDDl_Layout span {
    position: relative;
    top: -10px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: .9;
}

#pnDDl_Layout > ul {
    position: absolute;
    right: 0;
    left: auto;
    width: 280px;
    padding: 0 0 0 0;
    margin: 0;
    top: 100%;
    z-index: 1000;
}

#pnDDl_Layout .bg-blue {
    background-color: #0073b7 !important;
    color: #fff;
    padding: 4px 10px;
}

#pnDDl_Layout > ul > li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid;
}