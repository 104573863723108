body {
    background-color: #f3f3f3;
    font-size: .875em;
    overflow-x: hidden;
    font-family: open sans, sans-serif
}

ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0
}

* a {
    font-size: 14px
}

* a:hover,
* a:focus {
    text-decoration: none
}

p {
    font-size: 13px
}

.media-left {
    padding-right: 20px
}

.main-body .page-wrapper {
    padding: 1.8rem;
    transition: all ease-in .3s
}

.main-body .page-wrapper .page-header {
    margin-bottom: 20px
}

.main-body .page-wrapper .page-header-title {
    display: inline-block
}

.main-body .page-wrapper .page-header-title h4 {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 600;
    color: #4a6076;
    font-size: 20px
}

.main-body .page-wrapper .page-header-title span {
    font-size: 13px;
    color: #9f9f9f;
    display: block;
    margin-top: 5px
}

.main-body .page-wrapper .page-header-breadcrumb {
    float: right
}

.card {
    border-radius: 2px;
    border-top: 4px solid #8cddcd;
    box-shadow: 0 2px 1px rgba(0, 0, 0, .05);
    border-left: none;
    border-right: none;
    border-bottom: none;
    margin-bottom: 30px
}

.card .card-footer {
    background-color: #fff
}

.card .card-header {
    background-color: transparent;
    padding: 15px 20px
}

.card .card-header .card-header-left {
    display: inline-block
}

.card .card-header .card-header-right {
    border-radius: 0 0 0 5px;
    right: 0;
    top: 0;
    background: #f6f7f7;
    display: inline-block;
    float: right;
    padding: 7px 0;
    position: absolute
}

.card .card-header .card-header-right i {
    margin: 0 20px;
    cursor: pointer
}

.card .card-header span {
    color: #9f9f9f;
    display: block;
    font-size: 13px;
    margin-top: 5px
}

.card h5 {
    margin-bottom: 0;
    color: #757575;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px
}

.card .card-block table tr {
    padding-bottom: 20px
}

.card .card-block .sub-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px
}

.card .card-block code {
    background-color: #eee
}

.card .card-block .dropdown-menu {
    top: 38px
}

.card .card-block p {
    line-height: 25px
}

.card .card-block a.dropdown-item {
    margin-bottom: 0;
    font-size: 14px;
    transition: .25s
}

.card .card-block a.dropdown-item .active,
.card .card-block a.dropdown-item:active {
    background-color: #1abc9c
}

.card .card-block.remove-label i {
    margin: 0;
    padding: 0
}

.card .card-block.button-list span.badge {
    margin-left: 5px
}

.card .card-block .dropdown-menu {
    background-color: #fff;
    padding: 0
}

.card .card-block .dropdown-menu .dropdown-divider {
    background-color: #ddd;
    margin: 3px 0
}

.card .card-block .dropdown-menu>a {
    padding: 10px 16px;
    line-height: 1.429
}

.card .card-block .dropdown-menu>li>a:hover,
.card .card-block .dropdown-menu>li>a:focus {
    background-color: rgba(202, 206, 209, .5)
}

.card .card-block .dropdown-menu>li:first-child>a:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.card .card-block .badge-box {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 12px 0
}

.card .inner-card-block {
    border: 1px solid #ddd
}

.card-header-text {
    margin-bottom: 0;
    font-size: 1rem;
    color: rgba(51, 51, 51, .85);
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle
}

.icofont-rounded-down {
    -webkit-transition: all ease-in .3s;
    display: inline-block;
    transition: all ease-in .3s
}

.icon-up {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.rotate-refresh {
    -webkit-animation: mymove .8s infinite linear;
    animation: mymove .8s infinite linear;
    display: inline-block
}

@-webkit-keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.breadcrumb-title a {
    font-size: 14px;
    color: #4a6076
}

.breadcrumb-title li:last-child a {
    color: #7e7e7e
}

.sub-title {
    border-bottom: 1px solid rgba(204, 204, 204, .35);
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    color: #2c3e50
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
    margin: 20px 0;
    margin-top: 0
}

.typography small {
    margin-left: 10px;
    font-weight: 600;
    color: #777
}

.card-block.list-tag ul li {
    display: block;
    float: none;
    margin-bottom: 5px
}

.card-block.list-tag ol li {
    margin-bottom: 5px
}

.inline-order-list {
    margin-top: 50px
}

.inline-order-list h4,
.inline-order-list p {
    margin-bottom: 0
}

.card-block ul li.list-inline-item {
    display: inline-block;
    float: left
}

.modal {
    z-index: 99999999999
}

.bd-example .modal {
    display: block;
    position: inherit;
    background-color: #2c3e50;
    margin-bottom: 20px
}

.card .overflow-container h5 {
    margin-bottom: 5px
}

.button-page .card-block a.nav-link {
    margin-bottom: 0
}

.sweet-alert button.confirm {
    background-color: #1abc9c !important
}

.sweet-alert .sa-input-error {
    top: 23px
}

.location-selector {
    width: 100%;
    height: 250px;
    background-color: #fff;
    border: 2px dashed #e5e9ec;
    position: relative;
    margin-bottom: 20px
}

.location-selector .bit {
    background-color: #e5e9ec;
    cursor: pointer;
    position: absolute
}

.location-selector .bit:hover {
    background-color: #ddd
}

.location-selector .bit.top,
.location-selector .bit.bottom {
    height: 25%;
    width: 40%;
    margin: 0 30%
}

.location-selector .bit.top {
    top: 0
}

.location-selector .bit.bottom {
    bottom: 0
}

.location-selector .bit.right,
.location-selector .bit.left {
    height: 20%;
    width: 20%;
    margin-left: 0;
    margin-right: 0
}

.location-selector .bit.right {
    right: 0
}

.location-selector .bit.left {
    left: 0
}

button.close {
    margin-top: 7px;
    margin-bottom: 0
}

.mytooltip {
    display: inline;
    position: relative;
    z-index: 9999
}

.mytooltip .tooltip-item {
    background: rgba(0, 0, 0, .1);
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    padding: 0 10px
}

.mytooltip .tooltip-content {
    position: absolute;
    z-index: 9999;
    width: 360px;
    left: 50%;
    margin: 0 0 20px -180px;
    bottom: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 30px;
    box-shadow: -5px -5px 15px rgba(48, 54, 61, .2);
    background: #2b2b2b;
    opacity: 0;
    cursor: default;
    pointer-events: none
}

.mytooltip .tooltip-content::after {
    content: '';
    top: 100%;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #2a3035 transparent transparent;
    border-width: 10px;
    margin-left: -10px
}

.mytooltip .tooltip-content img {
    position: relative;
    height: 140px;
    display: block;
    float: left;
    margin-right: 1em
}

.mytooltip .tooltip-item::after {
    content: '';
    position: absolute;
    width: 360px;
    height: 20px;
    bottom: 100%;
    left: 50%;
    pointer-events: none;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
}

.mytooltip:hover .tooltip-item::after {
    pointer-events: auto
}

.mytooltip:hover .tooltip-content {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0)
}

.mytooltip:hover .tooltip-content2 {
    opacity: 1;
    font-size: 18px
}

.mytooltip:hover .tooltip-content2 i {
    opacity: 1;
    font-size: 18px
}

.mytooltip:hover .tooltip-content2 {
    opacity: 1;
    font-size: 18px;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
}

.mytooltip:hover .tooltip-content2 i {
    opacity: 1;
    font-size: 18px;
    pointer-events: auto;
    -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
}

.mytooltip:hover .tooltip-item2 {
    color: #fff;
    -webkit-transform: translate3d(0, -0.9em, 0);
    transform: translate3d(0, -0.9em, 0)
}

.mytooltip:hover .tooltip-text3 {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
}

.mytooltip:hover .tooltip-content3 {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
}

.mytooltip:hover .tooltip-content4 {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mytooltip:hover .tooltip-text2 {
    pointer-events: auto;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mytooltip:hover .tooltip-inner2 {
    -webkit-transition-delay: .3s;
    transition-delay: .3s;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mytooltip:hover .tooltip-content5 {
    opacity: 1;
    pointer-events: auto;
    -webkit-transition-delay: 0s;
    transition-delay: 0s
}

.mytooltip .tooltip-text {
    font-size: 14px;
    line-height: 24px;
    display: block;
    padding: 1.31em 1.21em 1.21em 0;
    color: #fff
}

.mytooltip .tooltip-item2 {
    color: #1abc9c;
    cursor: pointer;
    z-index: 100;
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    -webkit-transition: background-color .3s, color .3s, -webkit-transform .3s;
    transition: background-color .3s, color .3s, transform .3s
}

.tooltip.tooltip-effect-2:hover .tooltip-content {
    -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(1000px) rotate3d(1, 0, 0, 0deg)
}

.tooltip-effect-5 .tooltip-text {
    padding: 1.4em
}

.tooltip-effect-1 .tooltip-content {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    color: #fff
}

.tooltip-effect-2 .tooltip-content {
    -webkit-transform-origin: 50% calc(110%);
    transform-origin: 50% calc(110%);
    -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
    transform: perspective(1000px) rotate3d(1, 0, 0, 45deg);
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, transform .2s
}

.tooltip-effect-3 .tooltip-content {
    -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
    transform: translate3d(0, 10px, 0) rotate3d(1, 1, 0, 25deg);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-4 .tooltip-content {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale3d(.7, .3, 1);
    transform: scale3d(.7, .3, 1);
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, transform .2s
}

.tooltip-effect-5 .tooltip-content {
    width: 180px;
    margin-left: -90px;
    -webkit-transform-origin: 50% calc(106%);
    transform-origin: 50% calc(106%);
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
    -webkit-transition: opacity .2s, -webkit-transform .2s;
    transition: opacity .2s, transform .2s;
    -webkit-transition-timing-function: ease, cubic-bezier(.17, .67, .4, 1.39);
    transition-timing-function: ease, cubic-bezier(.17, .67, .4, 1.39)
}

.tooltip-effect-6 .tooltip-content2 {
    -webkit-transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
    transform: translate3d(0, 10px, 0) rotate3d(1, 1, 1, 45deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-6 .tooltip-content2 i {
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-7 .tooltip-content2 {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-7 .tooltip-content2 i {
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-8 .tooltip-content2 {
    -webkit-transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
    transform: translate3d(0, 10px, 0) rotate3d(0, 1, 0, 90deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-8 .tooltip-content2 i {
    -webkit-transform: scale3d(0, 0, 1);
    transform: scale3d(0, 0, 1);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-9 .tooltip-content2 {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-9 .tooltip-content2 i {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-effect-6:hover .tooltip-content2 i {
    -webkit-transform: rotate3d(1, 1, 1, 0);
    transform: rotate3d(1, 1, 1, 0)
}

.tooltip-content2 {
    position: absolute;
    z-index: 9999;
    width: 80px;
    height: 80px;
    padding-top: 25px;
    left: 50%;
    margin-left: -40px;
    bottom: 100%;
    border-radius: 50%;
    text-align: center;
    background: #1abc9c;
    color: #fff;
    opacity: 0;
    margin-bottom: 20px;
    cursor: default;
    pointer-events: none
}

.tooltip-content2 i {
    opacity: 0
}

.tooltip-content2::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin: -7px 0 0 -15px;
    width: 30px;
    height: 20px;
    background: url(../images/tooltip/tooltip1.svg) center center no-repeat;
    background-size: 100%
}

.tooltip-content3 {
    position: absolute;
    background: url(../images/tooltip/shape1.svg) center bottom no-repeat;
    background-size: 100% 100%;
    z-index: 9999;
    width: 200px;
    bottom: 100%;
    left: 50%;
    margin-left: -100px;
    padding: 50px 30px;
    text-align: center;
    color: #fff;
    opacity: 0;
    cursor: default;
    font-size: 14;
    line-height: 27px;
    pointer-events: none;
    -webkit-transform: scale3d(.1, .2, 1);
    transform: scale3d(.1, .2, 1);
    -webkit-transform-origin: 50% 120%;
    transform-origin: 50% 120%;
    -webkit-transition: opacity .4s, -webkit-transform .4s;
    transition: opacity .4s, transform .4s;
    -webkit-transition-timing-function: ease, cubic-bezier(.6, 0, .4, 1);
    transition-timing-function: ease, cubic-bezier(.6, 0, .4, 1)
}

.tooltip-content3::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    margin-left: -8px;
    top: 100%;
    background: #00aeef;
    -webkit-transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(0, -60%, 0) rotate3d(0, 0, 1, 45deg)
}

.tooltip-content4 {
    position: absolute;
    z-index: 99;
    width: 360px;
    left: 50%;
    margin-left: -180px;
    bottom: -5px;
    text-align: left;
    background: #1abc9c;
    opacity: 0;
    font-size: 14px;
    line-height: 27px;
    padding: 1.5em;
    color: #fff;
    border-bottom: 55px solid #34495e;
    cursor: default;
    pointer-events: none;
    border-radius: 5px;
    -webkit-transform: translate3d(0, -0.5em, 0);
    transform: translate3d(0, -0.5em, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-content4 a {
    color: #2b2b2b
}

.tooltip-content4 .tooltip-text2 {
    opacity: 0;
    -webkit-transform: translate3d(0, 1.5em, 0);
    transform: translate3d(0, 1.5em, 0);
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s
}

.tooltip-content5 {
    position: absolute;
    z-index: 9999;
    width: 300px;
    left: 50%;
    bottom: 100%;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    font-weight: 400;
    color: #fff;
    background: 0 0;
    opacity: 0;
    margin: 0 0 20px -150px;
    cursor: default;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -webkit-transition: opacity .3s .3s;
    transition: opacity .3s .3s
}

.tooltip-content5 span {
    display: block
}

.tooltip-content5::after {
    content: '';
    bottom: -20px;
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #1abc9c transparent transparent;
    border-width: 10px;
    margin-left: -10px
}

.tooltip-content5 .tooltip-text3 {
    border-bottom: 10px solid #1abc9c;
    overflow: hidden;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transition: -webkit-transform .3s .3s;
    transition: transform .3s .3s
}

.tooltip-content5 .tooltip-inner2 {
    background: #2b2b2b;
    padding: 40px;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    webkit-transition: -webkit-transform .3s;
    transition: transform .3s
}

a.mytooltip {
    font-weight: 700;
    color: #1abc9c;
    z-index: 9
}

.tooltip-link a {
    margin-left: 10px;
    color: #1abc9c
}

.tooltip-icon button i {
    margin-right: 0
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: #1abc9c
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #1abc9c
}

.animation-image img {
    margin: 10px auto 20px;
    cursor: pointer
}

.card-block.tree-view ul {
    display: inherit
}

.card-block.tree-view ul li {
    display: inherit;
    float: none
}
/* 
.jstree-default>.jstree-container-ul .jstree-loading>.jstree-ocl {
    background: url(../pages/treeview/throbber.gif) center center no-repeat
} */

.jFiler-theme-default .jFiler-input-button {
    background-color: #1abc9c;
    background-image: none;
    color: #fff
}

.jFiler-theme-default .jFiler-input {
    width: 100%
}

.jFiler-input-dragDrop {
    width: 100%;
    background-color: #fafafa
}

.jFiler-items-grid .jFiler-item .jFiler-item-container .jFiler-item-info {
    position: absolute;
    bottom: -10%;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 6px 10px;
    z-index: 9;
    opacity: 0;
    -webkit-transition: all .12s;
    -moz-transition: all .12s;
    transition: all .12s
}

.jFiler-input-choose-btn {
    border: 1px solid #1abc9c;
    padding: 10px 25px;
    background: #1abc9c;
    color: #fff !important
}

.jFiler-input-choose-btn:hover {
    background: #fff;
    border: 1px solid #1abc9c;
    color: #fff !important
}

.card .jFiler-item-assets ul li {
    margin-right: 0
}

.img-container,
.img-preview {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center
}

.img-container {
    min-height: 200px;
    max-height: 516px;
    margin-bottom: 20px
}

@media(min-width:768px) {
    .img-container {
        min-height: 516px
    }
}

.img-container>img {
    max-width: 100%
}

.docs-preview {
    margin-right: -15px
}

.img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden
}

.img-preview>img {
    max-width: 100%
}

.preview-lg {
    width: 100% !important
}

.preview-md {
    width: 139px;
    height: 78px
}

.preview-sm {
    width: 69px;
    height: 39px
}

.preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0
}

.docs-data>.input-group {
    margin-bottom: 10px
}

.docs-data>.input-group>label {
    min-width: 80px
}

.docs-data>.input-group>span {
    min-width: 50px
}

.docs-buttons>.btn,
.docs-buttons>.btn-group,
.docs-buttons>.form-control {
    margin-right: 5px
}

.docs-toggles>.btn,
.docs-toggles>.btn-group,
.docs-toggles>.dropdown {
    margin-bottom: 10px
}

.docs-tooltip {
    display: block;
    margin: -6px -12px;
    padding: 6px 12px
}

.docs-tooltip>.icon {
    margin: 0 -3px;
    vertical-align: top
}

.tooltip-inner {
    white-space: normal
}

.btn-upload .tooltip-inner {
    white-space: nowrap
}

@media(max-width:400px) {
    .btn-group-crop {
        margin-right: -15px !important
    }
    .btn-group-crop>.btn {
        padding-left: 5px;
        padding-right: 5px
    }
    .btn-group-crop .docs-tooltip {
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 5px;
        padding-right: 5px
    }
}

.docs-options .dropdown-menu {
    width: 100%
}

.docs-options .dropdown-menu>li {
    padding: 3px 20px;
    font-size: 14px
}

.docs-options .dropdown-menu>li:hover {
    background-color: #f7f7f7
}

.docs-options .dropdown-menu>li>label {
    display: block
}

.docs-cropped .modal-body {
    text-align: center
}

.docs-cropped .modal-body>img,
.docs-cropped .modal-body>canvas {
    max-width: 100%
}

.card-block .docs-options .dropdown-menu {
    top: inherit
}

label.btn-upload {
    height: 40px
}

.accordion-msg {
    display: block;
    color: #222;
    padding: 14px 20px;
    border-top: 1px solid #ddd;
    font-weight: 600;
    cursor: pointer
}

.accordion-msg:hover,
.accordion-msg:focus {
    text-decoration: none;
    outline: none
}

.faq-accordion .accordion-desc {
    padding: 20px
}

.accordion-desc {
    color: #222;
    padding: 0 20px 20px
}

#color-accordion .accordion-desc {
    margin-top: 14px
}

.ui-accordion-header-icon {
    float: right;
    font-size: 20px
}

.accordion-title {
    margin-bottom: 0
}

.accordion-block {
    padding: 0
}

.accordion-block p {
    margin-bottom: 0
}

.color-accordion-block a:hover,
.color-accordion-block a:focus {
    color: #fff
}

a.bg-default:focus,
a.bg-default:hover {
    background-color: #d9dcde !important;
    color: #fff
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #28e1bd !important;
    color: #fff
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #54d98c !important;
    color: #fff
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #5faee3 !important;
    color: #fff
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #f4d03f !important;
    color: #fff
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #ed7669 !important;
    color: #fff
}

.tab-list p {
    padding: 10px
}

.tab-with-img i {
    position: absolute;
    padding: 5px
}

.tab-icon {
    margin-bottom: 30px
}

.tab-icon i {
    padding-right: 10px
}

.tab-below {
    border-top: 1px solid #ddd;
    border-bottom: none
}

.tab-below.nav-tabs .nav-link.active {
    border-color: transparent #ddd #ddd #ddd
}

.tab-below .nav-item,
.tab-below .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .tab-below a,
.tab-below .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .tab-below a,
.tab-below .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .tab-below a,
.tab-below .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .tab-below a {
    margin-top: -2px
}

.tab-below.nav-tabs .nav-link {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-header~.tab-icon .tab-with-img .sub-title i {
    right: 10px
}

.tab-with-img .nav-link {
    position: relative
}

.tabs-left,
.tabs-right {
    min-width: 120px;
    vertical-align: top;
    width: 150px
}

.tabs-left,
.tabs-right,
.tabs-left-content,
.tabs-right-content {
    display: table-cell
}

.nav-tabs.tabs-left .slide {
    height: 35px;
    width: 4px;
    bottom: 15px
}

.nav-tabs.tabs-right .slide {
    height: 35px;
    width: 4px;
    bottom: 15px;
    right: 0
}

.product-edit .md-tabs .nav-item a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
.main-menu .main-menu-content .nav-item .tree-1 .product-edit .md-tabs a a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 .product-edit .md-tabs a a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 .product-edit .md-tabs a a,
.product-edit .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 .product-edit .md-tabs a a {
    padding: 0 0 20px !important
}

.md-tabs.tabs-left .nav-item,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs.tabs-left a,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs.tabs-left a,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs.tabs-left a,
.md-tabs.tabs-left .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs.tabs-left a,
.tabs-left .nav-item,
.tabs-left .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .tabs-left a,
.tabs-left .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .tabs-left a,
.tabs-left .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .tabs-left a,
.tabs-left .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .tabs-left a,
.md-tabs.tabs-right .nav-item,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs.tabs-right a,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs.tabs-right a,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs.tabs-right a,
.md-tabs.tabs-right .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs.tabs-right a,
.tabs-right .nav-item,
.tabs-right .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .tabs-right a,
.tabs-right .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .tabs-right a,
.tabs-right .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .tabs-right a,
.tabs-right .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .tabs-right a {
    width: 100%;
    position: relative
}

.md-tabs {
    position: relative
}

.md-tabs .nav-item+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a+.nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs .nav-item+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a+a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs .tree-1 a+a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs .tree-1 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs .tree-1 a+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs .tree-2 a+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a+a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs .tree-2 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs .tree-2 a+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs .tree-3 a+a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs .tree-3 a+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a+a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs .tree-3 a+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs .tree-4 a+a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a+a {
    margin: 0
}

.md-tabs .nav-link {
    border: none;
    color: #1b8bf9
}

.md-tabs .nav-item,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a {
    background-color: #fff;
    width: calc(100%/4);
    text-align: center
}

.md-tabs .nav-link:focus,
.md-tabs .nav-link:hover {
    border: none
}

.md-tabs .nav-item .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a .nav-link.active~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a .nav-link.active~.slide,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a .nav-link.active~.slide {
    opacity: 1;
    transition: all .3s ease-out
}

.md-tabs .nav-item .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a .nav-link~.slide,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a .nav-link~.slide,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a .nav-link~.slide {
    opacity: 0;
    transition: all .3s ease-out
}

.md-tabs .nav-item.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a.open .nav-link,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a.open .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a.open .nav-link,
.md-tabs .nav-item.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a.open .nav-link:focus,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a.open .nav-link:focus,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a.open .nav-link:focus,
.md-tabs .nav-item.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a.open .nav-link:hover,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a.open .nav-link:hover,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a.open .nav-link:hover,
.md-tabs .nav-link.active,
.md-tabs .nav-link.active:focus,
.md-tabs .nav-link.active:hover {
    color: #1abc9c;
    border: none;
    background-color: transparent;
    border-radius: 0
}

.md-tabs .nav-item:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a:first-child,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a:first-child,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a:first-child,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a:first-child,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a:first-child,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a:first-child {
    border-bottom: 1px solid #ddd
}

.md-tabs .nav-item a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
.main-menu .main-menu-content .nav-item .tree-1 .md-tabs a a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
.md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a {
    padding: 20px 0 !important;
    color: #1abc9c
}

.nav-tabs .slide {
    background: #1abc9c;
    width: calc(100%/4);
    height: 4px;
    position: absolute;
    -webkit-transition: left .3s ease-out;
    transition: left .3s ease-out;
    bottom: 0
}

.nav-tabs .slide .nav-item.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-1 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 .nav-tabs .slide a.show .nav-link,
.nav-tabs .slide .nav-link {
    color: #1abc9c
}

.img-tabs img {
    width: 100px;
    margin: 0 auto
}

.img-tabs a {
    opacity: .5;
    transition: all ease-in-out .3s
}

.img-tabs a span i {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    bottom: 10px;
    right: 70px
}

.img-tabs a img {
    border: 3px solid
}

.img-tabs a.active {
    opacity: 1;
    transition: all ease-in-out .3s
}

.img-tabs .nav-item:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-1 a:first-child,
.main-menu .main-menu-content .nav-item .tree-1 .img-tabs a:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-2 a:first-child,
.main-menu .main-menu-content .nav-item .tree-2 .img-tabs a:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-3 a:first-child,
.main-menu .main-menu-content .nav-item .tree-3 .img-tabs a:first-child,
.img-tabs .main-menu .main-menu-content .nav-item .tree-4 a:first-child,
.main-menu .main-menu-content .nav-item .tree-4 .img-tabs a:first-child {
    border-bottom: none
}

#pc-left-panel-menu {
    margin-bottom: 20px
}

.h-active a {
    color: #1b8bf9 !important;
    font-weight: 600
}

.img-circle {
    border-radius: 50%
}

.b-none {
    border: none !important
}

.table.table-xl td,
.table.table-xl th {
    padding: 1.25rem 2rem
}

.table.table-lg td,
.table.table-lg th {
    padding: .9rem 2rem
}

.table.table-de td,
.table.table-de th {
    padding: .75rem 2rem
}

.table.table-sm td,
.table.table-sm th {
    padding: .6rem 2rem
}

.table.table-xs td,
.table.table-xs th {
    padding: .4rem 2rem
}

.table-columned>tbody>tr>td:first-child,
.table-columned>tbody>tr>th:first-child {
    border-left: 0
}

.table-columned>tfoot>tr>td:first-child,
.table-columned>tfoot>tr>th:first-child {
    border-left: 0
}

.table-columned>tbody>tr>td,
.table-columned>tbody>tr>th {
    border: 0;
    border-left: 1px solid #ddd
}

.table-columned>tfoot>tr>td,
.table-columned>tfoot>tr>th {
    border: 0;
    border-left: 1px solid #ddd
}

.table-border-style {
    padding: 0
}

.table-border-style .table {
    margin-bottom: 0
}

.table>thead>tr>th {
    border-bottom-color: #ccc
}

.table-borderless tbody tr th,
.table-borderless tbody tr td {
    border: 0
}

.table-bordered>thead>tr.border-solid>td,
.table-bordered>thead>tr.border-solid>th {
    border-bottom-width: 2px
}

.table-bordered>thead>tr.border-solid:first-child>td,
.table-bordered>thead>tr.border-solid:first-child th {
    border-bottom-width: 2px
}

.table-bordered>thead>tr.border-double>td,
.table-bordered>thead>tr.border-double>th {
    border-bottom-width: 3px;
    border-bottom-style: double
}

.table-bordered>thead>tr.border-double:first-child>td,
.table-bordered>thead>tr.border-double:first-child th {
    border-bottom-width: 3px;
    border-bottom-style: double
}

.user-img img,
.rounded-card img {
    margin: 0 auto;
    display: block;
    width: 100%
}

.user-img img {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19)
}

.user-about ul li {
    border-top: 1px solid rgba(204, 204, 204, .28);
    padding: 10px 0 10px 10px;
    text-align: left
}

.user-about ul li a {
    font-size: 16px;
    color: #666
}

.user-about ul li.active a {
    color: #fff
}

.user-about ul li.active {
    font-weight: 600;
    background: #1b8bf9
}

h5,
.card-title {
    margin: 0
}

.card-header.followers a {
    font-weight: 500;
    color: rgba(51, 51, 51, .85)
}

.user-profile {
    padding: 20px 0
}

.follow-btn button:first-child,
.user-about i {
    margin-right: 10px
}

.btn-inline i {
    color: #fff
}

.btn-inline .fb-btn {
    background-color: #3b5998
}

.btn-inline .twitter-btn {
    background-color: #55acee
}

.btn-inline .pinterest-btn {
    background-color: #cb2027
}

.btn-inline .linkedin-btn {
    background-color: #007bb5
}

.btn-inline .dribbble-btn {
    background-color: #ea4c89
}

.user-post {
    font-style: italic
}

.connection-list img,
.list-inline,
.list-inline li,
.earn-heading,
.service-header,
.contact-user img,
.contact-user h4 {
    display: inline-block
}

.connection-list img {
    width: 55px;
    height: 55px;
    margin: 5px
}

.border-post {
    border: 1px solid #ccc
}

.earn-sub-header {
    font-size: 15px;
    color: #ccc
}

.btn-inline {
    margin-top: 20px
}

.order-summary .progress {
    margin-bottom: 32px
}

.services .service-btn::after {
    top: 20px;
    right: 15px
}

.services-list {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .11);
    top: 46px;
    right: 0
}

.services-list i,
.contact-menu i,
.fa-cog {
    margin-right: 10px
}

.review-star i,
.contact-details table .fa-star,
.contact-details table .fa-star-o {
    color: #1abc9c
}

.review-star i:last-child {
    color: #ccc
}

.card-block.user-info {
    position: absolute;
    width: 100%;
    bottom: 10px
}

.profile-bg-img {
    width: 100%
}

.user-title {
    position: relative;
    bottom: 20px
}

.user-title h2 {
    color: #fff;
    text-shadow: 1px 1px 4px #373a3c;
    font-size: 20px
}

.profile-image img {
    border: 4px solid #fff
}

.user-info .media-body,
.user-info .media-left {
    display: table-cell;
    vertical-align: middle
}

.cover-btn {
    bottom: 38px;
    right: 35px;
    position: absolute
}

.cover-profile .profile-bg-img {
    margin-bottom: 25px
}

.contact-user h4 {
    font-size: 20px;
    padding-left: 10px
}

.groups-contact span {
    float: right
}

.groups-contact span h4 {
    font-size: 18px;
    margin-bottom: 20px
}

.contact-menu {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .11);
    top: 15%;
    right: 10%
}

.contact-menu .dropdown-item {
    padding: 8px 20px
}

.tab-header {
    margin-bottom: 20px
}

.card.user-card {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .05), 0 -2px 1px -2px rgba(0, 0, 0, .04), 0 0 0 -1px rgba(0, 0, 0, .05);
    transition: all 150ms linear;
    margin-bottom: 0
}

.card.user-card:hover {
    box-shadow: 0 0 25px -5px #9e9c9e
}

.card-header-img~.btn-group i {
    margin-right: 0
}

.card.business-info {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .05), 0 -2px 1px -2px rgba(0, 0, 0, .04), 0 0 0 -1px rgba(0, 0, 0, .05);
    transition: all 150ms linear
}

.card.business-info:hover {
    box-shadow: 0 0 25px -5px #9e9c9e
}

.top-cap-text {
    padding: 20px
}

.top-cap-text p {
    padding: 10px 0
}

.user-content {
    text-align: center;
    margin-top: 20px
}

.user-content h4 {
    font-size: 16px;
    font-weight: 600
}

.user-content h5 {
    font-size: 14px
}

.img-overlay {
    bottom: 0;
    color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    position: absolute;
    transform: scale(0);
    margin: 0 auto
}

.img-overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%)
}

.img-overlay i {
    margin-right: 0
}

.img-hover-main {
    padding: 0 40px
}

.img-hover {
    position: relative;
    width: 80%;
    margin: 0 auto
}

.img-hover:hover .img-overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    transform: scale(1);
    margin: 0 auto;
    background-color: rgba(0, 0, 0, .7);
    z-index: 2;
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.card-header-img img {
    margin: 0 auto;
    display: block
}

.card-header-img h4 {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
    text-align: center
}

.card-header-img h5,
.card-header-img h6 {
    margin-top: 15px;
    font-size: 15px;
    color: #222;
    font-weight: 500;
    text-align: center
}

.simple-cards .btn-group {
    margin: 20px auto 0
}

.simple-cards .btn-group button {
    margin: 0
}

.simple-cards .user-card {
    padding: 20px 0;
    text-align: center
}

.card-icon {
    display: block;
    margin-bottom: 5px
}

.btn-outline-primary {
    border-color: #1abc9c;
    font-weight: 500;
    padding: 10px 16px;
    font-size: 15px
}

.btn-outline-primary:hover {
    background-color: #1abc9c;
    border-color: #1abc9c
}

.simple-cards p {
    margin: 20px;
    font-size: 15px
}

.user-profile #edit-info-btn,
.user-profile #edit-btn {
    margin-bottom: 0
}

.card-block ul.list-contacts,
.card-block.groups-contact ul {
    display: block;
    float: none
}

.card-block ul.list-contacts li,
.card-block.groups-contact ul li {
    display: block;
    float: none
}

ul.list-contacts .list-group-item a {
    color: #292b2c
}

ul.list-contacts .list-group-item.active a {
    color: #fff
}

.card-block.groups-contact {
    margin-bottom: 0
}

.card-block .connection-list {
    margin-bottom: 20px
}

.card-block .pagination li {
    margin-right: 0 !important
}

.table button {
    margin-bottom: 0
}

#crm-contact .img-circle,
img.comment-img {
    width: 40px;
    height: 40px
}

.page-link {
    color: #1abc9c
}

.page-item.active .page-link {
    background-color: #1abc9c;
    border-color: #1abc9c
}

#main {
    margin-bottom: 20px
}

.offline-box iframe {
    width: 100%;
    border: 1px solid #ddd
}

.blog-page {
    border-top: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .05), 0 -2px 1px -2px rgba(0, 0, 0, .04), 0 0 0 -1px rgba(0, 0, 0, .05)
}

.blog-page .blog-box {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .15);
    overflow: hidden
}

.blog-page .blog-box h5 {
    border-bottom: 1px solid #ccc;
    color: #1abc9c;
    margin-top: 0;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 18px;
    display: block
}

.blog-page .blog-box .option-font {
    background-color: #1abc9c;
    border-radius: 50%;
    bottom: 320px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    line-height: 35px;
    text-align: center;
    width: 35px
}

.blog-page .blog-box .blog-detail {
    padding: 10px
}

.blog-page .blog-img {
    transition: all .3s ease-in-out;
    overflow: hidden;
    margin-bottom: -20px
}

.blog-page .blog-img:hover {
    opacity: .8;
    transition: all .3s ease-in-out
}

.blog-page .blog-img .sharing {
    position: relative;
    bottom: 50px;
    left: 15px
}

.blog-page .blog-img .share {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 5px;
    bottom: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    left: 50px;
    padding: 3px 5px;
    position: absolute
}

.blog-page .blog-img .share::before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid rgba(0, 0, 0, .5);
    border-top: 6px solid rgba(0, 0, 0, .5);
    bottom: 8px;
    content: "";
    height: 42%;
    left: -6px;
    position: absolute;
    transform: rotate(-135deg);
    width: 12px
}

.blog-page .blog-img .share i {
    color: #fff;
    font-size: 15px;
    border-right: 1px solid #fff;
    padding: 0 5px
}

.blog-page .blog-img .share i:last-child {
    border: none
}

.blog-page .blog-img .share i a {
    padding: 0 5px;
    color: #fff
}

.author {
    display: inline-block;
    float: right
}

.author a {
    font-size: 13px
}

.blog_post_img h5 {
    display: block
}

.blog-date {
    font-size: 18px;
    padding-bottom: 10px;
    display: block
}

.blog-date i {
    margin-left: 5px;
    font-size: 14px;
    color: #1abc9c
}

.blog-date .icon-calendar {
    font-size: 16px;
    margin-top: 5px
}

.day {
    color: #bbb;
    padding-left: 10px;
    font-size: 14px
}

.btn-blog a {
    color: #fff !important
}

.blog-single h4 {
    margin-top: 20px;
    color: #1abc9c;
    font-weight: 400
}

.blog-single p {
    margin: 20px 0
}

.blog-single p:last-child {
    color: #777
}

.blog-single .qutoe-text {
    font-size: 15px;
    color: #1abc9c;
    border-left: 3px solid #1abc9c;
    padding-left: 25px;
    font-weight: 400
}

.blog-single img:first-child {
    width: 100%
}

.blog-article .articles img {
    width: 100%
}

.blog-big-user h6,
.blog-big-user p {
    color: #757575
}

.blog-s-reply h6 {
    color: #1abc9c
}

.shares-like li:first-child,
.shares-like li i {
    color: #777
}

.blog-tag li {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #ccc;
    margin-right: 15px
}

.blog-tag li a,
.blog-tag li i {
    color: #777
}

.blog-tag li:first-child {
    border: none;
    padding: 5px 0
}

.blog-tag li i {
    font-size: 20px;
    vertical-align: middle
}

.shares-like li {
    display: inline-block;
    margin-right: 15px;
    font-size: 20px
}

.shares-like li:first-child {
    font-size: 17px
}

.shares-like li i {
    color: #fff
}

.shares-like .btn-facebook,
.shares-like .btn-twitter,
.shares-like .btn-google-plus,
.shares-like .btn-linkedin,
.shares-like .btn-pinterest {
    color: #fff;
    padding: 10px 15px;
    display: inline-block
}

.btn-facebook i,
.btn-twitter i,
.btn-linkedin i,
.btn-dribbble i,
.btn-google-plus i,
.btn-instagram i,
.btn-pinterest i,
.btn-dropbox i,
.btn-flickr i,
.btn-tumblr i,
.btn-skype i,
.btn-youtube i,
.btn-github i {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 3px 0 0 3px;
    margin: -7px 0 -7px -13px
}

.blog-article .articles h6 {
    padding-top: 20px;
    font-weight: 400
}

.blog-article .articles a {
    font-weight: 400;
    font-size: 15px;
    color: #1abc9c;
    margin: 20px 0;
    display: block
}

.blog-s-reply h6 span {
    font-size: 12px;
    color: #777;
    margin-left: 5px
}

.blog-u-comment span {
    font-size: 14px
}

.blog-u-comment .blog-reply a,
.blog-u-comment .blog-edit a {
    margin-right: 10px;
    font-size: 12px
}

.system_font_color {
    font-size: 18px;
    color: #1abc9c
}

.system_font_color a {
    color: #1abc9c;
    margin-left: 5px
}

.port_detail_next_search h5 {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    display: block;
    font-weight: light
}

.port_detail_next_search a {
    color: #333;
    transition: all .3s 0s
}

.port_detail_next_search a i {
    float: right;
    margin-top: 6px
}

.blog_detail_social_icon span {
    font-size: 18px;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 50px;
    cursor: pointer;
    margin-right: 10px;
    transition: all .5s ease;
    display: inline-block;
    margin-bottom: 10px
}

.blog_detail_social_icon span:hover {
    background-color: #1abc9c;
    color: #fff
}

.latest_blog h5 a {
    color: #333;
    font-weight: 600
}

.gallery-page .card-block {
    margin-bottom: -20px
}

.thumbnail {
    margin-bottom: 20px
}

.thumbnail .thumb {
    position: relative;
    display: block
}

.card.gallery-desc {
    box-shadow: 0 0 1px 2px rgba(128, 128, 128, .16)
}

.masonry-media {
    overflow: hidden;
    transition: all .5s
}

.masonry-media img:hover {
    transform: scale(1.1);
    overflow: hidden;
    transition: all .5s
}

.masonry-image .masonry-media {
    margin-bottom: 20px
}

figure.effect-goliath {
    background: #1abc9c
}

.ct-series-a .ct-line {
    stroke: #1ce3bb
}

.ct-series-b .ct-line {
    stroke: rgba(255, 157, 136, .62)
}

.ct-series-c .ct-line {
    stroke: rgba(79, 84, 103, .45)
}

.ct-series-d .ct-line {
    stroke: rgba(129, 142, 219, .61)
}

.ct-series-a .ct-point,
.ct-series-b .ct-point,
.ct-series-c .ct-point,
.ct-series-d .ct-point {
    stroke: rgba(52, 54, 70, .47);
    stroke-width: 8px;
    stroke-linecap: round
}

.ct-series-a .ct-slice-donut {
    stroke: #01c0c8
}

.ct-series-b .ct-slice-donut {
    stroke: #83d6de
}

.ct-series-c .ct-slice-donut {
    stroke: #1abc9c
}

.ct-series-d .ct-slice-donut {
    stroke: #4f5467
}

.task-list select {
    width: 92%
}

.task-list select .task-list img {
    margin-right: 5px;
    display: inline-block
}

.task-list input {
    width: 92%
}

.task-list-table img {
    width: 40px
}

.task-list-table img i {
    color: #333;
    margin-right: 5px
}

.task-page td:last-child {
    position: relative
}

.task-page a {
    cursor: pointer
}

.task-page tr td:last-child i {
    margin-right: 10px
}

.thumb-img {
    position: relative;
    display: block
}

.thumb-img:hover .caption-hover {
    background-color: rgba(0, 0, 0, .7);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    position: absolute;
    width: 100%;
    height: 100%
}

.caption-hover {
    top: 0;
    visibility: hidden;
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all .15s ease-in-out;
    -o-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.caption-hover>span {
    top: 38%;
    width: 100%;
    position: absolute;
    text-align: center
}

.media .b-2-primary {
    border: 2px solid #1abc9c
}

.thumb-block {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    padding: 3px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out
}

.thumb-block a {
    font-size: 12px
}

.thumb-block i {
    margin-right: 0
}

.comment-block i {
    margin-right: 0
}

.dropdown-item {
    color: #666;
    padding: 6px 20px
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #1abc9c
}

.task-detail-right .counter {
    text-align: center;
    color: #777
}

.task-details .table.table-xs td,
.task-details .table.table-xs th {
    padding: 1rem .3rem
}

.assign-user .media-left img {
    margin-bottom: 0
}

.photo-table img {
    display: inline-block;
    width: 40px;
    margin-bottom: 5px
}

.v-middle {
    vertical-align: middle
}

.revision-block .form-group {
    margin-bottom: 0
}

.revision-block i {
    margin-right: 0
}

.task-setting .switchery {
    display: block !important;
    float: right
}

.task-setting .form-group {
    margin-bottom: 0
}

.task-attachment i {
    cursor: pointer
}

.filter-bar .nav,
.filter-bar .nav-item,
.filter-bar .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .filter-bar a,
.filter-bar .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .filter-bar a,
.filter-bar .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .filter-bar a,
.filter-bar .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .filter-bar a {
    display: inline-block
}

.filter-bar>.navbar {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .05), 0 3px 1px -2px rgba(0, 0, 0, .08), 0 1px 5px 0 rgba(0, 0, 0, .08);
    padding: .5rem 1rem
}

.navbar-nav .nav-item,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .navbar-nav a,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .navbar-nav a,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .navbar-nav a,
.navbar-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .navbar-nav a {
    float: left;
    line-height: 26px
}

.nav-item button i,
.main-menu .main-menu-content .nav-item .tree-1 a button i,
.main-menu .main-menu-content .nav-item .tree-2 a button i,
.main-menu .main-menu-content .nav-item .tree-3 a button i,
.main-menu .main-menu-content .nav-item .tree-4 a button i {
    margin-right: 0
}

.filter-bar .navbar-light .navbar-nav .nav-link {
    margin-right: 10px
}

.card-footer .task-list-table,
.card-footer .task-list-table a img {
    display: inline-block
}

.task-board {
    margin-top: 10px;
    float: right
}

.task-board .dropdown {
    display: inline-block
}

p.task-detail {
    margin-bottom: 5px
}

p.task-due {
    margin-bottom: 0
}

.task-right-header-status,
.task-right-header-users,
.task-right-header-revision {
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #ccc
}

.taskboard-right-progress,
.taskboard-right-users,
.taskboard-right-revision {
    margin-top: 10px
}

.task-right h6 {
    font-size: 12px
}

.task-right .icofont {
    margin-top: 5px
}

.taskboard-right-revision .media .media-body .chat-header {
    font-size: 13px
}

.media-left i {
    margin-right: 0
}

.nav-item.nav-grid,
.main-menu .main-menu-content .nav-item .tree-1 a.nav-grid,
.main-menu .main-menu-content .nav-item .tree-2 a.nav-grid,
.main-menu .main-menu-content .nav-item .tree-3 a.nav-grid,
.main-menu .main-menu-content .nav-item .tree-4 a.nav-grid {
    float: right
}

.faq-progress .progress {
    position: relative;
    background-color: #eeeded;
    height: 10px
}

.faq-progress .progress .faq-text1,
.faq-progress .progress .faq-text2,
.faq-progress .progress .faq-text3,
.faq-progress .progress .faq-text4,
.faq-progress .progress .faq-text5 {
    font-weight: 600;
    margin-right: -37px
}

.faq-progress .progress .faq-bar1,
.faq-progress .progress .faq-bar2,
.faq-progress .progress .faq-bar3,
.faq-progress .progress .faq-bar4,
.faq-progress .progress .faq-bar5 {
    background: #29aecc;
    height: 10px;
    border-radius: 0;
    position: absolute;
    top: 0%
}

.faq-progress .progress .faq-bar1 {
    background-color: #f1c40f
}

.faq-progress .progress .faq-text1 {
    color: #2196f3
}

.faq-progress .progress .faq-bar2,
.faq-progress .progress .faq-bar5 {
    background-color: #2ecc71
}

.faq-progress .progress .faq-text2,
.faq-progress .progress .faq-text5 {
    color: #4caf50
}

.faq-progress .progress .faq-bar3 {
    background-color: #e74c3c
}

.faq-progress .progress .faq-text3 {
    color: #ff5252
}

.faq-progress .progress .faq-bar4 {
    background-color: #1abc9c
}

.faq-progress .progress .faq-text4 {
    color: #f57c00
}

.card-faq h4 {
    color: #2196f3
}

.faq-progress .progress {
    margin-bottom: 10px
}

.issue-list-progress {
    border-bottom: 1px solid #ccc
}

.issue-list-progress .progress {
    position: relative;
    background-color: #eeeded;
    height: 9px;
    width: 100%;
    margin: 20px 0;
    overflow: visible
}

.issue-progress .progress .issue-text1 {
    font-weight: 600;
    position: absolute
}

.issue-progress .progress .issue-bar1 {
    background: #1abc9c;
    height: 10px;
    border-radius: 0;
    position: absolute;
    top: 0%
}

.matrics-issue .sub-title {
    padding-top: 20px;
    padding-bottom: 10px;
    display: block
}

.bg-white {
    background-color: #fff !important
}

.matrics-issue div h6 {
    padding-top: 10px;
    color: #777
}

table.matrics-table tr:first-child td {
    border-top: none !important
}

#issue-list-table>thead>tr>th {
    border-bottom: none
}

.prod-img {
    position: relative
}

.prod-item .prod-img .option-hover {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    left: 0
}

.hvr-shrink {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-property: transform;
    transition-property: transform
}

.prod-img .p-new a {
    position: absolute;
    top: 15px;
    right: 0;
    padding: 8px 13px;
    line-height: 1;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 2px 0 0 2px;
    background: #3498db;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600
}

.prod-info a {
    font-size: 18px
}

.prod-info .br-wrapper {
    margin: 0 auto 20px
}

.prod-info .br-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0
}

.prod-info h6 {
    font-size: 18px;
    font-weight: 600
}

.prod-view:hover .option-hover {
    display: inline-block;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    z-index: 1
}

.prod-item .prod-info {
    background: #fff;
    padding: 30px 0 20px
}

.prod-item .br-widget {
    min-height: inherit
}

.prod-img .p-sale {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 40px;
    height: 40px;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 50%;
    background-color: #ff5252;
    color: #fff;
    font-weight: 800;
    letter-spacing: 1px;
    padding: 11px 4px
}

.prod-info .br-widget {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0
}

.option-icon i {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    font-size: 20px;
    line-height: 36px;
    margin: 30px 0;
    padding: 17px 5px
}

.product-detail-page {
    border-top: none
}

.product-detail .br-wrapper {
    margin: 10px 0
}

.product-detail .product-detail .btn i {
    margin-right: 0
}

.product-detail .br-widget {
    min-height: 35px;
    margin-top: 0;
    display: block
}

.product-detail .btn-number {
    background-color: #e0e0e0;
    border-color: #d4d4d4;
    border-radius: 0;
    color: #000
}

.product-detail .product-price {
    display: inline-block;
    margin-right: 50px;
    font-size: 24px
}

.product-detail .pro-desc {
    margin-top: 15px;
    margin-bottom: 15px
}

.product-detail hr {
    margin-top: 15px;
    margin-bottom: 15px
}

#small_banner .slick-slide {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .5
}

#small_banner img {
    cursor: pointer
}

#small_banner .slick-center {
    filter: grayscale(0);
    opacity: 1
}

#small_banner:hover .slick-prev {
    left: 0;
    transition: all ease-in .3s
}

#small_banner:hover .slick-next {
    right: 0;
    transition: all ease-in .3s
}

.port_details_all_img {
    overflow: hidden
}

.slick-prev {
    left: -45px;
    z-index: 1;
    height: 100%;
    background: rgba(0, 0, 0, .62);
    width: 4%;
    transition: all ease-in .3s
}

.slick-prev:hover,
.slick-prev:focus {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, .8)
}

.slick-next {
    right: -45px;
    z-index: 1;
    height: 100%;
    background: rgba(0, 0, 0, .62);
    width: 4%;
    transition: all ease-in .3s
}

.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: rgba(0, 0, 0, .8)
}

.counter-input .input-group {
    width: 20%
}

.pro-det-tab .tab-content {
    border: 1px solid #ccc;
    border-top: 0
}

.big_banner .port_big_img {
    margin-bottom: 15px
}

.cd-price .cd-price-month {
    font-size: 64px
}

.product-edit .br-wrapper {
    margin: 10px 0 30px
}

.addcontact .md-content>div ul {
    padding-left: 0
}

.panel {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.panel-body p {
    overflow: hidden;
    margin-bottom: 0
}

.panels-wells .panel {
    margin-bottom: 20px
}

.panels-wells .panel .panel-body {
    padding: 15px
}

.panel-heading {
    padding: 10px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.panel-footer {
    padding: 10px 15px;
    background-color: #fafafa;
    border-top: 1px solid #eee;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)
}

.well-sm {
    padding: 9px;
    border-radius: 3px
}

.well-lg {
    padding: 24px;
    border-radius: 6px
}

.search-result .card {
    border-top: none;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, .15)
}

.seacrh-header {
    margin-top: 20px;
    display: flex;
    align-items: center
}

.search-content img {
    width: 120px
}

.search-content {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc
}

.search-content:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0
}

.search-result i {
    float: right
}

.search2 .card-block:first-child {
    padding-top: 0
}

.cart-page .wizard>.content>.body {
    width: 100%;
    padding: 0
}

.wizard>.content>.body {
    width: 100%
}

.payment-card {
    border: 1px solid #ccc;
    border-radius: 0;
    margin-bottom: 15px;
    padding: 20px
}

.payment-card table {
    margin-bottom: 0
}

.confirmation {
    text-align: center;
    font-size: 80px
}

.confirmation-icon {
    color: #e74c3c
}

.width-100 {
    width: 100%
}

.post-input {
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    resize: none
}

.file-upload-lbl {
    max-width: 15px;
    padding: 5px 0 0
}

.post-new-footer {
    border-top: 1px solid #ccc
}

.post-new-footer i {
    font-size: 18px;
    margin-right: 15px;
    cursor: pointer
}

.input-group span {
    cursor: pointer
}

.input-group a {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px
}

.msg-send {
    background-color: #f3f3f3
}

.msg-send:focus {
    background-color: #f3f3f3
}

.wall-dropdown:after {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer
}

.wall-img-preview {
    display: inline-block;
    text-align: center
}

.wall-img-preview .wall-item {
    display: block;
    float: left;
    position: relative;
    overflow: hidden;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center
}

.wall-img-preview .wall-item:first-child:nth-last-child(2),
.wall-img-preview .wall-item:first-child:nth-last-child(2)~div {
    width: 50%
}

.wall-img-preview .wall-item>img {
    display: none
}

.wall-img-preview .wall-item:first-child:nth-last-child(2),
.wall-img-preview .wall-item:first-child:nth-last-child(2)~div {
    width: 50%
}

.wall-img-preview .wall-item:first-child:nth-last-child(3),
.wall-img-preview .wall-item:first-child:nth-last-child(3)~div,
.wall-img-preview .wall-item:first-child:nth-last-child(4),
.wall-img-preview .wall-item:first-child:nth-last-child(4)~div:not(:last-child),
.wall-img-preview .wall-item:first-child:nth-last-child(5),
.wall-img-preview .wall-item:first-child:nth-last-child(5)~div:not(:nth-last-of-type(-n+2)),
.wall-img-preview .wall-item:first-child:nth-last-child(6),
.wall-img-preview .wall-item:first-child:nth-last-child(6)~div,
.wall-img-preview .wall-item:first-child:nth-last-child(7)~div:nth-last-of-type(-n+3),
.wall-img-preview .wall-item:first-child:nth-last-child(9),
.wall-img-preview .wall-item:first-child:nth-last-child(9)~div,
.wall-img-preview .wall-item:first-child:nth-last-child(10),
.wall-img-preview .wall-item:first-child:nth-last-child(10)~div:not(:last-child),
.wall-img-preview .wall-item:first-child:nth-last-child(11)~div:nth-last-of-type(-n+3) {
    width: 33.333333%
}

.wall-img-preview .wall-item:first-child:nth-last-child(5)~div:nth-last-of-type(-n+2) {
    width: 50%
}

.wall-img-preview .wall-item:first-child:nth-last-child(7),
.wall-img-preview .wall-item:first-child:nth-last-child(7)~div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wall-item:first-child:nth-last-child(11),
.wall-img-preview .wall-item:first-child:nth-last-child(11)~div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wall-item:first-child:nth-last-child(8),
.wall-img-preview .wall-item:first-child:nth-last-child(8)~div,
.wall-img-preview .wall-item:first-child:nth-last-child(12),
.wall-img-preview .wall-item:first-child:nth-last-child(12)~div {
    width: 25%
}

.wall-img-preview .wall-item:only-child,
.wall-img-preview .wall-item:first-child:nth-last-child(4)~div:nth-child(4),
.wall-img-preview .wall-item:first-child:nth-last-child(10)~div:nth-child(10) {
    width: 100%
}

.fb-timeliner h2 {
    background: #1abc9c;
    color: #fff;
    margin-top: 0;
    padding: 15px;
    font-size: 16px;
    border-radius: 2px;
    -webkit-border-radius: 4px;
    font-weight: 300
}

.fb-timeliner ul {
    margin-left: 15px;
    margin-bottom: 20px
}

.fb-timeliner ul li {
    margin-bottom: 3px
}

.fb-timeliner ul li a {
    color: #999797;
    border-left: 4px solid #d3d7dd;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    display: block
}

.fb-timeliner ul li a:hover {
    color: #999797;
    border-left: 4px solid #b1b1b1;
    padding-left: 10px
}

.fb-timeliner ul li.active a {
    color: #7a7a7a;
    border-left: 4px solid #7a7a7a;
    padding-left: 10px
}

.dotted-line-theme .no_edit {
    width: 100% !important
}

.dotted-line-theme .no_edit .i_text {
    font-size: 13px
}

.dotted-line-theme .no_edit {
    padding: 15px 2px
}

.dotted-line-theme .just_edit input[type=radio] {
    opacity: 0
}

.dotted-line-theme .ibtn_container {
    padding-left: 0;
    margin-top: 2px;
    position: absolute;
    top: 16px;
    z-index: 999
}

.dotted-line-theme .ibtn_container i {
    color: #fff
}

#msform #progressbar li.active {
    color: #1abc9c
}

#msform #progressbar li.active:before,
#progressbar li.active:after {
    background: #1abc9c
}

#msform a {
    color: #1abc9c;
    font-weight: 600
}

.invoice-contact {
    display: flex;
    margin-bottom: 30px;
    padding-top: 30px;
    align-items: center
}

.invoice-table {
    border-left: 1px solid #fff;
    padding-left: 20px
}

.invoice-table td,
.invoice-table th {
    border-top: none;
    padding: 3px 0
}

.invoice-table>tbody>tr:last-child>td,
.invoice-table>tfoot>tr:last-child>td {
    padding-bottom: 0
}

.invoice-box h1 {
    font-size: 7rem
}

.invoice-order {
    padding-left: 0
}

.invoice-order th:first-child {
    padding-left: 0;
    width: 80px
}

.invoice-detail-table th:first-child {
    width: 450px;
    text-align: left
}

.invoice-detail-table thead th {
    text-align: center
}

.invoice-detail-table td {
    vertical-align: middle;
    text-align: center
}

.invoice-detail-table td:first-child {
    text-align: left
}

.invoice-total {
    background: #f3f3f3;
    padding: 30px 0
}

.invoice-total th,
.invoice-total td {
    text-align: right
}

.invoice-total td {
    padding-left: 30px
}

.invoive-info {
    display: flex;
    margin-bottom: 30px
}

.invoive-info h6 {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #757575
}

.invoice-total tbody {
    padding-right: 20px;
    float: right
}

.dropdown-menu {
    left: initial;
    right: 0
}

.dropdown-menu i {
    margin-right: 5px
}

.grid-layout span {
    display: block;
    border: 1px solid #ddd;
    padding: 10px
}

.vertical-alignment .row {
    padding: 15px 0;
    min-height: 10rem;
    border: 1px solid #ddd
}

.inte-benifits {
    border: 1px solid #ddd
}

.inte-benifits ul {
    list-style: square;
    padding-left: 20px
}

.version .card-block {
    padding: 0
}

.card.version .card-header .card-header-right {
    background-color: #fff;
    top: 8px
}

.version .nav {
    display: block
}

.version .nav li {
    display: block;
    padding: 10px 20px;
    cursor: pointer
}

.version .nav li:hover {
    background-color: #f6f7f7
}

.version .nav li a {
    color: #666
}

.version .nav li span {
    position: absolute;
    right: 20px
}

.version .navigation-header a {
    color: #999
}

.support-btn {
    padding: 20px
}

.version .nav li:first-child {
    border-top: 1px solid #ddd;
    color: #666
}

.dd-w,
.sp-container {
    z-index: 99
}

.theme-loader {
    height: 100%;
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 999999
}

@-webkit-keyframes ball-scale {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes ball-scale {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

.ball-scale {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 45%;
    width: 100%
}

.ball-scale>div {
    -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
    animation: ball-scale 1s 0s ease-in-out infinite;
    background-color: #148f77;
    border-radius: 100px;
    display: inline-block;
    height: 60px;
    width: 60px
}

.color-1 {
    background: #2c3e50
}

.color-2 {
    background: #3498db
}

.color-3 {
    background: #0c7096
}

.color-4 {
    background: #36c7c7
}

.color-5 {
    background: #0f9da5
}

.color-6 {
    background: #fb6868
}

.color-picker {
    position: fixed;
    right: 0;
    top: 150px;
    width: 239px;
    background: #fff;
    z-index: 999999;
    -webkit-transition: all .3s ease
}

.color-picker a.handle {
    border: 1px solid #2c3e50;
    border-right-color: transperant;
    border-left-color: none;
    position: absolute;
    top: 0;
    right: 239px;
    width: 50px;
    height: 50px;
    text-align: center;
    background: #fff;
    z-index: 9999;
    transition: all ease-in .3s
}

.color-picker a.handle:hover {
    background: #4a6076;
    transition: all ease-in .3s
}

.color-picker a.handle:hover i {
    color: #fff;
    transition: all ease-in .3s
}

.color-picker a.handle i {
    color: #2c3e50;
    font-size: 25px;
    line-height: 50px
}

.color-picker .settings-header {
    background: #2c3e50;
    height: 50px;
    padding: 15px 34px 0
}

.color-picker .settings-header h3 {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
    padding: 0 0 5px;
    margin-top: 0
}

.color-picker .section:last-child {
    border-bottom: 1px solid #2c3e50
}

.color-picker .section {
    padding: 20px 14px;
    border-bottom: 1px solid #2c3e50;
    border-left: 1px solid #2c3e50;
    overflow: hidden
}

.color-picker .section h3 {
    font-size: 16px;
    text-transform: none;
    color: #3c3c3c;
    line-height: 16px;
    padding: 0;
    margin-bottom: 20px;
    text-align: left
}

.color-picker .section i {
    font-size: 16px;
    margin-right: 10px
}

.color-picker span {
    font-size: 13px;
    color: #9a9a9a
}

.color-picker .colors a {
    display: block;
    border-radius: 10px;
    width: 30px;
    height: 30px;
    margin-left: 0;
    margin-bottom: 10px;
    margin-right: 5px;
    float: left;
    transition: all ease-in .3s
}

.color-picker .colors a:hover {
    box-shadow: 0 0 2px 1px rgba(247, 54, 121, .58);
    transition: all ease-in .3s
}

.color-picker .skin a {
    display: block;
    width: 70px;
    height: 30px;
    margin-left: 0;
    margin-top: 0;
    margin-right: 5px;
    float: left;
    text-align: center;
    line-height: 30px;
    border: 2px transparent solid
}

.color-picker .skin a.actt {
    border: 2px #fff solid
}

#sessionTimeout-dialog .close {
    display: none
}

.generic-card-body code {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px
}

.generic-image-body li {
    padding: 0 20px;
    display: inline-block
}

.generic-image-body code {
    display: block
}

.generic-image-body img {
    display: block;
    margin: 10px auto 0
}

.p-0 {
    padding: 0
}

.p-5 {
    padding: 5px
}

.p-10 {
    padding: 10px
}

.p-15 {
    padding: 15px
}

.p-20 {
    padding: 20px
}

.p-25 {
    padding: 25px
}

.p-30 {
    padding: 30px
}

.p-35 {
    padding: 35px
}

.p-40 {
    padding: 40px
}

.p-45 {
    padding: 45px
}

.p-50 {
    padding: 50px
}

.p-t-0 {
    padding-top: 0 !important
}

.p-t-5 {
    padding-top: 5px !important
}

.p-t-10 {
    padding-top: 10px !important
}

.p-t-15 {
    padding-top: 15px !important
}

.p-t-20 {
    padding-top: 20px !important
}

.p-t-25 {
    padding-top: 25px !important
}

.p-t-30 {
    padding-top: 30px !important
}

.p-t-35 {
    padding-top: 35px !important
}

.p-t-40 {
    padding-top: 40px !important
}

.p-t-45 {
    padding-top: 45px !important
}

.p-t-50 {
    padding-top: 50px !important
}

.p-b-0 {
    padding-bottom: 0 !important
}

.p-b-5 {
    padding-bottom: 5px !important
}

.p-b-10 {
    padding-bottom: 10px !important
}

.p-b-15 {
    padding-bottom: 15px !important
}

.p-b-20 {
    padding-bottom: 20px !important
}

.p-b-25 {
    padding-bottom: 25px !important
}

.p-b-30 {
    padding-bottom: 30px !important
}

.p-b-35 {
    padding-bottom: 35px !important
}

.p-b-40 {
    padding-bottom: 40px !important
}

.p-b-45 {
    padding-bottom: 45px !important
}

.p-b-50 {
    padding-bottom: 50px !important
}

.p-l-0 {
    padding-left: 0
}

.p-l-5 {
    padding-left: 5px
}

.p-l-10 {
    padding-left: 10px
}

.p-l-15 {
    padding-left: 15px
}

.p-l-20 {
    padding-left: 20px
}

.p-l-25 {
    padding-left: 25px
}

.p-l-30 {
    padding-left: 30px
}

.p-l-35 {
    padding-left: 35px
}

.p-l-40 {
    padding-left: 40px
}

.p-l-45 {
    padding-left: 45px
}

.p-l-50 {
    padding-left: 50px
}

.p-r-0 {
    padding-right: 0
}

.p-r-5 {
    padding-right: 5px
}

.p-r-10 {
    padding-right: 10px
}

.p-r-15 {
    padding-right: 15px
}

.p-r-20 {
    padding-right: 20px
}

.p-r-25 {
    padding-right: 25px
}

.p-r-30 {
    padding-right: 30px
}

.p-r-35 {
    padding-right: 35px
}

.p-r-40 {
    padding-right: 40px
}

.p-r-45 {
    padding-right: 45px
}

.p-r-50 {
    padding-right: 50px
}

.m-0 {
    margin: 0
}

.m-5 {
    margin: 5px
}

.m-10 {
    margin: 10px
}

.m-15 {
    margin: 15px
}

.m-20 {
    margin: 20px
}

.m-25 {
    margin: 25px
}

.m-30 {
    margin: 30px
}

.m-35 {
    margin: 35px
}

.m-40 {
    margin: 40px
}

.m-45 {
    margin: 45px
}

.m-50 {
    margin: 50px
}

.m-t-0 {
    margin-top: 0
}

.m-t-5 {
    margin-top: 5px
}

.m-t-10 {
    margin-top: 10px
}

.m-t-15 {
    margin-top: 15px
}

.m-t-20 {
    margin-top: 20px
}

.m-t-25 {
    margin-top: 25px
}

.m-t-30 {
    margin-top: 30px
}

.m-t-35 {
    margin-top: 35px
}

.m-t-40 {
    margin-top: 40px
}

.m-t-45 {
    margin-top: 45px
}

.m-t-50 {
    margin-top: 50px
}

.m-b-0 {
    margin-bottom: 0
}

.m-b-5 {
    margin-bottom: 5px
}

.m-b-10 {
    margin-bottom: 10px
}

.m-b-15 {
    margin-bottom: 15px
}

.m-b-20 {
    margin-bottom: 20px
}

.m-b-25 {
    margin-bottom: 25px
}

.m-b-30 {
    margin-bottom: 30px
}

.m-b-35 {
    margin-bottom: 35px
}

.m-b-40 {
    margin-bottom: 40px
}

.m-b-45 {
    margin-bottom: 45px
}

.m-b-50 {
    margin-bottom: 50px
}

.m-l-0 {
    margin-left: 0
}

.m-l-5 {
    margin-left: 5px
}

.m-l-10 {
    margin-left: 10px
}

.m-l-15 {
    margin-left: 15px
}

.m-l-20 {
    margin-left: 20px
}

.m-l-25 {
    margin-left: 25px
}

.m-l-30 {
    margin-left: 30px
}

.m-l-35 {
    margin-left: 35px
}

.m-l-40 {
    margin-left: 40px
}

.m-l-45 {
    margin-left: 45px
}

.m-l-50 {
    margin-left: 50px
}

.m-r-0 {
    margin-right: 0
}

.m-r-5 {
    margin-right: 5px
}

.m-r-10 {
    margin-right: 10px
}

.m-r-15 {
    margin-right: 15px
}

.m-r-20 {
    margin-right: 20px
}

.m-r-25 {
    margin-right: 25px
}

.m-r-30 {
    margin-right: 30px
}

.m-r-35 {
    margin-right: 35px
}

.m-r-40 {
    margin-right: 40px
}

.m-r-45 {
    margin-right: 45px
}

.m-r-50 {
    margin-right: 50px
}

.b-radius-0 {
    border-radius: 0 !important
}

.b-radius-5 {
    border-radius: 5px !important
}

.b-radius-10 {
    border-radius: 10px !important
}

.f-12 {
    font-size: 12px
}

.f-14 {
    font-size: 14px
}

.f-16 {
    font-size: 16px
}

.f-18 {
    font-size: 18px
}

.f-20 {
    font-size: 20px
}

.f-22 {
    font-size: 22px
}

.f-24 {
    font-size: 24px
}

.f-26 {
    font-size: 26px
}

.f-28 {
    font-size: 28px
}

.f-30 {
    font-size: 30px
}

.f-32 {
    font-size: 32px
}

.f-34 {
    font-size: 34px
}

.f-36 {
    font-size: 36px
}

.f-38 {
    font-size: 38px
}

.f-40 {
    font-size: 40px
}

.f-42 {
    font-size: 42px
}

.f-44 {
    font-size: 44px
}

.f-46 {
    font-size: 46px
}

.f-48 {
    font-size: 48px
}

.f-50 {
    font-size: 50px
}

.f-52 {
    font-size: 52px
}

.f-54 {
    font-size: 54px
}

.f-56 {
    font-size: 56px
}

.f-58 {
    font-size: 58px
}

.f-60 {
    font-size: 60px
}

.f-62 {
    font-size: 62px
}

.f-64 {
    font-size: 64px
}

.f-66 {
    font-size: 66px
}

.f-68 {
    font-size: 68px
}

.f-70 {
    font-size: 70px
}

.f-72 {
    font-size: 72px
}

.f-74 {
    font-size: 74px
}

.f-76 {
    font-size: 76px
}

.f-78 {
    font-size: 78px
}

.f-80 {
    font-size: 80px
}

.f-w-100 {
    font-weight: 100
}

.f-w-300 {
    font-weight: 300
}

.f-w-400 {
    font-weight: 400
}

.f-w-600 {
    font-weight: 600
}

.f-w-700 {
    font-weight: 700
}

.f-w-900 {
    font-weight: 900
}

.f-s-normal {
    font-style: normal
}

.f-s-italic {
    font-style: italic
}

.f-s-oblique {
    font-style: oblique
}

.f-s-initial {
    font-style: initial
}

.f-s-inherit {
    font-style: inherit
}

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-capitalize {
    text-transform: capitalize
}

.text-uppercase {
    text-transform: uppercase
}

.text-lowercase {
    text-transform: lowercase
}

.text-overline {
    text-decoration: overline
}

.text-line-through {
    text-decoration: line-through
}

.text-underline {
    text-decoration: underline
}

.baseline {
    vertical-align: baseline
}

.sub {
    vertical-align: sub
}

.super {
    vertical-align: super
}

.top {
    vertical-align: top
}

.text-top {
    vertical-align: text-top
}

.middle {
    vertical-align: middle
}

.bottom {
    vertical-align: bottom
}

.text-bottom {
    vertical-align: text-bottom
}

.initial {
    vertical-align: initial
}

.inherit {
    vertical-align: inherit
}

.pos-static {
    position: static
}

.pos-absolute {
    position: absolute
}

.pos-fixed {
    position: fixed
}

.pos-relative {
    position: relative
}

.pos-initial {
    position: initial
}

.pos-inherit {
    position: inherit
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.f-none {
    float: none
}

.o-hidden {
    overflow: hidden
}

.o-visible {
    overflow: visible
}

.o-auto {
    overflow: auto
}

.img-20 {
    width: 20px
}

.img-30 {
    width: 30px
}

.img-40 {
    width: 40px
}

.img-50 {
    width: 50px
}

.img-60 {
    width: 60px
}

.img-70 {
    width: 70px
}

.img-80 {
    width: 80px
}

.img-90 {
    width: 90px
}

.img-100 {
    width: 100px
}

.text-primary {
    color: #1abc9c !important
}

.text-warning {
    color: #f1c40f !important
}

.text-default {
    color: #bdc3c7 !important
}

.text-danger {
    color: #e74c3c !important
}

.text-success {
    color: #2ecc71 !important
}

.text-inverse {
    color: #34495e !important
}

.text-info {
    color: #3498db !important
}

.text-custom {
    color: #64b0f2 !important
}

.text-pink {
    color: #ff7aa3 !important
}

.text-muted {
    color: #777 !important
}

.text-dark {
    color: #2b3d51 !important
}

.text-purple {
    color: #9261c6 !important
}

.text-muted {
    color: #777 !important
}

.label {
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize
}

.label-primary {
    background-color: #1abc9c
}

.label-warning {
    background-color: #f1c40f
}

.label-default {
    background-color: #bdc3c7
}

.label-danger {
    background-color: #e74c3c
}

.label-success {
    background-color: #2ecc71
}

.label-inverse {
    background-color: #34495e
}

.label-info {
    background-color: #3498db
}

.badge-primary {
    background-color: #1abc9c
}

.badge-warning {
    background-color: #f1c40f
}

.badge-default {
    background-color: #bdc3c7
}

.badge-danger {
    background-color: #e74c3c
}

.badge-success {
    background-color: #2ecc71
}

.badge-inverse {
    background-color: #34495e
}

.badge-info {
    background-color: #3498db
}

.bg-color-box {
    text-align: center;
    display: inline-block;
    padding: 10px 20px;
    box-shadow: 0 0 5px 0 rgba(128, 128, 128, .49);
    font-weight: 600;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer
}

.bg-color-box span {
    color: #fff
}

.bg-primary {
    background-color: #1abc9c !important;
    color: #fff
}

.bg-warning {
    background-color: #f1c40f !important;
    color: #fff
}

.bg-default {
    background-color: #bdc3c7 !important;
    color: #fff
}

.bg-danger {
    background-color: #e74c3c !important;
    color: #fff
}

.bg-success {
    background-color: #2ecc71 !important;
    color: #fff
}

.bg-inverse {
    background-color: #34495e !important;
    color: #fff
}

.bg-info {
    background-color: #3498db !important;
    color: #fff
}

.color-primary {
    background-color: #1abc9c
}

.color-warning {
    background-color: #f1c40f
}

.color-default {
    background-color: #bdc3c7
}

.color-danger {
    background-color: #e74c3c
}

.color-success {
    background-color: #2ecc71
}

.color-inverse {
    background-color: #34495e
}

.color-info {
    background-color: #3498db
}

.loader-primary {
    background-color: #1abc9c !important
}

.loader-warning {
    background-color: #f1c40f !important
}

.loader-default {
    background-color: #bdc3c7 !important
}

.loader-danger {
    background-color: #e74c3c !important
}

.loader-success {
    background-color: #2ecc71 !important
}

.loader-inverse {
    background-color: #34495e !important
}

.loader-info {
    background-color: #3498db !important
}

.nestable-primary {
    background-color: #1abc9c !important;
    border-color: #1abc9c;
    color: #fff !important
}

.nestable-warning {
    background-color: #f1c40f !important;
    border-color: #f1c40f;
    color: #fff !important
}

.nestable-default {
    background-color: #bdc3c7 !important;
    border-color: #bdc3c7;
    color: #fff !important
}

.nestable-danger {
    background-color: #e74c3c !important;
    border-color: #e74c3c;
    color: #fff !important
}

.nestable-success {
    background-color: #2ecc71 !important;
    border-color: #2ecc71;
    color: #fff !important
}

.nestable-inverse {
    background-color: #34495e !important;
    border-color: #34495e;
    color: #fff !important
}

.nestable-info {
    background-color: #3498db !important;
    border-color: #3498db;
    color: #fff !important
}

table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
    border-bottom: 1px solid #1abc9c
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
    border-bottom: 1px solid #f1c40f
}

table thead .border-bottom-default th,
table tbody .border-bottom-default th,
table tbody .border-bottom-default td {
    border-bottom: 1px solid #bdc3c7
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
    border-bottom: 1px solid #e74c3c
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
    border-bottom: 1px solid #2ecc71
}

table thead .border-bottom-inverse th,
table tbody .border-bottom-inverse th,
table tbody .border-bottom-inverse td {
    border-bottom: 1px solid #34495e
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
    border-bottom: 1px solid #3498db
}

.table-styling .table-primary,
.table-styling.table-primary {
    background-color: #1abc9c;
    color: #fff;
    border: 3px solid #1abc9c
}

.table-styling .table-primary thead,
.table-styling.table-primary thead {
    background-color: #148f77;
    border: 3px solid #148f77
}

.table-styling .table-warning,
.table-styling.table-warning {
    background-color: #f1c40f;
    color: #fff;
    border: 3px solid #f1c40f
}

.table-styling .table-warning thead,
.table-styling.table-warning thead {
    background-color: #c29d0b;
    border: 3px solid #c29d0b
}

.table-styling .table-default,
.table-styling.table-default {
    background-color: #bdc3c7;
    color: #fff;
    border: 3px solid #bdc3c7
}

.table-styling .table-default thead,
.table-styling.table-default thead {
    background-color: #a1aab0;
    border: 3px solid #a1aab0
}

.table-styling .table-danger,
.table-styling.table-danger {
    background-color: #e74c3c;
    color: #fff;
    border: 3px solid #e74c3c
}

.table-styling .table-danger thead,
.table-styling.table-danger thead {
    background-color: #d62c1a;
    border: 3px solid #d62c1a
}

.table-styling .table-success,
.table-styling.table-success {
    background-color: #2ecc71;
    color: #fff;
    border: 3px solid #2ecc71
}

.table-styling .table-success thead,
.table-styling.table-success thead {
    background-color: #25a25a;
    border: 3px solid #25a25a
}

.table-styling .table-inverse,
.table-styling.table-inverse {
    background-color: #34495e;
    color: #fff;
    border: 3px solid #34495e
}

.table-styling .table-inverse thead,
.table-styling.table-inverse thead {
    background-color: #22303d;
    border: 3px solid #22303d
}

.table-styling .table-info,
.table-styling.table-info {
    background-color: #3498db;
    color: #fff;
    border: 3px solid #3498db
}

.table-styling .table-info thead,
.table-styling.table-info thead {
    background-color: #217dbb;
    border: 3px solid #217dbb
}

.toolbar-primary .tool-item {
    background: #1abc9c !important
}

.toolbar-primary .tool-item.selected,
.toolbar-primary .tool-item:hover {
    background: #148f77 !important
}

.toolbar-primary.tool-top .arrow {
    border-color: #1abc9c transparent transparent
}

.toolbar-primary.tool-bottom .arrow {
    border-color: transparent transparent #1abc9c
}

.toolbar-primary.tool-left .arrow {
    border-color: transparent transparent transparent #1abc9c
}

.toolbar-primary.tool-right .arrow {
    border-color: transparent #1abc9c transparent transparent
}

.btn-toolbar-primary.pressed {
    background-color: #1abc9c
}

.toolbar-warning .tool-item {
    background: #f1c40f !important
}

.toolbar-warning .tool-item.selected,
.toolbar-warning .tool-item:hover {
    background: #c29d0b !important
}

.toolbar-warning.tool-top .arrow {
    border-color: #f1c40f transparent transparent
}

.toolbar-warning.tool-bottom .arrow {
    border-color: transparent transparent #f1c40f
}

.toolbar-warning.tool-left .arrow {
    border-color: transparent transparent transparent #f1c40f
}

.toolbar-warning.tool-right .arrow {
    border-color: transparent #f1c40f transparent transparent
}

.btn-toolbar-warning.pressed {
    background-color: #f1c40f
}

.toolbar-light .tool-item {
    background: #bdc3c7 !important
}

.toolbar-light .tool-item.selected,
.toolbar-light .tool-item:hover {
    background: #a1aab0 !important
}

.toolbar-light.tool-top .arrow {
    border-color: #bdc3c7 transparent transparent
}

.toolbar-light.tool-bottom .arrow {
    border-color: transparent transparent #bdc3c7
}

.toolbar-light.tool-left .arrow {
    border-color: transparent transparent transparent #bdc3c7
}

.toolbar-light.tool-right .arrow {
    border-color: transparent #bdc3c7 transparent transparent
}

.btn-toolbar-light.pressed {
    background-color: #bdc3c7
}

.toolbar-danger .tool-item {
    background: #e74c3c !important
}

.toolbar-danger .tool-item.selected,
.toolbar-danger .tool-item:hover {
    background: #d62c1a !important
}

.toolbar-danger.tool-top .arrow {
    border-color: #e74c3c transparent transparent
}

.toolbar-danger.tool-bottom .arrow {
    border-color: transparent transparent #e74c3c
}

.toolbar-danger.tool-left .arrow {
    border-color: transparent transparent transparent #e74c3c
}

.toolbar-danger.tool-right .arrow {
    border-color: transparent #e74c3c transparent transparent
}

.btn-toolbar-danger.pressed {
    background-color: #e74c3c
}

.toolbar-success .tool-item {
    background: #2ecc71 !important
}

.toolbar-success .tool-item.selected,
.toolbar-success .tool-item:hover {
    background: #25a25a !important
}

.toolbar-success.tool-top .arrow {
    border-color: #2ecc71 transparent transparent
}

.toolbar-success.tool-bottom .arrow {
    border-color: transparent transparent #2ecc71
}

.toolbar-success.tool-left .arrow {
    border-color: transparent transparent transparent #2ecc71
}

.toolbar-success.tool-right .arrow {
    border-color: transparent #2ecc71 transparent transparent
}

.btn-toolbar-success.pressed {
    background-color: #2ecc71
}

.toolbar-dark .tool-item {
    background: #34495e !important
}

.toolbar-dark .tool-item.selected,
.toolbar-dark .tool-item:hover {
    background: #22303d !important
}

.toolbar-dark.tool-top .arrow {
    border-color: #34495e transparent transparent
}

.toolbar-dark.tool-bottom .arrow {
    border-color: transparent transparent #34495e
}

.toolbar-dark.tool-left .arrow {
    border-color: transparent transparent transparent #34495e
}

.toolbar-dark.tool-right .arrow {
    border-color: transparent #34495e transparent transparent
}

.btn-toolbar-dark.pressed {
    background-color: #34495e
}

.toolbar-info .tool-item {
    background: #3498db !important
}

.toolbar-info .tool-item.selected,
.toolbar-info .tool-item:hover {
    background: #217dbb !important
}

.toolbar-info.tool-top .arrow {
    border-color: #3498db transparent transparent
}

.toolbar-info.tool-bottom .arrow {
    border-color: transparent transparent #3498db
}

.toolbar-info.tool-left .arrow {
    border-color: transparent transparent transparent #3498db
}

.toolbar-info.tool-right .arrow {
    border-color: transparent #3498db transparent transparent
}

.btn-toolbar-info.pressed {
    background-color: #3498db
}

.card-border-primary {
    border-top: 4px solid #1abc9c
}

.card-border-warning {
    border-top: 4px solid #f1c40f
}

.card-border-default {
    border-top: 4px solid #bdc3c7
}

.card-border-danger {
    border-top: 4px solid #e74c3c
}

.card-border-success {
    border-top: 4px solid #2ecc71
}

.card-border-inverse {
    border-top: 4px solid #34495e
}

.card-border-info {
    border-top: 4px solid #3498db
}

.panels-wells .panel-primary {
    border-color: #1abc9c
}

.panels-wells .panel-warning {
    border-color: #f1c40f
}

.panels-wells .panel-default {
    border-color: #bdc3c7
}

.panels-wells .panel-danger {
    border-color: #e74c3c
}

.panels-wells .panel-success {
    border-color: #2ecc71
}

.panels-wells .panel-inverse {
    border-color: #34495e
}

.panels-wells .panel-info {
    border-color: #3498db
}

.b-t-primary {
    border-top: 1px solid #1abc9c
}

.b-b-primary {
    border-bottom: 1px solid #1abc9c
}

.b-l-primary {
    border-left: 1px solid #1abc9c
}

.b-r-primary {
    border-right: 1px solid #1abc9c
}

.b-t-warning {
    border-top: 1px solid #f1c40f
}

.b-b-warning {
    border-bottom: 1px solid #f1c40f
}

.b-l-warning {
    border-left: 1px solid #f1c40f
}

.b-r-warning {
    border-right: 1px solid #f1c40f
}

.b-t-default {
    border-top: 1px solid #bdc3c7
}

.b-b-default {
    border-bottom: 1px solid #bdc3c7
}

.b-l-default {
    border-left: 1px solid #bdc3c7
}

.b-r-default {
    border-right: 1px solid #bdc3c7
}

.b-t-danger {
    border-top: 1px solid #e74c3c
}

.b-b-danger {
    border-bottom: 1px solid #e74c3c
}

.b-l-danger {
    border-left: 1px solid #e74c3c
}

.b-r-danger {
    border-right: 1px solid #e74c3c
}

.b-t-success {
    border-top: 1px solid #2ecc71
}

.b-b-success {
    border-bottom: 1px solid #2ecc71
}

.b-l-success {
    border-left: 1px solid #2ecc71
}

.b-r-success {
    border-right: 1px solid #2ecc71
}

.b-t-inverse {
    border-top: 1px solid #34495e
}

.b-b-inverse {
    border-bottom: 1px solid #34495e
}

.b-l-inverse {
    border-left: 1px solid #34495e
}

.b-r-inverse {
    border-right: 1px solid #34495e
}

.b-t-info {
    border-top: 1px solid #3498db
}

.b-b-info {
    border-bottom: 1px solid #3498db
}

.b-l-info {
    border-left: 1px solid #3498db
}

.b-r-info {
    border-right: 1px solid #3498db
}

.b-t-theme {
    border-top: 1px solid #ddd
}

.b-b-theme {
    border-bottom: 1px solid #ddd
}

.b-l-theme {
    border-left: 1px solid #ddd
}

.b-r-theme {
    border-right: 1px solid #ddd
}

.main-header {
    margin-bottom: 20px;
    padding: 20px 0 20px 20px
}

.main-header span {
    color: #9f9f9f;
    display: block;
    font-size: 13px;
    margin-top: 5px
}

.header-navbar {
    min-height: 4em;
    padding: 0
}

.header-navbar .navbar-wrapper {
    background-color: #2c3e50
}

.header-navbar .navbar-wrapper .navbar-logo {
    align-items: center;
    display: flex;
    float: left;
    height: 3.5rem;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    width: 240px
}

.header-navbar .navbar-wrapper .navbar-logo h5 {
    margin-bottom: 0
}

.header-navbar .navbar-wrapper .navbar-logo a {
    color: #fff
}

.header-navbar .navbar-wrapper .navbar-logo .mobile-menu,
.header-navbar .navbar-wrapper .navbar-logo .mobile-search,
.header-navbar .navbar-wrapper .navbar-logo .mobile-options {
    display: none
}

.header-navbar .navbar-wrapper .navbar-container {
    margin-left: 240px
}

.header-navbar .navbar-wrapper .navbar-container .nav-left,
.header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: left;
    margin-bottom: 0
}

.header-navbar .navbar-wrapper .navbar-container .nav-left li,
.header-navbar .navbar-wrapper .navbar-container .nav-right li {
    float: left;
    line-height: 4;
    padding: 0 10px;
    position: relative
}

.header-navbar .navbar-wrapper .navbar-container .nav-left a,
.header-navbar .navbar-wrapper .navbar-container .nav-right a {
    color: #fff;
    padding: 1.5rem .6rem
}

.header-navbar .navbar-wrapper .navbar-container .nav-left .mega-menu-top i,
.header-navbar .navbar-wrapper .navbar-container .nav-right .mega-menu-top i {
    font-size: 10px;
    margin-left: 10px
}

.header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: right
}

.header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile img {
    margin-right: 10px;
    width: 40px
}

.header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile i {
    font-size: 10px;
    margin-left: 10px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top {
    perspective: 1000px;
    z-index: 99
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .badge,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .badge {
    background: red;
    border-radius: 100px;
    left: 27px;
    position: absolute;
    top: 10px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    -webkit-transform: translateY(30px);
    background: #fff;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .03), 0 -2px 1px -2px rgba(0, 0, 0, .02), 0 0 0 -1px rgba(0, 0, 0, .03);
    opacity: 0;
    position: absolute;
    right: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 24rem
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
    color: #666
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li {
    border-top: 1px solid #1abc9c, #f1c40f, #bdc3c7, #e74c3c, #2ecc71, #34495e, #3498db;
    line-height: initial;
    padding: 1em 20px;
    width: 100%
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child {
    padding: 10px 20px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child h6,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child h6,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child h6,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child h6 {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child label,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child label,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child label,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child label {
    float: right
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li img,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li img,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img {
    width: 40px;
    margin-right: 10px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-user,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .notification-user,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-user,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .notification-user {
    font-size: 15px;
    font-weight: 700
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-msg,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .notification-msg,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-msg,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .notification-msg {
    margin-bottom: 5px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification .notification-time,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .notification-time,
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification .notification-time,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .notification-time {
    font-size: 12px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    width: 17em
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
    font-size: 15px;
    padding-left: 0
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification i,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification i {
    font-size: 15px;
    margin-left: 0;
    margin-right: 5px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li {
    border-top: none;
    padding: .7em 20px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child {
    padding: .7em 20px
}

.header-navbar .navbar-wrapper .navbar-container .header-notification:hover .show-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top:hover .show-notification,
.header-navbar .navbar-wrapper .navbar-container .header-notification:hover .profile-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top:hover .profile-notification {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    visibility: visible
}

.header-navbar .navbar-wrapper .navbar-container .lng-dropdown .show-notification,
.header-navbar .navbar-wrapper .navbar-container .lng-dropdown .header-notification .profile-notification,
.header-navbar .navbar-wrapper .navbar-container .header-notification .lng-dropdown .profile-notification,
.header-navbar .navbar-wrapper .navbar-container .lng-dropdown .mega-menu-top .profile-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .lng-dropdown .profile-notification {
    width: 14em
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top {
    perspective: inherit;
    position: static !important
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    left: 0;
    margin: 0 25px;
    width: calc(100% - 50px);
    z-index: 9999
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li {
    padding: 20px
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child {
    padding: 20px
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child h6,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child h6 {
    border-bottom: 1px solid #1abc9c, #f1c40f, #bdc3c7, #e74c3c, #2ecc71, #34495e, #3498db;
    display: block;
    margin-bottom: 20px;
    padding-bottom: 10px
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li h6,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li h6 {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-transform: uppercase
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img {
    border-radius: 0;
    transition: all ease-in-out .3s;
    width: auto
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img:hover,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img:hover {
    box-shadow: 0 0 10px 2px rgba(128, 128, 128, .37)
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li {
    border-top: none;
    padding-left: 0;
    padding-top: 0
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li a,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li a {
    padding: 0
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li a i,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li a i {
    font-size: 20px;
    margin-left: 0;
    margin-right: 15px
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li h5,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li h5 {
    font-size: 15px;
    font-weight: 600
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-links li {
    border-top: none;
    padding: 10px 0
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-links li:first-child {
    padding-top: 0;
    padding: 10px 0
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-links a {
    display: block;
    font-size: 14px;
    padding: 0;
    text-transform: capitalize
}

.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-contact label,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .mega-menu-contact .form-control {
    font-size: 14px
}

.main-menu {
    background: #2c3e50;
    float: left;
    width: 235px;
    z-index: 99
}

.main-menu .main-menu-header {
    align-items: center;
    background: #4a6076;
    display: flex;
    padding: 20px
}

.main-menu .main-menu-header .user-details {
    display: inline-block;
    margin-left: 10px
}

.main-menu .main-menu-header .user-details #more-details {
    cursor: pointer
}

.main-menu .main-menu-header .user-details span {
    color: #fff;
    display: block;
    font-size: 14px;
    text-transform: capitalize
}

.main-menu .main-menu-header .user-details span i {
    font-size: 10px;
    margin-left: 10px
}

.main-menu .main-menu-header .user-details span:first-child {
    margin-bottom: 5px
}

.main-menu .main-menu-content .more-details {
    display: none
}

.main-menu .main-menu-content .more-details a {
    padding: 10px 15px;
    display: block;
    color: #fff;
    transition: all ease-in .3s
}

.main-menu .main-menu-content .more-details a:hover {
    color: #1abc9c
}

.main-menu .main-menu-content .more-details a i {
    margin-right: 10px
}

.main-menu .main-menu-content .menu-search {
    padding: 20px;
    padding-bottom: 10px
}

.main-menu .main-menu-content li {
    position: relative
}

.main-menu .main-menu-content .nav-title {
    color: #2ed8b6;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 10px 5px;
    text-transform: uppercase
}

.main-menu .main-menu-content .nav-title i {
    font-weight: 800
}

.main-menu .main-menu-content .nav-title .menu-caption {
    right: 10px
}

.main-menu .main-menu-content .nav-item.has-class>a,
.main-menu .main-menu-content .nav-item .tree-1 a.has-class>a,
.main-menu .main-menu-content .nav-item .tree-2 a.has-class>a,
.main-menu .main-menu-content .nav-item .tree-3 a.has-class>a,
.main-menu .main-menu-content .nav-item .tree-4 a.has-class>a {
    background: #1abc9c
}

.main-menu .main-menu-content .nav-item.has-class>a:hover,
.main-menu .main-menu-content .nav-item .tree-1 a.has-class>a:hover,
.main-menu .main-menu-content .nav-item .tree-2 a.has-class>a:hover,
.main-menu .main-menu-content .nav-item .tree-3 a.has-class>a:hover,
.main-menu .main-menu-content .nav-item .tree-4 a.has-class>a:hover {
    color: #fff
}

.main-menu .main-menu-content .tree-1.open li .menu-caption,
.main-menu .main-menu-content .nav-item .open.tree-2 li .menu-caption,
.main-menu .main-menu-content .nav-item .open.tree-3 li .menu-caption,
.main-menu .main-menu-content .nav-item .open.tree-4 li .menu-caption {
    right: 10px
}

.main-menu .main-menu-content .tree-1.open .nav-sub-item>a,
.main-menu .main-menu-content .nav-item .open.tree-2 .nav-sub-item>a,
.main-menu .main-menu-content .nav-item .open.tree-3 .nav-sub-item>a,
.main-menu .main-menu-content .nav-item .open.tree-4 .nav-sub-item>a {
    background: #34495e
}

.main-menu .main-menu-content .tree-1.open .has-class>a,
.main-menu .main-menu-content .nav-item .open.tree-2 .has-class>a,
.main-menu .main-menu-content .nav-item .open.tree-3 .has-class>a,
.main-menu .main-menu-content .nav-item .open.tree-4 .has-class>a,
.main-menu .main-menu-content .tree-2.open .has-class>a,
.main-menu .main-menu-content .tree-3.open .has-class>a,
.main-menu .main-menu-content .tree-4.open .has-class>a {
    color: #1abc9c;
    font-weight: 600
}

.main-menu .main-menu-content .nav-item,
.main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-4 a {
    color: #fff
}

.main-menu .main-menu-content .nav-item a,
.main-menu .main-menu-content .nav-item .tree-1 a a,
.main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-4 a a {
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 10px 15px;
    transition: all ease-in .2s
}

.main-menu .main-menu-content .nav-item a:hover {
    color: #1abc9c
}

.main-menu .main-menu-content .nav-item i,
.main-menu .main-menu-content .nav-item .tree-1 a i,
.main-menu .main-menu-content .nav-item .tree-2 a i,
.main-menu .main-menu-content .nav-item .tree-3 a i,
.main-menu .main-menu-content .nav-item .tree-4 a i {
    margin-right: 10px;
    font-size: 15px;
    vertical-align: middle
}

.main-menu .main-menu-content .nav-item .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-1 a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-2 a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-3 a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-4 a .icon-arrow-down {
    display: none
}

.main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-4 a {
    padding-left: 50px;
    position: relative
}

.main-menu .main-menu-content .nav-item .tree-1 a:before,
.main-menu .main-menu-content .nav-item .tree-2 a:before,
.main-menu .main-menu-content .nav-item .tree-3 a:before,
.main-menu .main-menu-content .nav-item .tree-4 a:before {
    content: '\e649';
    font-family: themify;
    position: absolute;
    left: 30px;
    font-size: 10px;
    top: 13px
}

.main-menu .main-menu-content .nav-item .tree-1 .icon-arrow-right,
.main-menu .main-menu-content .nav-item .tree-2 .icon-arrow-right,
.main-menu .main-menu-content .nav-item .tree-3 .icon-arrow-right,
.main-menu .main-menu-content .nav-item .tree-4 .icon-arrow-right {
    display: none
}

.main-menu .main-menu-content .nav-item .tree-2 a {
    padding-left: 60px
}

.main-menu .main-menu-content .nav-item .tree-2 a:before {
    left: 40px
}

.main-menu .main-menu-content .nav-item .tree-3 a {
    padding-left: 80px
}

.main-menu .main-menu-content .nav-item .tree-3 a:before {
    left: 60px
}

.main-menu .main-menu-content .nav-item .tree-4 a {
    padding-left: 100px
}

.main-menu .main-menu-content .nav-item .tree-4 a:before {
    left: 80px
}

.main-navigation a.nav-link.disabled {
    color: #a2a2a2 !important
}

.main-navigation .nav-title span {
    padding-left: 5px
}

.main-navigation .nav-item.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.single-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.single-item>a:after,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.single-item>a:after {
    display: none
}

.main-navigation .nav-item.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.single-item .menu-caption,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.single-item .menu-caption {
    right: 10px
}

.main-navigation .menu-caption {
    position: absolute;
    right: 40px;
    top: 14px
}

.main-navigation .nav-item>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a,
.main-navigation .nav-sub-item>a,
.main-navigation .nav-sub-item-3>a,
.main-navigation .nav-sub-item-4>a {
    position: relative
}

.main-navigation .nav-item>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a:after,
.main-navigation .nav-sub-item>a:after,
.main-navigation .nav-sub-item-3>a:after,
.main-navigation .nav-sub-item-4>a:after {
    content: '\e61a';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

.main-navigation .nav-item.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class>a:after,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class>a:after,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class>a:after,
.main-navigation .nav-sub-item.has-class>a:after,
.main-navigation .nav-sub-item-3.has-class>a:after,
.main-navigation .nav-sub-item-4.has-class>a:after {
    content: '\e622';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

.main-navigation .nav-item .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-1,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .main-navigation .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .main-navigation .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-4,
.main-navigation .nav-item .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-2,
.main-navigation .nav-item .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-3,
.main-navigation .nav-item .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-4,
.main-navigation .nav-sub-item .tree-1,
.main-navigation .nav-sub-item .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item .tree-2,
.main-navigation .nav-sub-item .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item .tree-3,
.main-navigation .nav-sub-item .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item .tree-4,
.main-navigation .nav-sub-item .tree-2,
.main-navigation .nav-sub-item .tree-3,
.main-navigation .nav-sub-item .tree-4,
.main-navigation .nav-sub-item-3 .tree-1,
.main-navigation .nav-sub-item-3 .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3 .tree-2,
.main-navigation .nav-sub-item-3 .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3 .tree-3,
.main-navigation .nav-sub-item-3 .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3 .tree-4,
.main-navigation .nav-sub-item-3 .tree-2,
.main-navigation .nav-sub-item-3 .tree-3,
.main-navigation .nav-sub-item-3 .tree-4,
.main-navigation .nav-sub-item-4 .tree-1,
.main-navigation .nav-sub-item-4 .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4 .tree-2,
.main-navigation .nav-sub-item-4 .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4 .tree-3,
.main-navigation .nav-sub-item-4 .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4 .tree-4,
.main-navigation .nav-sub-item-4 .tree-2,
.main-navigation .nav-sub-item-4 .tree-3,
.main-navigation .nav-sub-item-4 .tree-4 {
    display: none
}

.main-navigation .nav-item.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-1.open,
.main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-2,
.main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-3,
.main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-4,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-4,
.main-navigation .nav-item.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-2.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-2.open,
.main-navigation .nav-item.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-3.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-3.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-3.open,
.main-navigation .nav-item.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .tree-4.open,
.main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-4.open,
.main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .tree-4.open,
.main-navigation .nav-sub-item.has-class .tree-1.open,
.main-navigation .nav-sub-item.has-class .main-menu .main-menu-content .nav-item .open.tree-2,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item.has-class .open.tree-2,
.main-navigation .nav-sub-item.has-class .main-menu .main-menu-content .nav-item .open.tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item.has-class .open.tree-3,
.main-navigation .nav-sub-item.has-class .main-menu .main-menu-content .nav-item .open.tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item.has-class .open.tree-4,
.main-navigation .nav-sub-item.has-class .tree-2.open,
.main-navigation .nav-sub-item.has-class .tree-3.open,
.main-navigation .nav-sub-item.has-class .tree-4.open,
.main-navigation .nav-sub-item-3.has-class .tree-1.open,
.main-navigation .nav-sub-item-3.has-class .main-menu .main-menu-content .nav-item .open.tree-2,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3.has-class .open.tree-2,
.main-navigation .nav-sub-item-3.has-class .main-menu .main-menu-content .nav-item .open.tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3.has-class .open.tree-3,
.main-navigation .nav-sub-item-3.has-class .main-menu .main-menu-content .nav-item .open.tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-3.has-class .open.tree-4,
.main-navigation .nav-sub-item-3.has-class .tree-2.open,
.main-navigation .nav-sub-item-3.has-class .tree-3.open,
.main-navigation .nav-sub-item-3.has-class .tree-4.open,
.main-navigation .nav-sub-item-4.has-class .tree-1.open,
.main-navigation .nav-sub-item-4.has-class .main-menu .main-menu-content .nav-item .open.tree-2,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4.has-class .open.tree-2,
.main-navigation .nav-sub-item-4.has-class .main-menu .main-menu-content .nav-item .open.tree-3,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4.has-class .open.tree-3,
.main-navigation .nav-sub-item-4.has-class .main-menu .main-menu-content .nav-item .open.tree-4,
.main-menu .main-menu-content .nav-item .main-navigation .nav-sub-item-4.has-class .open.tree-4,
.main-navigation .nav-sub-item-4.has-class .tree-2.open,
.main-navigation .nav-sub-item-4.has-class .tree-3.open,
.main-navigation .nav-sub-item-4.has-class .tree-4.open {
    display: block
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .main-menu-header,
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .main-menu-content {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .main-menu {
    width: 45px
}

.sidebar_toggle a {
    margin: -5px;
    line-height: 1
}

body.menu-collapsed label {
    display: none
}

body.menu-collapsed .slimScrollDiv,
body.menu-collapsed .main-menu-content {
    overflow: visible !important
}

body.menu-collapsed .main-menu-header,
body.menu-collapsed .main-navigation .nav-item .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-4,
body.menu-collapsed .main-menu .main-menu-content .main-navigation .nav-item .tree-2,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-1 .main-navigation a .tree-2,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a .tree-2,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a .tree-2,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a .tree-2,
body.menu-collapsed .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-2,
body.menu-collapsed .nav-title span,
body.menu-collapsed .nav-item span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-1 a span,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed a span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-2 a span,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed a span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-3 a span,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed a span,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-4 a span,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed a span,
body.menu-collapsed .nav-item>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-1 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-2 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-3 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .main-menu .main-menu-content .nav-item .tree-4 a>a i.icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed a>a i.icon-arrow-down,
body.menu-collapsed .menu-search {
    display: none
}

body.menu-collapsed .main-menu {
    width: 70px
}

body.menu-collapsed .main-menu .main-menu-content .nav-title {
    text-align: center
}

body.menu-collapsed .main-navigation {
    transition: all ease-in .3s
}

body.menu-collapsed .main-navigation .nav-item,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a {
    text-align: center
}

body.menu-collapsed .main-navigation .nav-item>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a>a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a>a {
    position: relative
}

body.menu-collapsed .main-navigation .nav-item>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a>a:after,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a>a:after {
    display: none
}

body.menu-collapsed .main-navigation .nav-item>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a i,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a>a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a>a i {
    margin-right: 0
}

body.menu-collapsed .main-navigation .nav-item i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a i,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a i,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a i {
    margin-right: 5px
}

body.menu-collapsed .main-navigation .nav-item .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-4 {
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 70px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 99
}

body.menu-collapsed .main-navigation .nav-item:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-1,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-2,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-3,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a:hover .tree-4,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-collapsed .main-navigation .nav-item .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-1 li,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-2 li,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-3 li,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-4 li,
body.menu-collapsed .main-navigation .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-1 a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-2 a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-3 a,
body.menu-collapsed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content body.menu-collapsed .main-navigation .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-collapsed .main-navigation a .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-collapsed .main-navigation a .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-collapsed .main-navigation a .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-collapsed .main-navigation a .tree-4 a {
    text-align: left !important
}

body.menu-collapsed .main-navigation .nav-sub-item>a {
    position: relative
}

body.menu-collapsed .main-navigation .nav-sub-item>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.menu-collapsed .main-navigation .nav-sub-item .tree-2 {
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 210px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 99
}

body.menu-collapsed .main-navigation .nav-sub-item .tree-2 a {
    padding-left: 30px
}

body.menu-collapsed .main-navigation .nav-sub-item .tree-2 a:before {
    left: 10px
}

body.menu-collapsed .main-navigation .nav-sub-item:hover .tree-2 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-collapsed .main-navigation .nav-sub-item-3>a {
    position: relative
}

body.menu-collapsed .main-navigation .nav-sub-item-3>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.menu-collapsed .main-navigation .nav-sub-item-3 .tree-3 {
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 210px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 99
}

body.menu-collapsed .main-navigation .nav-sub-item-3 .tree-3 a {
    padding-left: 30px
}

body.menu-collapsed .main-navigation .nav-sub-item-3 .tree-3 a:before {
    left: 10px
}

body.menu-collapsed .main-navigation .nav-sub-item-3:hover .tree-3 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-collapsed .main-navigation .nav-sub-item-4>a {
    position: relative
}

body.menu-collapsed .main-navigation .nav-sub-item-4>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.menu-collapsed .main-navigation .nav-sub-item-4 .tree-4 {
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 210px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 99
}

body.menu-collapsed .main-navigation .nav-sub-item-4 .tree-4 a {
    padding-left: 30px
}

body.menu-collapsed .main-navigation .nav-sub-item-4 .tree-4 a:before {
    left: 10px
}

body.menu-collapsed .main-navigation .nav-sub-item-4:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-collapsed .main-body .page-wrapper {
    margin-left: 70px;
    transition: all ease-in .3s
}

body.fix-menu .navbar-wrapper,
body.menu-compact .navbar-wrapper,
body.menu-bottom .navbar-wrapper,
body.menu-rtl .navbar-wrapper,
body.menu-sidebar .navbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999
}

body.fix-menu .main-menu,
body.menu-compact .main-menu,
body.menu-bottom .main-menu,
body.menu-rtl .main-menu,
body.menu-sidebar .main-menu {
    left: 0;
    position: fixed
}

body.menu-static .navbar-wrapper,
body.menu-static .main-menu {
    position: initial
}

body.header-fixed .navbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999
}

body.header-fixed .main-menu {
    position: fixed
}

body.header-fixed .main-body .page-wrapper {
    margin-left: 0
}

body.header-fixed.menu-hide .main-menu {
    opacity: 0;
    transform: translateX(-240px);
    transition: all linear 300ms
}

body.header-fixed.menu-show .main-menu {
    opacity: 1;
    transform: translateX(0px);
    transition: all linear 300ms
}

body.menu-compact .slimScrollDiv,
body.menu-compact .main-menu-content {
    overflow: visible !important
}

body.menu-compact #collapse-menu {
    display: none
}

body.menu-compact .menu-caption {
    display: none
}

body.menu-compact .main-menu {
    width: 150px
}

body.menu-compact .nav-item>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-1 a>a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a {
    padding: 20px 15px !important;
    text-align: center
}

body.menu-compact .nav-item>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-1 a>a i,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a i,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a i {
    display: block;
    font-size: 30px;
    margin-bottom: 10px;
    margin-right: 0 !important
}

body.menu-compact .nav-item>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-1 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a .icon-arrow-down,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a .icon-arrow-down,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a .icon-arrow-down {
    display: none
}

body.menu-compact .nav-item>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact a>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact a>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact a>a:after,
body.menu-compact .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact a>a:after {
    display: none
}

body.menu-compact .main-body .page-wrapper {
    margin-left: 150px
}

body.menu-compact .main-navigation .nav-item .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-4,
body.menu-compact .main-menu-header,
body.menu-compact .nav-title {
    display: none
}

body.menu-compact .main-navigation {
    transition: all ease-in .3s
}

body.menu-compact .main-navigation .nav-item,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a {
    text-align: center
}

body.menu-compact .main-navigation .nav-item i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a i,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a i,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a i,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a i,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a i,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a i {
    margin-right: 5px
}

body.menu-compact .main-navigation .nav-item .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-4 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background: #34495e;
    display: block;
    left: 150px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em
}

body.menu-compact .main-navigation .nav-item:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-1,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-2,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-3,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a:hover .tree-4,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-compact .main-navigation .nav-item .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-1 li,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-2 li,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-3 li,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-4 li,
body.menu-compact .main-navigation .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-1 a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-2 a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-3 a,
body.menu-compact .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content body.menu-compact .main-navigation .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-compact .main-navigation a .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-compact .main-navigation a .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-compact .main-navigation a .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-compact .main-navigation a .tree-4 a {
    text-align: left !important
}

body.menu-compact .main-navigation .nav-sub-item>a {
    position: relative
}

body.menu-compact .main-navigation .nav-sub-item>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.menu-compact .main-navigation .nav-sub-item .tree-2 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 210px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 9999
}

body.menu-compact .main-navigation .nav-sub-item .tree-2 a {
    padding-left: 30px
}

body.menu-compact .main-navigation .nav-sub-item .tree-2 a:before {
    left: 10px
}

body.menu-compact .main-navigation .nav-sub-item:hover .tree-2 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-compact .main-navigation .nav-sub-item-3>a {
    position: relative
}

body.menu-compact .main-navigation .nav-sub-item-3>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.menu-compact .main-navigation .nav-sub-item-3 .tree-3 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 210px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 9999
}

body.menu-compact .main-navigation .nav-sub-item-3 .tree-3 a {
    padding-left: 30px
}

body.menu-compact .main-navigation .nav-sub-item-3 .tree-3 a:before {
    left: 10px
}

body.menu-compact .main-navigation .nav-sub-item-3:hover .tree-3 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-compact .main-navigation .nav-sub-item-4>a {
    position: relative
}

body.menu-compact .main-navigation .nav-sub-item-4>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.menu-compact .main-navigation .nav-sub-item-4 .tree-4 {
    z-index: 999;
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 210px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 15em;
    z-index: 9999
}

body.menu-compact .main-navigation .nav-sub-item-4 .tree-4 a {
    padding-left: 30px
}

body.menu-compact .main-navigation .nav-sub-item-4 .tree-4 a:before {
    left: 10px
}

body.menu-compact .main-navigation .nav-sub-item-4:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.menu-bottom .header-navbar {
    min-height: auto
}

body.menu-bottom .navbar-wrapper {
    bottom: 0;
    top: auto
}

body.menu-bottom .mega-menu-top .show-notification,
body.menu-bottom .mega-menu-top .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
    bottom: 0 !important
}

body.menu-bottom .header-notification .show-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .show-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .header-notification .profile-notification,
body.menu-bottom .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
.header-navbar .navbar-wrapper .navbar-container body.menu-bottom .mega-menu-top .profile-notification {
    bottom: 65px
}

body.menu-bottom #collapse-menu {
    display: none
}

body.menu-rtl {
    direction: rtl
}

body.menu-rtl ul {
    padding-right: 0
}

body.menu-rtl .main-menu {
    right: 0
}

body.menu-rtl .media-left {
    padding-right: 0
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li a i,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li a i,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li a i {
    margin-left: 15px;
    margin-right: 0
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification .mega-mailbox li,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification .mega-mailbox li {
    padding-right: 0
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-logo,
body.menu-rtl .breadcrumb-item {
    float: right
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-container {
    margin-left: 0
}

body.menu-rtl .main-body .page-wrapper {
    margin-left: 0;
    margin-right: 240px
}

body.menu-rtl .main-body .page-wrapper .page-header-breadcrumb {
    float: left
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .nav-right .user-profile img {
    margin-left: 10px
}

body.menu-rtl .main-navigation .menu-caption {
    left: 40px;
    right: auto
}

body.menu-rtl .main-navigation .nav-item.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-rtl .main-navigation a.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-rtl .main-navigation a.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-rtl .main-navigation a.single-item .menu-caption,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.single-item .menu-caption,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-rtl .main-navigation a.single-item .menu-caption {
    right: auto
}

body.menu-rtl .main-navigation .nav-item>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
.main-menu .main-menu-content .nav-item .tree-1 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
.main-menu .main-menu-content .nav-item .tree-2 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
.main-menu .main-menu-content .nav-item .tree-3 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
.main-menu .main-menu-content .nav-item .tree-4 body.menu-rtl .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-1 .main-navigation a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
body.menu-rtl .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a>a:after,
body.menu-rtl .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a>a:after,
body.menu-rtl .main-navigation .nav-sub-item>a:after,
body.menu-rtl .main-navigation .nav-sub-item-3>a:after,
body.menu-rtl .main-navigation .nav-sub-item-4>a:after {
    left: 20px;
    right: auto
}

body.menu-rtl .main-menu .main-menu-header .user-details {
    margin-right: 10px
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li img {
    margin-left: 10px
}

body.menu-rtl .live-status {
    right: auto;
    left: 27px
}

body.menu-rtl .userlist-box .media-left {
    padding-left: 10px
}

body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child label,
body.menu-rtl .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification li:first-child label {
    float: left
}

body.menu-rtl .rotate-refresh {
    display: initial
}

body.horizontal-fixed .header-navbar,
body.horizontal-static .header-navbar,
body.horizontal-icon .header-navbar,
body.horizontal-icon-fixed .header-navbar {
    position: fixed;
    width: 100%;
    z-index: 9999
}

body.horizontal-fixed .main-menu-header,
body.horizontal-static .main-menu-header,
body.horizontal-icon .main-menu-header,
body.horizontal-icon-fixed .main-menu-header,
body.horizontal-fixed #collapse-menu,
body.horizontal-static #collapse-menu,
body.horizontal-icon #collapse-menu,
body.horizontal-icon-fixed #collapse-menu,
body.horizontal-fixed .main-menu .main-menu-content li.nav-title,
body.horizontal-static .main-menu .main-menu-content li.nav-title,
body.horizontal-icon .main-menu .main-menu-content li.nav-title,
body.horizontal-icon-fixed .main-menu .main-menu-content li.nav-title,
body.horizontal-fixed .main-navigation .nav-item.has-class .tree-1.open,
body.horizontal-static .main-navigation .nav-item.has-class .tree-1.open,
body.horizontal-icon .main-navigation .nav-item.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .nav-item.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .tree-1.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .tree-1.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-1.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .tree-1.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .open.tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .open.tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item.has-class .open.tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .open.tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .open.tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .open.tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .open.tree-4,
body.horizontal-fixed .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .main-navigation .nav-item.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-1 a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-2 a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-3 a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .main-navigation .nav-item .tree-4 a.has-class .open.tree-2,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 .main-navigation a.has-class .open.tree-2,
body.horizontal-fixed .main-navigation .nav-item.has-class .tree-2.open,
body.horizontal-static .main-navigation .nav-item.has-class .tree-2.open,
body.horizontal-icon .main-navigation .nav-item.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .nav-item.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a.has-class .tree-2.open,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a.has-class .tree-2.open,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a.has-class .tree-2.open,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a.has-class .tree-2.open,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a.has-class .tree-2.open {
    display: none
}

body.horizontal-fixed .main-menu,
body.horizontal-static .main-menu,
body.horizontal-icon .main-menu,
body.horizontal-icon-fixed .main-menu {
    position: fixed;
    width: 100%;
    top: 55px;
    z-index: 98;
    background: #34475a
}

body.horizontal-fixed .main-menu .main-menu-content .nav-item>a,
body.horizontal-static .main-menu .main-menu-content .nav-item>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a {
    padding: 20px 15px
}

body.horizontal-fixed .main-navigation,
body.horizontal-static .main-navigation,
body.horizontal-icon .main-navigation,
body.horizontal-icon-fixed .main-navigation {
    margin-bottom: 0
}

body.horizontal-fixed .main-navigation .nav-item .tree-1,
body.horizontal-static .main-navigation .nav-item .tree-1,
body.horizontal-icon .main-navigation .nav-item .tree-1,
body.horizontal-icon-fixed .main-navigation .nav-item .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4 {
    -webkit-transform: translateY(30px);
    background-color: #34495e;
    display: block !important;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 60px;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999
}

body.horizontal-fixed .main-navigation .nav-item .tree-1 li,
body.horizontal-static .main-navigation .nav-item .tree-1 li,
body.horizontal-icon .main-navigation .nav-item .tree-1 li,
body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-1 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-1 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-1 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-1 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-2 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-3 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-1 a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 li,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 li,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 li,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 li,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a .tree-4 li,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4 li {
    display: block
}

body.horizontal-fixed .main-navigation .nav-item:hover .tree-1,
body.horizontal-static .main-navigation .nav-item:hover .tree-1,
body.horizontal-icon .main-navigation .nav-item:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-1,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-1,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-1,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1 a .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-2,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-2,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1 a .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-3,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-3,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-fixed .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-static .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item:hover .tree-1 a .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item:hover .tree-1 a .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-static .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-static .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 body.horizontal-icon-fixed .main-navigation a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-4,
body.horizontal-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-fixed .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-fixed .main-navigation a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-4,
body.horizontal-static .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-static .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-static .main-navigation a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-4,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon .main-navigation a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation .tree-1 a:hover .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 body.horizontal-icon-fixed .main-navigation a:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.horizontal-fixed .main-navigation .nav-sub-item>a,
body.horizontal-static .main-navigation .nav-sub-item>a,
body.horizontal-icon .main-navigation .nav-sub-item>a,
body.horizontal-icon-fixed .main-navigation .nav-sub-item>a {
    position: relative
}

body.horizontal-fixed .main-navigation .nav-sub-item>a:after,
body.horizontal-static .main-navigation .nav-sub-item>a:after,
body.horizontal-icon .main-navigation .nav-sub-item>a:after,
body.horizontal-icon-fixed .main-navigation .nav-sub-item>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.horizontal-fixed .main-navigation .nav-sub-item .tree-2,
body.horizontal-static .main-navigation .nav-sub-item .tree-2,
body.horizontal-icon .main-navigation .nav-sub-item .tree-2,
body.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 {
    -webkit-transform: translateY(30px);
    background-color: #2c3e50;
    display: block !important;
    left: 250px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999
}

body.horizontal-fixed .main-navigation .nav-sub-item .tree-2 li,
body.horizontal-static .main-navigation .nav-sub-item .tree-2 li,
body.horizontal-icon .main-navigation .nav-sub-item .tree-2 li,
body.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 li {
    display: block;
    padding-left: 15px
}

body.horizontal-fixed .main-navigation .nav-sub-item .tree-2 a,
body.horizontal-static .main-navigation .nav-sub-item .tree-2 a,
body.horizontal-icon .main-navigation .nav-sub-item .tree-2 a,
body.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 a {
    padding-left: 30px
}

body.horizontal-fixed .main-navigation .nav-sub-item .tree-2 a:before,
body.horizontal-static .main-navigation .nav-sub-item .tree-2 a:before,
body.horizontal-icon .main-navigation .nav-sub-item .tree-2 a:before,
body.horizontal-icon-fixed .main-navigation .nav-sub-item .tree-2 a:before {
    left: 10px
}

body.horizontal-fixed .main-navigation .nav-sub-item:hover .tree-2,
body.horizontal-static .main-navigation .nav-sub-item:hover .tree-2,
body.horizontal-icon .main-navigation .nav-sub-item:hover .tree-2,
body.horizontal-icon-fixed .main-navigation .nav-sub-item:hover .tree-2 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.horizontal-fixed .main-navigation .nav-sub-item-3>a,
body.horizontal-static .main-navigation .nav-sub-item-3>a,
body.horizontal-icon .main-navigation .nav-sub-item-3>a,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3>a {
    position: relative
}

body.horizontal-fixed .main-navigation .nav-sub-item-3>a:after,
body.horizontal-static .main-navigation .nav-sub-item-3>a:after,
body.horizontal-icon .main-navigation .nav-sub-item-3>a:after,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3,
body.horizontal-static .main-navigation .nav-sub-item-3 .tree-3,
body.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 {
    -webkit-transform: translateY(30px);
    background-color: #2c3e50;
    display: block !important;
    left: 250px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999
}

body.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3 li,
body.horizontal-static .main-navigation .nav-sub-item-3 .tree-3 li,
body.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3 li,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 li {
    display: block;
    padding-left: 15px
}

body.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3 a,
body.horizontal-static .main-navigation .nav-sub-item-3 .tree-3 a,
body.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3 a,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 a {
    padding-left: 30px
}

body.horizontal-fixed .main-navigation .nav-sub-item-3 .tree-3 a:before,
body.horizontal-static .main-navigation .nav-sub-item-3 .tree-3 a:before,
body.horizontal-icon .main-navigation .nav-sub-item-3 .tree-3 a:before,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3 .tree-3 a:before {
    left: 10px
}

body.horizontal-fixed .main-navigation .nav-sub-item-3:hover .tree-3,
body.horizontal-static .main-navigation .nav-sub-item-3:hover .tree-3,
body.horizontal-icon .main-navigation .nav-sub-item-3:hover .tree-3,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-3:hover .tree-3 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.horizontal-fixed .main-navigation .nav-sub-item-4>a,
body.horizontal-static .main-navigation .nav-sub-item-4>a,
body.horizontal-icon .main-navigation .nav-sub-item-4>a,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4>a {
    position: relative
}

body.horizontal-fixed .main-navigation .nav-sub-item-4>a:after,
body.horizontal-static .main-navigation .nav-sub-item-4>a:after,
body.horizontal-icon .main-navigation .nav-sub-item-4>a:after,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4>a:after {
    content: '\e65d';
    font-family: themify;
    font-size: 14px;
    position: absolute;
    right: 20px
}

body.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4,
body.horizontal-static .main-navigation .nav-sub-item-4 .tree-4,
body.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 {
    -webkit-transform: translateY(30px);
    background-color: #2c3e50;
    display: block !important;
    left: 250px;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateY(30px);
    transition: all linear .3s;
    visibility: hidden;
    width: 18em;
    z-index: 9999
}

body.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4 li,
body.horizontal-static .main-navigation .nav-sub-item-4 .tree-4 li,
body.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4 li,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 li {
    display: block;
    padding-left: 15px
}

body.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4 a,
body.horizontal-static .main-navigation .nav-sub-item-4 .tree-4 a,
body.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4 a,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 a {
    padding-left: 30px
}

body.horizontal-fixed .main-navigation .nav-sub-item-4 .tree-4 a:before,
body.horizontal-static .main-navigation .nav-sub-item-4 .tree-4 a:before,
body.horizontal-icon .main-navigation .nav-sub-item-4 .tree-4 a:before,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4 .tree-4 a:before {
    left: 10px
}

body.horizontal-fixed .main-navigation .nav-sub-item-4:hover .tree-4,
body.horizontal-static .main-navigation .nav-sub-item-4:hover .tree-4,
body.horizontal-icon .main-navigation .nav-sub-item-4:hover .tree-4,
body.horizontal-icon-fixed .main-navigation .nav-sub-item-4:hover .tree-4 {
    -webkit-transform: translateY(0px);
    opacity: 1;
    transform: translateY(0px);
    transition: all linear .3s;
    visibility: visible
}

body.horizontal-fixed .main-body .page-wrapper,
body.horizontal-static .main-body .page-wrapper,
body.horizontal-icon .main-body .page-wrapper,
body.horizontal-icon-fixed .main-body .page-wrapper {
    margin-left: 0
}

body.horizontal-fixed .main-menu .main-menu-content .nav-item>a,
body.horizontal-static .main-menu .main-menu-content .nav-item>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a {
    display: inline-block;
    position: relative
}

body.horizontal-fixed .main-menu .main-menu-content .nav-item>a:after,
body.horizontal-static .main-menu .main-menu-content .nav-item>a:after,
body.horizontal-icon .main-menu .main-menu-content .nav-item>a:after,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a:after,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a:after,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a:after,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a:after,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a:after,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a:after {
    display: none
}

body.horizontal-fixed .main-menu .main-menu-content li,
body.horizontal-static .main-menu .main-menu-content li,
body.horizontal-icon .main-menu .main-menu-content li,
body.horizontal-icon-fixed .main-menu .main-menu-content li {
    border-top: none;
    display: inline-block;
    margin-right: 0
}

body.horizontal-fixed .main-menu .main-menu-content .nav-item .icon-arrow-down,
body.horizontal-static .main-menu .main-menu-content .nav-item .icon-arrow-down,
body.horizontal-icon .main-menu .main-menu-content .nav-item .icon-arrow-down,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .icon-arrow-down {
    margin-right: 20px;
    position: relative;
    right: -20px;
    top: 0
}

body.horizontal-fixed .tree-1 li,
body.horizontal-static .tree-1 li,
body.horizontal-icon .tree-1 li,
body.horizontal-icon-fixed .tree-1 li,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-fixed .tree-2 li,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-static .tree-2 li,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon .tree-2 li,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon-fixed .tree-2 li,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-fixed .tree-3 li,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-static .tree-3 li,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon .tree-3 li,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon-fixed .tree-3 li,
body.horizontal-fixed .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-fixed .tree-4 li,
body.horizontal-static .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-static .tree-4 li,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon .tree-4 li,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 li,
.main-menu .main-menu-content .nav-item body.horizontal-icon-fixed .tree-4 li {
    display: block
}

body.horizontal-static .header-navbar,
body.horizontal-static .main-menu {
    position: initial
}

body.horizontal-static .page-header {
    margin-top: 60px
}

body.horizontal-icon .header-navbar,
body.horizontal-icon-fixed .header-navbar,
body.horizontal-icon .main-menu,
body.horizontal-icon-fixed .main-menu {
    position: inherit
}

body.horizontal-icon .main-menu .main-menu-content .nav-item>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a {
    text-align: center
}

body.horizontal-icon .main-menu .main-menu-content .nav-item>a i,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a i,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-1 a>a i,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a i,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-2 a>a i,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a i,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-3 a>a i,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a i,
body.horizontal-icon .main-menu .main-menu-content .nav-item .tree-4 a>a i,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a i {
    display: block;
    margin-bottom: 5px;
    margin-right: 0
}

body.horizontal-icon .main-navigation .nav-item .tree-1,
body.horizontal-icon-fixed .main-navigation .nav-item .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-1,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-1,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-2,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-2,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-2,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-3,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-3,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-3,
body.horizontal-icon .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon .main-navigation .nav-item .tree-4,
body.horizontal-icon-fixed .main-navigation .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content body.horizontal-icon-fixed .main-navigation .nav-item .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-2 body.horizontal-icon-fixed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-3 body.horizontal-icon-fixed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-4 body.horizontal-icon-fixed .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon .main-navigation a .tree-4,
.main-menu .main-menu-content .nav-item .tree-1 body.horizontal-icon-fixed .main-navigation a .tree-4 {
    top: 80px
}

body.horizontal-icon-fixed .main-menu {
    position: fixed
}

body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item>a i.icon-arrow-down,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-1 a>a i.icon-arrow-down,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-2 a>a i.icon-arrow-down,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-3 a>a i.icon-arrow-down,
body.horizontal-icon-fixed .main-menu .main-menu-content .nav-item .tree-4 a>a i.icon-arrow-down {
    display: none
}

body.horizontal-icon-fixed .header-navbar {
    position: initial
}

body.horizontal-icon-fixed .page-header {
    margin-top: 80px
}

body.menu-sidebar .navbar-wrapper {
    position: initial
}

.btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    font-family: open sans
}

.button-page .card-block {
    margin-bottom: -20px
}

.button-page .card-block ul {
    margin-bottom: 0;
    display: inline-block
}

.button-page .card-block a {
    margin-bottom: 20px
}

.button-page .card-block ul li {
    display: inline-block;
    margin-right: 20px;
    float: left
}

.button-page .btn {
    margin-bottom: 20px;
    margin-right: 10px
}

.btn.btn-round {
    border-radius: 2rem
}

.btn.btn-square {
    border-radius: 0
}

.btn.btn-skew {
    transform: skew(-15deg)
}

.btn i {
    margin-right: 5px
}

.btn.btn-icon {
    border-radius: 50%;
    width: 40px;
    line-height: 30px;
    height: 40px;
    padding: 3px;
    text-align: center
}

.btn-group,
.btn-group-vertical {
    display: inline-block
}

.btn-group .btn {
    float: left
}

.icon-btn i {
    margin-right: 0 !important
}

.button-page .btn-group {
    margin-right: 10px
}

.show>.dropdown-menu {
    overflow: hidden
}

.dropdown-danger,
.dropdown-success,
.dropdown-warning,
.dropdown-info,
.dropdown-warning,
.dropdown-primary,
.dropdown-default,
.dropdown-inverse,
.dropdown-disabled {
    display: inline-block;
    vertical-align: center;
    position: relative
}

.dropdown-split [class*=btn-],
.btn-group [class*=btn-] {
    margin-right: 0
}

.list-group-item.active {
    background-color: #1abc9c;
    border-color: #1abc9c
}

.show>.btn-primary.dropdown-toggle,
.sweet-alert .show>button.dropdown-toggle.confirm,
.wizard>.actions .show>a.dropdown-toggle {
    background-color: #13866f !important;
    border-color: #13866f;
    box-shadow: none;
    color: #fff
}

.btn-disabled,
.dropdown-disabled,
.dropdown-split-disabled {
    cursor: not-allowed
}

#Note-list li:hover .Note-delete {
    opacity: 1;
    transform: translateX(0px)
}

#Note-list li:hover .Note-delete:hover {
    background: rgba(0, 0, 0, .8)
}

.button-list .btn {
    margin-bottom: 10px;
    margin-right: 30px
}

.Note-delete {
    margin-bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, .6);
    border: none;
    position: absolute;
    right: 20px;
    color: #fff;
    transition: all ease-in .3s;
    border-radius: 2px;
    cursor: pointer;
    transform: translateX(10px)
}

.popover-content .color-code {
    margin-bottom: 10px
}

.popover-content .display-color {
    width: 75px;
    height: 2.5rem;
    border-radius: .25rem;
    margin-right: 30px;
    background-color: rgba(26, 188, 156, .5);
    border-color: rgba(26, 188, 156, .5)
}

.popover-content span.block {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px
}

.popover-title {
    text-align: center
}

.nav-pills .nav-link.active.active,
.nav-pills .nav-link.active.active:focus,
.nav-pills .nav-link.active.active:hover,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
    background: 0 0;
    color: #0275d8;
    text-align: center;
    border-bottom: 1px solid #0275d8
}

.btn-primary,
.sweet-alert button.confirm,
.wizard>.actions a {
    background-color: #1abc9c;
    border-color: #1abc9c;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-primary:hover,
.sweet-alert button.confirm:hover,
.wizard>.actions a:hover {
    background-color: #28e1bd;
    border-color: #28e1bd
}

.btn-primary:active,
.sweet-alert button.confirm:active,
.wizard>.actions a:active {
    background-color: #13866f !important;
    border-color: #13866f;
    box-shadow: none;
    color: #fff
}

.btn-primary:focus,
.sweet-alert button.confirm:focus,
.wizard>.actions a:focus {
    box-shadow: none;
    color: #fff
}

.btn-primary.disabled,
.sweet-alert button.disabled.confirm,
.wizard>.actions a.disabled {
    background-color: rgba(26, 188, 156, .5);
    border-color: rgba(26, 188, 156, .5)
}

.btn-warning {
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-warning:hover {
    background-color: #f4d03f;
    border-color: #f4d03f
}

.btn-warning:active {
    background-color: #b8960b !important;
    border-color: #b8960b;
    box-shadow: none;
    color: #fff
}

.btn-warning:focus {
    box-shadow: none;
    color: #fff
}

.btn-warning.disabled {
    background-color: rgba(241, 196, 15, .5);
    border-color: rgba(241, 196, 15, .5)
}

.btn-default {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-default:hover {
    background-color: #d9dcde;
    border-color: #d9dcde
}

.btn-default:active {
    background-color: #9ca5ab !important;
    border-color: #9ca5ab;
    box-shadow: none;
    color: #fff
}

.btn-default:focus {
    box-shadow: none;
    color: #fff
}

.btn-default.disabled {
    background-color: rgba(189, 195, 199, .5);
    border-color: rgba(189, 195, 199, .5)
}

.btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-danger:hover {
    background-color: #ed7669;
    border-color: #ed7669
}

.btn-danger:active {
    background-color: #cd2a19 !important;
    border-color: #cd2a19;
    box-shadow: none;
    color: #fff
}

.btn-danger:focus {
    box-shadow: none;
    color: #fff
}

.btn-danger.disabled {
    background-color: rgba(231, 76, 60, .5);
    border-color: rgba(231, 76, 60, .5)
}

.btn-success {
    background-color: #2ecc71;
    border-color: #2ecc71;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-success:hover {
    background-color: #54d98c;
    border-color: #54d98c
}

.btn-success:active {
    background-color: #239a55 !important;
    border-color: #239a55;
    box-shadow: none;
    color: #fff
}

.btn-success:focus {
    box-shadow: none;
    color: #fff
}

.btn-success.disabled {
    background-color: rgba(46, 204, 113, .5);
    border-color: rgba(46, 204, 113, .5)
}

.btn-inverse {
    background-color: #34495e;
    border-color: #34495e;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-inverse:hover {
    background-color: #46637f;
    border-color: #46637f
}

.btn-inverse:active {
    background-color: #1e2a37 !important;
    border-color: #1e2a37;
    box-shadow: none;
    color: #fff
}

.btn-inverse:focus {
    box-shadow: none;
    color: #fff
}

.btn-inverse.disabled {
    background-color: rgba(52, 73, 94, .5);
    border-color: rgba(52, 73, 94, .5)
}

.btn-info {
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-info:hover {
    background-color: #5faee3;
    border-color: #5faee3
}

.btn-info:active {
    background-color: #2077b2 !important;
    border-color: #2077b2;
    box-shadow: none;
    color: #fff
}

.btn-info:focus {
    box-shadow: none;
    color: #fff
}

.btn-info.disabled {
    background-color: rgba(52, 152, 219, .5);
    border-color: rgba(52, 152, 219, .5)
}

.btn-disabled {
    background-color: rgba(26, 188, 156, .5);
    border-color: rgba(26, 188, 156, .5);
    color: #fff;
    cursor: pointer;
    transition: all ease-in .3s
}

.btn-outline-primary {
    color: #13866f;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-warning {
    color: #b8960b;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-default {
    color: #9ca5ab;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-default:hover {
    color: #fff
}

.btn-outline-danger {
    color: #cd2a19;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-success {
    color: #239a55;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-inverse {
    color: #1e2a37;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-inverse:hover {
    color: #fff
}

.btn-outline-warning {
    color: #b8960b;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-info {
    color: #2077b2;
    background-color: #fff;
    background-color: transparent
}

.btn-outline-disabled {
    color: #13866f;
    background-color: #fff
}

.btn-xlg {
    padding: 14px 20px;
    font-size: 21px;
    line-height: 40px
}

.btn-lg {
    padding: 12px 18px;
    font-size: 18px;
    line-height: 32px
}

.btn-md {
    padding: 10px 16px;
    font-size: 15px;
    line-height: 23px
}

.btn-sm {
    padding: 8px 14px;
    line-height: 16px;
    font-size: 11px
}

.btn-mini {
    padding: 5px 10px;
    line-height: 14px;
    font-size: 10px
}

.btn-default .badge {
    background-color: #34495e;
    color: #fff
}

.btn-primary .badge,
.sweet-alert button.confirm .badge,
.wizard>.actions a .badge {
    color: #1abc9c;
    background-color: #fff
}

.btn-success .badge {
    color: #2ecc71;
    background-color: #fff
}

.btn-info .badge {
    color: #3498db;
    background-color: #fff
}

.btn-warning .badge {
    color: #f1c40f;
    background-color: #fff
}

.btn-danger .badge {
    color: #e74c3c;
    background-color: #fff
}

.btn-facebook,
.btn-twitter,
.btn-linkedin,
.btn-dribbble,
.btn-google-plus,
.btn-instagram,
.btn-pinterest,
.btn-dropbox,
.btn-flickr,
.btn-tumblr,
.btn-skype,
.btn-youtube,
.btn-github {
    color: #fff
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-twitter:hover,
.btn-twitter:focus,
.btn-linkedin:hover,
.btn-linkedin:focus,
.btn-dribbble:hover,
.btn-dribbble:focus,
.btn-google-plus:hover,
.btn-google-plus:focus,
.btn-instagram:hover,
.btn-instagram:focus,
.btn-pinterest:hover,
.btn-pinterest:focus,
.btn-dropbox:hover,
.btn-dropbox:focus,
.btn-flickr:hover,
.btn-flickr:focus,
.btn-tumblr:hover,
.btn-tumblr:focus,
.btn-skype:hover,
.btn-skype:focus,
.btn-youtube:hover,
.btn-youtube:focus,
.btn-github:hover,
.btn-github:focus {
    color: #fff;
    box-shadow: none
}

.btn-facebook {
    background-color: #3b5998
}

.btn-twitter {
    background-color: #00aced
}

.btn-linkedin {
    background-color: #007bb6
}

.btn-dribbble {
    background-color: #ea4c89
}

.btn-google-plus {
    background-color: #dd4b39
}

.btn-instagram {
    background-color: #517fa4
}

.btn-pinterest {
    background-color: #cb2027
}

.btn-dropbox {
    background-color: #32506d
}

.btn-tumblr {
    background-color: #00aced
}

.dropdown-default .dropdown-item:hover,
.dropdown-split-default .dropdown-item:hover {
    background-color: #bdc3c7;
    color: #fff
}

.dropdown-primary .dropdown-menu a:hover,
.dropdown-split-primary .dropdown-menu a:hover {
    background-color: #1abc9c;
    color: #fff
}

.dropdown-success .dropdown-menu a:hover,
.dropdown-split-success .dropdown-menu a:hover {
    background-color: #2ecc71;
    color: #fff
}

.dropdown-info .dropdown-menu a:hover,
.dropdown-split-info .dropdown-menu a:hover {
    background-color: #3498db;
    color: #fff
}

.dropdown-warning .dropdown-menu a:hover,
.dropdown-split-warning .dropdown-menu a:hover {
    background-color: #f1c40f;
    color: #fff
}

.dropdown-danger .dropdown-menu a:hover,
.dropdown-split-danger .dropdown-menu a:hover {
    background-color: #e74c3c;
    color: #fff
}

.dropdown-inverse .dropdown-item,
.dropdown-split-inverse .dropdown-item {
    transition: all .3s ease-in
}

.dropdown-inverse .dropdown-item:hover,
.dropdown-split-inverse .dropdown-item:hover {
    background-color: #34495e;
    color: #fff
}

.col-form-label {
    font-size: 14px
}

.select2-container {
    width: 100% !important
}

.form-group {
    margin-bottom: 1.25em
}

.form-group .form-control-lg {
    font-size: 1.25em
}

.form-group .form-control-round {
    border-radius: 50px
}

.form-group .form-control-static:focus {
    outline: none
}

.form-control {
    font-size: 14px;
    border-radius: 2px;
    border-width: 2px
}

.form-control:focus {
    border-color: #1abc9c !important
}

.form-control.form-control-normal {
    font-weight: 400
}

.form-control.form-control-bold {
    font-weight: 600
}

.form-control.form-control-capitalize {
    text-transform: capitalize
}

.form-control.form-control-uppercase {
    text-transform: uppercase
}

.form-control.form-control-lowercase {
    text-transform: lowercase
}

.form-control.form-control-variant {
    font-variant: small-caps
}

.form-control.form-control-left {
    text-align: left
}

.form-control.form-control-center {
    text-align: center
}

.form-control.form-control-right {
    text-align: right
}

.form-control.form-control-rtl {
    direction: rtl
}

.form-control-primary {
    border-color: #1abc9c
}

.form-control-primary:focus {
    border-color: #1abc9c
}

.input-group-primary .input-group-addon {
    background-color: #1abc9c
}

.input-group-primary .form-control {
    border-color: #1abc9c
}

.form-control-warning {
    border-color: #f1c40f
}

.form-control-warning:focus {
    border-color: #f1c40f
}

.input-group-warning .input-group-addon {
    background-color: #f1c40f
}

.input-group-warning .form-control {
    border-color: #f1c40f
}

.form-control-default {
    border-color: #bdc3c7
}

.form-control-default:focus {
    border-color: #bdc3c7
}

.input-group-default .input-group-addon {
    background-color: #bdc3c7
}

.input-group-default .form-control {
    border-color: #bdc3c7
}

.form-control-danger {
    border-color: #e74c3c
}

.form-control-danger:focus {
    border-color: #e74c3c
}

.input-group-danger .input-group-addon {
    background-color: #e74c3c
}

.input-group-danger .form-control {
    border-color: #e74c3c
}

.form-control-success {
    border-color: #2ecc71
}

.form-control-success:focus {
    border-color: #2ecc71
}

.input-group-success .input-group-addon {
    background-color: #2ecc71
}

.input-group-success .form-control {
    border-color: #2ecc71
}

.form-control-inverse {
    border-color: #34495e
}

.form-control-inverse:focus {
    border-color: #34495e
}

.input-group-inverse .input-group-addon {
    background-color: #34495e
}

.input-group-inverse .form-control {
    border-color: #34495e
}

.form-control-info {
    border-color: #3498db
}

.form-control-info:focus {
    border-color: #3498db
}

.input-group-info .input-group-addon {
    background-color: #3498db
}

.input-group-info .form-control {
    border-color: #3498db
}

.form-txt-primary {
    color: #1abc9c
}

.form-txt-primary:focus {
    color: #1abc9c
}

.form-txt-primary::-moz-placeholder {
    color: #1abc9c;
    opacity: 1
}

.form-txt-primary:-ms-input-placeholder {
    color: #1abc9c
}

.form-txt-primary::-webkit-input-placeholder {
    color: #1abc9c
}

.form-txt-warning {
    color: #f1c40f
}

.form-txt-warning:focus {
    color: #f1c40f
}

.form-txt-warning::-moz-placeholder {
    color: #f1c40f;
    opacity: 1
}

.form-txt-warning:-ms-input-placeholder {
    color: #f1c40f
}

.form-txt-warning::-webkit-input-placeholder {
    color: #f1c40f
}

.form-txt-default {
    color: #bdc3c7
}

.form-txt-default:focus {
    color: #bdc3c7
}

.form-txt-default::-moz-placeholder {
    color: #bdc3c7;
    opacity: 1
}

.form-txt-default:-ms-input-placeholder {
    color: #bdc3c7
}

.form-txt-default::-webkit-input-placeholder {
    color: #bdc3c7
}

.form-txt-danger {
    color: #e74c3c
}

.form-txt-danger:focus {
    color: #e74c3c
}

.form-txt-danger::-moz-placeholder {
    color: #e74c3c;
    opacity: 1
}

.form-txt-danger:-ms-input-placeholder {
    color: #e74c3c
}

.form-txt-danger::-webkit-input-placeholder {
    color: #e74c3c
}

.form-txt-success {
    color: #2ecc71
}

.form-txt-success:focus {
    color: #2ecc71
}

.form-txt-success::-moz-placeholder {
    color: #2ecc71;
    opacity: 1
}

.form-txt-success:-ms-input-placeholder {
    color: #2ecc71
}

.form-txt-success::-webkit-input-placeholder {
    color: #2ecc71
}

.form-txt-inverse {
    color: #34495e
}

.form-txt-inverse:focus {
    color: #34495e
}

.form-txt-inverse::-moz-placeholder {
    color: #34495e;
    opacity: 1
}

.form-txt-inverse:-ms-input-placeholder {
    color: #34495e
}

.form-txt-inverse::-webkit-input-placeholder {
    color: #34495e
}

.form-txt-info {
    color: #3498db
}

.form-txt-info:focus {
    color: #3498db
}

.form-txt-info::-moz-placeholder {
    color: #3498db;
    opacity: 1
}

.form-txt-info:-ms-input-placeholder {
    color: #3498db
}

.form-txt-info::-webkit-input-placeholder {
    color: #3498db
}

.form-bg-primary {
    background-color: #1abc9c;
    border-color: #1abc9c;
    color: #fff
}

.form-bg-primary:focus {
    background-color: #1abc9c;
    border-color: #1abc9c;
    color: #fff
}

.form-bg-primary::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-primary:-ms-input-placeholder {
    color: #fff
}

.form-bg-primary::-webkit-input-placeholder {
    color: #fff
}

.form-bg-warning {
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #fff
}

.form-bg-warning:focus {
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #fff
}

.form-bg-warning::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-warning:-ms-input-placeholder {
    color: #fff
}

.form-bg-warning::-webkit-input-placeholder {
    color: #fff
}

.form-bg-default {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff
}

.form-bg-default:focus {
    background-color: #bdc3c7;
    border-color: #bdc3c7;
    color: #fff
}

.form-bg-default::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-default:-ms-input-placeholder {
    color: #fff
}

.form-bg-default::-webkit-input-placeholder {
    color: #fff
}

.form-bg-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #fff
}

.form-bg-danger:focus {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #fff
}

.form-bg-danger::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-danger:-ms-input-placeholder {
    color: #fff
}

.form-bg-danger::-webkit-input-placeholder {
    color: #fff
}

.form-bg-success {
    background-color: #2ecc71;
    border-color: #2ecc71;
    color: #fff
}

.form-bg-success:focus {
    background-color: #2ecc71;
    border-color: #2ecc71;
    color: #fff
}

.form-bg-success::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-success:-ms-input-placeholder {
    color: #fff
}

.form-bg-success::-webkit-input-placeholder {
    color: #fff
}

.form-bg-inverse {
    background-color: #34495e;
    border-color: #34495e;
    color: #fff
}

.form-bg-inverse:focus {
    background-color: #34495e;
    border-color: #34495e;
    color: #fff
}

.form-bg-inverse::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-inverse:-ms-input-placeholder {
    color: #fff
}

.form-bg-inverse::-webkit-input-placeholder {
    color: #fff
}

.form-bg-info {
    background-color: #3498db;
    border-color: #3498db;
    color: #fff
}

.form-bg-info:focus {
    background-color: #3498db;
    border-color: #3498db;
    color: #fff
}

.form-bg-info::-moz-placeholder {
    color: #fff;
    opacity: 1
}

.form-bg-info:-ms-input-placeholder {
    color: #fff
}

.form-bg-info::-webkit-input-placeholder {
    color: #fff
}

.has-success .col-form-label {
    color: #2ecc71
}

.has-success .form-control-success {
    border-color: #2ecc71
}

.has-warning .col-form-label {
    color: #f1c40f
}

.has-warning .form-control-success {
    border-color: #f1c40f
}

.has-danger .col-form-label {
    color: #e74c3c
}

.has-danger .form-control-success {
    border-color: #e74c3c
}

.left-icon-control {
    position: relative
}

.left-icon-control input {
    padding-left: 50px
}

.left-icon-control .form-icon {
    position: absolute;
    top: 50%;
    left: 17px;
    margin-top: -12px
}

.right-icon-control {
    position: relative
}

.right-icon-control input {
    padding-right: 50px
}

.right-icon-control .form-icon {
    position: absolute;
    top: 7px;
    right: 17px
}

.input-group {
    margin-bottom: 1.25em
}

.input-group-addon {
    background-color: #1abc9c;
    color: #fff
}

.input-group-button [class*=btn-] {
    padding: 0;
    margin-bottom: 0
}

.input-group-button .input-group-addon {
    transition: all ease-in .3s
}

.input-group-button .input-group-addon:hover {
    background-color: #28e1bd;
    border-color: #28e1bd
}

.input-group-button .input-group-addon:hover .btn {
    background-color: #28e1bd;
    border-color: #28e1bd
}

.input-group-dropdown [class*=btn-] {
    margin-bottom: 0
}

.js-dynamic-disable,
.js-dynamic-enable {
    vertical-align: inherit
}

.form-radio {
    position: relative
}

.form-radio .form-help {
    position: absolute;
    width: 100%
}

.form-radio label {
    position: relative;
    padding-left: 1.5rem;
    text-align: left;
    color: #333;
    display: block
}

.form-radio input {
    width: auto;
    opacity: .00000001;
    position: absolute;
    left: 0
}

.radio .helper {
    position: absolute;
    top: -.15rem;
    left: -.25rem;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #999
}

.radio .helper::after {
    transform: scale(0);
    background-color: #1abc9c;
    border-color: #1abc9c
}

.radio .helper::after,
.radio .helper::before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    margin: .25rem;
    width: 1rem;
    height: 1rem;
    -webkit-transition: -webkit-transform .28s ease;
    transition: -webkit-transform .28s ease;
    transition: transform .28s ease, -webkit-transform .28s ease;
    border-radius: 50%;
    border: .125rem solid #1abc9c
}

.radio label:hover .helper {
    color: #1abc9c
}

.radio input:checked~.helper::after {
    transform: scale(.5)
}

.radio input:checked~.helper::before {
    color: #1abc9c
}

.radio.radiofill input:checked~.helper::after {
    transform: scale(1)
}

.radio.radiofill .helper::after {
    background-color: #1abc9c
}

.radio.radio-disable {
    opacity: .7
}

.radio.radio-disable label {
    cursor: not-allowed
}

.radio-inline {
    display: inline-block;
    margin-right: 20px
}

.radio.radiofill.radio-primary .helper::after {
    background-color: #1abc9c;
    border-color: #1abc9c
}

.radio.radiofill.radio-primary .helper::before {
    border-color: #1abc9c
}

.radio.radiofill.radio-warning .helper::after {
    background-color: #f1c40f;
    border-color: #f1c40f
}

.radio.radiofill.radio-warning .helper::before {
    border-color: #f1c40f
}

.radio.radiofill.radio-default .helper::after {
    background-color: #bdc3c7;
    border-color: #bdc3c7
}

.radio.radiofill.radio-default .helper::before {
    border-color: #bdc3c7
}

.radio.radiofill.radio-danger .helper::after {
    background-color: #e74c3c;
    border-color: #e74c3c
}

.radio.radiofill.radio-danger .helper::before {
    border-color: #e74c3c
}

.radio.radiofill.radio-success .helper::after {
    background-color: #2ecc71;
    border-color: #2ecc71
}

.radio.radiofill.radio-success .helper::before {
    border-color: #2ecc71
}

.radio.radiofill.radio-inverse .helper::after {
    background-color: #34495e;
    border-color: #34495e
}

.radio.radiofill.radio-inverse .helper::before {
    border-color: #34495e
}

.radio.radiofill.radio-info .helper::after {
    background-color: #3498db;
    border-color: #3498db
}

.radio.radiofill.radio-info .helper::before {
    border-color: #3498db
}

.border-checkbox-section .border-checkbox-group {
    display: inline-block
}

.border-checkbox-section .border-checkbox-group .border-checkbox:checked+.border-checkbox-label:after {
    -webkit-animation: check linear .5s;
    animation: check linear .5s;
    opacity: 1;
    border-color: #1abc9c
}

.border-checkbox-section .border-checkbox-group .border-checkbox:checked+.border-checkbox-label:after .border-checkbox-label:before {
    border-color: #eee
}

.border-checkbox-section .border-checkbox-group .border-checkbox-label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 25px;
    line-height: 25px;
    padding-left: 30px;
    margin-right: 15px
}

.border-checkbox-section .border-checkbox-group .border-checkbox-label:after {
    content: "";
    display: block;
    width: 5px;
    height: 15px;
    opacity: .9;
    border-right: 2px solid #eee;
    border-top: 2px solid #eee;
    position: absolute;
    left: 5px;
    top: 15px;
    -webkit-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -webkit-transform-origin: left top;
    transform-origin: left top
}

.border-checkbox-section .border-checkbox-group .border-checkbox-label:before {
    content: "";
    display: block;
    border: 2px solid #1abc9c;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 0
}

.border-checkbox-section .border-checkbox {
    display: none
}

.border-checkbox-section .border-checkbox:disabled~.border-checkbox-label {
    cursor: no-drop;
    color: #ccc
}

@-webkit-keyframes check {
    0% {
        height: 0;
        width: 0
    }
    25% {
        height: 0;
        width: 5px
    }
    50% {
        height: 15px;
        width: 5px
    }
}

@keyframes check {
    0% {
        height: 0;
        width: 0
    }
    25% {
        height: 0;
        width: 5px
    }
    50% {
        height: 15px;
        width: 5px
    }
}

.border-checkbox-section .border-checkbox-group-primary .border-checkbox-label:before {
    border: 2px solid #1abc9c
}

.border-checkbox-section .border-checkbox-group-primary .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #1abc9c
}

.border-checkbox-section .border-checkbox-group-warning .border-checkbox-label:before {
    border: 2px solid #f1c40f
}

.border-checkbox-section .border-checkbox-group-warning .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #f1c40f
}

.border-checkbox-section .border-checkbox-group-default .border-checkbox-label:before {
    border: 2px solid #bdc3c7
}

.border-checkbox-section .border-checkbox-group-default .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #bdc3c7
}

.border-checkbox-section .border-checkbox-group-danger .border-checkbox-label:before {
    border: 2px solid #e74c3c
}

.border-checkbox-section .border-checkbox-group-danger .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #e74c3c
}

.border-checkbox-section .border-checkbox-group-success .border-checkbox-label:before {
    border: 2px solid #2ecc71
}

.border-checkbox-section .border-checkbox-group-success .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #2ecc71
}

.border-checkbox-section .border-checkbox-group-inverse .border-checkbox-label:before {
    border: 2px solid #34495e
}

.border-checkbox-section .border-checkbox-group-inverse .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #34495e
}

.border-checkbox-section .border-checkbox-group-info .border-checkbox-label:before {
    border: 2px solid #3498db
}

.border-checkbox-section .border-checkbox-group-info .border-checkbox:checked+.border-checkbox-label:after {
    border-color: #3498db
}

.checkbox-fade {
    display: inline-block;
    margin-right: 15px
}

.checkbox-fade label {
    line-height: 25px
}

.checkbox-fade label input[type=checkbox],
.checkbox-fade label input[type=radio] {
    display: none
}

.checkbox-fade label input[type=checkbox]:checked+.cr>.cr-icon,
.checkbox-fade label input[type=radio]:checked+.cr>.cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1
}

.checkbox-fade label input[type=checkbox]+.cr>.cr-icon,
.checkbox-fade label input[type=radio]+.cr>.cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in
}

.checkbox-fade label:after {
    content: '';
    display: table;
    clear: both
}

.checkbox-fade.fade-in-disable label,
.checkbox-fade.fade-in-disable .cr {
    color: #ccc;
    cursor: no-drop
}

.checkbox-fade .cr {
    border-radius: 0;
    border: 2px solid #1abc9c;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 25px;
    margin-right: .5em;
    position: relative;
    width: 25px
}

.checkbox-fade .cr .cr-icon {
    color: #1abc9c;
    font-size: .8em;
    left: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%
}

.checkbox-fade.fade-in-primary .cr {
    border: 2px solid #1abc9c
}

.checkbox-fade.fade-in-primary .cr .cr-icon {
    color: #1abc9c
}

.checkbox-fade.fade-in-warning .cr {
    border: 2px solid #f1c40f
}

.checkbox-fade.fade-in-warning .cr .cr-icon {
    color: #f1c40f
}

.checkbox-fade.fade-in-default .cr {
    border: 2px solid #bdc3c7
}

.checkbox-fade.fade-in-default .cr .cr-icon {
    color: #bdc3c7
}

.checkbox-fade.fade-in-danger .cr {
    border: 2px solid #e74c3c
}

.checkbox-fade.fade-in-danger .cr .cr-icon {
    color: #e74c3c
}

.checkbox-fade.fade-in-success .cr {
    border: 2px solid #2ecc71
}

.checkbox-fade.fade-in-success .cr .cr-icon {
    color: #2ecc71
}

.checkbox-fade.fade-in-inverse .cr {
    border: 2px solid #34495e
}

.checkbox-fade.fade-in-inverse .cr .cr-icon {
    color: #34495e
}

.checkbox-fade.fade-in-info .cr {
    border: 2px solid #3498db
}

.checkbox-fade.fade-in-info .cr .cr-icon {
    color: #3498db
}

.checkbox-color {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer
}

.checkbox-color label {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    line-height: 25px
}

.checkbox-color label::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 3px;
    width: 25px;
    height: 25px;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: -20px;
    border: 1px solid #ccc;
    border-radius: 0;
    background-color: #fff;
    transition: border .15s ease-in-out, color .15s ease-in-out
}

.checkbox-color label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 2px;
    margin-left: -17px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #fff
}

.checkbox-color input[type=checkbox] {
    opacity: 0
}

.checkbox-color input[type=checkbox]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.checkbox-color input[type=checkbox]:checked+label::after {
    font-family: fontawesome;
    content: "\f00c"
}

.checkbox-color input[type=checkbox]:disabled+label {
    opacity: .65
}

.checkbox-color input[type=checkbox]:disabled+label::before {
    background-color: #eee;
    cursor: not-allowed
}

.checkbox-color.checkbox-circle label::before {
    border-radius: 50%
}

.checkbox-color.checkbox-inline {
    margin-top: 0
}

.checkbox-primary input[type=checkbox]:checked+label::after,
.checkbox-danger input[type=checkbox]:checked+label::after,
.checkbox-info input[type=checkbox]:checked+label::after,
.checkbox-warning input[type=checkbox]:checked+label::after,
.checkbox-success input[type=checkbox]:checked+label::after {
    color: #fff
}

.checkbox-primary input[type=checkbox]:checked+label::before {
    background-color: #2196f3;
    border-color: #2196f3
}

.checkbox-primary input[type=checkbox]:checked+label::before {
    background-color: #1abc9c
}

.checkbox-warning input[type=checkbox]:checked+label::before {
    background-color: #f1c40f
}

.checkbox-default input[type=checkbox]:checked+label::before {
    background-color: #bdc3c7
}

.checkbox-danger input[type=checkbox]:checked+label::before {
    background-color: #e74c3c
}

.checkbox-success input[type=checkbox]:checked+label::before {
    background-color: #2ecc71
}

.checkbox-inverse input[type=checkbox]:checked+label::before {
    background-color: #34495e
}

.checkbox-info input[type=checkbox]:checked+label::before {
    background-color: #3498db
}

.bootstrap-tagsinput {
    border: 1px solid #1abc9c;
    line-height: 30px;
    border-radius: 2px
}

.bootstrap-tagsinput .tag {
    padding: 6px;
    border-radius: 2px
}

.select2-container--default:focus {
    border-color: #1abc9c
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #fff transparent
}

.select2-container--default .select2-search__field:focus {
    border: 1px solid #1abc9c
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #1abc9c
}

.select2-container--default .select2-selection--multiple {
    padding: 3px 30px 4px 10px
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #1abc9c;
    border: 1px solid #1abc9c;
    padding: 5px 15px;
    color: #fff
}

.select2-container--default .select2-selection--multiple .select2-selection__choice span {
    color: #fff
}

.select2-container--default .select2-selection--multiple .select2-search__field {
    border: none
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #1abc9c
}

.select2-container--default .select2-selection--single {
    color: #fff;
    height: auto
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    background-color: #1abc9c;
    color: #fff;
    padding: 8px 30px 8px 20px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px;
    right: 15px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #fff transparent transparent transparent
}

.ms-container .ms-list.ms-focus {
    border-color: #1abc9c;
    box-shadow: inset 0 1px 1px rgba(26, 188, 156, .49), 0 0 8px rgba(26, 188, 156, .5)
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
    background-color: #1abc9c
}

.ms-selectable .custom-header,
.ms-selection .custom-header {
    background-color: #1abc9c;
    color: #fff;
    text-align: center
}

.ms-list {
    display: block !important
}

.ms-list li {
    width: 100%
}

.form-group .messages p {
    margin-bottom: 0;
    transition: all ease-in .3s
}

.form-group .popover-valid {
    position: absolute;
    right: 40px;
    top: 7px
}

.wizard>.steps .current a {
    background: #1abc9c;
    transition: all ease-in .3s;
    cursor: pointer;
    border-radius: 2px;
    outline: 1px solid #fff;
    outline-offset: -7px
}

.wizard>.steps .current a:hover {
    background: #1dd2af;
    outline-offset: 0
}

#design-wizard .steps li {
    position: relative;
    z-index: 99;
    margin-bottom: 20px
}

#design-wizard .steps li:after {
    content: "";
    position: absolute;
    height: 2px;
    background: #1abc9c;
    width: 100%;
    top: 30px;
    z-index: -1
}

#design-wizard .steps li a {
    width: 20%;
    margin: 0 auto;
    text-align: center;
    border-radius: 2px
}

.wizard>.steps .done a {
    background: #bdc3c7
}

.card .card-block .wizard-form .steps ul {
    display: block
}

.card .card-block .wizard-form li {
    margin-right: 0
}

.wizard>.content {
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff
}

.label {
    border-radius: 4px;
    font-size: 75%;
    padding: 4px 7px;
    margin-right: 5px;
    font-weight: 700;
    color: #fff !important
}

.label-main {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 5px
}

.badge {
    border-radius: 10px;
    padding: 3px 7px
}

.label.label-lg {
    padding: 8px 21px
}

.label.label-md {
    padding: 6px 14px
}

.badge-lg {
    padding: 5px 9px;
    font-size: 14px
}

.badge-md {
    padding: 4px 8px;
    font-size: 14px
}

span.badge {
    display: inline-block !important
}

.label-default {
    background-color: #bdc3c7 !important;
    border-color: #bdc3c7;
    box-shadow: none;
    color: #fff
}

.label-inverse-default {
    border: 1px solid;
    border-color: #bdc3c7;
    color: #bdc3c7 !important
}

.label-inverse-primary {
    border: 1px solid;
    border-color: #1abc9c;
    color: #1abc9c !important
}

.label-inverse-success {
    border: 1px solid;
    border-color: #2ecc71;
    color: #2ecc71 !important
}

.label-inverse-warning {
    border: 1px solid;
    border-color: #f1c40f;
    color: #f1c40f !important
}

.label-inverse-danger {
    border: 1px solid;
    border-color: #e74c3c;
    color: #e74c3c !important
}

.label-inverse-info {
    border: 1px solid;
    border-color: #3498db;
    color: #3498db !important
}

.label-inverse-info-border {
    border: 1px solid;
    border-color: #34495e;
    color: #34495e !important
}

.badge-inverse-default {
    border: 1px solid;
    border-color: #bdc3c7;
    color: #bdc3c7 !important
}

.badge-inverse-primary {
    border: 1px solid;
    border-color: #1abc9c;
    color: #1abc9c !important
}

.badge-inverse-success {
    border: 1px solid;
    border-color: #2ecc71;
    color: #2ecc71 !important
}

.badge-inverse-warning {
    border: 1px solid;
    border-color: #f1c40f;
    color: #f1c40f !important
}

.badge-inverse-danger {
    border: 1px solid;
    border-color: #e74c3c;
    color: #e74c3c !important
}

.badge-inverse-info {
    border: 1px solid;
    border-color: #34495e;
    color: #34495e !important
}

.label-icon label {
    position: absolute
}

.icofont.icofont-envelope {
    font-size: 20px
}

.data-table-main.icon-list-demo [class*=col-] {
    margin-bottom: 10px
}

.data-table-main.icon-svg-demo [class*=col-] {
    margin-bottom: 10px
}

.icon-list-demo i {
    border: 1px solid #eceeef;
    border-radius: 3px;
    color: rgba(43, 61, 81, .7);
    display: inline-block;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    margin: 0 12px 0 0;
    text-align: center;
    vertical-align: middle;
    width: 50px
}

.icon-list-demo div {
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 10px
}

.icon-list-demo i:hover {
    color: #64b0f2
}

.flags .f-item {
    padding: 12px;
    border: 1px solid #ddd;
    margin-right: 15px;
    display: inline-block
}

.data-table-main.flags [class*=col-] {
    display: flex;
    align-items: center;
    margin-bottom: 10px
}

.content-flag label {
    margin-bottom: 0;
    cursor: pointer
}

.content-flag .txt-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 120px;
    overflow: hidden
}

.outer-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: 250px
}

label.txt-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: 150px
}

.flags .f-item .name,
.flags .f-item .capital {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 0
}

.flags .f-item .code {
    display: block;
    font-size: 90%;
    color: #999;
    padding-bottom: 0
}

.bg-pic {
    background-color: rgba(0, 0, 0, .5);
    background-blend-mode: overlay
}

.j-pro {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .15)
}

.j-pro .j-header {
    background-color: #1abc9c;
    border-top: 1px solid #1abc9c;
    box-shadow: none
}

.j-pro .j-icon-right {
    border-left: 1px solid rgba(0, 0, 0, .32)
}

.j-pro .j-icon-left {
    border-right: 1px solid rgba(0, 0, 0, .32)
}

.j-pro .j-footer {
    background-color: #fff;
    border-top: 1px dashed #1abc9c;
    padding: 20px 25px
}

.j-pro .j-footer button {
    margin-bottom: 0;
    float: right
}

.j-pro .j-divider-text span {
    color: #222
}

.j-pro .btn-primary.disabled,
.j-pro .sweet-alert button.disabled.confirm,
.sweet-alert .j-pro button.disabled.confirm,
.j-pro .wizard>.actions a.disabled,
.wizard>.actions .j-pro a.disabled,
.j-pro .btn-primary:disabled,
.j-pro .sweet-alert button.confirm:disabled,
.sweet-alert .j-pro button.confirm:disabled,
.j-pro .wizard>.actions a:disabled,
.wizard>.actions .j-pro a:disabled {
    background-color: #2ecc71;
    border-color: #2ecc71
}

.j-pro input[type=text],
.j-pro input[type=password],
.j-pro input[type=email],
.j-pro input[type=search],
.j-pro input[type=url],
.j-pro textarea,
.j-pro select,
.j-forms input[type=text],
.j-forms input[type=password],
.j-forms input[type=email],
.j-forms input[type=search],
.j-forms input[type=url],
.j-forms textarea,
.j-forms select {
    border: 1px solid rgba(0, 0, 0, .15)
}

.j-pro input[type=text]:hover,
.j-pro input[type=password]:hover,
.j-pro input[type=email]:hover,
.j-pro input[type=search]:hover,
.j-pro input[type=url]:hover,
.j-pro textarea:hover,
.j-pro select:hover,
.j-pro input[type=text]:focus,
.j-pro input[type=password]:focus,
.j-pro input[type=email]:focus,
.j-pro input[type=search]:focus,
.j-pro input[type=url]:focus,
.j-pro textarea:focus,
.j-pro select:focus,
.j-pro .j-file-button:hover+input,
.j-forms input[type=text]:hover,
.j-forms input[type=password]:hover,
.j-forms input[type=email]:hover,
.j-forms input[type=search]:hover,
.j-forms input[type=url]:hover,
.j-forms textarea:hover,
.j-forms select:hover,
.j-forms input[type=text]:focus,
.j-forms input[type=password]:focus,
.j-forms input[type=email]:focus,
.j-forms input[type=search]:focus,
.j-forms input[type=url]:focus,
.j-forms textarea:focus,
.j-forms select:focus {
    border: 1px solid #1abc9c
}

.j-pro .j-radio-toggle,
.j-pro .j-checkbox-toggle,
.j-pro .j-inline-group .j-radio-toggle,
.j-pro .j-inline-group .j-checkbox-toggle {
    padding: 9px 0 18px
}

.j-pro .j-tooltip,
.j-pro .j-tooltip-image {
    background-color: #1abc9c
}

.j-pro .j-tooltip:before,
.j-pro .j-tooltip-image:before {
    border-color: #1abc9c transparent
}

.j-pro .j-primary-btn,
.j-pro .j-file-button,
.j-pro .j-secondary-btn,
.j-pro .j-widget .j-addon-btn {
    background-color: #1abc9c
}

.j-pro .j-primary-btn:hover,
.j-pro .j-file-button:hover,
.j-pro .j-secondary-btn:hover,
.j-pro .j-widget .j-addon-btn:hover {
    background-color: #1dd2af
}

.j-pro .j-ratings input+label:hover,
.j-pro .j-ratings input+label:hover~label,
.j-pro .j-ratings input:checked+label,
.j-pro .j-ratings input:checked+label~label {
    color: #1abc9c
}

.j-unit .checkbox-fade {
    display: block;
    margin-top: 20px
}

.j-forms {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, .15)
}

.j-forms button i {
    margin-right: 0
}

.j-forms .checkbox-fade {
    margin-top: 10px
}

.j-forms .checkbox-fade .disabled-view {
    opacity: .5;
    cursor: not-allowed
}

.j-forms .link {
    color: #1abc9c;
    border-bottom: none;
    text-transform: capitalize;
    margin-bottom: 15px
}

.j-forms .label {
    margin-bottom: 15px
}

.j-forms .btn-primary:disabled,
.j-forms .sweet-alert button.confirm:disabled,
.sweet-alert .j-forms button.confirm:disabled,
.j-forms .wizard>.actions a:disabled,
.wizard>.actions .j-forms a:disabled {
    background-color: #bdc3c7;
    border-color: #bdc3c7
}

.j-forms .radio-toggle,
.j-forms .checkbox-toggle,
.j-forms .inline-group .radio-toggle,
.j-forms .inline-group .checkbox-toggle {
    padding: 9px 0 8px 0
}

.j-forms .header {
    background-color: #1abc9c;
    border-top: 1px solid #1abc9c;
    box-shadow: none
}

.j-forms .divider-text span {
    color: #222
}

.j-forms .widget .addon,
.j-forms .widget .addon-btn {
    background-color: #1abc9c;
    color: #fff
}

.j-forms .widget .addon:hover,
.j-forms .widget .addon:focus,
.j-forms .widget .addon-btn:hover,
.j-forms .widget .addon-btn:focus {
    background-color: #1dd2af
}

.j-forms .widget .addon:hover i,
.j-forms .widget .addon:focus i,
.j-forms .widget .addon-btn:hover i,
.j-forms .widget .addon-btn:focus i {
    color: #fff
}

.j-forms .widget .addon i,
.j-forms .widget .addon-btn i {
    color: #fff
}

.j-forms .footer {
    background-color: #fff;
    border-top: 1px dashed #1abc9c;
    padding: 20px 25px
}

.j-forms .footer button {
    float: right;
    margin-bottom: 0
}

.j-forms .stepper .stepper-arrow {
    background-color: #1abc9c
}

.j-forms .stepper .stepper-arrow:hover {
    background-color: #1dd2af
}

.j-forms .stepper .stepper-arrow.up:after {
    border-bottom: 7px solid #fff
}

.j-forms .stepper .stepper-arrow.down:after {
    border-top: 7px solid #fff
}

.popup-menu {
    padding: 0
}

.popup-menu .popup-list {
    background-color: #2c3e50;
    border-radius: 0
}

.popup-menu .popup-list>ul>li {
    transition: all ease-in .3s;
    color: #fff;
    border-left: none;
    cursor: pointer
}

.popup-menu .popup-list>ul>li:hover {
    background-color: #384c5f;
    color: #fff
}

.j-tabs-container .j-tabs-label,
.j-tabs-container input[type=radio]:checked+.j-tabs-label {
    border-top: 4px solid #1abc9c
}

.pop-up-logo img {
    margin-top: 10px;
    margin-left: 80px
}

.popup-list-open .popup-list-wrapper {
    z-index: 99
}

.span4 label {
    color: #222 !important
}

.pop-up-wrapper {
    margin-left: 0 !important
}

.box-list div div div {
    margin-bottom: 20px
}

.box-list {
    padding-bottom: 0
}

.box-list p {
    margin-bottom: 0 !important
}

.z-depth-top-0 {
    box-shadow: 0 -5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-top-1 {
    box-shadow: 0 -7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-top-2 {
    box-shadow: 0 -9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-top-3 {
    box-shadow: 0 -10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-top-4 {
    box-shadow: 0 -11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-top-5 {
    box-shadow: 0 -12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-bottom-0 {
    box-shadow: 0 5px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-bottom-1 {
    box-shadow: 0 7px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-bottom-2 {
    box-shadow: 0 9px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-bottom-3 {
    box-shadow: 0 10px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-bottom-4 {
    box-shadow: 0 11px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-bottom-5 {
    box-shadow: 0 12px 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-left-0 {
    box-shadow: -5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-left-1 {
    box-shadow: -7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-left-2 {
    box-shadow: -9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-left-3 {
    box-shadow: -10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-left-4 {
    box-shadow: -11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-left-5 {
    box-shadow: -12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-right-0 {
    box-shadow: 5px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-right-1 {
    box-shadow: 7px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-right-2 {
    box-shadow: 9px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-right-3 {
    box-shadow: 10px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-right-4 {
    box-shadow: 11px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-right-5 {
    box-shadow: 12px 0 25px -5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-0 {
    box-shadow: 0 0 25px 5px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-1 {
    box-shadow: 0 0 25px 7px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-2 {
    box-shadow: 0 0 25px 9px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-3 {
    box-shadow: 0 0 25px 10px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-4 {
    box-shadow: 0 0 25px 11px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

.z-depth-5 {
    box-shadow: 0 0 25px 12px #ccc, 0 1px 5px 0 rgba(0, 0, 0, .1), 0 0 0 0 transparent
}

table.table-bordered.dataTable tbody th:focus,
table.table-bordered.dataTable tbody td:focus {
    outline: none
}

.card .card-block ul.pagination li {
    margin-right: 0
}

.page-item.active .page-link {
    background-color: #1abc9c;
    border-color: #1abc9c
}

.page-link {
    color: #222
}

td.highlight {
    font-weight: 700;
    color: #3498db;
    background-color: #f5f5f5
}

.table.compact td,
.table.compact th {
    padding: .45rem
}

.dataTables_paginate .pagination {
    float: right
}

#multi-table_wrapper .dataTables_paginate .pagination {
    float: none
}

#footer-search tfoot .form-control,
#footer-select tfoot .form-control,
#form-input-table .form-control,
.search-api .form-control,
#dt-live-dom .form-control {
    width: 90%
}

.search-api .global_filter,
.search-api .column_filter {
    margin: 0 auto
}

.search-api .checkbox-fade {
    display: block;
    text-align: center
}

td.details-control {
    background: url(../images/details_open.png) no-repeat center center;
    cursor: pointer
}

tr.shown td.details-control {
    background: url(../images/details_close.png) no-repeat center center
}

#row-select .selected,
#row-delete .selected {
    background-color: #1abc9c;
    color: #fff
}

div.dt-autofill-list div.dt-autofill-button button {
    background-color: #1abc9c;
    border-color: #1abc9c
}

table.dataTable {
    border-collapse: collapse !important
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
    background-color: #1abc9c;
    color: #fff
}

table.dataTable td.select-checkbox:before,
table.dataTable th.select-checkbox:before {
    margin-top: 0
}

table.dataTable tr.selected td.select-checkbox:after,
table.dataTable tr.selected th.select-checkbox:after {
    margin-top: -6px;
    margin-left: -5px
}

button.dt-button,
div.dt-button,
a.dt-button,
button.dt-button:focus:not(.disabled),
div.dt-button:focus:not(.disabled),
a.dt-button:focus:not(.disabled),
button.dt-button:active:not(.disabled),
button.dt-button.active:not(.disabled),
div.dt-button:active:not(.disabled),
div.dt-button.active:not(.disabled),
a.dt-button:active:not(.disabled),
a.dt-button.active:not(.disabled) {
    background-color: #1abc9c;
    border-color: #1abc9c;
    border-radius: 2px;
    color: #fff;
    background-image: none;
    font-size: 14px
}

button.dt-button.btn-warning,
div.dt-button.btn-warning,
a.dt-button.btn-warning {
    background-color: #f1c40f;
    border-color: #f1c40f;
    border-radius: 2px;
    color: #fff;
    background-image: none
}

button.dt-button.btn-danger,
div.dt-button.btn-danger,
a.dt-button.btn-danger {
    background-color: #e74c3c;
    border-color: #e74c3c;
    border-radius: 2px;
    color: #fff;
    background-image: none
}

button.dt-button.btn-inverse,
div.dt-button.btn-inverse,
a.dt-button.btn-inverse {
    background-color: #34495e;
    border-color: #34495e;
    border-radius: 2px;
    color: #fff;
    background-image: none
}

button.dt-button:hover:not(.disabled),
div.dt-button:hover:not(.disabled),
a.dt-button:hover:not(.disabled) {
    background-image: none;
    background-color: #1dd2af;
    border-color: #1abc9c
}

button.dt-button.btn-warning:hover:not(.disabled),
div.dt-button.btn-warning:hover:not(.disabled),
a.dt-button.btn-warning:hover:not(.disabled) {
    background-image: none;
    background-color: #f4d03f;
    border-color: #f1c40f
}

button.dt-button.btn-danger:hover:not(.disabled),
div.dt-button.btn-danger:hover:not(.disabled),
a.dt-button.btn-danger:hover:not(.disabled) {
    background-image: none;
    background-color: #ed7669;
    border-color: #e74c3c
}

button.dt-button.btn-inverse:hover:not(.disabled),
div.dt-button.btn-inverse:hover:not(.disabled),
a.dt-button.btn-inverse:hover:not(.disabled) {
    background-image: none;
    background-color: #46637f;
    border-color: #34495e
}

div.dt-button-collection button.dt-button:active:not(.disabled),
div.dt-button-collection button.dt-button.active:not(.disabled),
div.dt-button-collection div.dt-button:active:not(.disabled),
div.dt-button-collection div.dt-button.active:not(.disabled),
div.dt-button-collection a.dt-button:active:not(.disabled),
div.dt-button-collection a.dt-button.active:not(.disabled) {
    background-color: #1abc9c;
    border-color: #1abc9c;
    background-image: none
}

div.dt-buttons {
    clear: both
}

.card .table-card-header b {
    display: block;
    color: #1abc9c;
    margin-top: 15px
}

.card .table-card-header span {
    color: #148f77;
    display: inline-block;
    margin-top: 0
}

div.dataTables_wrapper div.dataTables_info {
    display: inline-block
}

table.DTCR_clonedTable.dataTable {
    position: absolute !important;
    background-color: rgba(255, 255, 255, .7);
    z-index: 202
}

div.DTCR_pointer {
    width: 1px;
    background-color: #0259c4;
    z-index: 201
}

table.DTFC_Cloned thead,
table.DTFC_Cloned tfoot {
    background-color: #fff
}

div.DTFC_Blocker {
    background-color: #fff
}

div.DTFC_LeftWrapper table.dataTable,
div.DTFC_RightWrapper table.dataTable {
    margin-bottom: 0;
    z-index: 2
}

div.DTFC_LeftWrapper table.dataTable.no-footer,
div.DTFC_RightWrapper table.dataTable.no-footer {
    border-bottom: none
}

th,
td {
    white-space: nowrap
}

table.fixedHeader-floating {
    position: fixed !important;
    background-color: #fff
}

table.fixedHeader-floating.no-footer {
    border-bottom-width: 0
}

table.fixedHeader-locked {
    position: absolute !important;
    background-color: #fff
}

@media print {
    table.fixedHeader-floating {
        display: none
    }
}

table.dataTable th.focus,
table.dataTable td.focus {
    outline: 3px solid #1abc9c;
    outline-offset: -1px
}

table.dataTable td.focus {
    outline: 1px solid #e74c3c;
    outline-offset: -3px;
    background-color: #f8e6e6 !important
}

#events {
    margin-bottom: 1em;
    padding: 1em;
    background-color: #f6f6f6;
    border: 1px solid #999;
    border-radius: 3px;
    height: 100px;
    overflow: auto
}

table.dt-rowReorder-float {
    position: absolute !important;
    opacity: .8;
    table-layout: fixed;
    outline: 2px solid #888;
    outline-offset: -2px;
    z-index: 2001
}

tr.dt-rowReorder-moving {
    outline: 2px solid #555;
    outline-offset: -2px
}

body.dt-rowReorder-noOverflow {
    overflow-x: hidden
}

table.dataTable td.reorder {
    text-align: center;
    cursor: move
}

#result {
    border: 1px solid #888;
    background: #f7f7f7;
    padding: 1em;
    margin-bottom: 1em
}

div.DTS {
    display: block !important
}

div.DTS tbody th,
div.DTS tbody td {
    white-space: nowrap
}

div.DTS div.DTS_Loading {
    z-index: 1
}

div.DTS div.dataTables_scrollBody table {
    z-index: 2
}

div.DTS div.dataTables_paginate,
div.DTS div.dataTables_length {
    display: none
}

.footable .pagination>.active>a,
.footable .pagination>.active>a:focus,
.footable .pagination>.active>a:hover,
.footable .pagination>.active>span,
.footable .pagination>.active>span:focus,
.footable .pagination>.active>span:hover {
    background-color: #1abc9c;
    border-color: #1abc9c
}

.footable .pagination>li>a,
.footable .pagination>li>span {
    color: #222
}

.footable-details.table,
.footable.table,
table.footable>tfoot>tr.footable-paging>td>span.label {
    margin-bottom: 0
}

table.footable-paging-center>tfoot>tr.footable-paging>td {
    padding-bottom: 0
}

.make-me-red {
    color: red
}

.scroll-container .wtHolder {
    height: 350px !important
}

.scroll-container #highlighting .wtHolder,
.scroll-container #populating .wtHolder,
.scroll-container #paginating .wtHolder,
.scroll-container #searching .wtHolder,
.scroll-container #drag .wtHolder,
.scroll-container #validation .wtHolder,
.scroll-container #readOnly .wtHolder,
.scroll-container #nonEditable .wtHolder,
.scroll-container #numericData .wtHolder,
.scroll-container #dateDate .wtHolder,
.scroll-container #timeData .wtHolder,
.scroll-container #checkbox .wtHolder,
.scroll-container #select .wtHolder,
.scroll-container #dropdown .wtHolder,
.scroll-container #autocomplete .wtHolder,
.scroll-container #jQuery .wtHolder,
.scroll-container #chromaJS .wtHolder,
.scroll-container #context .wtHolder,
.scroll-container #configuration .wtHolder,
.scroll-container #copyPaste .wtHolder,
.scroll-container #buttons .wtHolder,
.scroll-container #comments .wtHolder {
    height: auto !important
}

.handson-pagination {
    margin-top: 30px
}

.currentRow {
    background-color: #f9f9fb !important
}

.currentCol {
    background-color: #e7e8ef !important
}

.contact-table tr td:nth-child(n-2) {
    text-align: center
}

.contact-table tr td:last-child {
    position: relative
}

.contact-table tr td:last-child .dropdown-menu {
    top: 52px
}

.card-block-big {
    padding: 2em
}

.widget-row-set {
    margin-left: 0;
    margin-right: 0
}

.borderless-card {
    border-top: none
}

.widget-main-card {
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .11)
}

.counter-card-1 h3,
.counter-card-2 h3,
.counter-card-3 h3 {
    margin-bottom: 16px;
    font-weight: 600;
    color: #666
}

.counter-card-1 [class*=card-]>i,
.counter-card-2 [class*=card-]>i,
.counter-card-3 [class*=card-]>i {
    position: absolute;
    color: #1abc9c;
    right: 30px;
    top: 30px;
    font-size: 28px
}

.counter-card-1 span.f-right,
.counter-card-2 span.f-right,
.counter-card-3 span.f-right {
    font-size: 16px;
    font-weight: 600
}

.counter-card-2 {
    border-top-color: #2ecc71
}

.counter-card-2 [class*=card-]>i {
    color: #2ecc71
}

.counter-card-3 {
    border-top-color: #bdc3c7
}

.counter-card-3 [class*=card-]>i {
    color: #bdc3c7
}

.table-card .row-table {
    display: flex;
    align-items: center;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0
}

.table-card .row-table:first-child {
    border-bottom: 1px solid #ddd
}

.table-card .row-table .br {
    border-right: 1px solid #ccc
}

.table-card .row-table i {
    font-size: 28px
}

.table-card .row-table span {
    text-transform: uppercase;
    font-size: 13px
}

.table-card .row-table h5 {
    display: block;
    margin-bottom: .3em;
    margin-right: 0
}

.table-card .row-table>[class*=col-] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle
}

.table-card .row-table>[class*=col-] .row {
    display: flex;
    align-items: center
}

.widget-primary-card,
.widget-success-card {
    border-top: none;
    background-color: #1abc9c;
    color: #fff
}

.widget-primary-card .row-table>[class*=col-],
.widget-success-card .row-table>[class*=col-] {
    display: inline-block;
    vertical-align: middle
}

.widget-primary-card .row-table>[class*=col-]:first-child,
.widget-success-card .row-table>[class*=col-]:first-child {
    background-color: #148f77;
    text-align: center
}

.widget-primary-card .row-table>[class*=col-] .row,
.widget-success-card .row-table>[class*=col-] .row {
    display: flex;
    align-items: center
}

.widget-success-card {
    background-color: #2ecc71
}

.widget-success-card .row-table>[class*=col-]:first-child {
    background-color: #25a25a
}

.client-blocks h5 {
    margin-bottom: 15px
}

.client-blocks ul li {
    display: inline-block;
    font-size: 40px
}

.client-blocks ul li:last-child {
    float: right
}

.warning-border {
    border-top-color: #f1c40f
}

.success-border {
    border-top-color: #2ecc71
}

.dark-primary-border {
    border-top-color: #17a689
}

.dark-primary-border li,
.dark-primary-border i {
    color: #17a689
}

.large-widget-card {
    border-top: none
}

.large-widget-card:hover i {
    opacity: 1;
    transform: scale(1.1)
}

.large-widget-card i {
    position: absolute;
    opacity: .4;
    right: 30px;
    top: 50px;
    font-size: 50px;
    transition: all ease-in .3s
}

.group-widget {
    border-top: none
}

.group-widget .card-block-big {
    height: 130px;
    text-align: center;
    color: #fff
}

.group-widget i {
    font-size: 2.5em
}

.group-widget p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px
}

.bg-dark-primary {
    background-color: #18aa8d;
    color: #fff
}

.bg-darkest-primary {
    background-color: #14947b;
    color: #fff
}

.social-widget-card {
    border-top: none;
    color: #fff
}

.social-widget-card:hover i {
    opacity: 1;
    transform: scale(1.1)
}

.social-widget-card i {
    position: absolute;
    right: 30px;
    top: 50px;
    font-size: 48px;
    opacity: .4;
    transition: all ease-in .3s
}

.bg-facebook {
    background-color: #3b5998
}

.bg-twitter {
    background-color: #4099ff
}

.bg-linkein {
    background-color: #0077b5
}

.bg-google-plus {
    background-color: #d34836
}

.widget-profile-card-1 {
    position: relative;
    margin-bottom: 50px
}

.widget-profile-card-1 .middle-user {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    bottom: -60px
}

.widget-profile-card-1 .middle-user img {
    width: 115px;
    border-radius: 50%
}

.widget-profile-card-1~.card-footer [class*=col-] {
    border-right: 1px solid #fff
}

.widget-profile-card-1~.card-footer [class*=col-]:last-child {
    border: none
}

.widget-profile-card-2 a {
    color: #fff;
    transition: all ease-in .3s
}

.widget-profile-card-2 a:hover {
    opacity: .7
}

.widget-profile-card-3 {
    background-image: url(../images/slider/slider6.jpg);
    background-size: cover;
    padding: 50px 0;
    text-align: center
}

.widget-profile-card-3 img {
    width: 120px;
    border-radius: 50%
}

.profile-card {
    position: relative
}

.profile-card img {
    width: 100%
}

.profile-card .card-block {
    text-align: center;
    color: #fff;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.72))
}

.profile-card .card-block h3 {
    font-weight: 600
}

.profile-card~.card-footer {
    padding: 0
}

.profile-card~.card-footer [class*=col-] {
    padding: 10px;
    border-right: 1px solid rgba(255, 255, 255, .57)
}

.profile-card~.card-footer [class*=col-] h4 {
    font-size: 18px;
    font-weight: 600
}

.profile-card~.card-footer [class*=col-]:last-child {
    border: none
}

.profile-card~.card-footer [class*=col-] span {
    text-transform: uppercase
}

.weather-card-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-right: 0
}

.weather-card-1 h4 {
    font-weight: 300;
    margin-top: 70px
}

.weather-card-1 span.d-block {
    margin-bottom: 70px
}

.weather-card-1 h5 {
    color: #fff
}

.weather-card-1 [class*=col-]:first-child {
    margin-bottom: 100px
}

.weather-card-1 .climacon {
    height: 100px;
    width: 100px;
    position: relative;
    top: -20px
}

.weather-card-1 .climacon_component-stroke {
    fill: #fff
}

.weather-card-1 .weather-temp {
    font-size: 70px
}

.weather-calender .pignose-calendar {
    width: auto;
    max-width: initial;
    box-shadow: none;
    border: none;
    border-left: 1px solid #ddd
}

.weather-calender .pignose-calendar .pignose-calendar-top {
    box-shadow: none
}

.weather-calender .pignose-calendar-unit.pignose-calendar-unit-active a {
    background-color: #1abc9c;
    box-shadow: none
}

.weather-bg-card {
    position: relative
}

.weather-bg-card img {
    width: 100%
}

.weather-bg-card .card-block {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center
}

.weather-bg-card h5 {
    color: #fff
}

.weather-bg-card .card-block-big {
    position: absolute;
    top: 0
}

.weather-bg-card .card-block-big span {
    color: #fff
}

.weather-bg-card .weather-temp {
    font-size: 70px;
    color: #fff
}

.weather-bg-card~.card-footer {
    border-top: none
}

.blur-user-card {
    color: #fff;
    text-align: center;
    padding-top: 45px;
    background-image: url(../images/widget/blur-bg.png);
    background-size: cover
}

.blur-user-card h3 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600
}

.blur-user-card p {
    font-size: 18px;
    font-weight: 300
}

.blur-user-card .blur-footer [class*=col-] {
    padding: 1em;
    border-right: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 30px
}

.blur-user-card .blur-footer [class*=col-]:last-child {
    border-right: none
}

.flip-clock-divider .flip-clock-label {
    top: 7.5em;
    font-size: 14px;
    color: #fff
}

.clock-widget {
    height: 165px;
    background-color: #36c;
    overflow: hidden;
    position: relative
}

.clock-widget #current-time {
    font-size: 50px;
    color: #fff;
    margin-top: 10px;
    margin-left: 10px
}

.clock-widget h5 {
    color: #fff;
    margin-left: 10px
}

.clock-widget .basic-alarm {
    position: absolute;
    right: 120px;
    font-size: 80px;
    top: 50px;
    color: #ffbf00;
    z-index: 999
}

.clock-widget .bg-alarm {
    position: absolute;
    right: 20px;
    font-size: 200px;
    top: 0;
    transform: rotate(-30deg);
    opacity: .2;
    color: #fff
}

.weather-card-2 {
    color: #fff
}

.weather-card-2 .weather-card-location i {
    position: absolute;
    right: 40px;
    top: 35px;
    font-weight: 600
}

.weather-card-2 .weather-card-temp {
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center
}

.weather-card-2 .weather-card-temp h1 {
    font-size: 70px
}

.weather-card-2 .weather-cloud {
    text-align: center
}

.weather-card-2 .weather-cloud svg {
    position: relative;
    top: 0;
    left: -10px
}

.weather-card-2 .weather-cloud .climacon_component-stroke {
    fill: #fff
}

.weather-card-2 .weather-cloud span {
    font-size: 18px
}

.weather-card-2 .card-footer {
    padding: 30px
}

.weather-card-2 .card-footer h6 {
    color: #666;
    font-weight: 600;
    font-size: 16px
}

.quick-note-card h6 {
    font-weight: 600
}

.quick-note-card h2 {
    font-weight: 300
}

.quick-note-card .btn-outline-primary {
    border-color: #fff;
    color: #fff
}

.social-card-1 .card-block-big {
    padding: 3em
}

.social-card-1 .card-block-big .media {
    display: flex;
    align-items: center
}

.social-card-1 .card-footer {
    padding: 0
}

.social-card-1 .card-footer .social-media {
    padding: 15px;
    color: #666
}

.social-card-1 .card-footer .social-media i {
    display: block;
    margin-bottom: 5px
}

.social-card-1 .card-footer .social-media h5 {
    margin-right: 0
}

.social-card-1 .card-footer [class*=col-] {
    border-right: 1px solid #ddd
}

.social-card-1 .card-footer [class*=col-]:last-child {
    border-right: none
}

.social-card-1 .card-footer .social-media-success {
    background-color: #2ecc71
}

.social-card-1 .card-footer .social-media-warning {
    background-color: #f1c40f
}

.social-card-1 .card-footer .social-media-danger {
    background-color: #e74c3c
}

.user-activity-card {
    border-top-color: #3498db
}

.user-activity-card .progress-bar-warning {
    background-color: #3498db
}

.user-activity-card .card-block-big {
    padding-top: 35px;
    padding-bottom: 35px
}

.user-activity-card .user-number {
    padding: 30px 0;
    font-weight: 600;
    color: #3498db
}

.user-activity-card .card-footer {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    background-color: #e6e9ea
}

.user-activity-card .link {
    position: absolute;
    right: 30px;
    top: 40px;
    font-size: 25px;
    color: #3498db
}

.table-1-card .table th {
    border-top: none
}

.table-1-card .card-block {
    padding-top: 10px;
    padding-bottom: 0
}

.widget-content-footer {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    background-color: #e6e9ea
}

.widget-chat-box .card-header i {
    font-size: 17px;
    color: #34495e;
    position: relative
}

.widget-chat-box .card-header .pop-up:after {
    content: '';
    position: absolute;
    background-color: #e74c3c;
    height: 8px;
    width: 8px;
    border-radius: 50px;
    right: -3px
}

.widget-chat-box .send-chat,
.widget-chat-box .receive-chat {
    position: relative;
    background: #c5f7ed;
    padding: 7px;
    border-radius: 0 10px 10px 10px;
    font-size: 13px;
    margin-bottom: 30px
}

.widget-chat-box .send-chat:before,
.widget-chat-box .receive-chat:before {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    border: 6px solid transparent;
    border-top: 6px solid #c5f7ed;
    border-right: 6px solid #c5f7ed;
    left: -12px;
    top: 0
}

.widget-chat-box .send-chat .time,
.widget-chat-box .receive-chat .time {
    position: absolute;
    bottom: -20px;
    left: 0
}

.widget-chat-box .receive-chat {
    background-color: #0077b5;
    color: #fff;
    border-radius: 10px
}

.widget-chat-box .receive-chat:before {
    display: none
}

.widget-chat-box .receive-chat .time {
    color: #666
}

.widget-chat-box .rc-10 {
    margin-bottom: 10px
}

.tab-card .media {
    margin-bottom: 10px
}

.tab-card .media:last-child {
    margin-bottom: 0
}

.d-timeline-btn .f-right {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    cursor: pointer;
    padding: 0
}

.d-timeline-btn .f-right i {
    margin-right: 0
}

#datebasechart {
    height: 590px;
    width: 100%
}

.bullet {
    font: 10px sans-serif
}

.bullet .marker {
    stroke: #000;
    stroke-width: 2px
}

.bullet .tick line {
    stroke: #666;
    stroke-width: .5px
}

.bullet .range.s0 {
    fill: #eee
}

.bullet .range.s1 {
    fill: #ddd
}

.bullet .range.s2 {
    fill: #ccc
}

.bullet .measure.s0 {
    fill: #b0c4de
}

.bullet .measure.s1 {
    fill: #4682b4
}

.bullet .title {
    font-size: 14px;
    font-weight: 700
}

.bullet .subtitle {
    fill: #999
}

.section-graph {
    position: relative;
    height: 151px;
    color: #fff;
    background: #55bc75;
    background-image: linear-gradient(#55bc75, #55bc75 50%, #4daf7c 50%)
}

.section-graph .graph-info {
    z-index: 99;
    position: absolute;
    font-weight: 700;
    margin-top: 18px;
    margin-left: 21px;
    width: 100px
}

.section-graph .graph-info .graph-arrow {
    width: 0;
    height: 0;
    margin-top: 18px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #fff;
    float: left
}

.section-graph .graph-info .graph-info-big {
    font-size: 24px;
    float: left;
    margin-left: 3px
}

.section-graph .graph-info .graph-info-small {
    margin-left: 3px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, .5);
    clear: left;
    margin-left: 8px
}

.section-info {
    padding: 21px;
    color: #8a786a;
    overflow: hidden
}

.info-time {
    font-size: 12px
}

.info-block {
    background-color: #fff;
    border: 1px solid #ccc;
    text-align: center;
    width: 120px;
    border-radius: 3px;
    float: left;
    padding: 4px 0
}

.info-block dt,
.info-block dd {
    margin: 0;
    padding: 0
}

.info-block dt {
    font-weight: 700
}

.info-block dd {
    font-size: 12px
}

.info-aapl {
    float: left;
    margin-top: 20px
}

.info-aapl h4 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px
}

.info-aapl ul {
    margin: 0;
    padding: 0
}

.info-aapl li {
    margin: 0;
    display: block;
    width: 9px;
    height: 40px;
    margin-right: 6px;
    background-color: #f5f0ec;
    float: left;
    position: relative
}

.info-aapl li span {
    display: block;
    width: 9px;
    height: 40px;
    position: absolute;
    bottom: 0
}

.info-aapl li span.orange {
    background-color: #f1c40f
}

.info-aapl li span.green {
    background-color: #2ecc71
}

.yearly-change {
    width: 94px;
    float: right;
    margin-top: 20px
}

.yearly-change h4 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px
}

.yearly-change span {
    display: block;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-color: #1abc9c;
    text-align: center;
    border-radius: 3px;
    font-weight: 700
}

.yearly-change span em {
    color: rgba(255, 255, 255, .7)
}

.analytic-table tr td:first-child {
    padding-left: 20px
}

.analytic-table tr td:last-child {
    padding-right: 30px;
    text-align: right;
    font-size: 16px
}

.analytic-table span {
    display: block
}

.analytic-table .count {
    font-size: 18px;
    font-weight: 600
}

.analytic-table .table-msg {
    font-size: 12px;
    margin-top: 5px
}

.analytic-user i {
    font-size: 52px;
    color: #1abc9c
}

.analytic-user .card-footer {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    background-color: #e6e9ea
}

.analytic-user h1 {
    margin: 25px 0;
    font-weight: 300
}

.analytic-user h4 {
    font-size: 20px;
    color: #3498db;
    font-weight: 600
}

.alert {
    font-size: 15px;
    margin-bottom: 2rem
}

.alert.icons-alert {
    border-left-width: 48px
}

.alert.icons-alert .alert-icon {
    left: 15px;
    position: relative;
    text-align: center;
    top: 0;
    z-index: 3
}

.alert.icons-alert p {
    line-height: 21px;
    margin-bottom: 0
}

.close {
    font-size: 16px;
    margin-top: 5px
}

.alert-default {
    background-color: #fff;
    border-color: #bdc3c7;
    color: #bdc3c7
}

.alert-primary {
    background-color: #fff;
    border-color: #1abc9c;
    color: #1abc9c
}

.alert-success {
    background-color: #fff;
    border-color: #2ecc71;
    color: #2ecc71
}

.alert-info {
    background-color: #fff;
    border-color: #3498db;
    color: #3498db
}

.alert-warning {
    background-color: #fff;
    border-color: #f1c40f;
    color: #f1c40f
}

.alert-danger {
    background-color: #fff;
    border-color: #e74c3c;
    color: #e74c3c
}

.border-default {
    border-color: transparent;
    border-left: 3px solid #bdc3c7;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #bdc3c7
}

.border-primary {
    border-color: transparent;
    border-left: 3px solid #1abc9c;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #1abc9c
}

.border-success {
    border-color: transparent;
    border-left: 3px solid #2ecc71;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #2ecc71
}

.border-info {
    border-color: transparent;
    border-left: 3px solid #3498db;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #3498db
}

.border-warning {
    border-color: transparent;
    border-left: 3px solid #f1c40f;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #f1c40f
}

.border-danger {
    border-color: transparent;
    border-left: 3px solid #e74c3c;
    border-radius: 0;
    box-shadow: 0 0 1px #999;
    color: #e74c3c
}

.background-default {
    background-color: #bdc3c7;
    color: #fff
}

.background-primary {
    background-color: #1abc9c;
    color: #fff
}

.background-success {
    background-color: #2ecc71;
    color: #fff
}

.background-info {
    background-color: #3498db;
    color: #fff
}

.background-warning {
    background-color: #f1c40f;
    color: #fff
}

.background-danger {
    background-color: #e74c3c;
    color: #fff
}

.icons-alert {
    position: relative
}

.icons-alert:before {
    color: #fff;
    content: '\f027';
    font-family: icofont !important;
    font-size: 16px;
    left: -30px;
    position: absolute
}

[class*=alert-] code {
    margin-left: 10px
}

.v-bal{
    background-color:#1abc9c;
    font-size:10px;
    padding: .2rem .5rem !important;
    border-radius:5px;
    letter-spacing:.5px;
}
	

.common-img-bg {
    background-size: cover;
    /* background: url(../images/bg.jpg) no-repeat center center fixed; */
    height: 100%
}

.auth-body {
    min-width: 450px
}

.login-card {
    border-radius: 30px;
    position: relative;
    display: block
}

.login-card h1 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 20px;
    text-align: center
}

.login {
    text-align: left
}

.login ul li label {
    color: #757575;
    font-weight: 600;
    letter-spacing: 1px
}

.login h3 {
    color: #666;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px
}

.login.header {
    align-items: flex-start
}

.header .login-card {
    margin-top: 30px
}

.social-login {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 30px
}

.social-login .btn {
    margin-left: 5px;
    margin-right: 5px
}

.login-card .card-footer {
    background: #fff
}

.login-card h1 {
    color: #1b8bf9
}

.login-card .forget {
    border-bottom: 1px solid #fff;
    font-size: 24px;
    line-height: .6
}

.login-card h3 {
    color: #666;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px
}

.btn-signup .btn-md,
.btn-singin1 .btn-md,
.btn-singin2 .btn-md {
    font-size: 22px;
    font-weight: 400;
    width: 400px
}

.form-group-main .form-group {
    margin: 30px 0
}

.login-card .unlock,
.login-card .btn-forgot {
    text-align: center
}

.imgs-screen .img-circle {
    padding: 4px;
    border: 1px solid #e6e6e4
}

.sign-in-with {
    font-weight: 600;
    margin: 24px auto 16px;
    position: relative;
    text-align: center
}

.sign-in-with .text {
    display: inline-block;
    padding: 0 8px;
    position: relative;
    vertical-align: baseline;
    z-index: 9999
}

.sign-in-with .text:after {
    left: 100%
}

.sign-in-with .text:before {
    right: 100%
}

.sign-in-with .text:after,
.sign-in-with .text:before {
    border-top: 1px solid rgba(0, 0, 0, .28);
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    width: 60px
}

.error-500,
.error-503,
.error-404,
.error-400,
.error-403,
.login {
    align-items: center;
    background-color: #2196f3;
    display: flex;
    height: 100%;
    position: fixed;
    text-align: center;
    width: 100%
}

.auth-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 20px 0 0;
    padding: 20px
}

.auth-box .confirm h3 {
    color: #1abc9c;
    font-size: 34px
}

.auth-box i.icofont-check-circled {
    font-size: 42px
}

.btn-facebook {
    background-color: #3c5a99;
    border-color: #3c5a99;
    border-radius: 2px;
    color: #fff;
    cursor: pointer
}

.btn-twitter {
    background-color: #41abe1;
    border-color: #41abe1;
    border-radius: 2px;
    color: #fff;
    cursor: pointer
}

.btn-google-plus,
.btn-linkedin,
.btn-pinterest {
    border-radius: 2px
}

.footer {
    bottom: 0;
    position: absolute;
    width: 100%
}

.footer p {
    margin-bottom: 0;
    padding: 18px;
    width: 100%
}

.login-card-modal {
    margin: 0 auto
}

.login.login-modal-main {
    background-color: #fff
}

.mixed-form .tabbed-modal .nav-link h6 {
    padding: 10px 0
}

.mixed-form .tabbed-modal .nav-link {
    background-color: #f5f5f5;
    border-radius: none;
    color: #888
}

.mixed-form .tabbed-modal .nav-link.active {
    background-color: transparent;
    border-color: transparent;
    color: #34495e
}

.mixed-form .modal-body {
    padding: 0
}

.mixed-form .auth-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 10px;
    padding: 10px
}

.mixed-form .auth-box h3 {
    color: #666;
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px
}

.mixed-form .auth-box i.icofont-check-circled {
    font-size: 42px
}

.auth-box-main .navbar-nav li {
    color: #fff;
    line-height: 40px
}

.offline-404 {
    background-color: #f3f3f3
}

.offline-404 .auth-body {
    margin-top: -100px
}

.offline-404 h1 {
    color: #2c3e50;
    font-size: 160px;
    font-weight: 600;
    letter-spacing: 5px;
    text-shadow: 3px -2px 4px rgba(128, 128, 128, .57)
}

.offline-404 h2 {
    color: #aaa;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 30px
}

.caption-breadcrumb .breadcrumb-header,
.primary-breadcrumb .breadcrumb-header,
.inverse-breadcrumb .breadcrumb-header,
.danger-breadcrumb .breadcrumb-header,
.info-breadcrumb .breadcrumb-header,
.warning-breadcrumb .breadcrumb-header,
.success-breadcrumb .breadcrumb-header {
    display: inline-block
}

.caption-breadcrumb .breadcrumb-header span,
.primary-breadcrumb .breadcrumb-header span,
.inverse-breadcrumb .breadcrumb-header span,
.danger-breadcrumb .breadcrumb-header span,
.info-breadcrumb .breadcrumb-header span,
.warning-breadcrumb .breadcrumb-header span,
.success-breadcrumb .breadcrumb-header span {
    display: block;
    font-size: 13px;
    margin-top: 5px
}

.front-icon-breadcrumb .breadcrumb-header {
    display: inline-block
}

.front-icon-breadcrumb .big-icon {
    display: inline-block
}

.front-icon-breadcrumb .big-icon i {
    font-size: 64px;
    color: #1abc9c
}

.front-icon-breadcrumb .d-inline-block span {
    display: block;
    font-size: 13px;
    margin-top: 5px
}

.primary-breadcrumb,
.inverse-breadcrumb,
.danger-breadcrumb,
.info-breadcrumb,
.warning-breadcrumb,
.success-breadcrumb {
    background-color: #1abc9c;
    color: #fff
}

.primary-breadcrumb h5,
.inverse-breadcrumb h5,
.danger-breadcrumb h5,
.info-breadcrumb h5,
.warning-breadcrumb h5,
.success-breadcrumb h5,
.primary-breadcrumb a,
.inverse-breadcrumb a,
.danger-breadcrumb a,
.info-breadcrumb a,
.warning-breadcrumb a,
.success-breadcrumb a,
.primary-breadcrumb .breadcrumb-title li:last-child a,
.inverse-breadcrumb .breadcrumb-title li:last-child a,
.danger-breadcrumb .breadcrumb-title li:last-child a,
.info-breadcrumb .breadcrumb-title li:last-child a,
.warning-breadcrumb .breadcrumb-title li:last-child a,
.success-breadcrumb .breadcrumb-title li:last-child a,
.primary-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.inverse-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.danger-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.info-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.warning-breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.success-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: #fff
}

.inverse-breadcrumb {
    background-color: #34495e
}

.danger-breadcrumb {
    background-color: #e74c3c
}

.info-breadcrumb {
    background-color: #3498db
}

.warning-breadcrumb {
    background-color: #f1c40f
}

.success-breadcrumb {
    background-color: #2ecc71
}

.nvd-chart {
    height: 400px
}

.peity-chart .peity {
    width: 100%;
    height: 250px
}

#areachart .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke: #187c68;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px
    }
}

.morris-hover {
    position: absolute;
    left: auto !important;
    top: -70px !important;
    right: 0;
    background: #fff;
    padding: 20px;
    border: 1px solid #ccc
}

.chat-bg {
    background-image: url(../images/chat-bg.jpg);
    background-size: cover;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, .45)
}

#main-chat .chat-single-box.active .chat-header {
    background-color: #1abc9c
}

#main-chat .chat-single-box.active .chat-header a {
    color: #fff
}

#main-chat .chat-single-box .chat-header a {
    color: #fff
}

#main-chat .chat-box ul.boxs li.chat-single-box .chat-header.custom-collapsed {
    position: absolute;
    bottom: 0;
    width: 300px
}

#main-chat .minimized {
    background-color: #1e2730
}

#main-chat .user-groups {
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px
}

#main-chat .user-groups .userlist-box {
    border-bottom: none
}

#main-chat .user-groups .userlist-box:last-child {
    padding-bottom: 0;
    margin-bottom: 0
}

#main-chat .user-groups .media {
    padding-left: 0
}

#main-chat .user-groups h6 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px
}

#main-chat .user-groups li {
    margin-bottom: 20px
}

#main-chat .user-groups li:last-child {
    margin-bottom: 0
}

#main-chat .user-groups li.work,
#main-chat .user-groups li.frnds {
    position: relative;
    padding-left: 20px
}

#main-chat .user-groups li.work:before,
#main-chat .user-groups li.frnds:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: #2ecc71;
    border-radius: 50px;
    left: 0;
    top: 6px
}

#main-chat .user-groups li.frnds:before {
    background-color: #e74c3c
}

.user-box {
    height: 100%
}

.close {
    opacity: 1
}

.write-msg {
    padding: 12px;
    bottom: 10px;
    border-top: 1px solid #ddd
}

.write-msg #paper-btn i {
    font-size: 18px;
    margin-right: 0;
    cursor: pointer
}

.chat-box .secondary:active:hover {
    background-color: #fff
}

#main-chat .chat-box ul.boxs li.minimized {
    height: 30px;
    width: 30px;
    bottom: 0;
    position: absolute;
    left: -50px;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    cursor: pointer
}

#main-chat .chat-box ul.boxs li.minimized .count {
    color: #fff;
    text-align: center;
    margin: 5px
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown {
    list-style: none;
    display: none;
    position: absolute;
    background-color: #f5f5f5;
    -webkit-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, .5);
    -moz-box-shadow: 0 1px 8px 0 rgba(5, 5, 5, .5);
    box-shadow: 0 1px 8px 0 rgba(5, 5, 5, .5);
    width: 150px;
    z-index: 100;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 5px 0
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li {
    padding: 2px 5px
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li div {
    display: inline-block
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li .username {
    width: 85%;
    height: 22px
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li .remove {
    width: 13%;
    padding: 2px 4px;
    float: right
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li .remove:hover {
    background-color: silver !important
}

#main-chat .chat-box ul.boxs li.minimized .chat-dropdown li:hover {
    color: #000
}

#main-chat .chat-box ul.boxs li.hidden {
    display: none
}

.chat-single-box {
    height: 440px;
    float: right;
    width: 300px;
    margin-right: 15px;
    direction: ltr;
    z-index: 999;
    bottom: 0
}

.chat-single-box .chat-header {
    background-color: #bdc3c7;
    color: #fff
}

.box-live-status {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    border: 1px solid
}

.chat-msg-img {
    height: 50px;
    width: 50px
}

.msg-reply {
    padding: 5px;
    position: relative;
    right: 10px;
    border-radius: 2px;
    top: 6px;
    margin-bottom: 10px
}

.header-users {
    right: 0;
    top: 103px;
    width: 300px;
    height: 100%
}

.users {
    right: 0;
    top: 103px;
    width: 300px;
    height: 100%
}

.p-chat-user {
    position: fixed;
    top: 50px;
    z-index: 99;
    border-left: 1px solid #ccc
}

.users-main {
    height: 100%;
    width: 300px;
    position: fixed;
    border-radius: 0
}

.userlist-box {
    cursor: pointer;
    border-bottom: 1px solid #efefef
}

.userlist-box .media-left {
    padding-right: 10px
}

.users-main .media {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative
}

.user-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block
}

.chat-menu-content,
.chat-menu-reply {
    background: #f3f3f3;
    position: relative;
    overflow: visible;
    border-radius: 4px
}

.chat-menu-content:before {
    position: absolute;
    top: 20px;
    left: -14px;
    width: 0;
    height: 0;
    content: '';
    border: 8px solid transparent;
    border-right-color: #f3f3f3
}

.chat-menu-reply:before {
    position: absolute;
    top: 20px;
    right: -14px;
    width: 0;
    height: 0;
    content: '';
    border: 8px solid transparent;
    border-left-color: #1abc9c
}

.chat-reply-box {
    background-color: #fff;
    position: absolute;
    bottom: 43px;
    width: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-top: 1px solid #ccc
}

.chat-inner-header {
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 20px
}

.chat-send {
    position: absolute;
    bottom: 5px;
    right: 0;
    border: none;
    background-color: transparent;
    color: #1abc9c;
    transform: rotate(45deg)
}

.showChat_inner .chat-messages {
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px
}

.chat-menu-content .chat-cont,
.chat-menu-reply .chat-cont {
    padding: 10px
}

.chat-menu-content .chat-time,
.chat-menu-reply .chat-time {
    padding-left: 10px;
    padding-bottom: 10px
}

.back_chatBox {
    margin: 0 auto;
    font-weight: 600
}

.back_chatBox i {
    float: left;
    position: absolute;
    margin-top: 5px;
    left: 20px;
    cursor: pointer
}

.designation {
    cursor: pointer
}

.extra-profile-list {
    display: none
}

.chat-menu-reply .chat-cont,
.chat-menu-reply .chat-time {
    color: #fff
}

.chat-menu-reply {
    background: #1abc9c;
    margin-right: 10px
}

.users-main .media .chat-header {
    font-size: 14px;
    font-weight: 600
}

.users-main .media-body div+div {
    font-size: 12px
}

.chat-body {
    overflow-y: auto;
    height: 340px
}

.users-main-fix {
    height: auto;
    position: fixed;
    bottom: 0;
    top: 151px
}

.chat-footer,
.chat-link {
    border-color: #1abc9c
}

.chat-footer .input-group-addon {
    padding: 7px .75rem
}

.form-control:focus~.chat-send {
    border-color: #1abc9c
}

.minimized-box {
    padding: 5px 10px
}

.write-msg input {
    border-radius: 0;
    border-right: 0
}

.write-msg .btn {
    box-shadow: none;
    border-left: 0;
    font-size: 14px
}

.write-msg .btn-secondary:hover {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-left: 0
}

.form-control:focus~.input-group-btn .btn-secondary {
    border-color: #66afe9;
    color: #1abc9c
}

.img-chat-profile {
    height: 45px;
    width: 45px
}

.chat-minimize .chat-body,
.chat-minimize .chat-footer {
    display: none
}

.chat-box {
    margin-right: 290px;
    height: 440px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999999
}

.user-box .media-object,
.friend-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block
}

.friend-box img {
    margin-right: 10px;
    margin-bottom: 10px
}

.chat-header {
    color: #222
}

.live-status {
    height: 10px;
    width: 10px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 100%;
    border: 1px solid
}

.showChat {
    display: none
}

.showChat_inner {
    position: fixed;
    top: 50px;
    background-color: #fff;
    right: 0;
    display: none;
    width: 300px;
    height: 100%;
    z-index: 99;
    border-left: 1px solid #ccc
}

.amber-colors ul li p,
.bg-danger li p,
.bg-default li p,
.bg-info li p,
.bg-primary li p,
.bg-success li p,
.bg-warning li p,
.blue-colors ul li p,
.blue-grey-colors ul li p,
.brown-colors ul li p,
.cyan-colors ul li p,
.deep-orange-colors ul li p,
.deep-purple-colors ul li p,
.green-colors ul li p,
.grey-colors ul li p,
.indigo-colors ul li p,
.light-blue-colors ul li p,
.light-green-colors ul li p,
.lime-colors ul li p,
.orange-colors ul li p,
.pink-colors ul li p,
.purple-colors ul li p,
.red-colors ul li p,
.teal-colors ul li p,
.yellow-colors ul li p,
.primary-colorr ul li p,
.success-colorr ul li p,
.info-colorr ul li p,
.warning-colorr ul li p,
.danger-colorr ul li p {
    font-size: 18px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 0
}

.red-colors ul li:nth-child(14) {
    background-color: #e40505
}

.red-colors ul li:nth-child(14) p:before {
    content: "#e40505"
}

.red-colors ul li:nth-child(13) {
    background-color: #f30606
}

.red-colors ul li:nth-child(13) p:before {
    content: "#f30606"
}

.red-colors ul li:nth-child(12) {
    background-color: #f90f0f
}

.red-colors ul li:nth-child(12) p:before {
    content: "#f90f0f"
}

.red-colors ul li:nth-child(11) {
    background-color: #fa1d1d
}

.red-colors ul li:nth-child(11) p:before {
    content: "#fa1d1d"
}

.red-colors ul li:nth-child(10) {
    background-color: #fa2c2c
}

.red-colors ul li:nth-child(10) p:before {
    content: "#fa2c2c"
}

.red-colors ul li:nth-child(9) {
    background-color: #fa3b3b
}

.red-colors ul li:nth-child(9) p:before {
    content: "#fa3b3b"
}

.red-colors ul li:nth-child(8) {
    background-color: #fb4a4a
}

.red-colors ul li:nth-child(8) p:before {
    content: "#fb4a4a"
}

.red-colors ul li:nth-child(7) {
    background-color: #fb5959
}

.red-colors ul li:nth-child(7) p:before {
    content: "#fb5959"
}

.red-colors ul li:nth-child(6) {
    background-color: #fb6868
}

.red-colors ul li:nth-child(6) p:before {
    content: "#fb6868"
}

.red-colors ul li:nth-child(5) {
    background-color: #fc7777
}

.red-colors ul li:nth-child(5) p:before {
    content: "#fc7777"
}

.red-colors ul li:nth-child(4) {
    background-color: #fc8686
}

.red-colors ul li:nth-child(4) p:before {
    content: "#fc8686"
}

.red-colors ul li:nth-child(3) {
    background-color: #fd9595
}

.red-colors ul li:nth-child(3) p:before {
    content: "#fd9595"
}

.red-colors ul li:nth-child(2) {
    background-color: #fda4a4
}

.red-colors ul li:nth-child(2) p:before {
    content: "#fda4a4"
}

.red-colors ul li:nth-child(1) {
    background-color: #fdb3b3
}

.red-colors ul li:nth-child(1) p:before {
    content: "#fdb3b3"
}

.pink-colors ul li:nth-child(14) {
    background-color: #d4166c
}

.pink-colors ul li:nth-child(14) p:before {
    content: "#d4166c"
}

.pink-colors ul li:nth-child(13) {
    background-color: #e21873
}

.pink-colors ul li:nth-child(13) p:before {
    content: "#e21873"
}

.pink-colors ul li:nth-child(12) {
    background-color: #e7217b
}

.pink-colors ul li:nth-child(12) p:before {
    content: "#e7217b"
}

.pink-colors ul li:nth-child(11) {
    background-color: #e92f83
}

.pink-colors ul li:nth-child(11) p:before {
    content: "#e92f83"
}

.pink-colors ul li:nth-child(10) {
    background-color: #ea3d8b
}

.pink-colors ul li:nth-child(10) p:before {
    content: "#ea3d8b"
}

.pink-colors ul li:nth-child(9) {
    background-color: #ec4b94
}

.pink-colors ul li:nth-child(9) p:before {
    content: "#ec4b94"
}

.pink-colors ul li:nth-child(8) {
    background-color: #ed599c
}

.pink-colors ul li:nth-child(8) p:before {
    content: "#ed599c"
}

.pink-colors ul li:nth-child(7) {
    background-color: #ef67a4
}

.pink-colors ul li:nth-child(7) p:before {
    content: "#ef67a4"
}

.pink-colors ul li:nth-child(6) {
    background-color: #f074ac
}

.pink-colors ul li:nth-child(6) p:before {
    content: "#f074ac"
}

.pink-colors ul li:nth-child(5) {
    background-color: #f282b5
}

.pink-colors ul li:nth-child(5) p:before {
    content: "#f282b5"
}

.pink-colors ul li:nth-child(4) {
    background-color: #f390bd
}

.pink-colors ul li:nth-child(4) p:before {
    content: "#f390bd"
}

.pink-colors ul li:nth-child(3) {
    background-color: #f59ec5
}

.pink-colors ul li:nth-child(3) p:before {
    content: "#f59ec5"
}

.pink-colors ul li:nth-child(2) {
    background-color: #f6accd
}

.pink-colors ul li:nth-child(2) p:before {
    content: "#f6accd"
}

.pink-colors ul li:nth-child(1) {
    background-color: #f8bad6
}

.pink-colors ul li:nth-child(1) p:before {
    content: "#f8bad6"
}

.purple-colors ul li:nth-child(14) {
    background-color: #b014ff
}

.purple-colors ul li:nth-child(14) p:before {
    content: "#b014ff"
}

.purple-colors ul li:nth-child(13) {
    background-color: #b524ff
}

.purple-colors ul li:nth-child(13) p:before {
    content: "#b524ff"
}

.purple-colors ul li:nth-child(12) {
    background-color: #ba33ff
}

.purple-colors ul li:nth-child(12) p:before {
    content: "#ba33ff"
}

.purple-colors ul li:nth-child(11) {
    background-color: #c042ff
}

.purple-colors ul li:nth-child(11) p:before {
    content: "#c042ff"
}

.purple-colors ul li:nth-child(10) {
    background-color: #c552ff
}

.purple-colors ul li:nth-child(10) p:before {
    content: "#c552ff"
}

.purple-colors ul li:nth-child(9) {
    background-color: #ca61ff
}

.purple-colors ul li:nth-child(9) p:before {
    content: "#ca61ff"
}

.purple-colors ul li:nth-child(8) {
    background-color: #cf70ff
}

.purple-colors ul li:nth-child(8) p:before {
    content: "#cf70ff"
}

.purple-colors ul li:nth-child(7) {
    background-color: #d47fff
}

.purple-colors ul li:nth-child(7) p:before {
    content: "#d47fff"
}

.purple-colors ul li:nth-child(6) {
    background-color: #d98fff
}

.purple-colors ul li:nth-child(6) p:before {
    content: "#d98fff"
}

.purple-colors ul li:nth-child(5) {
    background-color: #de9eff
}

.purple-colors ul li:nth-child(5) p:before {
    content: "#de9eff"
}

.purple-colors ul li:nth-child(4) {
    background-color: #e4adff
}

.purple-colors ul li:nth-child(4) p:before {
    content: "#e4adff"
}

.purple-colors ul li:nth-child(3) {
    background-color: #e9bdff
}

.purple-colors ul li:nth-child(3) p:before {
    content: "#e9bdff"
}

.purple-colors ul li:nth-child(2) {
    background-color: #ecf
}

.purple-colors ul li:nth-child(2) p:before {
    content: "#eeccff"
}

.purple-colors ul li:nth-child(1) {
    background-color: #f3dbff
}

.purple-colors ul li:nth-child(1) p:before {
    content: "#f3dbff"
}

.deep-purple-colors ul li:nth-child(14) {
    background-color: #6b05f9
}

.deep-purple-colors ul li:nth-child(14) p:before {
    content: "#6b05f9"
}

.deep-purple-colors ul li:nth-child(13) {
    background-color: #7414fa
}

.deep-purple-colors ul li:nth-child(13) p:before {
    content: "#7414fa"
}

.deep-purple-colors ul li:nth-child(12) {
    background-color: #7d23fa
}

.deep-purple-colors ul li:nth-child(12) p:before {
    content: "#7d23fa"
}

.deep-purple-colors ul li:nth-child(11) {
    background-color: #8632fb
}

.deep-purple-colors ul li:nth-child(11) p:before {
    content: "#8632fb"
}

.deep-purple-colors ul li:nth-child(10) {
    background-color: #8f41fb
}

.deep-purple-colors ul li:nth-child(10) p:before {
    content: "#8f41fb"
}

.deep-purple-colors ul li:nth-child(9) {
    background-color: #9850fb
}

.deep-purple-colors ul li:nth-child(9) p:before {
    content: "#9850fb"
}

.deep-purple-colors ul li:nth-child(8) {
    background-color: #a05ffc
}

.deep-purple-colors ul li:nth-child(8) p:before {
    content: "#a05ffc"
}

.deep-purple-colors ul li:nth-child(7) {
    background-color: #a96efc
}

.deep-purple-colors ul li:nth-child(7) p:before {
    content: "#a96efc"
}

.deep-purple-colors ul li:nth-child(6) {
    background-color: #b27cfc
}

.deep-purple-colors ul li:nth-child(6) p:before {
    content: "#b27cfc"
}

.deep-purple-colors ul li:nth-child(5) {
    background-color: #bb8bfd
}

.deep-purple-colors ul li:nth-child(5) p:before {
    content: "#bb8bfd"
}

.deep-purple-colors ul li:nth-child(4) {
    background-color: #c49afd
}

.deep-purple-colors ul li:nth-child(4) p:before {
    content: "#c49afd"
}

.deep-purple-colors ul li:nth-child(3) {
    background-color: #cda9fd
}

.deep-purple-colors ul li:nth-child(3) p:before {
    content: "#cda9fd"
}

.deep-purple-colors ul li:nth-child(2) {
    background-color: #d5b8fd
}

.deep-purple-colors ul li:nth-child(2) p:before {
    content: "#d5b8fd"
}

.deep-purple-colors ul li:nth-child(1) {
    background-color: #dec7fe
}

.deep-purple-colors ul li:nth-child(1) p:before {
    content: "#dec7fe"
}

.indigo-colors ul li:nth-child(14) {
    background-color: #415dfe
}

.indigo-colors ul li:nth-child(14) p:before {
    content: "#415dfe"
}

.indigo-colors ul li:nth-child(13) {
    background-color: #4d68fe
}

.indigo-colors ul li:nth-child(13) p:before {
    content: "#4d68fe"
}

.indigo-colors ul li:nth-child(12) {
    background-color: #5a72fe
}

.indigo-colors ul li:nth-child(12) p:before {
    content: "#5a72fe"
}

.indigo-colors ul li:nth-child(11) {
    background-color: #677dfe
}

.indigo-colors ul li:nth-child(11) p:before {
    content: "#677dfe"
}

.indigo-colors ul li:nth-child(10) {
    background-color: #7388fe
}

.indigo-colors ul li:nth-child(10) p:before {
    content: "#7388fe"
}

.indigo-colors ul li:nth-child(9) {
    background-color: #8093fe
}

.indigo-colors ul li:nth-child(9) p:before {
    content: "#8093fe"
}

.indigo-colors ul li:nth-child(8) {
    background-color: #8d9efe
}

.indigo-colors ul li:nth-child(8) p:before {
    content: "#8d9efe"
}

.indigo-colors ul li:nth-child(7) {
    background-color: #9aa9ff
}

.indigo-colors ul li:nth-child(7) p:before {
    content: "#9aa9ff"
}

.indigo-colors ul li:nth-child(6) {
    background-color: #a6b3ff
}

.indigo-colors ul li:nth-child(6) p:before {
    content: "#a6b3ff"
}

.indigo-colors ul li:nth-child(5) {
    background-color: #b3beff
}

.indigo-colors ul li:nth-child(5) p:before {
    content: "#b3beff"
}

.indigo-colors ul li:nth-child(4) {
    background-color: #c0c9ff
}

.indigo-colors ul li:nth-child(4) p:before {
    content: "#c0c9ff"
}

.indigo-colors ul li:nth-child(3) {
    background-color: #ccd4ff
}

.indigo-colors ul li:nth-child(3) p:before {
    content: "#ccd4ff"
}

.indigo-colors ul li:nth-child(2) {
    background-color: #d9dfff
}

.indigo-colors ul li:nth-child(2) p:before {
    content: "#d9dfff"
}

.indigo-colors ul li:nth-child(1) {
    background-color: #e6e9ff
}

.indigo-colors ul li:nth-child(1) p:before {
    content: "#e6e9ff"
}

.blue-colors ul li:nth-child(14) {
    background-color: #3a6eff
}

.blue-colors ul li:nth-child(14) p:before {
    content: "#3a6eff"
}

.blue-colors ul li:nth-child(13) {
    background-color: #4778ff
}

.blue-colors ul li:nth-child(13) p:before {
    content: "#4778ff"
}

.blue-colors ul li:nth-child(12) {
    background-color: #5381ff
}

.blue-colors ul li:nth-child(12) p:before {
    content: "#5381ff"
}

.blue-colors ul li:nth-child(11) {
    background-color: #608aff
}

.blue-colors ul li:nth-child(11) p:before {
    content: "#608aff"
}

.blue-colors ul li:nth-child(10) {
    background-color: #6d94ff
}

.blue-colors ul li:nth-child(10) p:before {
    content: "#6d94ff"
}

.blue-colors ul li:nth-child(9) {
    background-color: #7a9dff
}

.blue-colors ul li:nth-child(9) p:before {
    content: "#7a9dff"
}

.blue-colors ul li:nth-child(8) {
    background-color: #86a6ff
}

.blue-colors ul li:nth-child(8) p:before {
    content: "#86a6ff"
}

.blue-colors ul li:nth-child(7) {
    background-color: #93b0ff
}

.blue-colors ul li:nth-child(7) p:before {
    content: "#93b0ff"
}

.blue-colors ul li:nth-child(6) {
    background-color: #a0b9ff
}

.blue-colors ul li:nth-child(6) p:before {
    content: "#a0b9ff"
}

.blue-colors ul li:nth-child(5) {
    background-color: #adc3ff
}

.blue-colors ul li:nth-child(5) p:before {
    content: "#adc3ff"
}

.blue-colors ul li:nth-child(4) {
    background-color: #b9ccff
}

.blue-colors ul li:nth-child(4) p:before {
    content: "#b9ccff"
}

.blue-colors ul li:nth-child(3) {
    background-color: #c6d5ff
}

.blue-colors ul li:nth-child(3) p:before {
    content: "#c6d5ff"
}

.blue-colors ul li:nth-child(2) {
    background-color: #d3dfff
}

.blue-colors ul li:nth-child(2) p:before {
    content: "#d3dfff"
}

.blue-colors ul li:nth-child(1) {
    background-color: #e0e8ff
}

.blue-colors ul li:nth-child(1) p:before {
    content: "#e0e8ff"
}

.light-blue-colors ul li:nth-child(14) {
    background-color: #059cf9
}

.light-blue-colors ul li:nth-child(14) p:before {
    content: "#059cf9"
}

.light-blue-colors ul li:nth-child(13) {
    background-color: #14a2fa
}

.light-blue-colors ul li:nth-child(13) p:before {
    content: "#14a2fa"
}

.light-blue-colors ul li:nth-child(12) {
    background-color: #23a8fa
}

.light-blue-colors ul li:nth-child(12) p:before {
    content: "#23a8fa"
}

.light-blue-colors ul li:nth-child(11) {
    background-color: #32aefb
}

.light-blue-colors ul li:nth-child(11) p:before {
    content: "#32aefb"
}

.light-blue-colors ul li:nth-child(10) {
    background-color: #41b4fb
}

.light-blue-colors ul li:nth-child(10) p:before {
    content: "#41b4fb"
}

.light-blue-colors ul li:nth-child(9) {
    background-color: #50bafb
}

.light-blue-colors ul li:nth-child(9) p:before {
    content: "#50bafb"
}

.light-blue-colors ul li:nth-child(8) {
    background-color: #5fc0fc
}

.light-blue-colors ul li:nth-child(8) p:before {
    content: "#5fc0fc"
}

.light-blue-colors ul li:nth-child(7) {
    background-color: #6ec6fc
}

.light-blue-colors ul li:nth-child(7) p:before {
    content: "#6ec6fc"
}

.light-blue-colors ul li:nth-child(6) {
    background-color: #7cccfc
}

.light-blue-colors ul li:nth-child(6) p:before {
    content: "#7cccfc"
}

.light-blue-colors ul li:nth-child(5) {
    background-color: #8bd2fd
}

.light-blue-colors ul li:nth-child(5) p:before {
    content: "#8bd2fd"
}

.light-blue-colors ul li:nth-child(4) {
    background-color: #9ad7fd
}

.light-blue-colors ul li:nth-child(4) p:before {
    content: "#9ad7fd"
}

.light-blue-colors ul li:nth-child(3) {
    background-color: #a9ddfd
}

.light-blue-colors ul li:nth-child(3) p:before {
    content: "#a9ddfd"
}

.light-blue-colors ul li:nth-child(2) {
    background-color: #b8e3fd
}

.light-blue-colors ul li:nth-child(2) p:before {
    content: "#b8e3fd"
}

.light-blue-colors ul li:nth-child(1) {
    background-color: #c7e9fe
}

.light-blue-colors ul li:nth-child(1) p:before {
    content: "#c7e9fe"
}

.cyan-colors ul li:nth-child(14) {
    background-color: #05c6e3
}

.cyan-colors ul li:nth-child(14) p:before {
    content: "#05c6e3"
}

.cyan-colors ul li:nth-child(13) {
    background-color: #06d3f2
}

.cyan-colors ul li:nth-child(13) p:before {
    content: "#06d3f2"
}

.cyan-colors ul li:nth-child(12) {
    background-color: #0edbf9
}

.cyan-colors ul li:nth-child(12) p:before {
    content: "#0edbf9"
}

.cyan-colors ul li:nth-child(11) {
    background-color: #1dddfa
}

.cyan-colors ul li:nth-child(11) p:before {
    content: "#1dddfa"
}

.cyan-colors ul li:nth-child(10) {
    background-color: #2bdffa
}

.cyan-colors ul li:nth-child(10) p:before {
    content: "#2bdffa"
}

.cyan-colors ul li:nth-child(9) {
    background-color: #3ae1fa
}

.cyan-colors ul li:nth-child(9) p:before {
    content: "#3ae1fa"
}

.cyan-colors ul li:nth-child(8) {
    background-color: #49e4fb
}

.cyan-colors ul li:nth-child(8) p:before {
    content: "#49e4fb"
}

.cyan-colors ul li:nth-child(7) {
    background-color: #58e6fb
}

.cyan-colors ul li:nth-child(7) p:before {
    content: "#58e6fb"
}

.cyan-colors ul li:nth-child(6) {
    background-color: #67e8fb
}

.cyan-colors ul li:nth-child(6) p:before {
    content: "#67e8fb"
}

.cyan-colors ul li:nth-child(5) {
    background-color: #76eafc
}

.cyan-colors ul li:nth-child(5) p:before {
    content: "#76eafc"
}

.cyan-colors ul li:nth-child(4) {
    background-color: #85edfc
}

.cyan-colors ul li:nth-child(4) p:before {
    content: "#85edfc"
}

.cyan-colors ul li:nth-child(3) {
    background-color: #94effc
}

.cyan-colors ul li:nth-child(3) p:before {
    content: "#94effc"
}

.cyan-colors ul li:nth-child(2) {
    background-color: #a3f1fd
}

.cyan-colors ul li:nth-child(2) p:before {
    content: "#a3f1fd"
}

.cyan-colors ul li:nth-child(1) {
    background-color: #b2f3fd
}

.cyan-colors ul li:nth-child(1) p:before {
    content: "#b2f3fd"
}

.teal-colors ul li:nth-child(14) {
    background-color: #05cfb3
}

.teal-colors ul li:nth-child(14) p:before {
    content: "#05cfb3"
}

.teal-colors ul li:nth-child(13) {
    background-color: #06dec0
}

.teal-colors ul li:nth-child(13) p:before {
    content: "#06dec0"
}

.teal-colors ul li:nth-child(12) {
    background-color: #06edcd
}

.teal-colors ul li:nth-child(12) p:before {
    content: "#06edcd"
}

.teal-colors ul li:nth-child(11) {
    background-color: #0af9d7
}

.teal-colors ul li:nth-child(11) p:before {
    content: "#0af9d7"
}

.teal-colors ul li:nth-child(10) {
    background-color: #19f9da
}

.teal-colors ul li:nth-child(10) p:before {
    content: "#19f9da"
}

.teal-colors ul li:nth-child(9) {
    background-color: #27f9dc
}

.teal-colors ul li:nth-child(9) p:before {
    content: "#27f9dc"
}

.teal-colors ul li:nth-child(8) {
    background-color: #36fadf
}

.teal-colors ul li:nth-child(8) p:before {
    content: "#36fadf"
}

.teal-colors ul li:nth-child(7) {
    background-color: #45fae1
}

.teal-colors ul li:nth-child(7) p:before {
    content: "#45fae1"
}

.teal-colors ul li:nth-child(6) {
    background-color: #54fbe3
}

.teal-colors ul li:nth-child(6) p:before {
    content: "#54fbe3"
}

.teal-colors ul li:nth-child(5) {
    background-color: #63fbe6
}

.teal-colors ul li:nth-child(5) p:before {
    content: "#63fbe6"
}

.teal-colors ul li:nth-child(4) {
    background-color: #72fbe8
}

.teal-colors ul li:nth-child(4) p:before {
    content: "#72fbe8"
}

.teal-colors ul li:nth-child(3) {
    background-color: #81fceb
}

.teal-colors ul li:nth-child(3) p:before {
    content: "#81fceb"
}

.teal-colors ul li:nth-child(2) {
    background-color: #90fced
}

.teal-colors ul li:nth-child(2) p:before {
    content: "#90fced"
}

.teal-colors ul li:nth-child(1) {
    background-color: #9ffcef
}

.teal-colors ul li:nth-child(1) p:before {
    content: "#9ffcef"
}

.green-colors ul li:nth-child(14) {
    background-color: #05d85c
}

.green-colors ul li:nth-child(14) p:before {
    content: "#05d85c"
}

.green-colors ul li:nth-child(13) {
    background-color: #06e763
}

.green-colors ul li:nth-child(13) p:before {
    content: "#06e763"
}

.green-colors ul li:nth-child(12) {
    background-color: #06f669
}

.green-colors ul li:nth-child(12) p:before {
    content: "#06f669"
}

.green-colors ul li:nth-child(11) {
    background-color: #12f972
}

.green-colors ul li:nth-child(11) p:before {
    content: "#12f972"
}

.green-colors ul li:nth-child(10) {
    background-color: #21f97a
}

.green-colors ul li:nth-child(10) p:before {
    content: "#21f97a"
}

.green-colors ul li:nth-child(9) {
    background-color: #30fa83
}

.green-colors ul li:nth-child(9) p:before {
    content: "#30fa83"
}

.green-colors ul li:nth-child(8) {
    background-color: #3ffa8c
}

.green-colors ul li:nth-child(8) p:before {
    content: "#3ffa8c"
}

.green-colors ul li:nth-child(7) {
    background-color: #4efb95
}

.green-colors ul li:nth-child(7) p:before {
    content: "#4efb95"
}

.green-colors ul li:nth-child(6) {
    background-color: #5dfb9e
}

.green-colors ul li:nth-child(6) p:before {
    content: "#5dfb9e"
}

.green-colors ul li:nth-child(5) {
    background-color: #6cfba7
}

.green-colors ul li:nth-child(5) p:before {
    content: "#6cfba7"
}

.green-colors ul li:nth-child(4) {
    background-color: #7bfcb0
}

.green-colors ul li:nth-child(4) p:before {
    content: "#7bfcb0"
}

.green-colors ul li:nth-child(3) {
    background-color: #8afcb9
}

.green-colors ul li:nth-child(3) p:before {
    content: "#8afcb9"
}

.green-colors ul li:nth-child(2) {
    background-color: #98fcc2
}

.green-colors ul li:nth-child(2) p:before {
    content: "#98fcc2"
}

.green-colors ul li:nth-child(1) {
    background-color: #a7fdcb
}

.green-colors ul li:nth-child(1) p:before {
    content: "#a7fdcb"
}

.light-green-colors ul li:nth-child(14) {
    background-color: #6fe423
}

.light-green-colors ul li:nth-child(14) p:before {
    content: "#6fe423"
}

.light-green-colors ul li:nth-child(13) {
    background-color: #78e631
}

.light-green-colors ul li:nth-child(13) p:before {
    content: "#78e631"
}

.light-green-colors ul li:nth-child(12) {
    background-color: #81e73e
}

.light-green-colors ul li:nth-child(12) p:before {
    content: "#81e73e"
}

.light-green-colors ul li:nth-child(11) {
    background-color: #8ae94c
}

.light-green-colors ul li:nth-child(11) p:before {
    content: "#8ae94c"
}

.light-green-colors ul li:nth-child(10) {
    background-color: #93eb5a
}

.light-green-colors ul li:nth-child(10) p:before {
    content: "#93eb5a"
}

.light-green-colors ul li:nth-child(9) {
    background-color: #9bec67
}

.light-green-colors ul li:nth-child(9) p:before {
    content: "#9bec67"
}

.light-green-colors ul li:nth-child(8) {
    background-color: #a4ee75
}

.light-green-colors ul li:nth-child(8) p:before {
    content: "#a4ee75"
}

.light-green-colors ul li:nth-child(7) {
    background-color: #adf083
}

.light-green-colors ul li:nth-child(7) p:before {
    content: "#adf083"
}

.light-green-colors ul li:nth-child(6) {
    background-color: #b6f190
}

.light-green-colors ul li:nth-child(6) p:before {
    content: "#b6f190"
}

.light-green-colors ul li:nth-child(5) {
    background-color: #bff39e
}

.light-green-colors ul li:nth-child(5) p:before {
    content: "#bff39e"
}

.light-green-colors ul li:nth-child(4) {
    background-color: #c8f5ac
}

.light-green-colors ul li:nth-child(4) p:before {
    content: "#c8f5ac"
}

.light-green-colors ul li:nth-child(3) {
    background-color: #d1f6b9
}

.light-green-colors ul li:nth-child(3) p:before {
    content: "#d1f6b9"
}

.light-green-colors ul li:nth-child(2) {
    background-color: #daf8c7
}

.light-green-colors ul li:nth-child(2) p:before {
    content: "#daf8c7"
}

.light-green-colors ul li:nth-child(1) {
    background-color: #e3fad4
}

.light-green-colors ul li:nth-child(1) p:before {
    content: "#e3fad4"
}

.lime-colors ul li:nth-child(14) {
    background-color: #baf905
}

.lime-colors ul li:nth-child(14) p:before {
    content: "#baf905"
}

.lime-colors ul li:nth-child(13) {
    background-color: #bffa14
}

.lime-colors ul li:nth-child(13) p:before {
    content: "#bffa14"
}

.lime-colors ul li:nth-child(12) {
    background-color: #c3fa23
}

.lime-colors ul li:nth-child(12) p:before {
    content: "#c3fa23"
}

.lime-colors ul li:nth-child(11) {
    background-color: #c7fb32
}

.lime-colors ul li:nth-child(11) p:before {
    content: "#c7fb32"
}

.lime-colors ul li:nth-child(10) {
    background-color: #cbfb41
}

.lime-colors ul li:nth-child(10) p:before {
    content: "#cbfb41"
}

.lime-colors ul li:nth-child(9) {
    background-color: #cffb50
}

.lime-colors ul li:nth-child(9) p:before {
    content: "#cffb50"
}

.lime-colors ul li:nth-child(8) {
    background-color: #d3fc5f
}

.lime-colors ul li:nth-child(8) p:before {
    content: "#d3fc5f"
}

.lime-colors ul li:nth-child(7) {
    background-color: #d7fc6e
}

.lime-colors ul li:nth-child(7) p:before {
    content: "#d7fc6e"
}

.lime-colors ul li:nth-child(6) {
    background-color: #dbfc7c
}

.lime-colors ul li:nth-child(6) p:before {
    content: "#dbfc7c"
}

.lime-colors ul li:nth-child(5) {
    background-color: #dffd8b
}

.lime-colors ul li:nth-child(5) p:before {
    content: "#dffd8b"
}

.lime-colors ul li:nth-child(4) {
    background-color: #e3fd9a
}

.lime-colors ul li:nth-child(4) p:before {
    content: "#e3fd9a"
}

.lime-colors ul li:nth-child(3) {
    background-color: #e8fda9
}

.lime-colors ul li:nth-child(3) p:before {
    content: "#e8fda9"
}

.lime-colors ul li:nth-child(2) {
    background-color: #ecfdb8
}

.lime-colors ul li:nth-child(2) p:before {
    content: "#ecfdb8"
}

.lime-colors ul li:nth-child(1) {
    background-color: #f0fec7
}

.lime-colors ul li:nth-child(1) p:before {
    content: "#f0fec7"
}

.yellow-colors ul li:nth-child(14) {
    background-color: #ffd812
}

.yellow-colors ul li:nth-child(14) p:before {
    content: "#ffd812"
}

.yellow-colors ul li:nth-child(13) {
    background-color: #ffda1f
}

.yellow-colors ul li:nth-child(13) p:before {
    content: "#ffda1f"
}

.yellow-colors ul li:nth-child(12) {
    background-color: #ffdc2b
}

.yellow-colors ul li:nth-child(12) p:before {
    content: "#ffdc2b"
}

.yellow-colors ul li:nth-child(11) {
    background-color: #ffde38
}

.yellow-colors ul li:nth-child(11) p:before {
    content: "#ffde38"
}

.yellow-colors ul li:nth-child(10) {
    background-color: #ffe045
}

.yellow-colors ul li:nth-child(10) p:before {
    content: "#ffe045"
}

.yellow-colors ul li:nth-child(9) {
    background-color: #ffe352
}

.yellow-colors ul li:nth-child(9) p:before {
    content: "#ffe352"
}

.yellow-colors ul li:nth-child(8) {
    background-color: #ffe55e
}

.yellow-colors ul li:nth-child(8) p:before {
    content: "#ffe55e"
}

.yellow-colors ul li:nth-child(7) {
    background-color: #ffe76b
}

.yellow-colors ul li:nth-child(7) p:before {
    content: "#ffe76b"
}

.yellow-colors ul li:nth-child(6) {
    background-color: #ffe978
}

.yellow-colors ul li:nth-child(6) p:before {
    content: "#ffe978"
}

.yellow-colors ul li:nth-child(5) {
    background-color: #ffeb85
}

.yellow-colors ul li:nth-child(5) p:before {
    content: "#ffeb85"
}

.yellow-colors ul li:nth-child(4) {
    background-color: #ffed91
}

.yellow-colors ul li:nth-child(4) p:before {
    content: "#ffed91"
}

.yellow-colors ul li:nth-child(3) {
    background-color: #ffef9e
}

.yellow-colors ul li:nth-child(3) p:before {
    content: "#ffef9e"
}

.yellow-colors ul li:nth-child(2) {
    background-color: #fff1ab
}

.yellow-colors ul li:nth-child(2) p:before {
    content: "#fff1ab"
}

.yellow-colors ul li:nth-child(1) {
    background-color: #fff3b8
}

.yellow-colors ul li:nth-child(1) p:before {
    content: "#fff3b8"
}

.amber-colors ul li:nth-child(14) {
    background-color: #ffb012
}

.amber-colors ul li:nth-child(14) p:before {
    content: "#ffb012"
}

.amber-colors ul li:nth-child(13) {
    background-color: #ffb41f
}

.amber-colors ul li:nth-child(13) p:before {
    content: "#ffb41f"
}

.amber-colors ul li:nth-child(12) {
    background-color: #ffb92b
}

.amber-colors ul li:nth-child(12) p:before {
    content: "#ffb92b"
}

.amber-colors ul li:nth-child(11) {
    background-color: #ffbd38
}

.amber-colors ul li:nth-child(11) p:before {
    content: "#ffbd38"
}

.amber-colors ul li:nth-child(10) {
    background-color: #ffc145
}

.amber-colors ul li:nth-child(10) p:before {
    content: "#ffc145"
}

.amber-colors ul li:nth-child(9) {
    background-color: #ffc552
}

.amber-colors ul li:nth-child(9) p:before {
    content: "#ffc552"
}

.amber-colors ul li:nth-child(8) {
    background-color: #ffca5e
}

.amber-colors ul li:nth-child(8) p:before {
    content: "#ffca5e"
}

.amber-colors ul li:nth-child(7) {
    background-color: #ffce6b
}

.amber-colors ul li:nth-child(7) p:before {
    content: "#ffce6b"
}

.amber-colors ul li:nth-child(6) {
    background-color: #ffd278
}

.amber-colors ul li:nth-child(6) p:before {
    content: "#ffd278"
}

.amber-colors ul li:nth-child(5) {
    background-color: #ffd685
}

.amber-colors ul li:nth-child(5) p:before {
    content: "#ffd685"
}

.amber-colors ul li:nth-child(4) {
    background-color: #ffdb91
}

.amber-colors ul li:nth-child(4) p:before {
    content: "#ffdb91"
}

.amber-colors ul li:nth-child(3) {
    background-color: #ffdf9e
}

.amber-colors ul li:nth-child(3) p:before {
    content: "#ffdf9e"
}

.amber-colors ul li:nth-child(2) {
    background-color: #ffe3ab
}

.amber-colors ul li:nth-child(2) p:before {
    content: "#ffe3ab"
}

.amber-colors ul li:nth-child(1) {
    background-color: #ffe7b8
}

.amber-colors ul li:nth-child(1) p:before {
    content: "#ffe7b8"
}

.orange-colors ul li:nth-child(14) {
    background-color: #ff7814
}

.orange-colors ul li:nth-child(14) p:before {
    content: "#ff7814"
}

.orange-colors ul li:nth-child(13) {
    background-color: #ff8124
}

.orange-colors ul li:nth-child(13) p:before {
    content: "#ff8124"
}

.orange-colors ul li:nth-child(12) {
    background-color: #ff8933
}

.orange-colors ul li:nth-child(12) p:before {
    content: "#ff8933"
}

.orange-colors ul li:nth-child(11) {
    background-color: #ff9242
}

.orange-colors ul li:nth-child(11) p:before {
    content: "#ff9242"
}

.orange-colors ul li:nth-child(10) {
    background-color: #ff9b52
}

.orange-colors ul li:nth-child(10) p:before {
    content: "#ff9b52"
}

.orange-colors ul li:nth-child(9) {
    background-color: #ffa461
}

.orange-colors ul li:nth-child(9) p:before {
    content: "#ffa461"
}

.orange-colors ul li:nth-child(8) {
    background-color: #ffad70
}

.orange-colors ul li:nth-child(8) p:before {
    content: "#ffad70"
}

.orange-colors ul li:nth-child(7) {
    background-color: #ffb67f
}

.orange-colors ul li:nth-child(7) p:before {
    content: "#ffb67f"
}

.orange-colors ul li:nth-child(6) {
    background-color: #ffbe8f
}

.orange-colors ul li:nth-child(6) p:before {
    content: "#ffbe8f"
}

.orange-colors ul li:nth-child(5) {
    background-color: #ffc79e
}

.orange-colors ul li:nth-child(5) p:before {
    content: "#ffc79e"
}

.orange-colors ul li:nth-child(4) {
    background-color: #ffd0ad
}

.orange-colors ul li:nth-child(4) p:before {
    content: "#ffd0ad"
}

.orange-colors ul li:nth-child(3) {
    background-color: #ffd9bd
}

.orange-colors ul li:nth-child(3) p:before {
    content: "#ffd9bd"
}

.orange-colors ul li:nth-child(2) {
    background-color: #ffe2cc
}

.orange-colors ul li:nth-child(2) p:before {
    content: "#ffe2cc"
}

.orange-colors ul li:nth-child(1) {
    background-color: #ffeadb
}

.orange-colors ul li:nth-child(1) p:before {
    content: "#ffeadb"
}

.deep-orange-colors ul li:nth-child(14) {
    background-color: #ec3305
}

.deep-orange-colors ul li:nth-child(14) p:before {
    content: "#ec3305"
}

.deep-orange-colors ul li:nth-child(13) {
    background-color: #f93707
}

.deep-orange-colors ul li:nth-child(13) p:before {
    content: "#f93707"
}

.deep-orange-colors ul li:nth-child(12) {
    background-color: #fa4316
}

.deep-orange-colors ul li:nth-child(12) p:before {
    content: "#fa4316"
}

.deep-orange-colors ul li:nth-child(11) {
    background-color: #fa5025
}

.deep-orange-colors ul li:nth-child(11) p:before {
    content: "#fa5025"
}

.deep-orange-colors ul li:nth-child(10) {
    background-color: #fa5c34
}

.deep-orange-colors ul li:nth-child(10) p:before {
    content: "#fa5c34"
}

.deep-orange-colors ul li:nth-child(9) {
    background-color: #fb6843
}

.deep-orange-colors ul li:nth-child(9) p:before {
    content: "#fb6843"
}

.deep-orange-colors ul li:nth-child(8) {
    background-color: #fb7452
}

.deep-orange-colors ul li:nth-child(8) p:before {
    content: "#fb7452"
}

.deep-orange-colors ul li:nth-child(7) {
    background-color: #fb8061
}

.deep-orange-colors ul li:nth-child(7) p:before {
    content: "#fb8061"
}

.deep-orange-colors ul li:nth-child(6) {
    background-color: #fc8c70
}

.deep-orange-colors ul li:nth-child(6) p:before {
    content: "#fc8c70"
}

.deep-orange-colors ul li:nth-child(5) {
    background-color: #fc987f
}

.deep-orange-colors ul li:nth-child(5) p:before {
    content: "#fc987f"
}

.deep-orange-colors ul li:nth-child(4) {
    background-color: #fca48e
}

.deep-orange-colors ul li:nth-child(4) p:before {
    content: "#fca48e"
}

.deep-orange-colors ul li:nth-child(3) {
    background-color: #fdb09d
}

.deep-orange-colors ul li:nth-child(3) p:before {
    content: "#fdb09d"
}

.deep-orange-colors ul li:nth-child(2) {
    background-color: #fdbcac
}

.deep-orange-colors ul li:nth-child(2) p:before {
    content: "#fdbcac"
}

.deep-orange-colors ul li:nth-child(1) {
    background-color: #fdc8bb
}

.deep-orange-colors ul li:nth-child(1) p:before {
    content: "#fdc8bb"
}

.brown-colors ul li:nth-child(14) {
    background-color: #513631
}

.brown-colors ul li:nth-child(14) p:before {
    content: "#513631"
}

.brown-colors ul li:nth-child(13) {
    background-color: #61403a
}

.brown-colors ul li:nth-child(13) p:before {
    content: "#61403a"
}

.brown-colors ul li:nth-child(12) {
    background-color: #714b44
}

.brown-colors ul li:nth-child(12) p:before {
    content: "#714b44"
}

.brown-colors ul li:nth-child(11) {
    background-color: #81554d
}

.brown-colors ul li:nth-child(11) p:before {
    content: "#81554d"
}

.brown-colors ul li:nth-child(10) {
    background-color: #916057
}

.brown-colors ul li:nth-child(10) p:before {
    content: "#916057"
}

.brown-colors ul li:nth-child(9) {
    background-color: #a06b61
}

.brown-colors ul li:nth-child(9) p:before {
    content: "#a06b61"
}

.brown-colors ul li:nth-child(8) {
    background-color: #aa7a71
}

.brown-colors ul li:nth-child(8) p:before {
    content: "#aa7a71"
}

.brown-colors ul li:nth-child(7) {
    background-color: #b38981
}

.brown-colors ul li:nth-child(7) p:before {
    content: "#b38981"
}

.brown-colors ul li:nth-child(6) {
    background-color: #bd9791
}

.brown-colors ul li:nth-child(6) p:before {
    content: "#bd9791"
}

.brown-colors ul li:nth-child(5) {
    background-color: #c6a6a1
}

.brown-colors ul li:nth-child(5) p:before {
    content: "#c6a6a1"
}

.brown-colors ul li:nth-child(4) {
    background-color: #d0b5b1
}

.brown-colors ul li:nth-child(4) p:before {
    content: "#d0b5b1"
}

.brown-colors ul li:nth-child(3) {
    background-color: #dac4c1
}

.brown-colors ul li:nth-child(3) p:before {
    content: "#dac4c1"
}

.brown-colors ul li:nth-child(2) {
    background-color: #e3d3d0
}

.brown-colors ul li:nth-child(2) p:before {
    content: "#e3d3d0"
}

.brown-colors ul li:nth-child(1) {
    background-color: #ede2e0
}

.brown-colors ul li:nth-child(1) p:before {
    content: "#ede2e0"
}

.grey-colors ul li:nth-child(14) {
    background-color: #323232
}

.grey-colors ul li:nth-child(14) p:before {
    content: "#323232"
}

.grey-colors ul li:nth-child(13) {
    background-color: #3f3f3f
}

.grey-colors ul li:nth-child(13) p:before {
    content: "#3f3f3f"
}

.grey-colors ul li:nth-child(12) {
    background-color: #4b4b4b
}

.grey-colors ul li:nth-child(12) p:before {
    content: "#4b4b4b"
}

.grey-colors ul li:nth-child(11) {
    background-color: #585858
}

.grey-colors ul li:nth-child(11) p:before {
    content: "#585858"
}

.grey-colors ul li:nth-child(10) {
    background-color: #656565
}

.grey-colors ul li:nth-child(10) p:before {
    content: "#656565"
}

.grey-colors ul li:nth-child(9) {
    background-color: #727272
}

.grey-colors ul li:nth-child(9) p:before {
    content: "#727272"
}

.grey-colors ul li:nth-child(8) {
    background-color: #7e7e7e
}

.grey-colors ul li:nth-child(8) p:before {
    content: "#7e7e7e"
}

.grey-colors ul li:nth-child(7) {
    background-color: #8b8b8b
}

.grey-colors ul li:nth-child(7) p:before {
    content: "#8b8b8b"
}

.grey-colors ul li:nth-child(6) {
    background-color: #989898
}

.grey-colors ul li:nth-child(6) p:before {
    content: "#989898"
}

.grey-colors ul li:nth-child(5) {
    background-color: #a5a5a5
}

.grey-colors ul li:nth-child(5) p:before {
    content: "#a5a5a5"
}

.grey-colors ul li:nth-child(4) {
    background-color: #b1b1b1
}

.grey-colors ul li:nth-child(4) p:before {
    content: "#b1b1b1"
}

.grey-colors ul li:nth-child(3) {
    background-color: #bebebe
}

.grey-colors ul li:nth-child(3) p:before {
    content: "#bebebe"
}

.grey-colors ul li:nth-child(2) {
    background-color: #cbcbcb
}

.grey-colors ul li:nth-child(2) p:before {
    content: "#cbcbcb"
}

.grey-colors ul li:nth-child(1) {
    background-color: #d8d8d8
}

.grey-colors ul li:nth-child(1) p:before {
    content: "#d8d8d8"
}

.blue-grey-colors ul li:nth-child(14) {
    background-color: #35444a
}

.blue-grey-colors ul li:nth-child(14) p:before {
    content: "#35444a"
}

.blue-grey-colors ul li:nth-child(13) {
    background-color: #3f5159
}

.blue-grey-colors ul li:nth-child(13) p:before {
    content: "#3f5159"
}

.blue-grey-colors ul li:nth-child(12) {
    background-color: #4a5f68
}

.blue-grey-colors ul li:nth-child(12) p:before {
    content: "#4a5f68"
}

.blue-grey-colors ul li:nth-child(11) {
    background-color: #546d77
}

.blue-grey-colors ul li:nth-child(11) p:before {
    content: "#546d77"
}

.blue-grey-colors ul li:nth-child(10) {
    background-color: #5f7a85
}

.blue-grey-colors ul li:nth-child(10) p:before {
    content: "#5f7a85"
}

.blue-grey-colors ul li:nth-child(9) {
    background-color: #6a8894
}

.blue-grey-colors ul li:nth-child(9) p:before {
    content: "#6a8894"
}

.blue-grey-colors ul li:nth-child(8) {
    background-color: #78949f
}

.blue-grey-colors ul li:nth-child(8) p:before {
    content: "#78949f"
}

.blue-grey-colors ul li:nth-child(7) {
    background-color: #87a0aa
}

.blue-grey-colors ul li:nth-child(7) p:before {
    content: "#87a0aa"
}

.blue-grey-colors ul li:nth-child(6) {
    background-color: #96abb4
}

.blue-grey-colors ul li:nth-child(6) p:before {
    content: "#96abb4"
}

.blue-grey-colors ul li:nth-child(5) {
    background-color: #a5b7bf
}

.blue-grey-colors ul li:nth-child(5) p:before {
    content: "#a5b7bf"
}

.blue-grey-colors ul li:nth-child(4) {
    background-color: #b4c3ca
}

.blue-grey-colors ul li:nth-child(4) p:before {
    content: "#b4c3ca"
}

.blue-grey-colors ul li:nth-child(3) {
    background-color: #c3cfd4
}

.blue-grey-colors ul li:nth-child(3) p:before {
    content: "#c3cfd4"
}

.blue-grey-colors ul li:nth-child(2) {
    background-color: #d2dbdf
}

.blue-grey-colors ul li:nth-child(2) p:before {
    content: "#d2dbdf"
}

.blue-grey-colors ul li:nth-child(1) {
    background-color: #e1e7e9
}

.blue-grey-colors ul li:nth-child(1) p:before {
    content: "#e1e7e9"
}

.primary-colorr ul li:nth-child(14) {
    background-color: #1cc9a7
}

.primary-colorr ul li:nth-child(14) p:before {
    content: "#1cc9a7"
}

.primary-colorr ul li:nth-child(13) {
    background-color: #1ed7b2
}

.primary-colorr ul li:nth-child(13) p:before {
    content: "#1ed7b2"
}

.primary-colorr ul li:nth-child(12) {
    background-color: #23e1bb
}

.primary-colorr ul li:nth-child(12) p:before {
    content: "#23e1bb"
}

.primary-colorr ul li:nth-child(11) {
    background-color: #31e2bf
}

.primary-colorr ul li:nth-child(11) p:before {
    content: "#31e2bf"
}

.primary-colorr ul li:nth-child(10) {
    background-color: #3ee4c4
}

.primary-colorr ul li:nth-child(10) p:before {
    content: "#3ee4c4"
}

.primary-colorr ul li:nth-child(9) {
    background-color: #4ce6c8
}

.primary-colorr ul li:nth-child(9) p:before {
    content: "#4ce6c8"
}

.primary-colorr ul li:nth-child(8) {
    background-color: #59e8cc
}

.primary-colorr ul li:nth-child(8) p:before {
    content: "#59e8cc"
}

.primary-colorr ul li:nth-child(7) {
    background-color: #66ead0
}

.primary-colorr ul li:nth-child(7) p:before {
    content: "#66ead0"
}

.primary-colorr ul li:nth-child(6) {
    background-color: #74ecd4
}

.primary-colorr ul li:nth-child(6) p:before {
    content: "#74ecd4"
}

.primary-colorr ul li:nth-child(5) {
    background-color: #81eed8
}

.primary-colorr ul li:nth-child(5) p:before {
    content: "#81eed8"
}

.primary-colorr ul li:nth-child(4) {
    background-color: #8fefdc
}

.primary-colorr ul li:nth-child(4) p:before {
    content: "#8fefdc"
}

.primary-colorr ul li:nth-child(3) {
    background-color: #9cf1e1
}

.primary-colorr ul li:nth-child(3) p:before {
    content: "#9cf1e1"
}

.primary-colorr ul li:nth-child(2) {
    background-color: #aaf3e5
}

.primary-colorr ul li:nth-child(2) p:before {
    content: "#aaf3e5"
}

.primary-colorr ul li:nth-child(1) {
    background-color: #b7f5e9
}

.primary-colorr ul li:nth-child(1) p:before {
    content: "#b7f5e9"
}

.success-colorr ul li:nth-child(14) {
    background-color: #33d176
}

.success-colorr ul li:nth-child(14) p:before {
    content: "#33d176"
}

.success-colorr ul li:nth-child(13) {
    background-color: #3bd37c
}

.success-colorr ul li:nth-child(13) p:before {
    content: "#3bd37c"
}

.success-colorr ul li:nth-child(12) {
    background-color: #44d581
}

.success-colorr ul li:nth-child(12) p:before {
    content: "#44d581"
}

.success-colorr ul li:nth-child(11) {
    background-color: #4cd787
}

.success-colorr ul li:nth-child(11) p:before {
    content: "#4cd787"
}

.success-colorr ul li:nth-child(10) {
    background-color: #54d98c
}

.success-colorr ul li:nth-child(10) p:before {
    content: "#54d98c"
}

.success-colorr ul li:nth-child(9) {
    background-color: #5dda92
}

.success-colorr ul li:nth-child(9) p:before {
    content: "#5dda92"
}

.success-colorr ul li:nth-child(8) {
    background-color: #65dc98
}

.success-colorr ul li:nth-child(8) p:before {
    content: "#65dc98"
}

.success-colorr ul li:nth-child(7) {
    background-color: #6dde9d
}

.success-colorr ul li:nth-child(7) p:before {
    content: "#6dde9d"
}

.success-colorr ul li:nth-child(6) {
    background-color: #76e0a3
}

.success-colorr ul li:nth-child(6) p:before {
    content: "#76e0a3"
}

.success-colorr ul li:nth-child(5) {
    background-color: #7ee2a8
}

.success-colorr ul li:nth-child(5) p:before {
    content: "#7ee2a8"
}

.success-colorr ul li:nth-child(4) {
    background-color: #86e4ae
}

.success-colorr ul li:nth-child(4) p:before {
    content: "#86e4ae"
}

.success-colorr ul li:nth-child(3) {
    background-color: #8fe6b4
}

.success-colorr ul li:nth-child(3) p:before {
    content: "#8fe6b4"
}

.success-colorr ul li:nth-child(2) {
    background-color: #97e8b9
}

.success-colorr ul li:nth-child(2) p:before {
    content: "#97e8b9"
}

.success-colorr ul li:nth-child(1) {
    background-color: #9fe9bf
}

.success-colorr ul li:nth-child(1) p:before {
    content: "#9fe9bf"
}

.info-colorr ul li:nth-child(14) {
    background-color: #3d9cdd
}

.info-colorr ul li:nth-child(14) p:before {
    content: "#3d9cdd"
}

.info-colorr ul li:nth-child(13) {
    background-color: #45a1de
}

.info-colorr ul li:nth-child(13) p:before {
    content: "#45a1de"
}

.info-colorr ul li:nth-child(12) {
    background-color: #4ea5e0
}

.info-colorr ul li:nth-child(12) p:before {
    content: "#4ea5e0"
}

.info-colorr ul li:nth-child(11) {
    background-color: #57aae1
}

.info-colorr ul li:nth-child(11) p:before {
    content: "#57aae1"
}

.info-colorr ul li:nth-child(10) {
    background-color: #5faee3
}

.info-colorr ul li:nth-child(10) p:before {
    content: "#5faee3"
}

.info-colorr ul li:nth-child(9) {
    background-color: #68b2e4
}

.info-colorr ul li:nth-child(9) p:before {
    content: "#68b2e4"
}

.info-colorr ul li:nth-child(8) {
    background-color: #71b7e6
}

.info-colorr ul li:nth-child(8) p:before {
    content: "#71b7e6"
}

.info-colorr ul li:nth-child(7) {
    background-color: #79bbe7
}

.info-colorr ul li:nth-child(7) p:before {
    content: "#79bbe7"
}

.info-colorr ul li:nth-child(6) {
    background-color: #82c0e9
}

.info-colorr ul li:nth-child(6) p:before {
    content: "#82c0e9"
}

.info-colorr ul li:nth-child(5) {
    background-color: #8bc4ea
}

.info-colorr ul li:nth-child(5) p:before {
    content: "#8bc4ea"
}

.info-colorr ul li:nth-child(4) {
    background-color: #93c8ec
}

.info-colorr ul li:nth-child(4) p:before {
    content: "#93c8ec"
}

.info-colorr ul li:nth-child(3) {
    background-color: #9ccded
}

.info-colorr ul li:nth-child(3) p:before {
    content: "#9ccded"
}

.info-colorr ul li:nth-child(2) {
    background-color: #a5d1ef
}

.info-colorr ul li:nth-child(2) p:before {
    content: "#a5d1ef"
}

.info-colorr ul li:nth-child(1) {
    background-color: #add6f1
}

.info-colorr ul li:nth-child(1) p:before {
    content: "#add6f1"
}

.warning-colorr ul li:nth-child(14) {
    background-color: #f2c619
}

.warning-colorr ul li:nth-child(14) p:before {
    content: "#f2c619"
}

.warning-colorr ul li:nth-child(13) {
    background-color: #f2c922
}

.warning-colorr ul li:nth-child(13) p:before {
    content: "#f2c922"
}

.warning-colorr ul li:nth-child(12) {
    background-color: #f3cb2c
}

.warning-colorr ul li:nth-child(12) p:before {
    content: "#f3cb2c"
}

.warning-colorr ul li:nth-child(11) {
    background-color: #f3cd36
}

.warning-colorr ul li:nth-child(11) p:before {
    content: "#f3cd36"
}

.warning-colorr ul li:nth-child(10) {
    background-color: #f4d03f
}

.warning-colorr ul li:nth-child(10) p:before {
    content: "#f4d03f"
}

.warning-colorr ul li:nth-child(9) {
    background-color: #f4d249
}

.warning-colorr ul li:nth-child(9) p:before {
    content: "#f4d249"
}

.warning-colorr ul li:nth-child(8) {
    background-color: #f5d552
}

.warning-colorr ul li:nth-child(8) p:before {
    content: "#f5d552"
}

.warning-colorr ul li:nth-child(7) {
    background-color: #f5d75c
}

.warning-colorr ul li:nth-child(7) p:before {
    content: "#f5d75c"
}

.warning-colorr ul li:nth-child(6) {
    background-color: #f6d966
}

.warning-colorr ul li:nth-child(6) p:before {
    content: "#f6d966"
}

.warning-colorr ul li:nth-child(5) {
    background-color: #f7dc6f
}

.warning-colorr ul li:nth-child(5) p:before {
    content: "#f7dc6f"
}

.warning-colorr ul li:nth-child(4) {
    background-color: #f7de79
}

.warning-colorr ul li:nth-child(4) p:before {
    content: "#f7de79"
}

.warning-colorr ul li:nth-child(3) {
    background-color: #f8e083
}

.warning-colorr ul li:nth-child(3) p:before {
    content: "#f8e083"
}

.warning-colorr ul li:nth-child(2) {
    background-color: #f8e38c
}

.warning-colorr ul li:nth-child(2) p:before {
    content: "#f8e38c"
}

.warning-colorr ul li:nth-child(1) {
    background-color: #f9e596
}

.warning-colorr ul li:nth-child(1) p:before {
    content: "#f9e596"
}

.danger-colorr ul li:nth-child(14) {
    background-color: #e85445
}

.danger-colorr ul li:nth-child(14) p:before {
    content: "#e85445"
}

.danger-colorr ul li:nth-child(13) {
    background-color: #e95d4e
}

.danger-colorr ul li:nth-child(13) p:before {
    content: "#e95d4e"
}

.danger-colorr ul li:nth-child(12) {
    background-color: #ea6557
}

.danger-colorr ul li:nth-child(12) p:before {
    content: "#ea6557"
}

.danger-colorr ul li:nth-child(11) {
    background-color: #eb6d60
}

.danger-colorr ul li:nth-child(11) p:before {
    content: "#eb6d60"
}

.danger-colorr ul li:nth-child(10) {
    background-color: #ed7669
}

.danger-colorr ul li:nth-child(10) p:before {
    content: "#ed7669"
}

.danger-colorr ul li:nth-child(9) {
    background-color: #ee7e72
}

.danger-colorr ul li:nth-child(9) p:before {
    content: "#ee7e72"
}

.danger-colorr ul li:nth-child(8) {
    background-color: #ef867c
}

.danger-colorr ul li:nth-child(8) p:before {
    content: "#ef867c"
}

.danger-colorr ul li:nth-child(7) {
    background-color: #f08f85
}

.danger-colorr ul li:nth-child(7) p:before {
    content: "#f08f85"
}

.danger-colorr ul li:nth-child(6) {
    background-color: #f1978e
}

.danger-colorr ul li:nth-child(6) p:before {
    content: "#f1978e"
}

.danger-colorr ul li:nth-child(5) {
    background-color: #f29f97
}

.danger-colorr ul li:nth-child(5) p:before {
    content: "#f29f97"
}

.danger-colorr ul li:nth-child(4) {
    background-color: #f3a8a0
}

.danger-colorr ul li:nth-child(4) p:before {
    content: "#f3a8a0"
}

.danger-colorr ul li:nth-child(3) {
    background-color: #f4b0a9
}

.danger-colorr ul li:nth-child(3) p:before {
    content: "#f4b0a9"
}

.danger-colorr ul li:nth-child(2) {
    background-color: #f6b8b2
}

.danger-colorr ul li:nth-child(2) p:before {
    content: "#f6b8b2"
}

.danger-colorr ul li:nth-child(1) {
    background-color: #f7c1bb
}

.danger-colorr ul li:nth-child(1) p:before {
    content: "#f7c1bb"
}

#draggableMultiple .sortable-moves {
    cursor: move;
    margin-bottom: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .14);
    margin-bottom: 20px;
    padding: 15px 0 15px 60px
}

.sortable-moves {
    font-size: 14px;
    line-height: 1.55556em;
    list-style-type: none;
    margin-bottom: 15px;
    min-height: 3.55556em;
    padding-left: 5.11111em;
    position: relative;
    cursor: move
}

.sortable-moves img {
    position: absolute;
    height: 40px;
    left: 10px;
    border-radius: 50px;
    top: 15px
}

.sortable-moves h6 {
    margin-bottom: 0;
    font-weight: 700
}

.card-sub {
    cursor: move;
    border: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .05), 0 -2px 1px -2px rgba(0, 0, 0, .04), 0 0 0 -1px rgba(0, 0, 0, .05)
}

.card-sub:hover {
    box-shadow: 0 0 25px -5px #9e9c9e;
    transition: all 180ms linear
}

.payment-card .icofont-paypal-alt,
.payment-card .icofont-visa-alt,
.payment-card .icofont-mastercard {
    display: block;
    font-size: 60px;
    color: #ed5565
}

.payment-card .icofont-visa-alt {
    color: #1c84c6
}

.payment-card .icofont-mastercard {
    color: #f8ac59
}

.payment-tabs .nav-tabs .slide,
.payment-tabs .md-tabs .nav-item+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+.nav-item,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+.nav-item,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .nav-item+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+a,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs a+a,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .tree-1 a+a,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .tree-1 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .tree-1 a+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .tree-2 a+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+a,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs a+a,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .tree-2 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .tree-2 a+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .tree-3 a+a,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .tree-3 a+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+a,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs a+a,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .tree-3 a+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .nav-item+a,
.main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .tree-4 a+a,
.payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+a,
.main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+a {
    width: calc(100%/5)
}

.demo-container {
    padding-bottom: 0
}

.jp-card .jp-card-front,
.jp-card .jp-card-back {
    background: #1abc9c !important
}

.payment-form {
    max-width: 550px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 0
}

.ace_editor {
    width: 100% !important;
    position: relative !important;
    margin-top: 20px
}

.long-press:focus {
    outline-color: #1abc9c
}

.inputor:focus {
    border-color: #1abc9c
}

#edui1 {
    width: auto !important
}

.tab-pane form .md-add-on i {
    font-size: 20px
}

.wall-elips {
    position: absolute;
    right: 15px
}

.social-wallpaper {
    position: relative
}

.social-profile {
    position: relative;
    padding-top: 15px
}

.timeline-btn {
    position: absolute;
    bottom: 0;
    right: 30px
}

.nav-tabs.md-tabs.tab-timeline li a {
    padding: 20px 0 10px;
    color: #666;
    font-size: 18px
}

.social-timeline-left {
    position: absolute;
    top: -200px;
    margin-right: 15px
}

.post-input {
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    resize: none
}

.user-box .media-object,
.friend-box .media-object {
    height: 45px;
    width: 45px;
    display: inline-block
}

.friend-box img {
    margin-right: 10px;
    margin-bottom: 10px
}

.chat-header {
    color: #222
}

.live-status {
    height: 9px;
    width: 9px;
    position: absolute;
    bottom: 0;
    right: 17px;
    border-radius: 100%;
    border: 1px solid
}

.tab-timeline .slide {
    bottom: -1px
}

.image-upload input {
    visibility: hidden;
    max-width: 0;
    max-height: 0
}

.file-upload-lbl {
    max-width: 15px;
    padding: 5px 0 0
}

.ellipsis::after {
    top: 15px;
    border: none;
    position: absolute;
    content: '\f142';
    font-family: FontAwesome;
    right: 30px
}

.elipsis-box {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .11);
    top: 40px;
    right: -10px
}

.elipsis-box:after {
    content: '';
    height: 13px;
    width: 13px;
    background: #fff;
    position: absolute;
    top: -5px;
    right: 10px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: -3px -3px 11px 1px rgba(170, 170, 170, .22)
}

.friend-elipsis {
    left: -10px;
    top: -10px
}

.social-profile:hover .profile-hvr,
.social-wallpaper:hover .profile-hvr {
    opacity: 1;
    transition: all ease-in-out .3s
}

.profile-hvr {
    opacity: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 20px;
    padding: 10px;
    top: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, .61);
    transition: all ease-in-out .3s
}

.social-profile {
    margin: 0 15px
}

.social-follower {
    text-align: center
}

.social-follower h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-style: normal
}

.social-follower h5 {
    font-size: 14px
}

.social-follower .follower-counter {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 13px
}

.social-follower .follower-counter .txt-primary {
    font-size: 24px
}

.timeline-icon {
    height: 45px;
    width: 45px;
    display: block;
    margin: 0 auto;
    border: 4px #fff solid
}

.social-timelines-left:after {
    height: 3px;
    width: 25%;
    position: absolute;
    background: #ccc;
    top: 20px;
    content: "";
    right: 0;
    z-index: 0
}

.social-timelines:before {
    position: absolute;
    content: ' ';
    width: 3px;
    background: #ccc;
    left: 4%;
    z-index: 0;
    height: 100%;
    top: 0;
    z-index: -1
}

.timeline-dot:after,
.timeline-dot:before {
    content: "";
    position: absolute;
    height: 9px;
    width: 9px;
    background-color: #ccc;
    left: 3.8%;
    border-radius: 100%
}

.user-box .social-designation,
.post-timelines .social-time {
    font-size: 13px
}

.user-box .f-right {
    text-transform: capitalize
}

.social-msg span {
    color: #666;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px
}

.social-msg a {
    display: inline-block
}

.view-info .social-label,
.contact-info .social-label,
.work-info .social-label {
    font-size: 15px;
    padding-left: 0;
    padding-top: 0
}

.view-info .social-user-name,
.contact-info .social-user-name,
.work-info .social-user-name {
    font-size: 14px;
    padding-left: 0
}

.friend-elipsis .social-designation {
    font-size: 13px
}

.timeline-details p {
    padding-top: 10px;
    margin-bottom: 0
}

.timeline-details .chat-header,
.post-timelines .chat-header {
    font-size: 15px
}

.social-client-description {
    padding-bottom: 20px;
    margin-bottom: 20px
}

.social-client-description p {
    margin-top: 5px;
    margin-bottom: 0
}

.social-client-description span {
    font-size: 12px;
    margin-left: 10px
}

.social-client-description .chat-header {
    font-size: 13px
}

.social-tabs a {
    font-size: 18px
}

.timeline-btn a {
    margin-bottom: 20px
}

.profile-hvr i {
    cursor: pointer
}

.fc-left {
    margin-bottom: 10px
}

#external-events h4 {
    font-size: 16px;
    margin-top: 0;
    padding-top: 1em
}

#external-events .fc-event {
    background: #fff;
    color: #000;
    cursor: move;
    border-left: none;
    padding: 5px 18px;
    margin-bottom: 5px
}

#external-events p {
    margin: 1.5em 0;
    font-size: 11px;
    color: #666
}

#external-events p input {
    margin: 0;
    vertical-align: middle
}

.fc th,
.fc td {
    line-height: 2.5
}

.fc-state-default {
    background-color: #fff;
    color: #272727
}

tr:first-child>td>.fc-day-grid-event {
    color: #000;
    padding-left: 10px;
    background: #f3f3f3
}

.fc-nonbusiness {
    background-color: #fff
}

.fc-state-active {
    background-color: #1abc9c;
    color: #fff
}

.fc-toolbar {
    margin-bottom: 8px
}

.fc-toolbar .fc-center {
    display: block
}

.fc-center h2 {
    font-weight: 300;
    font-size: 25px;
    margin-top: 10px
}

.fc button {
    height: auto;
    padding: 10px
}

.fc-toolbar .fc-left {
    float: right
}

.fc-button-group {
    margin-right: 10px
}

.fc .fc-toolbar>*>* {
    margin-left: 0
}

.fc-event,
.fc-event-dot {
    background-color: transparent
}

.fc-day-grid-event:hover {
    color: #000 !important;
    opacity: .8
}

.fc-time-grid-event .fc-content {
    color: #fff
}

.fc-bgevent {
    background: #d8d6d6 !important
}

.fc-state-default {
    background-image: none !important;
    border-color: none !important;
    text-shadow: none !important;
    box-shadow: none !important
}

.fc-state-down,
.fc-state-active {
    background-color: none !important;
    background-image: none !important;
    box-shadow: none !important
}

.fc th {
    background: #1abc9c;
    color: #fff;
    border-color: #1abc9c
}

.fc-event {
    line-height: 1.3;
    border-radius: 2px;
    border-left: 4px solid #1abc9c !important
}

tr:first-child>td>.fc-day-grid-event {
    margin-left: 0
}

.clndr {
    width: 100%
}

.clndr {
    background-color: #f3f3f3;
    box-shadow: 0 4 px 0 #272727
}

.clndr .clndr-controls {
    padding: 14px;
    background-color: #37bc9b;
    color: #fff;
    text-align: center
}

.clndr.clndr-controls.clndr-previous-button {
    float: left;
    text-align: left
}

.clndr.clndr-controls.clndr-next-button {
    float: right;
    text-align: right
}

.clndr.clndr-controls.clndr-next-button,
.clndr.clndr-controls.clndr-previous-button {
    width: 30px;
    cursor: pointer
}

.clndr.clndr-controls.clndr-next-button:hover,
.clndr.clndr-controls.clndr-previous-button:hover {
    opacity: .5
}

.clndr.clndr-grid {
    float: left;
    width: 65%;
    background-color: #fff
}

.clndr.clndr-grid.days-of-the-week {
    width: 100%;
    background-color: #f6bb42
}

.clndr.clndr-grid.days-of-the-week.header-day {
    float: left;
    width: 14.2857%;
    padding: 14px;
    text-align: center;
    color: #fff
}

.clndr.clndr-grid.days {
    width: 100%
}

.clndr.clndr-grid.days.day,
.clndr.clndr-grid.days.empty {
    float: left;
    width: 14.2857%;
    padding: 12px 0;
    text-align: center;
    color: #4f4f4f
}

.clndr .clndr-grid .days .day.event .day-number,
.clndr .clndr-grid .days .empty.event .day-number {
    border: 1px solid #f6bb42;
    border-radius: 50%;
    padding: 6px 9px
}

.clndr.clndr-grid.days.day.adjacent-month.day-number,
.clndr.clndr-grid.days.day.inactive.day-number,
.clndr.clndr-grid.days.empty.adjacent-month.day-number {
    opacity: .3;
    cursor: pointer
}

.clndr.clndr-grid.days.day.selected {
    background-color: #da4453;
    color: #fff
}

.clndr.clndr-grid.days.today {
    background-color: #37bc9b;
    color: #fff;
    background-image: none
}

.clndr.event-listing {
    float: left;
    width: 35%
}

.clndr.event-listing.event-listing-title {
    padding: 15.5px;
    font-size: .88rem;
    background-color: #f4a911;
    text-align: center;
    color: #fff;
    letter-spacing: 1px
}

.clndr.event-listing.event-item {
    padding: 6px 14px;
    color: #4f4f4f
}

.clndr .event-listing .event-item-location {
    font-weight: 400
}

.fc-event {
    border-color: #1abc9c
}

.job-right-header .card-header .card-header-right {
    top: 7px;
    right: 15px
}

.job-right-header .radio-inline {
    display: block
}

.job-right-header .checkbox-fade {
    display: block
}

.job-right-header .checkbox-fade label {
    position: absolute;
    z-index: 99
}

.job-right-header .checkbox-fade div {
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px
}

.job-right-header a {
    color: #1abc9c;
    font-weight: 600
}

.job-details-list {
    padding-left: inherit;
    list-style-type: square;
    margin-bottom: 40px
}

.job-details-list li {
    margin-bottom: 10px
}

.company-name p {
    font-weight: 600;
    color: #1abc9c;
    font-size: 20px;
    margin-bottom: 5px
}

.job-cards .media {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #ddd
}

.job-cards:last-child .media {
    border-bottom: none;
    padding-bottom: 0
}

.job-cards img {
    width: 60px
}

.job-cards .media-right {
    position: absolute;
    top: 20px;
    right: 0
}

.job-badge {
    position: absolute;
    top: 20px;
    right: 10px
}

.job-lable .label {
    background-color: #1abc9c;
    color: #fff;
    margin: 5px 10px;
    position: relative;
    z-index: 9;
    display: inline-block;
    padding: 5px 10px 5px 12px
}

.job-lable .label:before {
    content: "";
    background-color: #1abc9c;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    position: absolute;
    left: -7px;
    top: 3px;
    z-index: -1
}

.job-lable .label:after {
    content: '\ed55';
    font-family: icofont !important;
    position: absolute;
    left: 0;
    font-size: 5px;
    top: 8px
}

.job-card-desc {
    font-weight: 600
}

.job-meta-data {
    text-transform: capitalize;
    font-size: 12px;
    margin-bottom: 10px
}

.job-meta-data i {
    margin-right: 5px;
    color: #1abc9c
}

.email-card {
    padding: 0 15px
}

.email-card .user-head {
    background-color: #1abc9c;
    padding: 10px;
    min-height: 80px;
    display: flex;
    align-items: center
}

.email-card .user-head .user-name {
    margin-left: 10px;
    margin-bottom: 10px
}

.email-card .user-head .user-name span {
    display: block;
    color: #fff
}

.email-card .user-body {
    margin-left: -15px;
    margin-right: -15px;
    background-color: #fff;
    border-right: 1px solid #ddd
}

.email-card .user-body .page-list li {
    text-transform: capitalize;
    border-bottom: 1px solid #ddd;
    transition: all ease-in .3s;
    cursor: pointer
}

.email-card .user-body .page-list li .mail-section {
    padding: 15px 20px;
    display: block;
    color: #6a6a6a
}

.email-card .user-body .page-list li .mail-section a {
    color: #6a6a6a
}

.email-card .user-body .page-list li i {
    margin-right: 10px
}

.email-card .user-body .page-list li.active,
.email-card .user-body .page-list li:hover {
    background-color: #e5e5e5
}

.email-card .user-body .label-list a {
    padding: 10px 20px;
    display: block;
    color: #6a6a6a;
    position: relative;
    padding-left: 25px
}

.email-card .user-body .label-list a:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 50px;
    left: 0;
    top: 16px
}

.email-card .user-body .label-list a.mail-work:before {
    background-color: #1abc9c
}

.email-card .user-body .label-list a.mail-design:before {
    background-color: #3498db
}

.email-card .user-body .label-list a.mail-family:before {
    background-color: #2ecc71
}

.email-card .user-body .label-list a.mail-friends:before {
    background-color: #f1c40f
}

.email-card .user-body .label-list a.mail-office:before {
    background-color: #e74c3c
}

.email-card .mail-box-head {
    background-color: #bdc3c7;
    min-height: 80px;
    padding: 20px 0
}

.email-card .mail-box-head h3 {
    color: #fff
}

.email-card .mail-box-head .input-group {
    margin-bottom: 0
}

.email-card .mail-body {
    padding: 20px 0
}

.email-card .mail-body .mail-body-header {
    margin-bottom: 20px
}

.email-card .mail-body .mail-body-header .btn-group {
    height: 42px
}

.email-card .mail-body-content .check-star {
    display: flex;
    align-items: center
}

.email-card .mail-body-content .check-star .icofont-star {
    margin-top: -8px
}

.email-card .mail-body-content tr {
    transition: all ease-in .3s
}

.email-card .mail-body-content tr:hover {
    background-color: #e5e5e5;
    cursor: pointer
}

.email-card .mail-body-content .unread a {
    color: #222;
    font-weight: 600
}

.email-card .mail-body-content .read a {
    color: #222;
    font-weight: 400
}

.email-summernote~.note-frame {
    border: 1px solid #ddd;
    border-radius: 2px
}

.email-read .photo-table {
    padding-right: 10px
}

.email-read .user-name {
    margin-bottom: 10px
}

.mail-body-content.email-read {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .05), 0 -2px 1px -2px rgba(0, 0, 0, .04), 0 0 0 -1px rgba(0, 0, 0, .05);
    border-radius: 4px
}

.mail-body-content.email-read .card {
    border-top: none;
    margin-bottom: 0
}

.mail-body-content.email-read:hover {
    box-shadow: 0 0 25px -5px #9e9c9e;
    transition: all 150ms linear
}

.user-name {
    margin-bottom: 20px
}

.email-card .user-mail h6 {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 20px;
    color: #292b2c
}

.email-content {
    line-height: 30px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px
}

.mail-img {
    margin-top: 20px
}

.email-welcome-txt {
    font-weight: 600
}

.set-map {
    height: 400px
}

#mapGeo {
    height: 345px
}

#address-search .input-group {
    margin-bottom: 0
}

.light-nav-border,
.primary-nav,
.warning-nav,
.success-nav,
.info-nav,
.danger-nav {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 15px
}

.light-nav-border a,
.primary-nav a,
.warning-nav a,
.success-nav a,
.info-nav a,
.danger-nav a {
    color: #222
}

.light-nav-border li.active a,
.primary-nav li.active a,
.warning-nav li.active a,
.success-nav li.active a,
.info-nav li.active a,
.danger-nav li.active a {
    color: #1abc9c;
    font-weight: 600
}

.light-nav-border .nav-item,
.primary-nav .nav-item,
.warning-nav .nav-item,
.success-nav .nav-item,
.info-nav .nav-item,
.danger-nav .nav-item,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .light-nav-border a,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a {
    margin-left: 15px
}

.light-nav-border .nav-item:hover .navbar-varient-submenu,
.primary-nav .nav-item:hover .navbar-varient-submenu,
.warning-nav .nav-item:hover .navbar-varient-submenu,
.success-nav .nav-item:hover .navbar-varient-submenu,
.info-nav .nav-item:hover .navbar-varient-submenu,
.danger-nav .nav-item:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a:hover .navbar-varient-submenu,
.light-nav-border .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .light-nav-border a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible
}

.light-nav-border .navbar-varient-submenu,
.primary-nav .navbar-varient-submenu,
.warning-nav .navbar-varient-submenu,
.success-nav .navbar-varient-submenu,
.info-nav .navbar-varient-submenu,
.danger-nav .navbar-varient-submenu {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, .03), 0 -2px 1px -2px rgba(0, 0, 0, .02), 0 0 0 -1px rgba(0, 0, 0, .03);
    border: 1px solid #ddd;
    position: absolute;
    width: 16em;
    background-color: #fff;
    transition: all linear .3s;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    z-index: 999
}

.light-nav-border .navbar-varient-submenu a,
.primary-nav .navbar-varient-submenu a,
.warning-nav .navbar-varient-submenu a,
.success-nav .navbar-varient-submenu a,
.info-nav .navbar-varient-submenu a,
.danger-nav .navbar-varient-submenu a {
    padding: 10px;
    display: block;
    transition: background-color linear .3s
}

.light-nav-border .navbar-varient-submenu a:hover,
.primary-nav .navbar-varient-submenu a:hover,
.warning-nav .navbar-varient-submenu a:hover,
.success-nav .navbar-varient-submenu a:hover,
.info-nav .navbar-varient-submenu a:hover,
.danger-nav .navbar-varient-submenu a:hover {
    background-color: #f1f1f1
}

.light-nav-border .profile-sub-menu,
.primary-nav .profile-sub-menu,
.warning-nav .profile-sub-menu,
.success-nav .profile-sub-menu,
.info-nav .profile-sub-menu,
.danger-nav .profile-sub-menu {
    right: 10px
}

.light-nav-border .profile-sub-menu i,
.primary-nav .profile-sub-menu i,
.warning-nav .profile-sub-menu i,
.success-nav .profile-sub-menu i,
.info-nav .profile-sub-menu i,
.danger-nav .profile-sub-menu i {
    margin-right: 10px
}

.primary-nav {
    background-color: #55e7ca
}

.primary-nav a {
    color: #666
}

.primary-nav li.active a {
    color: #666;
    font-weight: 600
}

.primary-nav .navbar-varient-submenu a {
    color: #222
}

.warning-nav {
    background-color: #f7dc6f
}

.warning-nav a {
    color: #666
}

.warning-nav li.active a {
    color: #666;
    font-weight: 600
}

.warning-nav .navbar-varient-submenu a {
    color: #222
}

.success-nav {
    background-color: #7ee2a8
}

.success-nav a {
    color: #666
}

.success-nav li.active a {
    color: #666;
    font-weight: 600
}

.success-nav .navbar-varient-submenu a {
    color: #222
}

.info-nav {
    background-color: #8bc4ea
}

.info-nav a {
    color: #666
}

.info-nav li.active a {
    color: #666;
    font-weight: 600
}

.info-nav .navbar-varient-submenu a {
    color: #222
}

.danger-nav {
    background-color: #f5b4ae
}

.danger-nav a {
    color: #666
}

.danger-nav li.active a {
    color: #666;
    font-weight: 600
}

.danger-nav .navbar-varient-submenu a {
    color: #222
}

.bg-dark {
    background-color: #2c3e50;
    color: #fff
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .9)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .navbar-dark .navbar-nav .nav-link.open,
.navbar-dark .navbar-nav .navbar-light .navbar-nav .open>.nav-link {
    color: rgba(255, 255, 255, .9)
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .3)
}

.navbar-dark button.btn {
    color: #fff;
    border-color: #fff
}

.dark-nav-border,
.primary-nav-dark,
.warning-nav-dark,
.success-nav-dark,
.info-nav-dark,
.danger-nav-dark {
    border: 1px solid #2c3e50;
    padding: 15px
}

.primary-nav,
.warning-nav,
.success-nav,
.info-nav,
.danger-nav {
    border: none;
    padding: 15px
}

.dark-nav-border a,
.primary-nav-dark a,
.warning-nav-dark a,
.success-nav-dark a,
.info-nav-dark a,
.danger-nav-dark a,
.primary-nav a,
.warning-nav a,
.success-nav a,
.info-nav a,
.danger-nav a {
    color: rgba(0, 0, 0, .9)
}

.dark-nav-border .navbar-varient-submenu,
.primary-nav-dark .navbar-varient-submenu,
.warning-nav-dark .navbar-varient-submenu,
.success-nav-dark .navbar-varient-submenu,
.info-nav-dark .navbar-varient-submenu,
.danger-nav-dark .navbar-varient-submenu,
.primary-nav .navbar-varient-submenu,
.warning-nav .navbar-varient-submenu,
.success-nav .navbar-varient-submenu,
.info-nav .navbar-varient-submenu,
.danger-nav .navbar-varient-submenu {
    box-shadow: -2 0 1px 2px rgba(0, 0, 0, .03), 0 -2px 1px -2px rgba(0, 0, 0, .02), 0 0 0 -1px rgba(0, 0, 0, .03);
    border: 1px solid #ddd;
    position: absolute;
    width: 16em;
    background-color: #fff;
    transition: all linear .3s;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    z-index: 999
}

.dark-nav-border .nav-item:hover .navbar-varient-submenu,
.primary-nav-dark .nav-item:hover .navbar-varient-submenu,
.warning-nav-dark .nav-item:hover .navbar-varient-submenu,
.success-nav-dark .nav-item:hover .navbar-varient-submenu,
.info-nav-dark .nav-item:hover .navbar-varient-submenu,
.danger-nav-dark .nav-item:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.primary-nav .nav-item:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .nav-item:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .nav-item:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a:hover .navbar-varient-submenu,
.info-nav .nav-item:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .nav-item:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-1 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-1 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-1 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-1 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-1 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-1 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-1 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-3 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-2 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-3 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .tree-1 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .tree-4 a .tree-4 .danger-nav-dark a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .primary-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .primary-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .warning-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .warning-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .success-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .success-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .info-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .info-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-1 a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-3 a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-4 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-1 .danger-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-3 .danger-nav a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-4 .danger-nav a:hover .navbar-varient-submenu,
.dark-nav-border .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.primary-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.warning-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.success-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.info-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.danger-nav-dark .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .dark-nav-border a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav-dark a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav-dark a:hover .navbar-varient-submenu,
.primary-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .primary-nav a:hover .navbar-varient-submenu,
.warning-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .warning-nav a:hover .navbar-varient-submenu,
.success-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .success-nav a:hover .navbar-varient-submenu,
.info-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .info-nav a:hover .navbar-varient-submenu,
.danger-nav .main-menu .main-menu-content .nav-item .tree-2 a:hover .navbar-varient-submenu,
.main-menu .main-menu-content .nav-item .tree-2 .danger-nav a:hover .navbar-varient-submenu {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    opacity: 1;
    visibility: visible
}

.dark-nav-border .navbar-varient-submenu a:hover,
.primary-nav-dark .navbar-varient-submenu a:hover,
.warning-nav-dark .navbar-varient-submenu a:hover,
.success-nav-dark .navbar-varient-submenu a:hover,
.info-nav-dark .navbar-varient-submenu a:hover,
.danger-nav-dark .navbar-varient-submenu a:hover,
.primary-nav .navbar-varient-submenu a:hover,
.warning-nav .navbar-varient-submenu a:hover,
.success-nav .navbar-varient-submenu a:hover,
.info-nav .navbar-varient-submenu a:hover,
.danger-nav .navbar-varient-submenu a:hover {
    background-color: #f1f1f1
}

.dark-nav-border .navbar-varient-submenu a,
.primary-nav-dark .navbar-varient-submenu a,
.warning-nav-dark .navbar-varient-submenu a,
.success-nav-dark .navbar-varient-submenu a,
.info-nav-dark .navbar-varient-submenu a,
.danger-nav-dark .navbar-varient-submenu a,
.primary-nav .navbar-varient-submenu a,
.warning-nav .navbar-varient-submenu a,
.success-nav .navbar-varient-submenu a,
.info-nav .navbar-varient-submenu a,
.danger-nav .navbar-varient-submenu a {
    padding: 10px;
    display: block;
    transition: background-color linear .3s
}

.dark-nav-border li.active a,
.primary-nav-dark li.active a,
.warning-nav-dark li.active a,
.success-nav-dark li.active a,
.info-nav-dark li.active a,
.danger-nav-dark li.active a,
.primary-nav li.active a,
.warning-nav li.active a,
.success-nav li.active a,
.info-nav li.active a,
.danger-nav li.active a {
    color: #666;
    font-weight: 600
}

.dark-nav-border .profile-sub-menu,
.primary-nav-dark .profile-sub-menu,
.warning-nav-dark .profile-sub-menu,
.success-nav-dark .profile-sub-menu,
.info-nav-dark .profile-sub-menu,
.danger-nav-dark .profile-sub-menu,
.primary-nav .profile-sub-menu,
.warning-nav .profile-sub-menu,
.success-nav .profile-sub-menu,
.info-nav .profile-sub-menu,
.danger-nav .profile-sub-menu {
    right: 10px
}

.primary-nav-dark {
    background-color: #148f77;
    border: none
}

.primary-nav-dark a {
    color: #fff
}

.primary-nav-dark li.active a {
    color: #fff;
    font-weight: 600
}

.primary-nav-dark .navbar-varient-submenu a {
    color: #fff
}

.primary-nav-dark .nav-link.disabled {
    color: rgba(255, 255, 255, .5)
}

.primary-nav-dark .navbar-varient-submenu {
    background-color: #148f77;
    border-color: #117964
}

.primary-nav-dark .navbar-varient-submenu a:hover {
    background-color: #117964
}

.warning-nav-dark {
    background-color: #c29d0b;
    border: none
}

.warning-nav-dark a {
    color: #fff
}

.warning-nav-dark li.active a {
    color: #fff;
    font-weight: 600
}

.warning-nav-dark .navbar-varient-submenu a {
    color: #fff
}

.warning-nav-dark .nav-link.disabled {
    color: rgba(255, 255, 255, .5)
}

.warning-nav-dark .navbar-varient-submenu {
    background-color: #c29d0b;
    border-color: #aa8a0a
}

.warning-nav-dark .navbar-varient-submenu a:hover {
    background-color: #aa8a0a
}

.success-nav-dark {
    background-color: #25a25a;
    border: none
}

.success-nav-dark a {
    color: #fff
}

.success-nav-dark li.active a {
    color: #fff;
    font-weight: 600
}

.success-nav-dark .navbar-varient-submenu a {
    color: #fff
}

.success-nav-dark .nav-link.disabled {
    color: rgba(255, 255, 255, .5)
}

.success-nav-dark .navbar-varient-submenu {
    background-color: #25a25a;
    border-color: #208e4e
}

.success-nav-dark .navbar-varient-submenu a:hover {
    background-color: #208e4e
}

.info-nav-dark {
    background-color: #217dbb;
    border: none
}

.info-nav-dark a {
    color: #fff
}

.info-nav-dark li.active a {
    color: #fff;
    font-weight: 600
}

.info-nav-dark .navbar-varient-submenu a {
    color: #fff
}

.info-nav-dark .nav-link.disabled {
    color: rgba(255, 255, 255, .5)
}

.info-nav-dark .navbar-varient-submenu {
    background-color: #217dbb;
    border-color: #1d6fa5
}

.info-nav-dark .navbar-varient-submenu a:hover {
    background-color: #1d6fa5
}

.danger-nav-dark {
    background-color: #d62c1a;
    border: none
}

.danger-nav-dark a {
    color: #fff
}

.danger-nav-dark li.active a {
    color: #fff;
    font-weight: 600
}

.danger-nav-dark .navbar-varient-submenu a {
    color: #fff
}

.danger-nav-dark .nav-link.disabled {
    color: rgba(255, 255, 255, .5)
}

.danger-nav-dark .navbar-varient-submenu {
    background-color: #d62c1a;
    border-color: #bf2718
}

.danger-nav-dark .navbar-varient-submenu a:hover {
    background-color: #bf2718
}

.nav-item label.badge-top-left,
.main-menu .main-menu-content .nav-item .tree-1 a label.badge-top-left,
.main-menu .main-menu-content .nav-item .tree-2 a label.badge-top-left,
.main-menu .main-menu-content .nav-item .tree-3 a label.badge-top-left,
.main-menu .main-menu-content .nav-item .tree-4 a label.badge-top-left {
    right: 4.4%;
    bottom: 38px
}

.nav-item .radio-inline,
.main-menu .main-menu-content .nav-item .tree-1 a .radio-inline,
.main-menu .main-menu-content .nav-item .tree-2 a .radio-inline,
.main-menu .main-menu-content .nav-item .tree-3 a .radio-inline,
.main-menu .main-menu-content .nav-item .tree-4 a .radio-inline {
    margin-top: 7px
}

.primary-nav-dark button,
.warning-nav-dark button,
.success-nav-dark button,
.info-nav-dark button,
.danger-nav-dark button {
    border-color: #fff
}

.primary-nav-dark i,
.warning-nav-dark i,
.success-nav-dark i,
.info-nav-dark i,
.danger-nav-dark i {
    color: #fff
}

.primary-nav button,
.warning-nav button,
.success-nav button,
.info-nav button,
.danger-nav button {
    border-color: rgba(0, 0, 0, .6) !important
}

.primary-nav i,
.warning-nav i,
.success-nav i,
.info-nav i,
.danger-nav i {
    color: rgba(0, 0, 0, .6) !important
}

.light-nav-border button,
.primary-nav button,
.warning-nav button,
.success-nav button,
.info-nav button,
.danger-nav button {
    border-color: rgba(0, 0, 0, .1)
}

.light-nav-border i,
.primary-nav i,
.warning-nav i,
.success-nav i,
.info-nav i,
.danger-nav i {
    color: rgba(0, 0, 0, .4)
}

.note-card {
    padding-top: 0;
    padding-bottom: 0
}

.note-card .note-box-aside {
    border-right: 1px solid #ddd
}

.note-card .note-box-aside .row {
    padding: 20px 0;
    display: flex;
    align-items: center
}

.note-card .Note-header {
    padding: 20px 0
}

.note-card .note-write {
    position: relative;
    /* background: -webkit-linear-gradient(top, #f0f0f0 0%, #ffffec 5%) 0 0;
    background: -moz-linear-gradient(top, #f0f0f0 0%, #ffffec 5%) 0 0; */
    /* background: linear-gradient(top, #f0f0f0 0%, #ffffec 5%) 0 0; */
    -webkit-background-size: 100% 35px;
    -moz-background-size: 100% 35px;
    background-size: 100% 35px
}

.note-card .note-write:before {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    left: 32px;
    bottom: 0;
    border-left: 1px solid #dacaaa
}

.note-card .note-write:after {
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    left: 34px;
    bottom: 0;
    border-left: 1px solid #dacaaa
}

.note-card #Note-pad {
    border: none;
    resize: none;
    background: 0 0;
    padding: 0 20px 0 50px;
    line-height: 35px
}

.note-card .Note-created__on {
    display: block
}

.summernote-page .modal-footer {
    margin: 0 auto
}

pre[class*=language-] {
    background-color: #fff;
    border: 1px solid #ddd
}

pre[class*=language-] code {
    background-color: #fff !important
}

pre.line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber
}

pre.line-numbers>code {
    position: relative
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em;
    letter-spacing: -1px;
    border-right: 1px solid #999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.line-numbers-rows>span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber
}

.line-numbers-rows>span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: .8em;
    text-align: right
}

.progress-xl {
    height: 30px
}

.progress-lg {
    height: 24px
}

.progres-md {
    height: 18px
}

.progress-sm {
    height: 12px
}

.progress-xs {
    height: 6px
}

.progress-bar-page .progress {
    margin-bottom: 2rem
}

.progress-bar-default {
    background-color: #bdc3c7
}

.progress-bar-primary {
    background-color: #1abc9c
}

.progress-bar-success {
    background-color: #2ecc71
}

.progress-bar-info {
    background-color: #3498db
}

.progress-bar-warning {
    background-color: #f1c40f
}

.progress-bar-danger {
    background-color: #e74c3c
}

.progress-bar-pink {
    background-color: #ff7aa3
}

.progress-bar-purple {
    background-color: #9261c6
}

.progress-bar-orange {
    background-color: #e67e22
}

.progress-bar-yellow {
    background-color: #f1c40f
}

.progress-bar-emrald {
    background-color: #2ecc71
}

.loader {
    margin: 18px auto;
    top: 65px;
    left: 65px;
    right: 0;
    width: 100px;
    height: 100px;
    position: relative;
    transform: translate(-50%, -50%)
}

.loader.animation-start .circle {
    animation: rotating 2s ease-in-out infinite
}

.loader.animation-stop .circle {
    animation-iteration-count: 1
}

.loader .circle {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    transform-origin: 50% 50%;
    animation-iteration-count: 0
}

.loader .circle:after {
    content: '';
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #1abc9c
}

.loader .circle.delay-1 {
    animation-delay: .125s
}

.loader .circle.size-1:after {
    width: 11px;
    height: 11px
}

.loader .circle.delay-2 {
    animation-delay: .25s
}

.loader .circle.size-2:after {
    width: 12px;
    height: 12px
}

.loader .circle.delay-3 {
    animation-delay: .375s
}

.loader .circle.size-3:after {
    width: 13px;
    height: 13px
}

.loader .circle.delay-4 {
    animation-delay: .5s
}

.loader .circle.size-4:after {
    width: 14px;
    height: 14px
}

.loader .circle.delay-5 {
    animation-delay: .625s
}

.loader .circle.size-5:after {
    width: 15px;
    height: 15px
}

.loader .circle.delay-6 {
    animation-delay: .75s
}

.loader .circle.size-6:after {
    width: 16px;
    height: 16px
}

.loader .circle.delay-7 {
    animation-delay: .875s
}

.loader .circle.size-7:after {
    width: 17px;
    height: 17px
}

.loader .circle.delay-8 {
    animation-delay: 1s
}

.loader .circle.size-8:after {
    width: 18px;
    height: 18px
}

.loader .circle.delay-9 {
    animation-delay: 1.125s
}

.loader .circle.size-9:after {
    width: 19px;
    height: 19px
}

.loader .circle.delay-10 {
    animation-delay: 1.25s
}

.loader .circle.size-10:after {
    width: 20px;
    height: 20px
}

.loader .circle.delay-11 {
    animation-delay: 1.375s
}

.loader .circle.size-11:after {
    width: 21px;
    height: 21px
}

.loader .circle.delay-12 {
    animation-delay: 1.5s
}

.loader .circle.size-12:after {
    width: 22px;
    height: 22px
}

@keyframes rotating {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

#loader2 {
    width: 80px;
    height: 130px;
    display: block;
    transform-origin: 50% 50%;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    animation: spin 1s linear infinite;
    margin: 0 auto
}

#circle-loader2 {
    fill: none;
    stroke: #1abc9c;
    stroke-width: 4;
    animation: draw 3s ease-in-out infinite
}

@keyframes draw {
    0% {
        stroke-dasharray: 20, 282.6
    }
    50% {
        stroke-dasharray: 200, 282.6
    }
    100% {
        stroke-dasharray: 20, 282.6
    }
}

@keyframes spin {
    to {
        transform: rotate(360deg)
    }
}

.preloader3 {
    width: auto;
    height: 130px;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center
}

.preloader3>div {
    margin: 2px;
    background-color: #1abc9c;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    -webkit-animation: stretchdelay .7s infinite ease-in-out;
    animation: stretchdelay .7s infinite ease-in-out
}

.preloader3 .circ2 {
    -webkit-animation-delay: -.6s;
    animation-delay: -.6s
}

.preloader3 .circ3 {
    -webkit-animation-delay: -.5s;
    animation-delay: -.5s
}

.preloader3 .circ4 {
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s
}

.preloader3 .circ5 {
    -webkit-animation-delay: -.3s;
    animation-delay: -.3s
}

.preloader3 .loader-md {
    height: 13px;
    width: 13px
}

.preloader3 .loader-lg {
    height: 18px;
    width: 18px
}

@-webkit-keyframes stretchdelay {
    0%,
    40%,
    100% {
        -webkit-transform: translateY(-10px)
    }
    20% {
        -webkit-transform: translateY(-20px)
    }
}

@keyframes stretchdelay {
    0%,
    40%,
    100% {
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px)
    }
    20% {
        transform: translateY(-20px);
        -webkit-transform: translateY(-20px)
    }
}

@keyframes rotateColor {
    0% {
        border-top-color: #1b8bf9
    }
    12.5% {
        border-bottom-color: rgba(27, 139, 249, .8)
    }
    25% {
        border-bottom-color: rgba(27, 139, 249, .6)
    }
    37.5% {
        border-bottom-color: rgba(27, 139, 249, .4)
    }
    50% {
        border-bottom-color: rgba(27, 139, 249, .6)
    }
    50.00001% {
        border-bottom-color: #1b8bf9
    }
    62.5% {
        border-bottom-color: rgba(27, 139, 249, .8)
    }
    75% {
        border-bottom-color: rgba(27, 139, 249, .6)
    }
    87.5% {
        border-bottom-color: rgba(27, 139, 249, .4)
    }
}

.preloader4 {
    width: 30px;
    height: 30px;
    position: relative;
    margin: 55px auto
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #1abc9c;
    opacity: .6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out;
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0)
    }
    50% {
        -webkit-transform: scale(1)
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}

.preloader5 {
    margin: 55px 0;
    text-align: center
}

.preloader5 .circle-5 {
    display: inline-block;
    padding: 11px;
    margin: 0 .6em;
    background: #1abc9c;
    border-radius: 100%
}

.preloader5 .l {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear
}

.preloader5 .m {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.preloader5 .r {
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}

@-webkit-keyframes pulse {
    10% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    20% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    30% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    50% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    70% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    80% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    90% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pulse {
    10% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    20% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    30% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    50% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    70% {
        -webkit-transform: scale(1.7);
        transform: scale(1.7)
    }
    80% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    90% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.preloader6 {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: inherit;
    margin: 18px 0
}

.preloader6 hr {
    border: 0;
    background: inherit;
    width: 80%;
    height: 80%;
    margin: 10%;
    border-radius: 100%
}

.preloader6::before,
.preloader6::after,
.preloader6 hr::before,
.preloader6 hr::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    left: 33px;
    border: 17px solid transparent;
    border-bottom: 50px solid #1abc9c;
    border-top: 50px solid #1abc9c;
    animation: rotateColor 1000ms infinite
}

.preloader6::after {
    transform: rotate(45deg);
    animation-delay: 125ms
}

.preloader6 hr::before {
    transform: rotate(90deg);
    animation-delay: 250ms
}

.preloader6 hr::after {
    transform: rotate(135deg);
    animation-delay: 375ms
}

.loader-block,
.loader {
    display: flex;
    align-items: center;
    justify-content: center
}

@keyframes rotateColor {
    0% {
        border-top-color: #1abc9c
    }
    12.5% {
        border-bottom-color: rgba(26, 188, 156, .8)
    }
    25% {
        border-bottom-color: rgba(26, 188, 156, .6)
    }
    37.5% {
        border-bottom-color: rgba(26, 188, 156, .4)
    }
    50% {
        border-bottom-color: rgba(26, 188, 156, .6)
    }
    50.00001% {
        border-bottom-color: #1abc9c
    }
    62.5% {
        border-bottom-color: rgba(26, 188, 156, .8)
    }
    75% {
        border-bottom-color: rgba(26, 188, 156, .6)
    }
    87.5% {
        border-bottom-color: rgba(26, 188, 156, .4)
    }
}

.range-slider {
    min-height: 200px;
    display: flex;
    align-items: center;
    margin-left: 15px
}

#RGB {
    height: 10px;
    background: gray
}

#RC .slider-selection {
    background: #ff8282
}

#RC .slider-handle {
    background: red
}

#GC .slider-selection {
    background: #428041
}

#GC .slider-handle {
    background: green
}

#BC .slider-selection {
    background: #8283ff
}

#BC .slider-handle {
    border-bottom-color: blue
}

#R,
#G,
#B {
    width: 300px
}

.range-slider-contain {
    position: absolute;
    bottom: 12%
}

.slider-handle {
    top: -5px;
    background-image: linear-gradient(to bottom, #1abc9c 0%, #1abc9c 100%);
    box-shadow: none
}

.slider-tick,
.slider-track {
    background-image: linear-gradient(to bottom, #BDC3C7 0%, #BDC3C7 100%)
}

.slider-handle.custom::before {
    line-height: 15px;
    font-size: 35px;
    color: #1abc9c
}

#slider12a .slider-track-high,
#slider12c .slider-track-high {
    background: green
}

#slider12b .slider-track-low,
#slider12c .slider-track-low {
    background: red
}

#slider12c .slider-selection {
    background: #ff0
}

.slider-selection.tick-slider-selection {
    background-image: linear-gradient(to bottom, #28E1BD 0%, #28E1BD 100%)
}

.slider-tick.in-selection {
    background-image: linear-gradient(to bottom, #28E1BD 0%, #28E1BD 100%)
}

.slider.slider-horizontal .slider-track {
    height: 2px
}

.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
    top: -5px;
    cursor: pointer
}

.br-wrapper {
    margin: 20px 0 50px
}

.br-theme-bars-1to10 .br-widget .br-current-rating {
    clear: both;
    padding: 0;
    font-size: 16px;
    color: #757575;
    font-weight: 600
}

.br-theme-bars-reversed .br-widget .br-current-rating {
    clear: both;
    padding: 0;
    line-height: 2;
    font-size: 16px
}

.br-theme-bars-movie .br-widget .br-current-rating {
    color: #757575;
    font-weight: 600;
    text-align: left
}

.br-theme-bars-horizontal .br-widget .br-current-rating,
.br-theme-bars-reversed .br-widget .br-current-rating {
    color: #757575;
    font-weight: 600
}

span.value {
    display: inline
}

.current-rating {
    position: relative;
    top: -50px
}

.current-rating.hidden {
    display: none
}

.your-rating {
    position: relative;
    top: -50px
}

.your-rating.hidden {
    display: none
}

.br-theme-bars-1to10 .br-widget a,
.br-theme-bars-movie .br-widget a,
.br-theme-bars-reversed .br-widget a,
.br-theme-bars-horizontal .br-widget a {
    background-color: #8cddcd
}

.br-theme-bars-1to10 .br-widget a.br-active,
.br-theme-bars-1to10 .br-widget a.br-selected,
.br-theme-bars-movie .br-widget a.br-active,
.br-theme-bars-movie .br-widget a.br-selected,
.br-theme-bars-reversed .br-widget a.br-active,
.br-theme-bars-reversed .br-widget a.br-selected,
.br-theme-bars-horizontal .br-widget a.br-active,
.br-theme-bars-horizontal .br-widget a.br-selected {
    background-color: #1abc9c
}

.br-theme-bars-pill .br-widget a {
    background-color: #8cddcd;
    color: #1abc9c
}

.br-theme-bars-pill .br-widget a.br-active,
.br-theme-bars-pill .br-widget a.br-selected {
    background-color: #1abc9c
}

.br-theme-fontawesome-stars .br-widget a.br-active:after,
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
    color: #1abc9c
}

.br-theme-css-stars .br-widget a.br-active:after,
.br-theme-css-stars .br-widget a.br-selected:after {
    color: #1abc9c
}

.br-theme-bars-square .br-widget a {
    border: 2px solid #8cddcd;
    color: #8cddcd
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
    border: 2px solid #1abc9c;
    color: #1abc9c
}

.br-theme-fontawesome-stars-o .br-widget a.br-selected:after,
.br-theme-fontawesome-stars-o .br-widget a:after,
.br-theme-fontawesome-stars-o .br-widget a.br-active:after,
.br-theme-fontawesome-stars-o .br-widget a.br-fractional:after {
    color: #1abc9c
}

#task-container ul {
    overflow: hidden
}

#task-container .task-headline {
    display: none;
    color: #666;
    border-bottom: 1px solid #c8c7bb;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 1.6em;
    position: relative
}

#task-container .task-headline:before {
    height: 1px;
    width: 100%;
    background: #fff;
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0
}

#task-container .nothing-message {
    height: 160px;
    color: #666;
    background-size: 15%
}

#task-container li {
    float: left;
    width: 49%;
    overflow: auto;
    height: auto;
    min-height: 10px;
    background: #fff;
    display: inline-block;
    padding: 20px;
    border: 1px solid #ccc;
    color: #666;
    border-top: 9px solid #1abc9c;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 2%;
    transition: all .3s;
    position: relative
}

#task-container li:nth-child(even) {
    margin-right: 0
}

#task-container li:hover {
    opacity: 1;
    border-top: 9px solid #bdc3c7
}

#task-container li.complete {
    opacity: 1;
    border-top: 9px solid #e74c3c;
    transition: all ease-in .3s
}

#task-container li.complete:before {
    background: url(../images/complete.png) no-repeat;
    position: absolute;
    top: 5px;
    right: 5px;
    content: "";
    width: 55px;
    height: 55px;
    background-size: 100%
}

#task-container li.complete:hover {
    border-top: 9px solid #bdc3c7;
    opacity: 1
}

#task-container li.complete p {
    text-decoration: line-through
}

#task-container p {
    line-height: 1.6em;
    text-align: left
}

.add-line.complete {
    text-decoration: line-through
}

.task-panel .to-do-label {
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px
}

.task-panel .to-do-label:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0
}

.to-do-list {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px
}

.to-do-list i {
    color: #ccc;
    font-size: 24px;
    opacity: 0
}

.to-do-list:last-child {
    border: none;
    padding: 0;
    margin: 0
}

.to-do-list:hover i {
    opacity: 1;
    transition: opacity ease-in .3s
}

.to-do-list p {
    display: inline-block
}

.to-do-label .checkbox-fade {
    display: block
}

.to-do-label .checkbox-fade .delete_todo {
    float: right;
    font-size: 24px;
    color: #ccc
}

.to-do-label .check-task {
    display: block
}

.to-do-label i {
    cursor: pointer
}

.done-task span,
.done-task .captions {
    text-decoration: line-through
}

@media only screen and (max-width:1199px) {
    .blog-page-card {
        margin-bottom: 30px
    }
    .blog-page-card:last-child {
        margin-bottom: 0
    }
    #draggablePanelList [class*=col-] .card-sub {
        margin-bottom: 30px
    }
    #draggablePanelList [class*=col-]:last-child .card-sub {
        margin-bottom: 0
    }
    .user-head {
        justify-content: center
    }
    .user-body {
        min-height: auto !important
    }
    .mail-box-head {
        text-align: center
    }
    .mail-box-head form {
        float: inherit
    }
    #external-events h6 {
        margin-top: 0;
        margin-bottom: 20px
    }
    .just_edit .input-group {
        width: 100% !important
    }
    .dotted-line-theme .ibtn_container {
        top: 10px;
        position: inherit
    }
    .users-card [class*=col-] {
        margin-bottom: 20px
    }
    .users-card [class*=col-]:last-child {
        margin-bottom: 0
    }
    .timeline-btn {
        text-align: center;
        width: 100%;
        right: 0
    }
    .social-timeline-left {
        position: inherit;
        top: 0;
        margin-right: 0
    }
    .timeline-icon {
        position: absolute;
        margin-left: 0
    }
    .timeline-right .card {
        margin-left: 70px !important
    }
    .social-timelines:before {
        left: 35px
    }
    .timeline-dot:after,
    .timeline-dot:before {
        left: 32px
    }
    .user-box .social-client-description {
        padding-bottom: 20px;
        margin-bottom: 0;
        border-bottom: 1px solid #ddd
    }
    .user-box .f-right {
        float: none;
        display: block
    }
    .main-timeline {
        padding: 10px
    }
    .main-timeline:before,
    .main-timeline:after {
        margin-left: -94px;
        margin-top: 25px;
        display: none
    }
    .cd-timeline-content::before {
        top: 15px
    }
    .cd-date,
    .cd-details {
        display: block;
        padding-left: 20px !important;
        padding-top: 0 !important
    }
    .cd-details {
        margin-bottom: 20px
    }
    .cd-timeline-content p {
        margin-bottom: 0
    }
    .clock-widget .basic-alarm {
        display: none
    }
    .weather-card-2 .weather-card-temp {
        padding: 0
    }
    .weather-card-2 .card-footer {
        padding-top: 20px;
        padding-bottom: 20px
    }
    .user-activity-card .card-block-big {
        padding-top: 15px;
        padding-bottom: 15px
    }
}

@media only screen and (max-width:1024px) {
    .main-body .page-wrapper .page-header-title {
        display: block
    }
    .main-body .page-wrapper .page-header {
        margin-bottom: 10px
    }
    .main-body .page-wrapper .page-header-breadcrumb {
        float: inherit;
        display: inline-block;
        margin-top: 10px
    }
}

@media only screen and (max-width:992px) {
    #footer-search tfoot .form-control,
    #footer-select tfoot .form-control,
    #form-input-table .form-control,
    .search-api .form-control,
    #dt-live-dom .form-control {
        width: 80%
    }
    .pcoded-main-container {
        margin-top: 56px !important
    }
    .card-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px
    }
    .header-navbar {
        position: fixed !important;
        z-index: 1209
    }
    .header-navbar[pcoded-header-position=relative] {
        position: fixed !important;
        top: 0
    }
    .header-navbar .navbar-wrapper .navbar-container {
        margin-left: 0
    }
    .header-navbar .navbar-wrapper .navbar-logo {
        width: 100%
    }
    .header-navbar .navbar-wrapper .navbar-logo .mobile-menu {
        display: block;
        position: absolute;
        left: 30px;
        top: 18px
    }
    .header-navbar .navbar-wrapper .navbar-logo .mobile-search {
        display: block;
        position: absolute;
        left: 70px;
        top: 18px
    }
    .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
        display: block;
        position: absolute;
        right: 30px
    }
    .header-navbar .navbar-container .nav-left,
    .header-navbar .navbar-wrapper .navbar-container .nav-right {
        display: none
    }
    .btn-blog {
        text-align: center
    }
    .animation-image img {
        margin: 20px auto 0
    }
    .animation-type {
        text-align: center
    }
    .nav-tabs.md-tabs.tab-timeline li a {
        font-size: 16px
    }
}

@media only screen and (min-width:992px) {
    .btn-blog {
        text-align: right
    }
}

@media only screen and (max-width:768px) {
    .card-block.list-tag ul li:last-child {
        margin-bottom: 20px
    }
    .inline-order-list {
        margin-top: 0
    }
    h4 {
        font-size: 1rem;
        font-weight: 600
    }
    .mail-body-content .form-group:nth-child(2) .form-control {
        margin-bottom: 20px
    }
    .mail-body-content .form-group:nth-child(2) .col-md-6:last-child .form-control {
        margin-bottom: 0
    }
    .card h5 {
        font-size: 16px
    }
    .header-navbar .navbar-wrapper .navbar-logo {
        display: inherit;
        padding-top: 13px
    }
    .header-navbar .navbar-wrapper .navbar-logo a {
        display: inline-block
    }
    .header-navbar .navbar-wrapper .navbar-logo .mobile-options {
        top: 18px
    }
    .main-body .page-wrapper .page-header-title {
        display: block
    }
    body.header-fixed .main-body .page-wrapper {
        padding-bottom: 0;
        padding-top: 15px
    }
    .card-header-right i:nth-child(n+2) {
        display: none
    }
    .dataTables_paginate .pagination {
        float: inherit;
        text-align: center;
        display: inline-block;
        margin-top: 10px !important
    }
    .card .card-block ul.pagination li {
        float: left
    }
    div.dataTables_wrapper div.dataTables_info {
        display: inherit
    }
    .wizard>.steps>ul>li {
        float: inherit;
        width: 100%
    }
    .gallery-page .grid {
        padding: 0
    }
    figure.effect-steve h2 {
        margin-top: 0
    }
    .cover-profile .profile-bg-img {
        margin-bottom: 0
    }
    .card-block.user-info {
        position: inherit;
        text-align: center;
        background-color: #bdc3c7;
        margin-bottom: 20px
    }
    .user-info .media-left,
    .user-info .media-body {
        display: block
    }
    .cover-btn {
        position: inherit;
        float: none
    }
    .cover-btn .btn:first-child {
        margin-right: 10px
    }
    .user-info .user-title {
        position: initial;
        margin-bottom: 10px;
        margin-top: 10px
    }
    .nav-tabs.md-tabs.tab-timeline li a {
        font-size: 14px
    }
    .user-content h4 {
        font-size: 1rem
    }
    .user-content h5 {
        margin-right: 0;
        font-size: 14px
    }
    .top-cap-text p {
        padding: 0
    }
    .top-cap-text p:last-child {
        margin-bottom: 0
    }
    .user-card .btn span {
        font-size: 13px
    }
    .toolbar-page [class*=col-] {
        margin-bottom: 30px
    }
    .toolbar-page [class*=col-]:last-child {
        margin-bottom: 0
    }
    .offline-404 h1 {
        font-size: 80px
    }
    .offline-404 h2 {
        font-size: 25px
    }
    .invoice-contact {
        margin-top: 20px;
        margin-bottom: 0
    }
    .lng-dropdown {
        display: none
    }
    .group-widget .card-block-big {
        height: 110px
    }
    .client-blocks ul li {
        font-size: 30px
    }
    .widget-profile-card-1~.card-footer [class*=col-] {
        border: none;
        margin: 0 auto
    }
    .large-widget-card i {
        top: 15px
    }
    .user-activity-card .user-number {
        padding: 15px 0
    }
    .user-activity-card .m-b-40 {
        margin-bottom: 10px
    }
    .blur-user-card .blur-footer [class*=col-] {
        margin: 0 auto;
        border: none
    }
    .switchery {
        margin-bottom: 20px
    }
    .switchery-large,
    .switchery-large~.switchery-default,
    .switchery-small {
        margin-bottom: 0
    }
    .js-dynamic-disable,
    .js-dynamic-enable {
        display: block;
        width: 100%
    }
    .js-dynamic-disable {
        margin-bottom: 20px
    }
    .radio-inline,
    .border-checkbox-section .border-checkbox-group,
    .checkbox-color {
        display: block
    }
    .checkbox-fade {
        display: inline-block
    }
}

@media only screen and (max-width:640px) {
    .dt-button {
        margin-bottom: 5px
    }
    .email-read .card-header h6 {
        float: none;
        margin-top: 10px
    }
    .email-read .media {
        display: inline-block
    }
    .email-read .media.m-b-20 {
        margin-bottom: 0
    }
    .email-read .mail-img [class*=col-] {
        margin-bottom: 30px
    }
    .email-read .mail-img [class*=col-]:last-child {
        margin-bottom: 0
    }
}

@media only screen and (max-width:573px) {
    .md-tabs {
        display: initial
    }
    .md-tabs .nav-item,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a,
    .main-menu .main-menu-content .nav-item .tree-1 .md-tabs a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a,
    .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a,
    .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a,
    .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a {
        width: calc(100%/1)
    }
    .nav-tabs .slide {
        width: calc(100%/1)
    }
    .toolbar-page [class*=col-] .sub-title {
        text-align: center
    }
    #task-container li {
        width: 100%
    }
    .nav-tabs.tabs {
        display: inherit
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-1 .nav-tabs a.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-2 .nav-tabs a.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-3 .nav-tabs a.show .nav-link,
    .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
    .main-menu .main-menu-content .nav-item .tree-4 .nav-tabs a.show .nav-link,
    .nav-tabs .nav-link.active {
        border: none
    }
    .nav-tabs .nav-link {
        text-align: center
    }
    .search-content {
        display: block;
        text-align: center
    }
    .search-content img {
        margin-top: 20px
    }
    .seacrh-header {
        margin-top: 0
    }
    .seacrh-header .input-group {
        margin-bottom: 0
    }
    .payment-card .text-right {
        text-align: left !important
    }
    .payment-tabs .md-tabs .nav-item+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+.nav-item,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+.nav-item,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .nav-item+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a+a,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs a+a,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .tree-1 a+a,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .tree-1 a+a,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .tree-1 a+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .tree-2 a+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a+a,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs a+a,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .tree-2 a+a,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .tree-2 a+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .tree-3 a+a,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .tree-3 a+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a+a,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs a+a,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .tree-3 a+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs .nav-item+a,
    .main-menu .main-menu-content .nav-item .tree-1 .payment-tabs .md-tabs .tree-4 a+a,
    .main-menu .main-menu-content .nav-item .tree-2 .payment-tabs .md-tabs .tree-4 a+a,
    .main-menu .main-menu-content .nav-item .tree-3 .payment-tabs .md-tabs .tree-4 a+a,
    .payment-tabs .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a+a,
    .main-menu .main-menu-content .nav-item .tree-4 .payment-tabs .md-tabs a+a {
        width: calc(100%/1)
    }
    .jp-card {
        min-width: 225px !important
    }
    .payment-form {
        padding: 0;
        padding-top: 20px
    }
    .modal-mob-btn [class*=col-]:first-child {
        margin-bottom: 20px
    }
    .location-mob-btn .btn {
        margin-bottom: 20px;
        margin-right: 0
    }
    .location-mob-btn .btn:last-child {
        margin-bottom: 0
    }
    .job-cards .media {
        display: inline-block;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px
    }
    .job-cards .media p {
        margin-bottom: 0
    }
    .invoice-btn-group .m-r-20 {
        margin-right: 0
    }
    .table-card .row-table {
        display: block;
        text-align: center
    }
    .table-card .row-table i {
        font-size: 28px;
        margin-bottom: 10px;
        display: inline-block
    }
    .table-card .row-table .br {
        border: none;
        border-bottom: 1px solid #ccc
    }
    .table-card .row-table>[class*=col-] {
        display: block
    }
    .card-block-big {
        padding: 1em
    }
    .card-block-big #barchart,
    .card-block-big #barchart2 {
        display: inline-block;
        margin-bottom: 10px
    }
    .widget-primary-card [class*=col-],
    .widget-success-card [class*=col-] {
        padding: 1em
    }
    .widget-primary-card [class*=col-] h6,
    .widget-success-card [class*=col-] h6 {
        margin-bottom: 0
    }
    .cd-horizontal-timeline .events-content em {
        text-align: left
    }
    .events-content h2 {
        font-size: 20px
    }
    .cd-horizontal-timeline {
        margin: 0 auto
    }
    .social-widget-card i {
        top: 25px;
        font-size: 38px
    }
    .widget-chat-box .card-header i {
        display: none
    }
    .mobile-inputs [class*=col-]:first-child {
        margin-bottom: 20px
    }
    .pagination-lg .page-link {
        padding: .75rem .65rem
    }
    .mobtn {
        padding: 10px
    }
}

@media only screen and (max-width:460px) {
    .fc-toolbar .fc-right .fc-button-group {
        margin-right: 0
    }
    .social-msg a {
        margin-bottom: 10px
    }
    .social-msg a:last-child {
        margin-bottom: 0
    }
}

@media only screen and (max-width:480px) {
    [class*=alert-] code {
        margin-left: 0
    }
    .auth-body {
        min-width: auto
    }
    .breadcrumb-page .card {
        text-align: center
    }
    div.dataTables_wrapper div.dataTables_filter input {
        width: 150px
    }
    #footer-search tfoot .form-control,
    #footer-select tfoot .form-control,
    #form-input-table .form-control,
    .search-api .form-control,
    #dt-live-dom .form-control {
        width: 70%
    }
    .grid figure {
        min-width: auto
    }
    .mytooltip .tooltip-content {
        width: 310px;
        margin: 0 0 20px -90px
    }
    .mytooltip .tooltip-content:after {
        margin-left: -90px
    }
    .cd-timeline-content .media {
        display: inherit !important;
        padding-left: 20px
    }
    .cd-timeline-content .media .media-left {
        text-align: center;
        margin: 10px auto
    }
    .summernote-page .modal-footer {
        display: inline-block;
        text-align: center
    }
    .summernote-page .modal-footer>:not(:first-child) {
        margin-top: 10px;
        margin-left: 0
    }
    .product-detail .txt-muted.d-inline-block {
        margin-top: 10px
    }
    .product-detail .txt-muted.d-inline-block~.f-right {
        float: none;
        display: block;
        margin-top: 10px
    }
    .mob-product-btn {
        text-align: center
    }
    .mob-product-btn .btn-primary,
    .mob-product-btn .sweet-alert button.confirm,
    .sweet-alert .mob-product-btn button.confirm,
    .mob-product-btn .wizard>.actions a,
    .wizard>.actions .mob-product-btn a {
        margin-right: 0;
        display: block;
        margin: 10px auto
    }
}

@media only screen and (max-width:576px) {
    .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
    .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
    .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
    .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
        min-width: 280px;
        left: 0;
        right: auto;
        width: auto
    }
    .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
    .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
        left: auto;
        right: 0
    }
    .nav-tabs .nav-link.active {
        box-shadow: 0 0 1px #1abc9c;
        margin-bottom: 5px
    }
    .md-tabs .nav-item a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
    .main-menu .main-menu-content .nav-item .tree-1 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
    .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
    .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
    .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
    .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a {
        box-shadow: 0 0 0 1px #d2cece;
        margin-bottom: 5px
    }
}

@media only screen and (min-width:801px) and (max-width:915px) {
    .grid figure {
        min-width: auto
    }
}

@media only screen and (min-width:1200px) and (max-width:1258px) {
    .user-head {
        text-align: center
    }
    .user-head .user-face {
        margin: 0 auto
    }
    .user-head .user-name {
        display: none
    }
}

@media only screen and (min-width:1279px) and (max-width:1500px) {
    .cd-horizontal-timeline {
        margin: 0 auto
    }
    #chartdiv {
        height: 440px
    }
    .social-card-1 .card-block-big {
        padding: 1.3em
    }
    #morris-extra-area {
        height: 400px !important
    }
    .widget-chat-box .send-chat,
    .widget-chat-box .receive-chat,
    .widget-chat-box .receive-chat {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden
    }
}

body.dark-layout {
    background-color: #333;
    transition: all linear .3s
}

body.dark-layout .header-navbar .navbar-wrapper,
body.dark-layout .jstree-default .jstree-clicked,
body.dark-layout .prod-item .prod-info,
body.dark-layout .filter-bar>.navbar,
body.dark-layout #task-container li {
    background-color: #1b1b1b
}

body.dark-layout .main-menu {
    background: #242424
}

body.dark-layout .main-menu .main-menu-header,
body.dark-layout .nav-tabs .nav-link.active,
body.dark-layout .page-link:hover,
body.dark-layout .location-selector .bit:hover,
body.dark-layout .ms-container .ms-selectable li.disabled,
body.dark-layout .ms-container .ms-selection li.disabled,
body.dark-layout .j-forms .divider-text span,
body.dark-layout .j-pro .j-divider-text span,
body.dark-layout .handsontable th,
body.dark-layout .cd-timeline-content,
body.dark-layout .email-card .user-body .page-list li.active,
body.dark-layout .email-card .user-body .page-list li:hover,
body.dark-layout .email-card .mail-body-content tr:hover,
body.dark-layout #external-events .fc-event {
    background: #000
}

body.dark-layout .card {
    background: #292929;
    border-top-color: #555;
    color: #fff
}

body.dark-layout .card h5 {
    color: #a3a3a3
}

body.dark-layout .well,
body.dark-layout .page-link,
body.dark-layout .table-bordered td,
body.dark-layout .table-bordered th,
body.dark-layout .grid-layout span,
body.dark-layout .vertical-alignment .row,
body.dark-layout .location-selector,
body.dark-layout pre[class*=language-],
body.dark-layout .icon-list-demo i,
body.dark-layout .ms-container .ms-list,
body.dark-layout .ms-container .ms-selectable li.ms-elem-selectable,
body.dark-layout .ms-container .ms-selection li.ms-elem-selection,
body.dark-layout .wizard>.content,
body.dark-layout .j-forms,
body.dark-layout .j-pro,
body.dark-layout .table-bordered,
body.dark-layout table.dataTable td.select-checkbox:before,
body.dark-layout table.dataTable th.select-checkbox:before,
body.dark-layout .email-summernote~.note-frame,
body.dark-layout .thumb-block,
body.dark-layout #task-container li {
    border-color: #555
}

body.dark-layout .cd-timeline::before,
body.dark-layout .cd-timeline-content::before,
body.dark-layout .main-timeline:before,
body.dark-layout .main-timeline:after {
    background: #555
}

body.dark-layout .card-footer,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    background: #292929;
    color: #fff
}

body.dark-layout .table-card .row-table .br,
body.dark-layout .social-card-1 .card-footer [class*=col-],
body.dark-layout .email-card .user-body,
body.dark-layout .messages-content,
body.dark-layout .note-card .note-box-aside {
    border-right-color: #555
}

body.dark-layout .table-card .row-table:first-child,
body.dark-layout .to-do-list,
body.dark-layout .modal-header,
body.dark-layout .email-card .user-body .page-list li,
body.dark-layout .search-content {
    border-bottom-color: #555
}

body.dark-layout .table td,
body.dark-layout .table th,
body.dark-layout .card-footer,
body.dark-layout .tab-below,
body.dark-layout .chat-reply-box,
body.dark-layout .panel-footer,
body.dark-layout .modal-footer,
body.dark-layout .j-forms .footer,
body.dark-layout .j-forms .divider,
body.dark-layout .j-forms .divider-text,
body.dark-layout .j-pro .j-footer,
body.dark-layout .footable-details.table>tbody>tr>td,
body.dark-layout .footable-details.table>tbody>tr>th,
body.dark-layout .footable-details.table>tfoot>tr>td,
body.dark-layout .footable-details.table>tfoot>tr>th,
body.dark-layout .footable-details.table>thead>tr>td,
body.dark-layout .footable-details.table>thead>tr>th,
body.dark-layout .footable.table>tbody>tr>td,
body.dark-layout .footable.table>tbody>tr>th,
body.dark-layout .footable.table>tfoot>tr>td,
body.dark-layout .footable.table>tfoot>tr>th,
body.dark-layout .footable.table>thead>tr>td,
body.dark-layout .footable.table>thead>tr>th,
body.dark-layout .write-msg,
body.dark-layout .version .nav li:first-child {
    border-top-color: #555
}

body.dark-layout .p-chat-user,
body.dark-layout .showChat_inner,
body.dark-layout .invoice-table {
    border-left-color: #555
}

body.dark-layout .table>thead>tr>th,
body.dark-layout .userlist-box,
body.dark-layout .card-header,
body.dark-layout .nav-tabs,
body.dark-layout .chat-inner-header {
    border-bottom-color: #555
}

body.dark-layout .widget-chat-box .send-chat,
body.dark-layout .widget-chat-box .receive-chat,
body.dark-layout .widget-chat-box .receive-chat {
    background: #333
}

body.dark-layout .widget-chat-box .send-chat:before,
body.dark-layout .widget-chat-box .receive-chat:before,
body.dark-layout .widget-chat-box .receive-chat:before {
    border-top-color: #333;
    border-right-color: #333
}

body.dark-layout .nav-tabs .nav-item.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .nav-link.active {
    border-color: #555 #555 #555;
    color: #fff
}

body.dark-layout .tab-below .active {
    border-top: none
}

body.dark-layout text {
    fill: #fff
}

body.dark-layout .main-menu .main-menu-content .nav-item.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-1 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-1 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-1 a .tree-1 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a .tree-2 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a .tree-3 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a.has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a .tree-4 a.has-class>a,
body.dark-layout .card .card-header .card-header-right,
body.dark-layout code,
body.dark-layout .card .card-block code,
body.dark-layout .fb-timeliner,
body.dark-layout .version .nav li:hover {
    background: #1b1b1b
}

body.dark-layout .main-menu .main-menu-content .nav-item a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-1 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 a a,
body.dark-layout .sub-title,
body.dark-layout .breadcrumb-title a,
body.dark-layout h4.m-b-30,
body.dark-layout code,
body.dark-layout .card .card-block code,
body.dark-layout .clock-widget .bg-alarm,
body.dark-layout .clock-widget .basic-alarm,
body.dark-layout .nav-tabs .nav-item a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a a,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a a,
body.dark-layout .dark-nav-border a,
body.dark-layout .primary-nav-dark a,
body.dark-layout .warning-nav-dark a,
body.dark-layout .success-nav-dark a,
body.dark-layout .info-nav-dark a,
body.dark-layout .danger-nav-dark a,
body.dark-layout .page-link,
body.dark-layout .main-body .page-wrapper .page-header-title h4,
body.dark-layout .modal-title,
body.dark-layout .close,
body.dark-layout .icon-list-demo i,
body.dark-layout .form-radio label,
body.dark-layout .ms-container .ms-selectable li.ms-elem-selectable,
body.dark-layout .ms-container .ms-selection li.ms-elem-selection,
body.dark-layout .j-forms .divider-text span,
body.dark-layout .j-pro .j-label,
body.dark-layout .j-pro .j-divider-text span,
body.dark-layout .j-pro .j-icon-left,
body.dark-layout .j-pro .j-icon-right,
body.dark-layout .j-pro .j-radio,
body.dark-layout .j-pro .j-checkbox,
body.dark-layout .j-pro .j-radio-toggle,
body.dark-layout .j-pro .j-checkbox-toggle,
body.dark-layout .handsontable th,
body.dark-layout .left-icon-control .form-icon,
body.dark-layout .offline-404 h1,
body.dark-layout .cd-timeline-content .cd-date,
body.dark-layout ul.list-contacts .list-group-item a,
body.dark-layout .port_detail_next_search a,
body.dark-layout .email-card .mail-body-content .unread a,
body.dark-layout .email-card .user-mail h6,
body.dark-layout .navbar-light .navbar-nav .nav-link,
body.dark-layout h4.m-b-30,
body.dark-layout .thead-default th,
body.dark-layout .fc-state-default,
body.dark-layout .light-nav-border a,
body.dark-layout .primary-nav a,
body.dark-layout .warning-nav a,
body.dark-layout .success-nav a,
body.dark-layout .info-nav a,
body.dark-layout .danger-nav a,
body.dark-layout .primary-nav a,
body.dark-layout .warning-nav a,
body.dark-layout .success-nav a,
body.dark-layout .info-nav a,
body.dark-layout .danger-nav a {
    color: #a3a3a3
}

body.dark-layout .nav-tabs .nav-link:focus,
body.dark-layout .nav-tabs .nav-link:hover {
    border-color: #555 #555 #555
}

body.dark-layout .widget-chat-box .card-header i,
body.dark-layout .card .card-header span,
body.dark-layout .main-menu .main-menu-content .tree-1.open .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-2 .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-3 .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-4 .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-2 .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-3 .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-4 .has-class>a,
body.dark-layout .main-menu .main-menu-content .tree-2.open .has-class>a,
body.dark-layout .main-menu .main-menu-content .tree-3.open .has-class>a,
body.dark-layout .main-menu .main-menu-content .tree-4.open .has-class>a,
body.dark-layout .main-menu .main-menu-content .nav-title,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a,
body.dark-layout .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a,
body.dark-layout .mega-menu-title,
body.dark-layout .form-group label,
body.dark-layout .chat-header,
body.dark-layout .cd-horizontal-timeline .events a,
body.dark-layout .borderless-card [class*=breadcrumb-] h5,
body.dark-layout .borderless-card [class*=breadcrumb-] a,
body.dark-layout .form-control,
body.dark-layout .dropdown-item,
body.dark-layout .pignose-calendar .pignose-calendar-top .pignose-calendar-top-year,
body.dark-layout .nav-tabs .nav-item.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .primary-nav-dark a,
body.dark-layout .warning-nav-dark a,
body.dark-layout .success-nav-dark a,
body.dark-layout .info-nav-dark a,
body.dark-layout .danger-nav-dark a,
body.dark-layout .primary-nav a,
body.dark-layout .warning-nav a,
body.dark-layout .success-nav a,
body.dark-layout .info-nav a,
body.dark-layout .danger-nav a,
body.dark-layout .accordion-msg,
body.dark-layout .card .card-block p,
body.dark-layout .chat-inner-header,
body.dark-layout .right-icon-control .form-icon,
body.dark-layout .jstree-default .jstree-clicked,
body.dark-layout .close:hover,
body.dark-layout .modal-body,
body.dark-layout .j-forms input[type=text],
body.dark-layout .j-forms input[type=password],
body.dark-layout .j-forms input[type=email],
body.dark-layout .j-forms input[type=search],
body.dark-layout .j-forms input[type=url],
body.dark-layout .j-forms textarea,
body.dark-layout .j-forms select,
body.dark-layout .j-pro input[type=text],
body.dark-layout .j-pro input[type=password],
body.dark-layout .j-pro input[type=email],
body.dark-layout .j-pro input[type=search],
body.dark-layout .j-pro input[type=url],
body.dark-layout .j-pro textarea,
body.dark-layout .j-pro select,
body.dark-layout .note-editor.note-frame .note-editing-area .note-editable,
body.dark-layout .email-card .mail-body-content .read a,
body.dark-layout #external-events .fc-event,
body.dark-layout #ta {
    color: #fff
}

body.dark-layout .main-menu .main-menu-content .tree-1.open .nav-sub-item>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-2 .nav-sub-item>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-3 .nav-sub-item>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-4 .nav-sub-item>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-2 .nav-sub-item>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-3 .nav-sub-item>a,
body.dark-layout .main-menu .main-menu-content .nav-item .open.tree-4 .nav-sub-item>a {
    background: #2d2d2d
}

body.dark-layout .morphsearch.open .morphsearch-form,
body.dark-layout .dummy-media-object,
body.dark-layout .card .card-block .dropdown-menu,
body.dark-layout .clock-widget,
body.dark-layout .pignose-calendar,
body.dark-layout .pignose-calendar .pignose-calendar-top,
body.dark-layout .nav-tabs .nav-item.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-1 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a.show .nav-link,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .nav-tabs a.show .nav-link,
body.dark-layout .alert-danger,
body.dark-layout .alert-warning,
body.dark-layout .alert-info,
body.dark-layout .alert-success,
body.dark-layout .alert-primary,
body.dark-layout .alert-default,
body.dark-layout .showChat_inner,
body.dark-layout .chat-reply-box,
body.dark-layout .modal-content,
body.dark-layout .location-selector .bit,
body.dark-layout .j-forms,
body.dark-layout .j-forms .footer,
body.dark-layout .j-pro,
body.dark-layout .j-pro .j-footer,
body.dark-layout .handsontable th,
body.dark-layout .handsontable td,
body.dark-layout .offline-404,
body.dark-layout .dropdown-menu,
body.dark-layout .email-card .user-body {
    background: #292929
}

body.dark-layout .morphsearch-close::before,
body.dark-layout .morphsearch-close::after {
    background: #999
}

body.dark-layout .morphsearch.open,
body.dark-layout .morphsearch-content,
body.dark-layout .theme-loader,
body.dark-layout .form-control,
body.dark-layout .md-tabs .nav-item a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-1 .md-tabs a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a,
body.dark-layout .well,
body.dark-layout .panel-footer,
body.dark-layout .panel,
body.dark-layout .page-link,
body.dark-layout .location-selector,
body.dark-layout pre[class*=language-],
body.dark-layout .bootstrap-tagsinput,
body.dark-layout .select2-container--default .select2-selection--multiple,
body.dark-layout .ms-container .ms-selectable,
body.dark-layout .ms-container .ms-selection,
body.dark-layout .wizard>.steps .disabled a,
body.dark-layout .wizard>.steps .disabled a:hover,
body.dark-layout .wizard>.steps .disabled a:active,
body.dark-layout .wizard>.content,
body.dark-layout .j-forms input[type=text],
body.dark-layout .j-forms input[type=password],
body.dark-layout .j-forms input[type=email],
body.dark-layout .j-forms input[type=search],
body.dark-layout .j-forms input[type=url],
body.dark-layout .j-forms textarea,
body.dark-layout .j-forms select,
body.dark-layout .j-pro input[type=text],
body.dark-layout .j-pro input[type=password],
body.dark-layout .j-pro input[type=email],
body.dark-layout .j-pro input[type=search],
body.dark-layout .j-pro input[type=url],
body.dark-layout .j-pro textarea,
body.dark-layout .j-pro select,
body.dark-layout .footable-details.table-striped>tbody>tr:nth-child(odd),
body.dark-layout .footable.table-striped>tbody>tr:nth-child(odd),
body.dark-layout .main-timeline,
body.dark-layout .dropdown-item:focus,
body.dark-layout .dropdown-item:hover,
body.dark-layout .list-group-item,
body.dark-layout .note-editor.note-frame .note-editing-area .note-editable,
body.dark-layout #main-chat .chat-single-box.active .chat-header,
body.dark-layout .post-input,
body.dark-layout .msg-send,
body.dark-layout .note-editor.note-frame .note-statusbar,
body.dark-layout #ta,
body.dark-layout .thead-default th,
body.dark-layout .invoice-total,
body.dark-layout .fc-state-default,
body.dark-layout .jFiler-input-dragDrop,
body.dark-layout .jFiler-theme-default .jFiler-input,
body.dark-layout .light-nav-border,
body.dark-layout .primary-nav,
body.dark-layout .warning-nav,
body.dark-layout .success-nav,
body.dark-layout .info-nav,
body.dark-layout .danger-nav,
body.dark-layout .primary-nav,
body.dark-layout .warning-nav,
body.dark-layout .success-nav,
body.dark-layout .info-nav,
body.dark-layout .danger-nav {
    background: #333
}

body.dark-layout .md-tabs .nav-item a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
.main-menu .main-menu-content .nav-item .tree-1 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
.main-menu .main-menu-content .nav-item .tree-2 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
.main-menu .main-menu-content .nav-item .tree-3 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
.main-menu .main-menu-content .nav-item .tree-4 body.dark-layout .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-1 a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-1 .md-tabs a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-2 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2 .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-3 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3 .md-tabs a a,
body.dark-layout .md-tabs .main-menu .main-menu-content .nav-item .tree-4 a a,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4 .md-tabs a a {
    border-radius: 0
}

body.dark-layout .ball-scale>div {
    background: #fff
}

body.dark-layout .tree-1,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-2,
.main-menu .main-menu-content .nav-item body.dark-layout .tree-2,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-3,
.main-menu .main-menu-content .nav-item body.dark-layout .tree-3,
body.dark-layout .main-menu .main-menu-content .nav-item .tree-4,
.main-menu .main-menu-content .nav-item body.dark-layout .tree-4,
body.dark-layout .tree-2,
body.dark-layout .tree-3,
body.dark-layout .tree-4 {
    background-color: #2d2d2d !important
}

body.dark-layout .page-item.disabled .page-link {
    color: #a3a3a3;
    background-color: #333;
    border-color: #555
}

body.dark-layout .ct-grid {
    stroke: rgba(255, 255, 255, .2)
}

body.dark-layout .ct-label.ct-vertical.ct-start,
body.dark-layout .ct-label.ct-horizontal.ct-end {
    color: rgba(255, 255, 255, .38)
}

body.dark-layout .img-tabs a {
    opacity: 1
}

body.dark-layout pre[class*=language-] code {
    background-color: #000 !important
}

body.dark-layout .bg-white {
    background-color: #000 !important
}

body.dark-layout code[class*=language-] {
    text-shadow: none
}

body.dark-layout #reportrange {
    background: #333 !important
}

body.dark-layout .chat-bg {
    background: rgba(1, 1, 1, .69)
}

.pcoded .pcoded-container {
    position: relative;
    background: #d5dae6
}

.pcoded .pcoded-header {
    position: relative;
    display: block
}

.pcoded .pcoded-navbar {
    display: block
}

.pcoded-main-container {
    display: block;
    position: relative;
    background: #e9e9e9;
    min-height: calc(100vh - 56px)
}

.pcoded .pcoded-content {
    position: relative;
    display: block
}

.pcoded-inner-navbar {
    display: block;
    position: relative
}

.pcoded-inner-content {
    padding: 10px
}

.pcoded .pcoded-navbar .pcoded-item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item>li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item>li>a {
    display: block;
    font-size: 14px;
    padding: 0 15px;
    text-decoration: none;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    list-style: outside none none;
    margin: 0;
    padding: 0
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
    display: block;
    font-size: 14px;
    font-weight: 400;
    padding: 0 15px;
    text-decoration: none;
    position: relative
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-micon {
    font-size: 10px;
    padding-right: 5px
}

.pcoded .pcoded-navbar .pcoded-item>li>a>.pcoded-micon {
    font-size: 15px;
    padding-right: 10px
}

.pcoded-inner-navbar {
    height: 100%
}

.pcoded[theme-layout=vertical] .pcoded-header {
    width: 100%;
    box-shadow: 2px 6px 6px -8px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 2px 6px 6px -8px rgba(0, 0, 0, .2);
    -moz-box-shadow: 2px 6px 6px -8px rgba(0, 0, 0, .2)
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header {
    display: block;
    z-index: 1028
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header .pcoded-logo {
    opacity: 1;
    text-align: center;
    visibility: visible;
    height: 50px;
    white-space: nowrap
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header .pcoded-logo a {
    display: block;
    text-decoration: none;
    padding: 9px 0
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header .pcoded-logo .logo-icon {
    bottom: -21px;
    font-size: 60px;
    left: 8px;
    position: absolute;
    z-index: -1;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-icon {
    font-size: 45px;
    bottom: -7px;
    left: 5px;
    transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -ms-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear
}

.pcoded[theme-layout=vertical][pcoded-device-type=desktop] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text,
.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text,
.pcoded[theme-layout=vertical][pcoded-device-type=tablet] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text {
    font-family: open sans;
    font-size: 16px;
    font-weight: 600;
    left: 60px;
    margin: 0 auto !important;
    opacity: 1;
    position: absolute;
    text-transform: uppercase;
    top: 25px;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    visibility: visible
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text,
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-header .pcoded-left-header .pcoded-logo .logo-text {
    left: 15px;
    top: 13px
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .pcoded-left-header .pcoded-logo .logo-text {
    font-family: open sans;
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto !important;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    position: relative;
    top: 5px
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo .logo-text {
    opacity: 0;
    position: absolute;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    visibility: hidden
}

.pcoded[theme-layout=vertical][vertical-nav-type=offcanvas] .pcoded-header.iscollapsed .pcoded-left-header .pcoded-logo {
    opacity: 0;
    visibility: hidden;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg)
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-header .pcoded-left-header .pcoded-logo a .hide-in-smallsize {
    display: none
}

.pcoded[theme-layout=vertical] .pcoded-header .sidebar_toggle a {
    border-radius: 4px;
    float: left;
    font-size: 18px;
    height: 35px;
    margin-right: 5px;
    position: relative;
    text-align: center;
    top: 7px;
    width: 40px;
    border-width: 0;
    border-style: solid
}

.pcoded[theme-layout=vertical] .pcoded-header .sidebar_toggle a i {
    position: relative;
    top: -7px
}

.pcoded[theme-layout=vertical] .pcoded-header .sidebar_toggle a:hover {
    text-decoration: none
}

.sidebar_toggle a:focus {
    text-decoration: none
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header {
    line-height: 50px;
    padding: 0 15px
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    float: left
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header {
    float: right
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    padding-left: 20px
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    padding-left: 0
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header ul {
    list-style: outside none none;
    margin: 0;
    padding: 0
}

.pcoded-right-header .pcoded-rr-header ul {
    list-style: outside none none;
    margin: 0;
    padding: 0
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header ul>li,
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header ul>li {
    float: left;
    list-style: outside none none;
    margin: 0;
    padding: 0 5px;
    position: relative
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header ul>li>a {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header li.icons>a>i,
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header li.icons>a>i {
    font-size: 18px;
    position: relative;
    top: 3px
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rl-header li.icons>a,
.pcoded[theme-layout=vertical] .pcoded-header .pcoded-right-header .pcoded-rr-header li.icons>a {
    padding: 0 5px
}

.pcoded[theme-layout=vertical] .pcoded-right-header .pcoded-badge {
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    min-height: 17px;
    min-width: 10px;
    opacity: 1;
    padding: 4px 7px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: 9px;
    transition: opacity .3s linear 0s;
    -webkit-transition: opacity .3s linear 0s;
    -ms-transition: opacity .3s linear 0s;
    -moz-transition: opacity .3s linear 0s;
    -o-transition: opacity .3s linear 0s;
    vertical-align: middle;
    visibility: visible;
    white-space: nowrap
}

.pcoded[theme-layout=vertical] .pcoded-badge.badge-success {
    background-color: #70ca63;
    color: #fff
}

.pcoded[theme-layout=vertical] .pcoded-badge.badge-warning {
    background-color: #fecd33;
    color: #fff
}

.pcoded[theme-layout=vertical] .pcoded-badge.badge-danger {
    background-color: #f13b48;
    color: #fff
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .hide-small-device {
    display: none
}

.pcoded[theme-layout=vertical] .pcoded-navbar {
    z-index: 1027;
    height: 100%
}

.pcoded[theme-layout=vertical] .pcoded-content {
    position: relative;
    display: block
}

.pcoded[theme-layout=vertical] .pcoded-container {
    overflow: hidden;
    position: relative;
    margin: 0 auto
}

.pcoded[theme-layout=vertical].pcoded-wrapper,
.pcoded[theme-layout=vertical] .pcoded-main-container {
    position: relative;
    margin: 0 auto
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-search {
    border-top-width: 1px;
    border-top-style: solid;
    padding: 20px 10px;
    position: relative
}

.pcoded[theme-layout=vertical][vnavigation-view=view2] .pcoded-navbar .pcoded-search {
    border-radius: 4px 4px 0 0
}

.pcoded-search input[type=text] {
    display: block;
    margin: 0;
    width: 100%;
    font-family: open sans, sans-serif;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: none;
    -moz-border-radius: none;
    -ms-border-radius: none;
    -o-border-radius: none;
    border-radius: none;
    background: 0 0;
    padding: 6px 22px 6px 3px;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    -webkit-transition: border .3s;
    -moz-transition: border .3s;
    -o-transition: border .3s;
    transition: border .3s
}

.pcoded-search input[type=text]:focus {
    border-bottom-width: 1px;
    border-bottom-style: solid
}

.style-4 input[type=text].focus {
    border-bottom-width: 1px;
    border-bottom-style: solid
}

.pcoded-search .search-icon {
    cursor: pointer;
    margin-top: -33px;
    padding: 4px;
    position: absolute;
    right: 10px
}

.pcoded[vertical-nav-type=collapsed] .pcoded-search-box {
    visibility: hidden;
    opacity: 0;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    position: absolute
}

.pcoded[vertical-nav-type=collapsed] .pcoded-search input[type=text] {
    padding: 10px 22px 10px 3px
}

.searchbar-toggle:before {
    content: "\f002";
    cursor: pointer;
    float: right;
    font-family: FontAwesome;
    left: 0;
    position: absolute;
    top: 10px;
    visibility: hidden;
    opacity: 0;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg)
}

.pcoded[vertical-nav-type=collapsed] .searchbar-toggle:before {
    left: 15px;
    top: 10px;
    visibility: visible;
    opacity: 1;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg)
}

.pcoded[vertical-nav-type=collapsed] .pcoded-search.open .searchbar-toggle:before {
    content: "\f05c"
}

.pcoded[vertical-nav-type=collapsed] .pcoded-search.open .pcoded-search-box {
    margin-left: 35px;
    opacity: 1;
    position: absolute;
    top: 0;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    visibility: visible;
    width: 200px;
    padding-right: 10px
}

.pcoded[vertical-nav-type=collapsed] .pcoded-search .search-icon {
    margin-top: -35px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li>a {
    text-align: left;
    padding: 10px 15px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    position: relative;
    width: 100%
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
    text-align: left;
    padding: 8.7px 10px
}

.pcoded[theme-layout=vertical][vertical-nav-type=expanded] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
    padding-left: 30px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    width: 100%;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
    -webkit-transform-origin: 0 0;
    transform-origin: 10% 10%;
    -webkit-transition: -webkit-transform .5s, opacity .5s;
    transition: transform .5s, opacity .5s
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform: rotateX(-90deg);
    -webkit-transform: rotateX(-90deg)
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg)
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=dashed] li>a {
    border-bottom-style: dashed
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=dotted] li>a {
    border-bottom-style: dotted
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=solid] li>a {
    border-bottom-style: solid
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true][item-border-style=none] li>a {
    border: none
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=false]>li>a {
    border-bottom-width: 0
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[item-border=true]>li>a {
    border-bottom-width: 1px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[subitem-border=false] .pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-width: 0
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item[subitem-border=true] .pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-width: 1px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu>a:after {
    float: right;
    font-style: normal;
    margin-left: 3px;
    font-size: 10px;
    position: absolute;
    right: 15px;
    top: 0;
    padding-top: 10px;
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after {
    float: right;
    font-style: normal;
    margin-left: 3px;
    font-size: 10px;
    position: absolute;
    right: 15px;
    top: 0;
    padding-top: 13px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    float: right;
    font-style: normal;
    margin-left: 3px;
    position: absolute;
    right: 15px;
    font-size: 12px;
    top: 0;
    padding-top: 10px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after {
    float: right;
    font-style: normal;
    margin-left: 3px;
    position: absolute;
    right: 15px;
    font-size: 12px;
    top: 0;
    padding-top: 13px;
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style1]>a:after {
    content: "\e606";
    font-family: simple-line-icons
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style1]>a:after {
    content: "\e604";
    font-family: simple-line-icons
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style2]>a:after {
    content: "\e095";
    font-family: simple-line-icons
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style2]>a:after {
    content: "\e615";
    font-family: simple-line-icons
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style3]>a:after {
    content: '\e61a';
    font-family: themify;
    font-size: 14px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style3]>a:after {
    content: '\e622';
    font-family: themify;
    font-size: 14px
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header[pcoded-lheader-position=relative] {
    position: relative
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header[pcoded-lheader-position=fixed] {
    position: fixed
}

.pcoded[theme-layout=vertical] .pcoded-header .pcoded-left-header[pcoded-lheader-position=absolute] {
    position: absolute
}

.pcoded[theme-layout=vertical] .pcoded-navbar[pcoded-navbar-position=relative] {
    position: relative
}

.pcoded[theme-layout=vertical] .pcoded-navbar[pcoded-navbar-position=fixed] {
    position: fixed
}

.pcoded[theme-layout=vertical] .pcoded-navbar[pcoded-navbar-position=absolute] {
    position: absolute
}

.pcoded[theme-layout=vertical] .pcoded-header[pcoded-header-position=relative] {
    position: relative
}

.pcoded[theme-layout=vertical] .pcoded-header[pcoded-header-position=fixed] {
    position: fixed;
    z-index: 1028
}

.pcoded[theme-layout=vertical] .pcoded-header[pcoded-header-position=absolute] {
    position: absolute
}

.pcoded[theme-layout=vertical][vertical-layout=wide] .pcoded-container {
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=box] .pcoded-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][pcoded-device-type=tablet][vertical-layout=box] .pcoded-container {
    max-width: 900px;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-layout=box] .pcoded-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][vertical-layout=widebox] .pcoded-container {
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=widebox] .pcoded-main-container,
.pcoded[theme-layout=vertical][pcoded-device-type=desktop][vertical-layout=widebox] .pcoded-wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][pcoded-device-type=tablet][vertical-layout=widebox] .pcoded-main-container,
.pcoded[theme-layout=vertical][pcoded-device-type=tablet][vertical-layout=widebox] .pcoded-wrapper {
    max-width: 900px;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-layout=widebox] .pcoded-main-container,
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-layout=widebox] .pcoded-wrapper {
    max-width: 100%;
    width: 100%;
    margin: 0 auto
}

.pcoded[theme-layout=vertical][vertical-layout=widebox] .pcoded-wrapper {
    overflow: hidden
}

.pcoded .pcoded-overlay-box {
    background: #000 none repeat scroll 0 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    overflow: hidden
}

.pcoded[vertical-nav-type=compact][vertical-effect=overlay] .pcoded-overlay-box,
.pcoded[vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-overlay-box,
.pcoded[vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-overlay-box,
.pcoded[vertical-nav-type=fullsub-collapsed][vertical-effect=overlay] .pcoded-overlay-box,
.pcoded[vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-overlay-box {
    visibility: visible;
    z-index: 1026;
    opacity: .3;
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    overflow: hidden
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-micon {
    display: none;
    font-size: 10px;
    padding-right: 5px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    font-style: normal;
    padding-right: 4px;
    font-size: 10px;
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style1] .pcoded-submenu li>a .pcoded-mtext:before {
    content: "\f105";
    font-family: FontAwesome;
    padding-right: 5px;
    font-size: 12px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style2] .pcoded-submenu li>a .pcoded-mtext:before {
    content: "\f22d";
    font-family: FontAwesome;
    padding-right: 5px;
    font-size: 12px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style3] .pcoded-submenu li>a .pcoded-mtext:before {
    content: "\e08d";
    font-family: simple-line-icons
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style4] .pcoded-submenu li>a .pcoded-mtext:before {
    content: "\f124";
    font-family: FontAwesome;
    padding-right: 10px;
    font-size: 12px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style5] .pcoded-submenu li>a .pcoded-mtext:before {
    content: "\f0da";
    font-family: FontAwesome;
    padding-right: 10px;
    font-size: 12px
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu[subitem-icon=style6] .pcoded-submenu li>a .pcoded-mtext:before {
    content: '\e649';
    font-family: themify;
    padding-right: 10px;
    font-size: 12px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
    background: transparent none repeat scroll 0 0;
    border-bottom: 10px solid transparent;
    border-right: 8px solid #e9e9e9;
    border-top: 10px solid transparent;
    display: block;
    float: right;
    height: 0;
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    width: 0
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li:hover>a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0
}

.pcoded .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li.active>a:before {
    border-left-style: solid;
    border-left-width: 5px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar {
    float: right;
    right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-content {
    float: unset;
    left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-navbar {
    float: left
}

.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-content {
    float: unset;
    right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-left-header {
    float: right;
    right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-right-header {
    float: unset;
    left: unset
}

.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-header .pcoded-left-header {
    float: left
}

.pcoded[theme-layout=vertical][vertical-placement=left] .pcoded-header .pcoded-right-header {
    float: unset;
    right: unset
}

.pcoded[theme-layout=vertical][vertical-nav-type=expanded] .pcoded-header .pcoded-left-header,
.pcoded[theme-layout=vertical][vertical-nav-type=expanded] .pcoded-navbar {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=push] .pcoded-content {
    margin-left: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=push] .pcoded-content {
    margin-right: 235px;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-content {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=shrink] .pcoded-content {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][vertical-effect=overlay] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-effect=overlay] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
    background: 0 0 !important;
    border-bottom: transparent !important;
    border-right: transparent !important;
    border-top: 15px solid transparent
}

.pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 230px;
    z-index: 1024;
    top: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 45px;
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-content {
    margin-left: 45px;
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 45px;
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=push] .pcoded-content {
    margin-right: 45px;
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-content {
    margin-left: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=shrink] .pcoded-content {
    margin-right: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-content {
    margin-left: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed][vertical-effect=overlay] .pcoded-content {
    margin-right: 45px
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item>li>a .pcoded-mtext {
    opacity: 1;
    visibility: visible;
    position: absolute
}

.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item li.pcoded-hasmenu>a:after {
    opacity: 1;
    visibility: visible
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li>a .pcoded-mtext {
    opacity: 0;
    visibility: hidden;
    position: absolute
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li.pcoded-trigger>a .pcoded-mtext {
    opacity: 1;
    visibility: visible;
    position: absolute;
    padding-left: 22px
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item li.pcoded-hasmenu>a:after {
    opacity: 0;
    visibility: hidden
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger a:after {
    opacity: 1;
    visibility: visible
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li.pcoded-trigger {
    display: block;
    position: relative;
    width: 255px;
    z-index: 1024
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: 45px;
    position: absolute;
    width: 210px;
    z-index: 1024;
    border-left-style: solid;
    border-left-width: 1px
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    position: relative
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>.pcoded-hasmenu>.pcoded-submenu .pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    position: absolute
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li.pcoded-trigger.active>a>.pcoded-mcaret {
    display: none
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0;
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-navbar {
    width: 235px;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-content {
    margin-left: 0;
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0;
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-navbar {
    width: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=push] .pcoded-content {
    margin-right: 0;
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-navbar {
    width: 235px;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-navbar {
    width: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=shrink] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-navbar {
    width: 235px;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-navbar {
    width: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=offcanvas][vertical-effect=overlay] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 190px;
    margin-right: -190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-left: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-navbar {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=push] .pcoded-content {
    margin-left: 190px;
    margin-right: -190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 190px;
    margin-left: -190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-right: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-navbar {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=push] .pcoded-content {
    margin-right: 190px;
    margin-left: -190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-left: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-navbar {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-content {
    margin-left: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-right: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-navbar {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=shrink] .pcoded-content {
    margin-right: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-left: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-navbar {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-right: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-navbar {
    width: 190px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=compact][vertical-effect=overlay] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li>a {
    line-height: unset;
    text-align: center
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li>a .pcoded-mtext {
    opacity: 1;
    position: relative;
    text-align: center;
    visibility: visible
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li>a>.pcoded-micon {
    display: block;
    font-size: 18px;
    padding: 5px
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
    background: transparent none repeat scroll 0 0;
    border-bottom: 18px solid transparent;
    border-right: 12px solid #e9e9e9;
    border-top: 18px solid transparent;
    top: 18px
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li.active>a:before {
    height: 100%
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li:hover>a:before {
    height: 100%
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style1]>a:after,
.pcoded[theme-layout=vertical][vertical-nav-type=compact] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu[dropdown-icon=style1]>a:after {
    top: 18px
}

.pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=desktop] .pcoded-navbar.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=compact][pcoded-device-type=tablet] .pcoded-navbar.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 210px;
    z-index: 1024;
    top: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-header .pcoded-left-header,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-content {
    margin-left: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=push] .pcoded-content {
    margin-right: 235px;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-content {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-navbar {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=shrink] .pcoded-content {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-navbar {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=sub-expanded][vertical-effect=overlay] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-inner-navbar,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>li,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-inner-navbar,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>li {
    position: unset
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 230px;
    z-index: 1024;
    top: 0;
    bottom: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
    position: unset
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 230px;
    z-index: 1024;
    top: 0;
    bottom: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-style: solid;
    border-left-width: 1px
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-header.iscollapsed .pcoded-left-header,
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-content {
    margin-left: 235px;
    margin-right: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=push] .pcoded-content {
    margin-right: 235px;
    margin-left: -235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-content {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header.iscollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=shrink] .pcoded-content {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-content {
    margin-left: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-header.iscollapsed .pcoded-right-header {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=ex-popover][vertical-effect=overlay] .pcoded-content {
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>li,
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>li {
    position: relative
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 230px;
    z-index: 1024;
    top: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li {
    position: relative
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger .pcoded-submenu {
    left: 100%;
    position: absolute;
    width: 230px;
    z-index: 1024;
    top: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret,
.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    background: transparent none repeat scroll 0 0;
    border-bottom: 19px solid transparent;
    border-right: 12px solid;
    border-top: 19px solid transparent;
    display: block;
    float: right;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    z-index: 1025
}

.pcoded[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-style: solid;
    border-left-width: 1px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=fullpage] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=fullpage] .pcoded-header .pcoded-right-header {
    margin-left: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=left][vertical-nav-type=fullpage] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar {
    width: 100%
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-content {
    margin-left: 0;
    margin-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=fullpage] .pcoded-header .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=fullpage] .pcoded-header .pcoded-right-header {
    margin-right: 235px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=fullpage] .pcoded-header.iscollapsed .pcoded-left-header {
    width: 235px
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    opacity: 1;
    visibility: visible;
    width: 100%
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li.active>a {
    background: 0 0 !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item[item-border=true]>li>a {
    border-bottom-width: 0 !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[pcoded-navbar-position=absolute] {
    position: absolute
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[pcoded-navbar-position=fixed] {
    position: fixed
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-inner-navbar {
    margin-top: 40px
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-main-container {
    display: block;
    position: unset
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li>a {
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 24px;
    font-weight: 500
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li>a .pcoded-mtext {
    opacity: 1;
    position: relative;
    visibility: visible
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li>a>.pcoded-micon {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-navigatio-lavel {
    display: none !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li.active>a:before,
.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li a .pcoded-badge {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li:hover>a {
    background: 0 0 !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li:hover>a:before {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li>a {
    padding: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .sidebar_toggle {
    height: 30px
}

.pcoded-navbar .sidebar_toggle a {
    opacity: 1;
    visibility: visible;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    position: absolute
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .sidebar_toggle a {
    color: #f1f1f1;
    float: right;
    font-size: 36px;
    opacity: 1;
    position: relative;
    right: 10px;
    text-decoration: none;
    top: 0;
    transition: opacity .8s linear 0s;
    -webkit-transition: opacity .8s linear 0s;
    -ms-transition: opacity .8s linear 0s;
    -moz-transition: opacity .8s linear 0s;
    -o-transition: opacity .8s linear 0s;
    visibility: visible;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg)
}

.pcoded-navbar .sidebar_toggle a:hover {
    color: #fff
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu>a:after {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item>li.pcoded-trigger>a {
    background: 0 0 !important;
    border-bottom-color: transparent !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu {
    background: 0 0 !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item[subitem-border=true] .pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-width: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
    text-align: center;
    font-size: 24px;
    font-weight: 500
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    display: none
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: 0 0 !important
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-width: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar .pcoded-item .pcoded-submenu>li.active>a {
    background: 0 0 !important;
    color: #fff !important
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .pcoded-left-header {
    width: 100% !important;
    padding-left: 235px
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=collapsed] .pcoded-header.nocollapsed .pcoded-left-header {
    padding-left: 45px
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-effect=overlay] .pcoded-header.nocollapsed .pcoded-left-header,
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=fullpage] .pcoded-header.nocollapsed .pcoded-left-header,
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=offcanvas] .pcoded-header.nocollapsed .pcoded-left-header {
    padding-left: 0
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed {
    height: 100px !important
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .pcoded-right-header {
    padding: 50px 5px 0 !important
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-effect=overlay] .pcoded-header.nocollapsed .pcoded-right-header,
.pcoded[theme-layout=vertical][pcoded-device-type=phone][vertical-nav-type=offcanvas] .pcoded-header.nocollapsed .pcoded-right-header {
    margin: 0 !important
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .pcoded-header.nocollapsed .sidebar_toggle a {
    background: transparent none repeat scroll 0 0;
    border-radius: 4px;
    color: #fff;
    float: left;
    font-size: 18px;
    height: 35px;
    margin-right: 5px;
    position: fixed;
    right: 0;
    text-align: center;
    top: 6px;
    width: 40px;
    z-index: 1028
}

.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] .pcoded-navbar {
    top: 0;
    z-index: 1030
}

.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] .pcoded-main-container {
    position: unset
}

.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] #styleSelector {
    top: 100px
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    opacity: 1;
    padding: 10px 5px 2px;
    text-transform: uppercase;
    visibility: visible;
    width: 100%;
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear;
    border-bottom: 2px solid
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel:before {
    content: "\e6e7";
    font-family: themify;
    margin-right: 10px
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme1] {
    color: #2a394f !important
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme2] {
    color: #624e89 !important
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme3] {
    color: #3c4fb1 !important
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme4] {
    color: #2c3e50 !important
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme5] {
    color: #2ed8b6 !important
}

.pcoded .pcoded-navbar .pcoded-navigatio-lavel[menu-title-theme=theme6] {
    color: #3f5485 !important
}

.pcoded[vertical-nav-type=collapsed] .pcoded-navbar .pcoded-navigatio-lavel {
    opacity: 0;
    visibility: hidden;
    transform: rotateX(90deg);
    -webkit-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -o-transform: rotateX(90deg);
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear
}

.pcoded[vertical-nav-type=compact] .pcoded-navbar .pcoded-navigatio-lavel {
    text-align: center
}

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge {
    position: absolute;
    right: 30px;
    text-align: center;
    top: 12px;
    vertical-align: middle;
    white-space: nowrap;
    opacity: 1;
    visibility: visible;
    transition: opacity .3s linear
}

.pcoded[vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li>a .pcoded-badge {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear
}

.pcoded[vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li.pcoded-trigger>a .pcoded-badge {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s linear;
    -webkit-transition: opacity .3s linear;
    -ms-transition: opacity .3s linear;
    -moz-transition: opacity .3s linear;
    -o-transition: opacity .3s linear
}

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge.badge-success {
    color: #fff;
    background-color: #70ca63
}

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge.badge-danger {
    color: #fff;
    background-color: #f13b48
}

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge.badge-warning {
    color: #000;
    background-color: #fecd33
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li.active>a:before {
    left: auto;
    right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li.active>a>.pcoded-mcaret {
    background: transparent none repeat scroll 0 0;
    border-bottom: 15px solid transparent;
    border-left: 10px solid #e9e9e9;
    border-top: 15px solid transparent;
    left: 0;
    right: auto;
    top: 4px;
    border-right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li>a .pcoded-micon i {
    float: right;
    position: absolute;
    right: 0;
    top: 11px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu>a:after {
    left: 8px;
    right: auto;
    top: -1px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li>a .pcoded-mtext {
    left: auto;
    right: 0;
    padding-right: 45px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li a .pcoded-badge {
    position: relative;
    right: -1px;
    top: -2px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li:hover>a:before {
    left: auto;
    right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    left: auto;
    right: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a {
    text-align: right
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    float: right;
    left: 6px;
    top: 3px;
    padding-right: 10px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li .pcoded-submenu li>a .pcoded-badge {
    left: 23px;
    position: absolute;
    right: auto;
    top: 11px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-navigatio-lavel {
    text-align: right
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li.pcoded-hasmenu[dropdown-icon=style1]>a:after {
    content: "\e605";
    font-family: simple-line-icons
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item li.pcoded-hasmenu.pcoded-trigger[dropdown-icon=style1]>a:after {
    content: "\e604";
    font-family: simple-line-icons
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=desktop] .pcoded-navbar.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.is-hover.pcoded-trigger .pcoded-submenu,
.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=expanded][pcoded-device-type=tablet] .pcoded-navbar.is-hover .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: auto;
    right: 100%
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu>a:after {
    left: 7px;
    right: auto;
    top: 0
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>li.pcoded-trigger {
    left: auto;
    right: 210px
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    left: auto
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-navbar .pcoded-item li a .pcoded-badge {
    position: absolute;
    right: auto;
    top: 10px
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-right-header .pcoded-rl-header {
    float: right
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .pcoded-right-header .pcoded-rr-header {
    float: left
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-header .sidebar_toggle {
    float: right
}

.pcoded[theme-layout=vertical][vertical-placement=right][vertical-nav-type=collapsed] .pcoded-search.open .pcoded-search-box {
    right: 0
}

body[themebg-pattern=pattern1] {
    background-image: url(../images/pattern1.png)
}

body[themebg-pattern=pattern2] {
    background-image: url(../images/pattern2.png)
}

body[themebg-pattern=pattern3] {
    background-image: url(../images/pattern3.png)
}

body[themebg-pattern=pattern4] {
    background-image: url(../images/pattern4.png)
}

body[themebg-pattern=pattern5] {
    background-image: url(../images/pattern5.png)
}

body[themebg-pattern=pattern6] {
    background-image: url(../images/pattern6.png)
}

body[themebg-pattern=pattern7] {
    background-image: url(../images/pattern7.png)
}

body[themebg-pattern=pattern8] {
    background-image: url(../images/pattern8.png)
}

body[themebg-pattern=pattern9] {
    background-image: url(../images/pattern9.png)
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li.active>a {
    color: #ff5e3a !important
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #ff5e3a !important
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li:hover>a {
    color: #ff5e3a !important;
    background: #ff5e3a
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item>li.active>a:before {
    border-left-color: #ff5e3a !important
}

.pcoded .pcoded-navbar[active-item-theme=theme1] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li .pcoded-submenu li.active>a {
    color: #0096b1 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #0096b1 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li:hover>a {
    color: #0096b1 !important;
    background: #0096b1
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item>li.active>a:before {
    border-left-color: #0096b1 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme2] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li .pcoded-submenu li.active>a {
    color: #e64056 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #e64056 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li:hover>a {
    color: #e64056 !important;
    background: #e64056
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item>li.active>a:before {
    border-left-color: #e64056 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme3] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li .pcoded-submenu li.active>a {
    color: #0073aa !important
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #0073aa !important
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li:hover>a {
    color: #0073aa !important;
    background: #0073aa
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item>li.active>a:before {
    border-left-color: #0073aa !important
}

.pcoded .pcoded-navbar[active-item-theme=theme4] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li .pcoded-submenu li.active>a {
    color: #1abc9c !important
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #1abc9c !important
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li:hover>a {
    color: #1abc9c !important;
    background: #1abc9c
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item>li.active>a:before {
    border-left-color: #1abc9c !important
}

.pcoded .pcoded-navbar[active-item-theme=theme5] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li .pcoded-submenu li.active>a {
    color: #967adc !important
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #967adc !important
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li:hover>a {
    color: #967adc !important;
    background: #967adc
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item>li.active>a:before {
    border-left-color: #967adc !important
}

.pcoded .pcoded-navbar[active-item-theme=theme6] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li .pcoded-submenu li.active>a {
    color: #fe9375 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #fe9375 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li:hover>a {
    color: #fe9375 !important;
    background: #fe9375
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item>li.active>a:before {
    border-left-color: #fe9375 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme7] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li .pcoded-submenu li.active>a {
    color: #565a90 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li.pcoded-hasmenu.active>a {
    background: #565a90 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li.pcoded-hasmenu:hover>a {
    color: #fff !important
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li:hover>a {
    color: #565a90 !important;
    background: #565a90
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item li:hover>a:before {
    border-left-color: transparent !important
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item>li.active>a:before {
    border-left-color: #565a90 !important
}

.pcoded .pcoded-navbar[active-item-theme=theme8] .pcoded-item>li.active>a:after {
    color: #fff !important
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme1] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme1] .sidebar_toggle a {
    background: 0 0;
    color: #fff;
    border-color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme1] .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme1] .pcoded-right-header .pcoded-rr-header ul>li>a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme1] {
    background: 0 0
}

.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme1] {
    background: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] {
    background: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .main-menu {
    background-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .main-menu .main-menu-header {
    background-color: #3c5170
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li>a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.active>a {
    background: #2a394f !important;
    color: #fff;
    border-bottom-color: #3c5170
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.active>a:before {
    border-left-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li>a {
    border-bottom-color: #3c5170
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-color: #2a394f;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.active:hover>a,
.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li:hover>a {
    background: #2a394f;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: #2a394f;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a {
    color: #fff;
    background: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:before {
    border-bottom-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:after {
    border-left-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    border-bottom-color: #ffff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li:hover>a:before {
    border-left-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-trigger>a {
    background: #2a394f;
    border-bottom-color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-trigger.active>a {
    background: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger.active>a:after {
    color: #2a394f
}

.pcoded .pcoded-navbar[navbar-theme=theme1] .pcoded-navigatio-lavel {
    color: #2a394f;
    border-bottom-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme1] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme1] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme1] .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    border-right-color: #2a394f !important
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme1] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme1] {
    background-color: #2a394f
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme1] .pcoded-item>li:hover>a {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme1] .sidebar_toggle a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme2] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme2] .sidebar_toggle a {
    background: 0 0;
    color: #fff;
    border-color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme2] .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme2] .pcoded-right-header .pcoded-rr-header ul>li>a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme2] {
    background: 0 0
}

.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme2] {
    background: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] {
    background: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .main-menu {
    background-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .main-menu .main-menu-header {
    background-color: #495871
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li>a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.active>a {
    background: #354052 !important;
    color: #fff;
    border-bottom-color: #495871
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.active>a:before {
    border-left-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li>a {
    border-bottom-color: #495871
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-color: #354052;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.active:hover>a,
.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li:hover>a {
    background: #354052;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: #354052;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a {
    color: #fff;
    background: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:before {
    border-bottom-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:after {
    border-left-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    border-bottom-color: #ffff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li:hover>a:before {
    border-left-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-trigger>a {
    background: #354052;
    border-bottom-color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-trigger.active>a {
    background: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger.active>a:after {
    color: #354052
}

.pcoded .pcoded-navbar[navbar-theme=theme2] .pcoded-navigatio-lavel {
    color: #354052;
    border-bottom-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme2] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme2] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme2] .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    border-right-color: #354052 !important
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme2] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme2] {
    background-color: #354052
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme2] .pcoded-item>li:hover>a {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme2] .sidebar_toggle a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme3] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme3] .sidebar_toggle a {
    background: 0 0;
    color: #fff;
    border-color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme3] .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme3] .pcoded-right-header .pcoded-rr-header ul>li>a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme3] {
    background: 0 0
}

.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme3] {
    background: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] {
    background: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .main-menu {
    background-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .main-menu .main-menu-header {
    background-color: #7a64a6
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li>a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.active>a {
    background: #624e89 !important;
    color: #fff;
    border-bottom-color: #7a64a6
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.active>a:before {
    border-left-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li>a {
    border-bottom-color: #7a64a6
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-color: #624e89;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.active:hover>a,
.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li:hover>a {
    background: #624e89;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: #624e89;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a {
    color: #fff;
    background: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:before {
    border-bottom-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:after {
    border-left-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    border-bottom-color: #ffff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li:hover>a:before {
    border-left-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-trigger>a {
    background: #624e89;
    border-bottom-color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-trigger.active>a {
    background: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger.active>a:after {
    color: #624e89
}

.pcoded .pcoded-navbar[navbar-theme=theme3] .pcoded-navigatio-lavel {
    color: #624e89;
    border-bottom-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme3] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme3] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme3] .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    border-right-color: #624e89 !important
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme3] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme3] {
    background-color: #624e89
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme3] .pcoded-item>li:hover>a {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme3] .sidebar_toggle a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme4] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme4] .sidebar_toggle a {
    background: 0 0;
    color: #fff;
    border-color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme4] .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme4] .pcoded-right-header .pcoded-rr-header ul>li>a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme4] {
    background: 0 0
}

.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme4] {
    background: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] {
    background: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .main-menu {
    background-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .main-menu .main-menu-header {
    background-color: #3e5871
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li>a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.active>a {
    background: #2c3e50 !important;
    color: #fff;
    border-bottom-color: #3e5871
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.active>a:before {
    border-left-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li>a {
    border-bottom-color: #3e5871
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-color: #2c3e50;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.active:hover>a,
.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li:hover>a {
    background: #2c3e50;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: #2c3e50;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a {
    color: #fff;
    background: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:before {
    border-bottom-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:after {
    border-left-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    border-bottom-color: #ffff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li:hover>a:before {
    border-left-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-trigger>a {
    background: #2c3e50;
    border-bottom-color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-trigger.active>a {
    background: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger.active>a:after {
    color: #2c3e50
}

.pcoded .pcoded-navbar[navbar-theme=theme4] .pcoded-navigatio-lavel {
    color: #2c3e50;
    border-bottom-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme4] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme4] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme4] .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    border-right-color: #2c3e50 !important
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme4] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme4] {
    background-color: #2c3e50
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme4] .pcoded-item>li:hover>a {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme4] .sidebar_toggle a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme5] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme5] .sidebar_toggle a {
    background: 0 0;
    color: #fff;
    border-color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme5] .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme5] .pcoded-right-header .pcoded-rr-header ul>li>a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme5] {
    background: 0 0
}

.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme5] {
    background: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] {
    background: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .main-menu {
    background-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .main-menu .main-menu-header {
    background-color: #28e1bd
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li>a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.active>a {
    background: #1abc9c !important;
    color: #fff;
    border-bottom-color: #28e1bd
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.active>a:before {
    border-left-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li>a {
    border-bottom-color: #28e1bd
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-color: #1abc9c;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.active:hover>a,
.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li:hover>a {
    background: #1abc9c;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: #1abc9c;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a {
    color: #fff;
    background: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:before {
    border-bottom-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:after {
    border-left-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    border-bottom-color: #ffff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li:hover>a:before {
    border-left-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-trigger>a {
    background: #1abc9c;
    border-bottom-color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-trigger.active>a {
    background: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger.active>a:after {
    color: #1abc9c
}

.pcoded .pcoded-navbar[navbar-theme=theme5] .pcoded-navigatio-lavel {
    color: #1abc9c;
    border-bottom-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme5] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme5] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme5] .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    border-right-color: #1abc9c !important
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme5] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme5] {
    background-color: #1abc9c
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme5] .pcoded-item>li:hover>a {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme5] .sidebar_toggle a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme6] {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme6] .sidebar_toggle a {
    background: 0 0;
    color: #fff;
    border-color: #fff
}

.pcoded .pcoded-navbar .pcoded-header[header-theme=theme6] .pcoded-right-header .pcoded-rl-header ul>li>a,
.pcoded .pcoded-navbar .pcoded-header[header-theme=theme6] .pcoded-right-header .pcoded-rr-header ul>li>a {
    color: #fff
}

.pcoded .pcoded-navbar .pcoded-header.nocollapsed .pcoded-left-header[lheader-theme=theme6] {
    background: 0 0
}

.pcoded .pcoded-navbar .pcoded-header.iscollapsed .pcoded-left-header[lheader-theme=theme6] {
    background: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] {
    background: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .main-menu {
    background-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .main-menu .main-menu-header {
    background-color: #596bc7
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li>a {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.active>a {
    background: #3c4fb1 !important;
    color: #fff;
    border-bottom-color: #596bc7
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.active>a:before {
    border-left-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li>a {
    border-bottom-color: #596bc7
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item li.pcoded-hasmenu .pcoded-submenu {
    background: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item li.pcoded-hasmenu .pcoded-submenu li>a {
    border-bottom-color: #3c4fb1;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger .pcoded-submenu li.pcoded-hasmenu.pcoded-trigger>a:after,
.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.active:hover>a,
.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li:hover>a {
    background: #3c4fb1;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a {
    background: #3c4fb1;
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li:hover>a:before {
    border-left-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a {
    color: #fff;
    background: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:before {
    border-bottom-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li.active>a .pcoded-mtext:after {
    border-left-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:before {
    border-bottom-color: #ffff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item .pcoded-hasmenu .pcoded-submenu li>a .pcoded-mtext:after {
    border-left-color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li:hover>a:before {
    border-left-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-trigger>a {
    background: #3c4fb1;
    border-bottom-color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-trigger.active>a {
    background: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu .pcoded-submenu li.pcoded-hasmenu:hover>a:after {
    color: #fff
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li.pcoded-hasmenu.pcoded-trigger.active>a:after {
    color: #3c4fb1
}

.pcoded .pcoded-navbar[navbar-theme=theme6] .pcoded-navigatio-lavel {
    color: #3c4fb1;
    border-bottom-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=collapsed] .pcoded-navbar[navbar-theme=theme6] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu,
.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=sub-expanded] .pcoded-navbar[navbar-theme=theme6] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme6] .pcoded-item li.pcoded-trigger>a>.pcoded-mcaret {
    border-right-color: #3c4fb1 !important
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=ex-popover] .pcoded-navbar[navbar-theme=theme6] .pcoded-item>.pcoded-hasmenu.pcoded-trigger>.pcoded-submenu {
    border-left-color: transparent
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme6] {
    background-color: #3c4fb1
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme6] .pcoded-item>li:hover>a {
    color: #fff
}

.pcoded .pcoded-navbar[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-navbar[navbar-theme=theme6] .sidebar_toggle a {
    color: #fff
}

.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-navbar,
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-navbar {
    margin-top: 10px;
    border-radius: 4px 4px 0 0
}

.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-navbar .profile-box,
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-navbar .profile-box {
    border-radius: 4px 4px 0 0
}

.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-header,
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=desktop] .pcoded-main-container,
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-header,
.pcoded[theme-layout=vertical][vnavigation-view=view2][pcoded-device-type=tablet] .pcoded-main-container {
    padding-left: 10px
}

.pcoded[theme-layout=vertical][vnavigation-view=view2][vertical-layout=widebox][pcoded-device-type=desktop] .pcoded-header .pcoded-wrapper,
.pcoded[theme-layout=vertical][vnavigation-view=view2][vertical-layout=widebox][pcoded-device-type=tablet] .pcoded-header .pcoded-wrapper {
    padding-left: 5px
}

.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-navbar {
    top: 0
}

.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-main-container {
    position: unset
}

.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-header .pcoded-left-header {
    display: none
}

.pcoded #styleSelector {
    min-height: 300px;
    height: 80vh;
    border: 1px solid #e9e9e9;
    background: #fff;
    position: fixed;
    margin: 0;
    padding: 20px;
    width: 300px;
    top: 10vh;
    z-index: 100;
    transition: .5s;
    -webkit-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s
}

.pcoded #styleSelector.open {
    background: #fff none repeat scroll 0 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2)
}

.pcoded[theme-layout=horizontal] #styleSelector,
.pcoded[vertical-placement=left] #styleSelector {
    right: -300px
}

.pcoded[theme-layout=horizontal] #styleSelector.open,
.pcoded[vertical-placement=left] #styleSelector.open {
    right: 0
}

.pcoded[vertical-placement=right] #styleSelector {
    left: -300px
}

.pcoded[vertical-placement=right] #styleSelector.open {
    left: 0
}

.selector-toggle {
    position: relative
}

.pcoded .selector-toggle>a {
    border: 2px solid #51cdb3;
    position: absolute;
    top: 200px;
    width: 60px;
    height: 66px;
    display: block;
    cursor: pointer;
    text-align: center;
    background: #2d3e50;
    color: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
    padding: 14px 4px
}

.pcoded[theme-layout=horizontal] .selector-toggle>a,
.pcoded[vertical-placement=left] .selector-toggle>a {
    left: -80px;
    border-right: 0;
    border-radius: 4px 0 0 4px
}

.pcoded[vertical-placement=right] .selector-toggle>a {
    right: -80px;
    border-left: 0;
    border-radius: 0 4px 4px 0
}

.selector-toggle>a:before {
    font-family: themify;
    font-size: 30px;
    content: "\e60f";
    position: relative;
    float: left;
    left: 13px;
    line-height: 35px;
    transition: .5s;
    -webkit-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    animation: mymove 1.3s infinite linear
}

.open .selector-toggle>a:before {
    font-family: themify;
    font-size: 30px;
    content: "\e646";
    position: relative;
    float: left;
    left: 13px;
    line-height: 35px;
    transition: .5s;
    -webkit-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    animation: mymove 0s infinite linear
}

#styleSelector .nav-tabs {
    margin: 20px 0
}

#styleSelector .nav-tabs .nav-item,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-1 a,
.main-menu .main-menu-content .nav-item .tree-1 #styleSelector .nav-tabs a,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-2 a,
.main-menu .main-menu-content .nav-item .tree-2 #styleSelector .nav-tabs a,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-3 a,
.main-menu .main-menu-content .nav-item .tree-3 #styleSelector .nav-tabs a,
#styleSelector .nav-tabs .main-menu .main-menu-content .nav-item .tree-4 a,
.main-menu .main-menu-content .nav-item .tree-4 #styleSelector .nav-tabs a {
    width: calc(100%/3);
    margin: 0;
    padding: 0;
    float: left
}

#styleSelector .nav-tabs .nav-item a,
.main-menu .main-menu-content .nav-item .tree-1 #styleSelector .nav-tabs a a,
.main-menu .main-menu-content .nav-item .tree-2 #styleSelector .nav-tabs a a,
.main-menu .main-menu-content .nav-item .tree-3 #styleSelector .nav-tabs a a,
.main-menu .main-menu-content .nav-item .tree-4 #styleSelector .nav-tabs a a {
    color: #1abe9b;
    text-align: center;
    border-radius: 0;
    background-color: #fff;
    border: none;
    transition: all .3s ease-in-out;
    border-bottom: 3px solid #1ebb9e
}

#styleSelector .nav-tabs .nav-item a.active,
.main-menu .main-menu-content .nav-item .tree-1 #styleSelector .nav-tabs a a.active,
.main-menu .main-menu-content .nav-item .tree-2 #styleSelector .nav-tabs a a.active,
.main-menu .main-menu-content .nav-item .tree-3 #styleSelector .nav-tabs a a.active,
.main-menu .main-menu-content .nav-item .tree-4 #styleSelector .nav-tabs a a.active {
    color: #fff;
    background-color: #2d3e50
}

#styleSelector .form-control {
    border: none;
    box-shadow: none;
    background-color: #f1f1f1;
    margin-bottom: 10px
}

#styleSelector .form-control:focus {
    border: none;
    background-color: #f1f1f1
}

#styleSelector ul {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%
}

#styleSelector ul li {
    border-bottom: 0 solid #e9e9e9;
    display: block;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%
}

#styleSelector ul li p.selector-title,
#styleSelector ul li span.selector-title {
    color: #444;
    font-size: 14px;
    margin: 0 0 20px;
    padding: 10px 0;
    text-align: left
}

#styleSelector ul li p.selector-title {
    font-weight: 400;
    border-bottom: 1px solid #e9e9e9
}

#styleSelector ul li p.selector-title.main-title {
    font-weight: 600
}

#styleSelector ul li p.sub-title.drp-title {
    border-bottom: 0 solid transparent;
    margin-bottom: 0;
    margin-top: 10px
}

#styleSelector>ul>li>.sub-title {
    color: #9c9c9c;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 5px 0;
    position: relative;
    text-align: left
}

#styleSelector li {
    padding: 5px
}

.theme-option select {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 3px 10px
}

@-moz-document url-prefix() {
    .theme-option select {
        background-color: #f1f1f1;
        border: 1px solid #ccc;
        border-radius: 2px;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 3px 10px;
        margin: 0;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none
    }
    .theme-option select.minimal {
        background-image: -webkit-linear-gradient(45deg, transparent 50%, gray 50%), -webkit-linear-gradient(315deg, gray 50%, transparent 50%), -webkit-linear-gradient(left, #ccc, #ccc);
        background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
        background-position: calc(100% - 18px) calc(1em + -1px), calc(100% - 13px) calc(1em + -1px), calc(100% - 2.5em) 4px;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat
    }
    .theme-option select.minimal:active,
    .theme-option select.minimal:focus {
        background-image: -webkit-linear-gradient(45deg, gray 50%, transparent 50%), -webkit-linear-gradient(315deg, transparent 50%, gray 50%), -webkit-linear-gradient(left, gray, gray);
        background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%), linear-gradient(to right, gray, gray);
        background-position: calc(100% - 15px) 13px, calc(100% - 20px) 13px, calc(100% - 2.5em) .3em;
        background-size: 5px 5px, 5px 5px, 1px 1.5em;
        background-repeat: no-repeat;
        border-color: #66afe9;
        outline: 0
    }
}

.theme-color {
    padding: 0;
    width: 100%
}

.theme-color a {
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 5px 10px;
    box-shadow: .4px .9px 3px 0 rgba(0, 0, 0, .33)
}

.theme-color a:first-child {
    margin-left: 0
}

.theme-color a:focus,
.theme-color a:hover {
    text-decoration: none
}

.theme-color a[lheader-theme=theme1] {
    background: #2a394f
}

.theme-color a[lheader-theme=theme2] {
    background: #624e89
}

.theme-color a[lheader-theme=theme3] {
    background: #3c4fb1
}

.theme-color a[lheader-theme=theme4] {
    background: #2c3e50
}

.theme-color a[lheader-theme=theme5] {
    background: #2ed8b6
}

.theme-color a[lheader-theme=theme6] {
    background: #3f5485
}

.theme-color a[header-theme=theme1] {
    background: #2a394f
}

.theme-color a[header-theme=theme2] {
    background: #624e89
}

.theme-color a[header-theme=theme3] {
    background: #3c4fb1
}

.theme-color a[header-theme=theme4] {
    background: #2c3e50
}

.theme-color a[header-theme=theme5] {
    background: #1abc9c
}

.theme-color a[header-theme=theme6] {
    background: #3f5485
}

.theme-color a[navbar-theme=theme1] {
    background: #2a394f
}

.theme-color a[navbar-theme=theme2] {
    background: #354052
}

.theme-color a[navbar-theme=theme3] {
    background: #624e89
}

.theme-color a[navbar-theme=theme4] {
    background: #2c3e50
}

.theme-color a[navbar-theme=theme5] {
    background: #1abc9c
}

.theme-color a[navbar-theme=theme6] {
    background: #3c4fb1
}

.theme-color a[active-item-theme=theme1] {
    background: #ff5e3a
}

.theme-color a[active-item-theme=theme2] {
    background: #0096b1
}

.theme-color a[active-item-theme=theme3] {
    background: #e64056
}

.theme-color a[active-item-theme=theme4] {
    background: #0073aa
}

.theme-color a[active-item-theme=theme5] {
    background: #1abc9c
}

.theme-color a[active-item-theme=theme6] {
    background: #967adc
}

.theme-color a[active-item-theme=theme7] {
    background: #fe9375
}

.theme-color a[active-item-theme=theme8] {
    background: #565a90
}

.theme-color a[sub-item-theme=theme1] {
    background: #70ca63
}

.theme-color a[sub-item-theme=theme2] {
    background: #3498db
}

.theme-color a[sub-item-theme=theme3] {
    background: #485bbd
}

.theme-color a[sub-item-theme=theme4] {
    background: #e7604a
}

.theme-color a[sub-item-theme=theme5] {
    background: #333843
}

.theme-color a[sub-item-theme=theme6] {
    background: #624e89
}

.theme-color a[sub-item-theme=theme7] {
    background: #fff
}

.theme-color a[sub-item-theme=theme8] {
    background: #384b5f
}

.theme-color a[sub-item-theme=theme9] {
    background: #578ebe
}

.theme-color a[themebg-pattern=pattern1] {
    background-image: url(../images/pattern1.png)
}

.theme-color a[themebg-pattern=pattern2] {
    background-image: url(../images/pattern2.png)
}

.theme-color a[themebg-pattern=pattern3] {
    background-image: url(../images/pattern3.png)
}

.theme-color a[themebg-pattern=pattern4] {
    background-image: url(../images/pattern4.png)
}

.theme-color a[themebg-pattern=pattern5] {
    background-image: url(../images/pattern5.png)
}

.theme-color a[themebg-pattern=pattern6] {
    background-image: url(../images/pattern6.png)
}

.theme-color a[themebg-pattern=pattern7] {
    background-image: url(../images/pattern7.png)
}

.theme-color a[themebg-pattern=pattern8] {
    background-image: url(../images/pattern8.png)
}

.theme-color a[themebg-pattern=pattern9] {
    background-image: url(../images/pattern9.png)
}

.sparkline-chart {
    text-align: center
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .spark-chart,
.pcoded[theme-layout=vertical][pcoded-device-type=tablet] .spark-chart {
    display: none
}

.spark-chart-title {
    text-align: center
}

.content-title {
    margin-bottom: 10px
}

.main-title {
    color: #424242;
    font-size: 22px;
    font-weight: 400;
    margin: 0
}

.small-text {
    font-size: 14px
}

.info-widget .info-box-stats {
    float: left;
    margin-top: 5px
}

.info-widget .info-box-stats p {
    font-size: 28px;
    color: #666;
    font-weight: 300
}

.info-widget .info-box-stats span.info-box-title {
    display: block;
    font-size: 13px;
    margin-bottom: 10px;
    color: #a4a4a4
}

.info-widget .info-box-icon {
    position: absolute;
    right: 30px
}

.info-widget .info-box-icon i {
    font-size: 45px;
    color: #fff
}

.info-widget .info-box-progress {
    clear: both
}

.info-widget .progress {
    margin: 0
}

.progress-xs {
    height: 5px
}

.progress-squared {
    border-radius: 0
}

.progress-bar {
    background-color: #7a6fbe
}

.progress-bar-success {
    background-color: #22baa0
}

.progress-bar-info {
    background-color: #12afcb
}

.progress-bar-warning {
    background-color: #f6d433
}

.progress-bar-danger {
    background-color: #f25656
}

.info-widget .earnings-panel-theme .info-box-icon i {
    color: #6a7bdb
}

.info-widget .balance-panel-theme .info-box-icon i {
    color: #f7786c
}

.info-widget .total-earnings-panel-theme .info-box-icon i {
    color: #36bcf8
}

.info-widget .item-sales-panel-theme .info-box-icon i {
    color: #65d5a4
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    padding: 8px 0;
    margin-bottom: 10px
}

.pcoded[theme-layout=vertical][pcoded-device-type=phone] .breadcrumb {
    display: none
}

.top-country-panel .country-name {
    color: #7e7e7e;
    font-size: 14px;
    font-weight: 400
}

.top-country-panel .earning {
    color: #7e7e7e;
    float: right;
    font-size: 15px
}

.top-country-panel .list-group {
    border: 1px solid #d1d1d1;
    border-radius: 4px
}

.top-country-panel .list-group-item {
    background-color: #fff;
    border: 0 solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    display: block;
    margin-bottom: 0;
    padding: 7px 15px;
    position: relative
}

.top-country-panel .list-group-item:last-child {
    border-bottom: 0 solid #f1f1f1
}

.top-country-panel .top-country-title {
    font-size: 16px;
    margin: 0;
    padding: 3px 0
}

.top-country-panel .img-thumbnail {
    background-color: #fff;
    border: 0 solid #ddd;
    border-radius: 0;
    margin-right: 5px;
    padding: 6px 8px 8px;
    position: relative;
    top: 3px
}

.table-container .table {
    border: 0 solid #f1f1f1;
    margin-bottom: 0
}

.table-container .table tr th {
    border-bottom: 1px solid #f1f1f1;
    font-size: 13px;
    font-weight: 600;
    background: #00bcd4;
    color: #fff
}

.table-container .table>tbody>tr>td,
.table-container .table>tbody>tr>th {
    border-top: 1px solid #f1f1f1;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top
}

.table-container .table>tfoot>tr>td,
.table-container .table>tfoot>tr>th {
    border-top: 1px solid #f1f1f1;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top
}

.table-container .table>thead>tr>td,
.table-container .table>thead>tr>th {
    border-top: 1px solid #f1f1f1;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top
}

.table-container .table>tbody>tr>td,
.table-container .table>tfoot>tr>td,
.table-container .table>thead>tr>td {
    font-family: arial;
    font-size: 13px;
    font-weight: 300
}

.table-container .table>tbody>tr.total>td {
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    font-weight: 600;
    background: #fff
}

.pageview-statistics-panel .statistics-name {
    font-size: 14px
}

.pageview-statistics-panel .value {
    float: right;
    font-size: 15px
}

.pageview-statistics-panel .list-group-item {
    background-color: #00bcd4;
    border: 1px solid #1dcde4;
    display: block;
    margin-bottom: -1px;
    padding: 7px 15px;
    position: relative;
    color: #fff
}

.pageview-statistics-panel .pageview-statistics-title {
    font-size: 18px;
    margin: 0;
    padding: 3px 0
}

.traffic-source-panel .traffic-source-name {
    font-size: 14px
}

.traffic-source-panel .value {
    float: right;
    font-size: 18px;
    position: relative;
    top: -4px
}

.traffic-source-panel .value small {
    font-size: 13px;
    padding-left: 10px;
    color: #f1f1f1
}

.traffic-source-panel .list-group-item {
    background-color: #46be8a;
    border: 1px solid #65d5a4;
    color: #fff;
    display: block;
    margin-bottom: -1px;
    padding: 10px 15px;
    position: relative
}

.traffic-source-panel .traffic-source-title {
    font-size: 18px;
    margin: 0;
    padding: 3px 0
}

.review-panel .review-title {
    color: #666;
    font-size: 14px;
    font-weight: 700
}

.review-panel .user-by {
    float: right;
    font-size: 14px;
    position: absolute;
    right: 12px;
    top: 6px
}

.review-panel .user-by a {
    color: #4d5ec1
}

.review-panel .user-by .time-ago {
    font-size: 13px;
    padding-left: 2px;
    color: #999
}

.review-panel .list-group {
    border: 1px solid #d1d1d1;
    border-radius: 4px
}

.review-panel .list-group-item {
    background-color: #fff;
    border: 0 solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    color: #333;
    display: block;
    margin-bottom: 0;
    padding: 10px 15px;
    position: relative
}

.review-panel .list-group-item:last-child {
    border-bottom: 0 solid #f1f1f1
}

.review-panel .review-heading {
    font-size: 18px;
    margin: 0;
    padding: 3px 0
}

.rate {
    color: #f9ab49
}

.review-panel .review-text {
    color: #7e7e7e;
    font-size: 13px;
    font-weight: 400;
    padding-top: 2px
}

.up-arrow {
    color: #46be8a
}

.down-arrow {
    color: #eb6357
}

.up-arrow i {
    color: #46be8a;
    padding-left: 5px
}

.down-arrow i {
    color: #eb6357;
    padding-left: 5px
}

.comment-panel .user-by {
    color: #666;
    font-size: 14px;
    font-weight: 700
}

.comment-panel .time-ago {
    float: right;
    font-size: 13px;
    position: absolute;
    right: 12px;
    top: 6px;
    color: #999
}

.comment-panel .time-ago a {
    color: #4d5ec1
}

.comment-panel .list-group {
    border: 1px solid #d1d1d1;
    border-radius: 4px
}

.comment-panel .list-group-item {
    background-color: #fff;
    border: 0 solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    color: #333;
    display: block;
    margin-bottom: 0;
    padding: 10px 15px;
    position: relative
}

.comment-panel .list-group-item:last-child {
    border-bottom: 0 solid #f1f1f1
}

.comment-panel .comment-heading {
    font-size: 18px;
    margin: 0;
    padding: 3px 0
}

.comment-panel .comment-text {
    color: #7e7e7e;
    font-size: 13px;
    font-weight: 400;
    padding-top: 2px
}

.comment-panel .user-img img {
    border-radius: 50%;
    height: 35px;
    width: 35px
}

.comment-panel .item-purchase-status {
    background-color: #00bcd4;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    min-width: 10px;
    opacity: 1;
    padding: 3px 7px;
    position: relative;
    text-align: center;
    transition: opacity .3s linear 0s;
    -webkit-transition: opacity .3s linear 0s;
    -ms-transition: opacity .3s linear 0s;
    -moz-transition: opacity .3s linear 0s;
    -o-transition: opacity .3s linear 0s;
    vertical-align: middle;
    visibility: visible;
    white-space: nowrap
}

.comment-panel .comment-item-status {
    padding: 4px 0;
    text-align: right
}

.comment-panel .comment-reply a {
    color: #4285f4;
    font-size: 13px;
    padding-left: 10px
}

.comment-panel p {
    margin: 0 0 2px
}

.item-download-box .progress {
    background-color: #f5f5f5;
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1) inset;
    height: 8px;
    margin-bottom: 10px;
    overflow: hidden
}

.progress-bar {
    box-shadow: 0 0 0 rgba(0, 0, 0, .1) inset;
    color: #fff;
    float: left;
    font-size: 9px;
    height: 100%;
    line-height: 10px;
    text-align: center
}

.item-download-box {
    margin-bottom: 10px
}

.item-download-box .item-name {
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px
}

.item-download-box .item-name img {
    border-radius: 0;
    height: 25px;
    margin-right: 5px;
    width: 25px;
    display: none
}

.item-download-box .count {
    color: #666;
    float: right;
    font-size: 14px;
    font-weight: 500
}

.message-panel .user-by {
    color: #666;
    font-size: 14px;
    font-weight: 600
}

.message-panel .time-ago {
    color: #999;
    float: right;
    font-size: 11px;
    position: absolute;
    right: 8px;
    top: 6px
}

.message-panel .time-ago a {
    color: #4d5ec1
}

.message-panel .list-group {
    border: 1px solid #d1d1d1;
    border-radius: 4px
}

.message-panel .list-group-item {
    background-color: #fff;
    border: 0 solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    color: #333;
    display: block;
    margin-bottom: 0;
    padding: 10px 15px;
    position: relative
}

.message-panel .list-group-item:last-child {
    border-bottom: 0 solid #f1f1f1
}

.message-panel .message-heading {
    font-size: 18px;
    margin: 0;
    padding: 3px 0
}

.message-panel .message-text {
    color: #7e7e7e;
    font-size: 13px;
    font-weight: 400;
    padding-top: 2px
}

.message-panel .user-img img {
    border-radius: 4px;
    height: 30px;
    width: 30px
}

.message-panel .item-purchase-status {
    background-color: #00bcd4;
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    min-width: 10px;
    opacity: 1;
    padding: 3px 7px;
    position: relative;
    text-align: center;
    transition: opacity .3s linear 0s;
    -webkit-transition: opacity .3s linear 0s;
    -ms-transition: opacity .3s linear 0s;
    -moz-transition: opacity .3s linear 0s;
    -o-transition: opacity .3s linear 0s;
    vertical-align: middle;
    visibility: visible;
    white-space: nowrap
}

.message-panel .message-item-status {
    padding: 4px 0;
    text-align: right
}

.message-panel .message-reply a {
    color: #4285f4;
    font-size: 13px;
    padding-left: 10px
}

.message-panel p {
    margin: 0 0 2px
}

.panel-body {
    overflow-x: hidden
}

.pcoded-rr-header .btn {
    font-size: 12px;
    font-weight: 600
}

.pcoded[theme-layout=vertical][vertical-placement=right] .pcoded-navbar .pcoded-item>li>a .pcoded-micon i {
    float: none;
    position: relative;
    z-index: 1051;
    left: 93%;
    top: 1px
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed][vertical-placement=right] .pcoded-navbar .pcoded-item>li>a .pcoded-micon i {
    left: 0
}

.pcoded[theme-layout=vertical][vertical-nav-type=collapsed][vertical-placement=right] .pcoded-navbar .pcoded-item>li.pcoded-hasmenu.pcoded-trigger>a .pcoded-micon i,
.pcoded[theme-layout=vertical][vertical-nav-type=collapsed][vertical-placement=right] .pcoded-navbar .pcoded-item>li.pcoded-trigger.active>a .pcoded-micon i {
    left: 94%
}

.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=desktop] .pcoded-navbar .pcoded-item>li,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li,
.pcoded[theme-layout=vertical][vertical-nav-type=sub-expanded][pcoded-device-type=tablet] .pcoded-navbar .pcoded-item>li {
    position: static
}

.pcoded.nocollapsed[theme-layout=vertical][pcoded-device-type=phone] .pcoded-main-container {
    position: static
}

.pcoded[theme-layout=vertical][vertical-nav-type=fullpage] .pcoded-main-container,
.pcoded[theme-layout=vertical][vnavigation-view=view3] .pcoded-main-container {
    position: static
}

.pcoded .pcoded-header[header-theme=theme1] {
    background: #2a394f
}

.pcoded .pcoded-header[header-theme=theme2] {
    background: #624e89
}

.pcoded .pcoded-header[header-theme=theme3] {
    background: #3c4fb1
}

.pcoded .pcoded-header[header-theme=theme4] {
    background: #2c3e50
}

.pcoded .pcoded-header[header-theme=theme5] {
    background: #1abc9c
}

.pcoded .pcoded-header[header-theme=theme6] {
    background: #3f5485
}

body .pcoded[vertical-placement=right] .users,
body .pcoded[vertical-placement=right] .showChat_inner {
    right: auto;
    left: 0
}

body .pcoded[vertical-placement=right] .main-body {
    direction: rtl
}

body .pcoded[vertical-placement=right] .main-body .page-wrapper .page-header-breadcrumb {
    float: left
}

body .pcoded[vertical-placement=right] .main-body .language-markup {
    direction: ltr
}

body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-logo {
    float: right !important
}

body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container {
    margin-left: 0;
    margin-right: 240px
}

body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-left li,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-right li,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-right li {
    float: right !important
}

body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-left,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: right
}

body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .nav-right {
    float: left
}

body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .header-notification .show-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .show-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification,
body .pcoded[vertical-placement=right] .header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification {
    right: auto;
    left: 0
}

body .pcoded[vertical-nav-type=compact] .main-menu {
    width: 190px
}

body.menu-bottom .navbar-wrapper {
    z-index: 1050
}